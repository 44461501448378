<!-- popular-category -->

<div class="app-container">
    <div class="popular d-flex flex-column">
        <div class="popular-tag d-flex justify-content-md-between justify-content-center">
            <p class="popular-category-heading" translate>Popular In</p>
        </div>




        <ngx-slick-carousel class="carousel popular-img" #slickModal="slick-carousel" [config]="slideConfig">

            <ng-container *ngFor="let list of popularcategory; index as index">

                <a ngxSlickItem [routerLink]="list.url" [queryParams]="list.queryParams" class="content-box-wrapper d-flex flex-column justify-content-center align-items-center" >
                    <div *ngIf="list.image" class="content-box  background-image justify-content-center d-flex align-items-center  {{ index%2 ==0 ? 'green' : 'red'}}"
                        [style.background-image]="'url('+ (list?.image | scaleImage:'format') +')'">
                        <!-- <img [src]="list?.image | scaleImage:'format'" loading="lazy"> -->
                        <!-- <p class="m-0 fw-500">{{list.others}}</p> -->
                    </div>
                    <div class="justify-content-center d-flex align-items-center">
                        <p class="popular-category-items" translate >{{list?.categories}}</p>
                    </div>
                </a>

            </ng-container>

        </ngx-slick-carousel>

    </div>
</div>

<!-- popular-category -->