import { Component, Inject, OnInit } from '@angular/core';
import { DeliveryAddress } from '@app/models';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA } from '@kalgudi/common';
import { KalgudiDialogResult } from '@kalgudi/types';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from 'src/app/project-common/services/spinner.service';

import { AddressService } from '../../services/address.service';

@Component({
  selector: 'address-form-mobile-dialog',
  templateUrl: './address-form-mobile-dialog.component.html',
  styleUrls: ['./address-form-mobile-dialog.component.scss']
})
export class AddressFormMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  addressForm: DeliveryAddress;
  
  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: any,
    protected dialogRef: KalgudiMobileDialogRef,
    private spinner: SpinnerService,
    private addressService: AddressService) { 
      super(dialogRef)
    }

    ngOnInit() {
      this.addressForm = this.dialogData.data.address;
    }

    submitClicked(data) {

      if(!this.dialogData || !this.dialogData.data || !this.dialogData.data.forUserCreation) {

        this.ok();

      } else {

        this.dialogRef.close({ data, accepted: true });

      }

    }

    ok(): void {
      if(this.dialogData && this.dialogData.data && !this.dialogData.data.forUserCreation) {

        this.spinner.start();
        this.addressService.saveAddress(this.addressForm)
          .pipe(finalize(() => this.spinner.stop()))
          .subscribe(response => {
            this.dialogRef.close({ data: response, accepted: true });
          })
      }else {
        
      }
    }


    cancel(): void {
      const result: KalgudiDialogResult = {
        accepted: false,
        data: null
      };
      this.dialogRef.close(result);
    }
}
