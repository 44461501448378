<ng-container *ngIf=" (categories$ | async) as categories">

    <div class="menu-item" (mouseenter)="isCategoriesVisible = true" (mouseleave)="isCategoriesVisible = false">
        <div class="categories" fxLayoutAlign="start center">
            <!-- <span class="ml-10 mr-5" translate>Categories</span> -->
            <span class="title d-flex flex-row ml-10 mr-5 align-item-center">
                <svg-icon src="assets/aboutus/menu-app.svg" [svgStyle]="{'width.px': '15', 'height.px': '15'}"
                    [applyCss]="true" class="mr-9 store-products-icon"></svg-icon> <span translate>Categories</span>
            </span>
            <!-- <mat-icon>arrow_drop_down</mat-icon> -->
            <i class="fa fa-caret-down arrow-col" aria-hidden="true"></i>
            <!-- <span class="mr-5" fxLayoutAlign="start center">
      </span> -->
        </div>
        <div class="menu-wrapper" *ngIf="categories && isCategoriesVisible">
            <ul class="menu">
                <li>
                    <a class="d-flex  flex-row justify-content-between" fxFlex (click)="openAllCategories()">
                        <span class="capitalize categories-list">
                            <svg-icon src="assets/categories-menu-svg/OTHERS.svg"
                                [svgStyle]="{'width.px': '15', 'height.px': '15'}" [applyCss]="true"
                                class="mr-3 store-products-icon"></svg-icon>
                            All
                        </span> 
                        <!-- <i fxLayoutAlign="end center" class="fa fa-chevron-right" aria-hidden="true"></i> -->
                    </a>
                </li>
                <li *ngFor="let category1 of categories;" (mouseenter)="category1.isCategoriesVisible = true"
                    (mouseleave)="category1.isCategoriesVisible = false">
                    <a class="d-flex  flex-row justify-content-between" fxFlex (click)="selectedCategory(category1)">
                        <span class="capitalize categories-list">
                            <svg-icon src="assets/categories-menu-svg/{{category1?.id}}.svg"
                                [svgStyle]="{'width.px': '15', 'height.px': '15'}" [applyCss]="true"
                                class="mr-3 store-products-icon">
                            </svg-icon>
                            {{category1.name | translate | titlecase}}
                        </span> 
                        <!-- <i *ngIf="category1?.subCategory?.length" 
                            fxLayoutAlign="end center" class="fa fa-chevron-right" aria-hidden="true"></i> -->
                    </a>
                    <!-- <div class="level1-wrapper" *ngIf="category1?.subCategory?.length && category1.isCategoriesVisible">
                        <ul class="level1">
                            <li *ngFor="let category2 of category1?.subCategory"
                                (mouseenter)="category2.isCategoriesVisible = true"
                                (mouseleave)="category2.isCategoriesVisible = false">
                                <a fxFlex class="d-flex flex-row justify-content-between"
                                    (click)="selectedCategory(category1.id ,category2.id )">
                                    {{ category2.name | translate }}<i *ngIf="category2?.subCategory?.length"
                                        class="fa fa-chevron-right" aria-hidden="true"></i>
                                </a>
                                <div class="level2-wrapper"
                                    *ngIf="category2?.subCategory?.length && category2.isCategoriesVisible">
                                    <ul class="level2">
                                        <li *ngFor="let category3 of category2?.subCategory"
                                            (mouseenter)="category3.isCategoriesVisible = true"
                                            (mouseleave)="category3.isCategoriesVisible = false">
                                            <a fxFlex class="d-flex flex-row justify-content-between"
                                                [routerLink]="['/categories',  category1?.id.toLowerCase(),  category2.id.toLowerCase(), category3.id.toLowerCase() ]">
                                                {{ category3.name | translate }}<i
                                                    *ngIf="category3?.subCategory?.length" class="fa fa-chevron-right"
                                                    aria-hidden="true"></i>
                                            </a>
                                            <div class="level3-wrapper"
                                                *ngIf="category3?.subCategory?.length && category3.isCategoriesVisible">
                                                <ul class="level3">
                                                    <li *ngFor="let category4 of category3?.subCategory">
                                                        <a fxFlex class="d-flex flex-row justify-content-between"
                                                            [routerLink]="['/categories',  category1?.id.toLowerCase(),  category2.id.toLowerCase(), category3.id.toLowerCase(), category4.id.toLowerCase()  ]">
                                                            {{ category4.name | translate }}<i
                                                                *ngIf="category4?.subCategory?.length"
                                                                class="fa fa-chevron-right" aria-hidden="true"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                </li>
            </ul>
        </div>
    </div>
</ng-container>