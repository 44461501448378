<section>
  <div class="app-container">
    <h1 class="heading">Custom Mango Box</h1>
    <!-- Mobile View -->
    <div fxLayout="column" fxHide fxShow.lt-md>
      <!-- Mobile Product List -->
      <ng-container *ngIf="isProductLoading">
        <mango-tile-skeleton></mango-tile-skeleton>
      </ng-container>
      <ng-container *ngIf="!isProductLoading">
        <div class="mobile-container" fxLayout="column" fxLayoutGap="10px">
          <ng-container *ngFor="let product of products; index as index">
            <div fxLayout="row" [class.active]="true" fxLayoutAlign="space-between center" class="mobile-list" [ngStyle]="getProductQuantity(product) >= 1 && {'border': '1px solid #316e2c'}">
              <div class="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
                <div fxLayoutAlign="center center" class="product-image">
                  <div class="quantity text-light" fxLayoutAlign="center center">
                    <span class="text-center" *ngIf="getProductQuantity(product) === 0">
                      <ng-container *ngFor="let smartElement of product?.selectedSKU | slice: 0:1">
                        <span *ngIf="smartElement !== 'dimensions'">
                          {{ (product?.SKUSmartElements)[smartElement].value }}
                          {{ (product?.SKUSmartElements)[smartElement].unit }}
                        </span>
                      </ng-container>
                    </span>
                    <span class="text-center" *ngIf="getProductQuantity(product) >= 1"><i class="fa fa-check"></i></span>
                  </div>
                  <img class="img-responsive" width="100" height="80" loading="lazy"
                  [src]="product?.productPicURL_level3 || 'assets/icons/No_Image.jpg'| scaleImage: 'md'" alt="" />
                </div>
                <div fxLayout="column" fxLayoutGap="10px">
                  <h6 class="product-name ">{{product?.productName_level3}}</h6>
                  <span class="price">{{product?.pricePerSKU  | currency: "INR":"symbol":"1.0"}}</span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="8px" class="quantity-change mt-2" fxLayoutAlign="center center">
                <div class="qty-changer" (click)="decreaseQuantity(product)">
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </div>
                <div class="quantity-value">
                  {{getProductQuantity(product)}}
                </div>
                <div class="qty-changer" (click)="increaseQuantity(product)">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- Mobile Product List -->

      <!-- Mobile Bottom Section -->
      <div fxLayout="row" fxLayoutAlign="space-between center" class="fixed-bottom mobile-button">
        <img src="assets/custom-m-box/{{cartItems?.length}}.png" width="50">
        <div>
          <small class="text-light">No. of items</small>
          <h5 class="totol-amount">0{{cartItems?.length}}</h5>
        </div>
        <div>
          <small class="text-light">Basket Amount</small>
          <h5 class="totol-amount">{{cartTotalAmount | currency: "INR":"symbol":"1.0"}}</h5>
        </div>
        <div class="d-flex align-items-center justify-content-center checkout">
          <button class="add-to-cart" [disabled]="cartItems?.length < 5" (click)="addMangoBoxToRealCart()">
            <B translate>ADD TO BASKET <i class="fa ml-5 fa-arrow-right"></i></B>
          </button>
        </div>
      </div>
       <!-- Mobile Bottom Section -->
    </div>
    <!-- Mobile View -->

    <!-- Desktop View -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" class="mt-20 mb-40" fxHide.lt-md>
      <!-- Desktop product section -->
      <div fxFlex="70"  class="product-container">
        <ng-container *ngIf="isProductLoading">
          <mango-tile-skeleton></mango-tile-skeleton>
        </ng-container>
     <ng-container *ngIf="!isProductLoading">
      <div fxLayout="row wrap">
        <ng-container *ngFor="let product of products; index as index">
          <div fxLayout="column" fxLayoutAlign="center" fxFlex="18%" fxLayoutGap="20px" class="p-10 m-5 product">
            <div fxLayoutAlign="center center" class="product-image">
              <div class="quantity text-light" fxLayoutAlign="center center">
                <span class="text-center">
                  <small class="name">
                    <ng-container *ngFor="let smartElement of product?.selectedSKU | slice: 0:1">
                      <span *ngIf="smartElement !== 'dimensions'">
                        {{ (product?.SKUSmartElements)[smartElement].value }}
                        {{ (product?.SKUSmartElements)[smartElement].unit }}
                      </span>
                    </ng-container>
                  </small>
                </span>
              </div>
              <img class="img-responsive" width="100" height="80" loading="lazy"
        [src]="product?.productPicURL_level3 || 'assets/icons/No_Image.jpg'| scaleImage: 'md'" alt="" />
            </div>
            <h6 class="text-center  align-self-center product-name">{{product?.productName_level3}}</h6>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <span class="price">{{product?.pricePerSKU  | currency: "INR":"symbol":"1.0"}}</span>
              <div fxLayout="row" fxLayoutGap="8px" class="quantity-change mt-2" fxLayoutAlign="center center">
                <div class="qty-changer" (click)="decreaseQuantity(product)">
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </div>
                <div class="quantity-value">
                  {{getProductQuantity(product)}}
                </div>
                <div class="qty-changer" (click)="increaseQuantity(product)">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
      
        </ng-container>

      </div>
     </ng-container>
      </div>
       <!-- Desktop product section -->

       <!-- Desktop Mango box -->
      <div fxFlex="30" fxLayout="column" class="cart-box">
        <div class="top-section">
          <div class="box-image" fxLayoutAlign="center center">
            <img src="assets/custom-m-box/{{cartItems?.length}}.png" width="350">
          </div>
          <div fxLayout="column" class="mango-list">
            <span fxLayout="row" fxLayoutAlign="space-between center">
              <h6>Number of items</h6>
              <h5 class="pr-10">0{{cartItems?.length}}</h5>
            </span>
            <div class="scroll">
              <ng-container *ngIf="!cartItems.length">
                <div  fxLayoutAlign="center center" class="no-product">No product found.</div>
              </ng-container>
              <ng-container *ngFor="let product of cartItems">
                <div fxLayout="column" fxLayoutGap="3px" class="mango-name">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <small class="name">{{product?.productName_level3}}</small>
                    <span class="price">
                      {{(product?.pricePerSKU * product?.productQuantity) | currency: "INR":"symbol":"1.0"}}
                    </span>
                  </div>
                  <div fxLayout="row" fxLayoutGap="10px">

                    <small class="name">
                      <ng-container *ngFor="let smartElement of product?.selectedSKU | slice: 0:1">
                        <span *ngIf="smartElement !== 'dimensions'">
                          {{ (product?.SKUSmartElements)[smartElement].value }}
                          {{ (product?.SKUSmartElements)[smartElement].unit }}
                        </span>
                      </ng-container>
                      X {{product?.productQuantity}}
                    </small>
                    <small class="delete cursor-pointer" (click)="removeFromCart(product)">Remove</small>
                  </div>
                </div>
              </ng-container>
            </div>


          </div>

        </div>
        <div fxLayout="column" class="bottom-section" fxLayoutAlign="center center">
          <div class="d-flex align-items-center justify-content-center checkout">
            <button class="add-to-cart" [disabled]="cartItems?.length < 5" (click)="addMangoBoxToRealCart()">
              <B translate>ADD TO BASKET <i class="fa ml-5 fa-arrow-right"></i></B>
            </button>
          </div>
          <small class="text-light">Total amount</small>
          <div class="totol-amount">{{cartTotalAmount | currency: "INR":"symbol":"1.0"}}</div>
        </div>

      </div>
      <!-- Desktop Mango box -->
    </div>
    <!-- Desktop View -->
  </div>
</section>