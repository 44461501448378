
<ng-container *ngIf="allCategories && allCategories?.length">

  <a class="menu-item d-menu" (mouseenter)="isCategoriesVisible = true" (mouseleave)="isCategoriesVisible = false">
    <span class="mr-5">Shop by region</span>
    <i class="fa fa-angle-down" aria-hidden="true"></i>
    <div class="menu-wrapper" *ngIf="allCategories && isCategoriesVisible">
      <ul class="menu">
        <li *ngFor="let category1 of allCategories;" (mouseenter)="category1.isCategoriesVisible = true"
          (mouseleave)="category1.isCategoriesVisible = false">
          <a class="capitalize" fxFlex [routerLink]="['/search', category1.value]" [queryParams]="{ category: category1.id}">
            {{category1.value | translate}} <i *ngIf="category1.subCategories" class="fa fa-chevron-right"
              aria-hidden="true"></i>
          </a>
          <div class="level1-wrapper" *ngIf="category1?.subCategories && category1.isCategoriesVisible">
            <ul class="level1">
              <li *ngFor="let category2 of category1?.subCategories" (mouseenter)="category2.isCategoriesVisible = true"
                (mouseleave)="category2.isCategoriesVisible = false">
                <a fxFlex [routerLink]="['/search', category2.value]"
                  [queryParams]="{ category: category1.id, l2Id: category2.id, limit: 20, view: 'grid' }">
                  {{ category2.value | translate }}<i *ngIf="category2.subCategories" class="fa fa-chevron-right"
                    aria-hidden="true"></i>
                </a>
                <div class="level2-wrapper" *ngIf="category2?.subCategories && category2.isCategoriesVisible">
                  <ul class="level2">
                    <li *ngFor="let category3 of category2?.subCategories"
                      (mouseenter)="category3.isCategoriesVisible = true"
                      (mouseleave)="category3.isCategoriesVisible = false">
                      <a fxFlex [routerLink]="['/search', category3.value]"
                        [queryParams]="{ category: category1.id, l2Id: category2.id,   l3Id: category3.id, limit: 20, view: 'grid' }">
                        {{ category3.value | translate }}<i *ngIf="category3.subCategories" class="fa fa-chevron-right"
                          aria-hidden="true"></i>
                      </a>
                      <div class="level3-wrapper" *ngIf="category3?.subCategories && category3.isCategoriesVisible">
                        <ul class="level3">
                          <li *ngFor="let category4 of category3?.subCategories">
                            <a fxFlex
                              [routerLink]="['/search', category4.value]"
                              [queryParams]="{ category: category1.id, l2Id: category2.id, l3Id: category3.id, l4Id: category4.id, limit: 20, view: 'grid' }">
                              {{ category4.value | translate }}<i *ngIf="category4.subCategories"
                                class="fa fa-chevron-right" aria-hidden="true"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </a>
</ng-container>
