import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../project-common/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipeModule } from '../project-common/pipes/pipe.module';
import { SocialSharingComponent } from './components/social-sharing/social-sharing.component';

@NgModule({
  declarations: [
    SocialSharingComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    PipeModule
  ],
  exports: [
    // Common modules
    MaterialModule,
    FlexLayoutModule,
    PipeModule,

    // Common components
    SocialSharingComponent
  ]
})
export class SharedModule { }
