<div class="h-100">

  <address-info 
  [listOfAddress]="listOfAddress"
  [selectedAddress]="selectedAddress" 
  (selectedAddressEvent)="onAddressSelection($event)"></address-info>
  
  <div class="py-5 px-15 buttons fixed-bottom w-100" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <kl-link-button [label]="'Cancel'" buttonColor="warn" (clicked)="cancel()"></kl-link-button>
    <kl-link-button [label]="'Confirm'" buttonColor="primary" (clicked)="ok()"></kl-link-button>
  </div>
</div>


