<header>

    <!-- <div class="covid-info">
        <div class="app-container">
            <p class="m-0">Note: We are temporarily unable to deliver across hyderabad because of the govt covid restrictions and plan to resume as soon as the restrictions are removed</p>
        </div>
    </div> -->

    <div class="top-header">
        <!-- Web header -->
        <ng-container *fxIf="'gt-xs'">
            <!-- <div class="header--1" fxHide.xs [class.display-none]="(headerCollapseChange$ | async)">
    
                <div class="py-10 border-bottom primary-col" *ngIf="false">
                    <div class="app-container" translate>Due to the COVID 19 epidemic, orders may be processed with a slight delay</div>
                </div>
            </div> -->
    
            <div #headerSec [style.minHeight]="'67px'" class="large-screen">
                <div class="header--2 border-bottom position-relative" fxHide.xs [class.collapsed]="(headerCollapseChange$ | async)" *ngIf="!isAdminPage">
                    <div class="app-container">
                        <div fxLayoutAlign="space-between center" class="header-menu" id="app-header-menu">
                            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                                <a class="d-flex flex-column" [routerLink]="[ '/' ]" fxFlex="150px">
                                    <div class="align-items-center d-flex flex-row">
                                        <img height="28px" [src]="'assets/images/kl-red-logo.png'" class="kl-logo" alt="Logo">
                                        <span class="text-uppercase kalgudi-text font-22 ml-8" translate>Kalgudi</span>
                                    </div>
                                    <span class="market-text ml-5 font-16 mt-5" translate>RURAL INDIA STORE</span>
                                </a>
        
                                <searchbar [isUserLoggedIn]="isUserLoggedIn" [showSearch]="showSearch" (change)=search($event) (onSearchFocusChange)="onFocusChange($event)"></searchbar>
        
                                <div fxLayoutAlign="space-between center" [class.hidden]="isFocusChanged">
        
                                    <div fxLayoutAlign="start center" fxLayoutGap="10px">
        
                                        <div class="menu" fxLayout="row wrap" fxLayoutAlign="start">
        
                                            <shop-by-category class="d-flex"></shop-by-category>
        
                                            <!-- <shop-by-region class="d-flex"></shop-by-region> -->
        
                                            <nested-menu class="d-flex"></nested-menu>
        
                                        </div>
        
                                    </div>
        
                                </div>
        
                                <a 
                                    translate 
                                    
                                    [class.hidden]="isFocusChanged">
                                    <all-about [otherAboutList]= "otherAboutDetails"></all-about>
                                </a>
                                <a 
                                [class.hidden]="isFocusChanged">
                                    <all-store [otherAppsList]= "otherAppsList"></all-store>
                                </a>
                            </div>
        
                            <div fxLayoutAlign="space-between center">
        
                                <ul fxLayoutAlign="start center">
        
                                    <li>
                                        <!-- <div class="langs-container">
                                            <div class="lang" [class.active]="selectedLanguage === 'en'"
                                                (click)="switchLanguage('en')">English</div>
                                            <div class="lang" [class.active]="selectedLanguage === 'te'"
                                                (click)="switchLanguage('te')">తెలుగు</div>
                                        </div> -->

                                        <div class="k-dropdown langs-container">
                                            <span class="lang">{{availableLanguage[selectedLanguage]}}</span>
                                            <i class="fa fa-angle-down ml-2 mt-3 mr-1"></i>
                                            <div class="k-dropdown-container">
                                                <div class="k-drop-item" (click)="switchLanguage('en')">English</div>
                                                <div class="k-drop-item disabled">हिन्दी</div><!--(click)="switchLanguage('hi')"-->
                                                <div class="k-drop-item disabled">తెలుగు</div><!--(click)="switchLanguage('te')"-->
                                                <!-- <div class="k-drop-item" (click)="switchLanguage('ka')">ಕೆನಡಾ</div> -->
                                            </div>
                                        </div>
                                    </li>
    
                                    <li>
                                        <ng-container *ngIf="isUserLoggedIn; then loggedIn; else loggedOut"></ng-container>
                                        <ng-template #loggedOut>
                                    <li>
                                        <span translate class="d-flex align-items-center">
                                            <i class="material-icons mr-5 profile-icon">account_circle</i>
                                            <!-- <i class="fa fa-user-circle mr-5" aria-hidden="true"></i> -->
                                            <span class="d-flex flex-column font-15">
                                                <span class="login-css" translate (click)="goToLogin()">Login</span>
                                                <!-- <span  routerLink="/auth/signup" class="fw-700 register-css" translate>Register</span> -->
                                            </span>
                                        </span>
                                    </li>
                                    </ng-template>
                                    <ng-template #loggedIn>
                                        <li class="k-dropdown d-flex align-items-center">
        
                                            <img class="fit-image-to-container" class="rounded-circle mr-10" height="30px" width="30px"
                                                [src]="(userData?.profilePicUrl) | prefixDomain">
                                            <a [title]="loggedInUserName" routerLink="/account/profile" *ngIf="!userData?.guestRegistration">
                                                <span class="username"> {{ loggedInUserName }} </span>
                                                <i class="fa fa-caret-down ml-5" aria-hidden="true"></i>
                                            </a>
                                            <a [title]="loggedInUserName"  *ngIf="userData?.guestRegistration">

                                                <span class="username"> Guest</span>

                                                <i class="fa fa-caret-down ml-5" aria-hidden="true"></i>

                                            </a>
        
                                            <div class="k-dropdown-container1">
                                                <!-- <div class="k-heading" translate>My Account</div> -->
                                                <div class="k-drop-item">
                                                    <a [routerLink]="['/account/my-orders']" translate><i class="fa mr-10  fa-archive" aria-hidden="true"></i>Orders</a>
                                                </div>
                                                <div class="k-drop-item"  *ngIf="!userData?.guestRegistration">
                                                    <a [routerLink]="['/account/profile']"> 
                                                        <i class="fa mr-10  fa-user-circle-o" aria-hidden="true"></i>
                                                       <span translate> Profile</span>
                                                    </a>
                                                </div>
<!--                                            
                                                <div class="k-drop-item">
                                                    <a [routerLink]="['/account/address']" translate>
                                                        <i class="fa mr-10  fa-address-card-o" aria-hidden="true"> 
                                                        </i>
                                                       <span translate>Addresses</span> 
                                                    </a>
                                                </div>
                                                <div class="k-drop-item">
                                                    <a [routerLink]="['/account/wish-list']" translate><i class="fa mr-10  fa-heart" aria-hidden="true"></i>Wishlist</a>
                                                </div> -->
                                                
                                                <!-- <div class="k-heading" translate>Orders & Review</div> -->
                                                <!-- <div class="k-drop-item">
                                                    <a [routerLink]="['/account/my-reviews']" translate><i class="fa mr-10  fa-commenting-o" aria-hidden="true"></i>My reviews</a>
                                                </div>
                                               -->
        
                                                <!-- <div class="k-drop-item" *ngIf="isKalgudiTeam">
                                                    <a href="/admin" target="_blank" translate>Dashboard</a>
                                                </div> -->
        
                                                <!-- <div class="k-drop-item" *ngIf="isKalgudiTeam">
                                                    <span fxLayout fxLayoutAlign="space-between">
                                                        <span class="one-line-text" translate>Quick links</span>
                                                        <i class="fa fa-angle-right" aria-hidden="true"
                                                            fxFlexAlign="center"></i>
                                                    </span>
                                                    <div class="sub-dropdown">
                                                        <div class="items-container" fxLayout="row wrap">
                                                            <a class="k-drop-item" href="/admin/#/app/requirements/grid"
                                                                target="_blank" translate>RFQs</a>
                                                            <a class="k-drop-item" href="/admin/#/app/orders" target="_blank"
                                                                translate>Orders</a>
                                                            <a *ngIf="canAccessSupportPage" class="k-drop-item"
                                                                routerLink="/support/orders" target="_blank">Fulfillment</a>
        
                                                        </div>
                                                    </div>
                                                </div> -->
        
        
                                                <div class="k-drop-item" (click)="logout()" translate><i class="fa  mr-10  fa-sign-out" aria-hidden="true"></i>Logout</div>
                                            </div>
                                        </li>
                                    </ng-template>
                                    </li>
        
                                    <!--  (click)="onToggleCart()" -->
                                    <li   (click)="onToggleCart()">
                                        <cart-info class="d-flex" [cart]="cart" [cartQuantity]="productQuantityInCart"
                                            (deleteProduct)="deleteProduct($event)"></cart-info>
                                    </li>
        
                                </ul>
                            </div>
        
                        </div>
                    </div>
        
                    <div fxLayoutAlign="center center" id="app-header-location-wrapper" class="location-wrapper position-absolute" *ngIf="!isFocusChanged && !isCheckoutPage && false">
                        <div class="del-loc-select-wrapper">
                            <div  class="del-loc-select" fxLayoutAlign="space-between center" fxLayoutGap="10px"
                                (click)="togglePopup($event)">
                                <i class="fa fa-map-marker"></i>
                                <ng-container *ngTemplateOutlet="deliveryLocationSelectedTemplate"></ng-container>
                                <i class="fa fa-angle-down"></i>
                            </div>
        
                            <div class="loc-select" [class.show]="showDeliveryLocationSelection">
                                <div class="loc-select-container" #pincodeBox>
                                    <div class="scrollable">
                                        <i class="material-icons close-popup" (click)="closePincodePopUp()">cancel</i>
                                        <delivery-pincode-selector [isUserLoggedIn]="isUserLoggedIn"
                                            (onChange)="showDeliveryLocationSelection = false">
                                        </delivery-pincode-selector>
                                    </div>
                                </div>
                            </div>
        
                            <ng-container *ngTemplateOutlet="deliveryLocationChangedDialog"></ng-container>
                        </div>
        
                        <!-- <cart-info fxLayout [cart]="cart" [cartQuantity]="productQuantityInCart"
                            (deleteProduct)="deleteProduct($event)" [hidden]="!(headerCollapseChange$ | async)">
                        </cart-info> -->
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- / Web header -->
    
        <!-- Mobile header -->
        <div class="medium-screen">
            <div class="m-header position-relative" [class.collapsed]="(headerCollapseChange$ | async)">
                <div class="header--1 pos-relative" *ngIf="isTopHeader">

                    <!-- <div class="py-5 px-10 border-bottom primary-col">
                        <div class="app-container d-flex align-content-between align-items-center" translate>
                            <span class="mr-10" translate>Due to the COVID 19 epidemic, orders may be processed with a slight delay</span>
                            
                            <i class="material-icons close-icon" (click)="toggleTopHeader()">close</i>
                        </div>
                    </div> -->
                </div>
                <div class="m-header--1 bg-white py-5 px-10 d-flex align-items-center justify-content-between" fxLayoutAlign="start center">
                    
    
                    <!-- <a class="align-items-center d-flex" [routerLink]="[ '/' ]">
                        <img width="35" [src]=" 'assets/images/kl-red-logo.png'" class="kl-logo" alt="Logo">
    
                        <div fxLayout="column" class="ml-5">
                            <span class="text-uppercase kalgudi-text font-22">Kalgudi</span>
                            <span class="market-text font-14 text-uppercase">store</span>
                        </div>
    
                    </a> -->

                    <a class="d-flex flex-column" [routerLink]="[ '/' ]" fxFlex="150px">
                        <div class="align-items-center d-flex flex-row">
                            <img height="40px" width="40px" [src]="'assets/images/kl-red-logo.png'" class="kl-logo" alt="Logo">
                            <span class="text-uppercase kalgudi-text font-22 ml-8" translate>Kalgudi</span>
                        </div>
                        <span class="market-text ml-5 font-16 mt-5" translate>RURAL INDIA STORE</span>
                    </a>
                    
                    <div class="col-7 small-screen">
                        <div class="searchbar-container" (click)="showSearch = true">
                            <div class="searchbar d-flex" (click)="showSearch = false">
                                <i class="fa fa-search"></i>
                                <span class="one-line" translate>Search across our exclusive range...</span>
                            </div>
                        </div>
                    </div>

                    <!-- <div fxFlex></div> -->
                    <div class="d-flex justify-content-between align-items-center">

                        <a (click)="showSearch = true" class="small-screen-hide">
                            <div class="p-10 search-label-icon" (click)="showSearch = false">
                                <i class="fa fa-search"></i>
                            </div>
                        </a>
        
                        <div (click)="onToggleCart()">
                            <cart-info [cart]="cart" [cartQuantity]="productQuantityInCart"></cart-info>
                        </div>
                        <mat-icon fxLayoutAlign="center center" (click)="showSideNav = true; loadSideNav = true">menu</mat-icon>
                    
                    </div>
    
                </div>

                <div class="small-screen">

                    <div class="w-100 position-absolute" *ngIf="!isCheckoutPage">
                        <div class="location-info d-flex justify-content-center">
                            <div  class="ml-10" fxLayoutAlign="center center">
                                <div class="del-location border px-15 py-8 d-flex justify-content-center align-items-center rounded-pill w-100"
                                    (click)="showDeliveryLocationSelection = !showDeliveryLocationSelection">
                                    <i class="fa fa-map-marker mr-10"></i>
                                    <ng-container *ngTemplateOutlet="deliveryLocationSelectedTemplate"></ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                

                <div class="small-screen-hide m-header--2">

                    <div class="m-header--2 pt-0 row no-gutters">
                        <div class="col-7">
                            <div class="searchbar-container" (click)="showSearch = true">
                                <div class="searchbar d-flex" (click)="showSearch = false">
                                    <i class="fa fa-search"></i>
                                    <span class="one-line" translate>Search across our exclusive range...</span>
                                </div>
                            </div>
                        </div>
        
                        <div class="col-5">
                            <div  class="location-info ml-10" fxLayoutAlign="center center">
                                <div class="del-location border px-15 py-8 d-flex justify-content-center align-items-center rounded-pill w-100"
                                    (click)="showDeliveryLocationSelection = !showDeliveryLocationSelection">
                                    <i class="fa fa-map-marker mr-10"></i>
                                    <ng-container *ngTemplateOutlet="deliveryLocationSelectedTemplate"></ng-container>
                                </div>
                            </div>
                        </div>
    
        
                    </div>
                </div>
    
                <ng-container *ngTemplateOutlet="deliveryLocationChangedDialog"></ng-container>
                
                <div *ngIf="showSearch">
                    <searchbar [showSearch]="showSearch" (change)=search($event) fxFlex [isUserLoggedIn]="isUserLoggedIn"></searchbar>
                </div>
            </div>
    
            <div class="del-location-selector" [hidden]="!showDeliveryLocationSelection">
    
                <div class="del-location-selection-header" fxLayoutAlign="start center">
                    <div class="p-10 pl-15" fxLayout (click)="showDeliveryLocationSelection = false">
                        <mat-icon>arrow_back</mat-icon>
                    </div>
                    <div fxFlex></div>
                    <span class="font-20 mr-30" translate>Choose Delivery Location</span>
                    <div fxFlex></div>
                </div>
    
                <delivery-pincode-selector [isUserLoggedIn]="isUserLoggedIn"
                    (onChange)="showDeliveryLocationSelection = false">
                </delivery-pincode-selector>
    
            </div>
            <!-- Mobile sidenav -->
            <ng-container *ngIf="loadSideNav">
                <mobile-sidenav [showSideNav]="showSideNav" [userData]="userData" [isUserLoggedIn]="isUserLoggedIn"
                    (logout)="logout()" (toggle)="showSideNav = !showSideNav">
                </mobile-sidenav>
            </ng-container>

        </div>
        <!-- / Mobile header -->
    </div>

</header>



<ng-template #deliveryLocationSelectedTemplate>
    <ng-container
    *ngIf="(deliveryLocationSelected$ | async) as deliveryLocationSelected; else noDeliveryLocationSelected">
    <span class="one-line">{{ deliveryLocationSelected?.postalCode ? (deliveryLocationSelected?.city+' -
        '+deliveryLocationSelected?.postalCode) : deliveryLocationSelected}}</span>
    </ng-container>
    <ng-template #noDeliveryLocationSelected>
        <span class="one-line" translate>Choose location</span>
    </ng-template>
</ng-template>

<ng-template #deliveryLocationChangedDialog>
    <ng-container *ngIf="shippingMsgDialog | async">
        <div @notifyAnimation class="loc-selected-dialog">
            <div class="loc-selected-dialog-container">
                <span>
                    {{ shippingMessage }}
                </span>
                <mat-icon (click)="shippingMsgDialog.next(false)">close</mat-icon>
            </div>
        </div>
    </ng-container>
</ng-template>