<section class="pre-book">
  <div class="app-container">


    <div class="pre-book-wrapper"  fxLayoutAlign.gt-xs="center center">
      <form [formGroup]="preBookForm" class="pre-book-container mat-elevation-z5">
        <h4 class="text-center mb-20 pink fw-600">Pre-Book Now</h4>
    
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
          <div fxFlex fxFlex.xs="none" fxLayout="column" fxLayout.xs="row" fxLayoutAlign="center center" fxLayoutAlign.xs="space-between center" fxLayoutGap="10px">
            <img width="65" src="assets/tfresh/images/1.png" alt="">
            <div fxLayout="column" fxLayoutAlign.xs="none" fxLayoutAlign="center center">
              <span class="fw-600">Banganpalli</span>
              <div class="fw-600 mt-5">5kg ({{ 450 | currency: 'INR'}})</div>
            </div>
            <div class="select">
              <select formControlName="banganpalliQty" class="units">
                <option value="0">Units</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
              <i class="fa fa-angle-down"></i>
            </div>
          </div>
          <div fxFlex fxFlex.xs="none" fxLayout="column" fxLayout.xs="row" fxLayoutAlign="center center" fxLayoutAlign.xs="space-between center" fxLayoutGap="10px">
            <img width="65" src="assets/tfresh/images/2.png" alt="">
            <div fxLayout="column" fxLayoutAlign.xs="none" fxLayoutAlign="center center">
              <span class="fw-600">Sweet Lime</span>
              <div class="fw-600 mt-5">10Kg ({{ 400 | currency: 'INR'}})</div>
            </div>
            <div class="select">
              <select formControlName="limeQty" class="units">
                <option value="0">Units</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
              <i class="fa fa-angle-down"></i>
            </div>
          </div>
          <div fxFlex fxFlex.xs="none" fxLayout="column" fxLayout.xs="row" fxLayoutAlign="center center" fxLayoutAlign.xs="space-between center" fxLayoutGap="10px">
            <img width="65" src="assets/tfresh/images/3.png" alt="">
            <div fxLayout="column" fxLayoutAlign.xs="none" fxLayoutAlign="center center">
              <span class="fw-600">Himayat mango</span>
              <div class="fw-600 mt-5">5kg ({{ 1250 | currency: 'INR'}})</div>
            </div>
            <div class="select">
              <select formControlName="himayatQty" class="units">
                <option value="0">Units</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
              <i class="fa fa-angle-down"></i>
            </div>
          </div>

        </div>

        <div class="personal">Personal Details</div>
        <div formGroupName="userDetails" class="my-10" fxLayout fxLayout.xs="column" fxLayoutGap="20px">
          <div fxLayout="column">
            <input formControlName="firstName" placeholder="Name" type="text">
            <span class="mt-2 pl-5 text-danger font-12" 
              *ngIf="preBookForm?.get('userDetails.firstName')?.touched && preBookForm?.get('userDetails.firstName')?.invalid">
              Name is required
            </span>
          </div>
          <div fxLayout="column">
            <input formControlName="email" placeholder="Email" type="email">
            <span class="mt-2 pl-5 text-danger font-12" 
              *ngIf="preBookForm?.get('userDetails.email')?.touched && preBookForm?.get('userDetails.email')?.invalid">
              Enter valid email
            </span>
          </div>
          <div fxLayout="column">
            <input formControlName="mobile" placeholder="Mobile" type="number">
            <span class="mt-2 pl-5 text-danger font-12" 
              *ngIf="preBookForm?.get('userDetails.mobile')?.touched && preBookForm?.get('userDetails.mobile')?.invalid">
              Enter valid mobile number
            </span>
          </div>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">

          <div>
            <div class="fw-600 font-18 mt-20 mb-10">
              Total Amount: {{ totalValue | currency: 'INR'}}
            </div>
    
            <div class="my-10"><small>*Excluding Transaction Fees</small></div>
          </div>

          <button mat-button [disabled]="!isValid" (click)="bookNow()">Book Now</button>
        </div>


      </form>
    </div>



  </div>
</section>