import { Component, Input, OnInit } from '@angular/core';
import { ThirdPartyLibraries } from 'src/app/project-common/properties/constants';
import { ScriptLoaderService } from 'src/app/project-common/services/script-loader.service';

import { MangoService } from '../../mango.service';

@Component({
  selector: 'mango-cart-item',
  templateUrl: './mango-cart-item.component.html',
  styleUrls: ['./mango-cart-item.component.scss']
})
export class MangoCartItemComponent implements OnInit {

  @Input() product: any;
  
  constructor(
    private loader: ScriptLoaderService,
    private service: MangoService,
  ) {
    this.loader.loadStyle(ThirdPartyLibraries.jostFont).subscribe();
  }

  ngOnInit() {
  }

  removeFromCart() {
    this.service.removeFromPrebookObject(this.product.productId_level3);
  }

}
