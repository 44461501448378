import { Component, OnInit, Inject, OnChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'price-update-dialog',
  templateUrl: './price-update-dialog.component.html',
  styleUrls: ['./price-update-dialog.component.scss']
})
export class PriceUpdateDialogComponent implements OnInit, OnChanges {

  updatedProducts: any[];
  invalidRemovedProducts: any[];

  updatedProductsDisplayedColumns: string[] = ['product', 'old', 'new', 'amount'];
  invalidRemovedProductsDisplayedColumns: string[] = ['product', 'amount'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.updatedProducts = [];
    if (this.data) {
      this.updatedProducts = this.data.updatedProducts;
      this.invalidRemovedProducts = this.data.invalidRemovedProducts;
    } 
  }

  ngOnChanges() {
    if (this.data) {
      this.updatedProducts = this.data.updatedProducts;
      this.invalidRemovedProducts = this.data.invalidRemovedProducts;
    } 
  }
  

}
