<div class="shipping-form" fxLayoutGap="20px" fxLayout.xs="column">

    <div fxFlex fxFlex.xs="none" class="pt-20" fxLayout="column" fxLayoutGap="10px">

        <checkout-title (check-shipping-charge)="getShippingCharges($event)">
            {{'Select Shipping Address' | translate}}
        </checkout-title>

        <!-- <delivery-discount [chargesAndOtherDetails]="chargesAndOtherDetails"></delivery-discount> -->

        <div>
            <p class="mt-0 mb-10" *ngIf="assistedUser?.profileKey" translate>Your addresses</p>
            <delivery-address-selector [loggedInUser]="loggedInUser" (selected-address)="setAddress($event)" (requestForCharges)="getShippingCharges($event)">
            </delivery-address-selector>
        </div>

        <div *ngIf="assistedUser?.profileKey">
            <p class="mt-0 mb-10">
                <span class="capitalize fw-600">{{ assistedUser?.firstName }}</span><span translate>'s addresses</span>
            </p>
            <delivery-address-selector [loggedInUser]="assistedUser" (selected-address)="setAddress($event)">
            </delivery-address-selector>
        </div>
    </div>

    <div class="summary-sec" fxFlexAlign="start" fxFlexAlign.xs="stretch" fxFlex="320px" fxFlex.xs="none">

        <div fxLayout="column">
            <button fxHide.xs (click)="saveShippingAddress()" class="place-order app-row" translate>Continue</button>

            <summary class="app-row" [charges]="chargesAndOtherDetails"></summary>

            <a routerLink="/home" class="continue-shopping-link app-row" translate>
               <span translate>< BACK TO STORE</span> 
            </a>
                    <button fxHide.xs (click)="saveShippingAddress()" class="place-order app-row" translate>Continue</button>

                    <div fxHide fxShow.xs id="placeBtnInBottom" class="mobile-button">
                        <button (click)="saveShippingAddress()" class="place-order app-row" translate>Proceed to Payment</button>
                    </div>

        </div>

    </div>

</div>