<ng-container *ngIf="products?.listOfLevel2Cards?.length">
  <div class="title-bar" *fxIf="'xs'">
    <h3 [routerLink]="['/search', products?.value]" [queryParams]="{category: products?.id }" class="m-0" translate>{{products?.value}}</h3>
  </div>
</ng-container>


<ng-container *ngIf="(categoryCards$ | async) as categories">
  <div  class="d-container d-flex flex-wrap" fxLayoutGap="12px grid"
  [class.separator]="products?.listOfLevel2Cards?.length > 4" [style.color]="categories[products?.id]?.backgroundColor">
    <ng-container *ngFor="let product of products?.listOfLevel2Cards index as index">
      <ng-container *fxIf="'gt-xs'">
  
        <ng-container *ngIf="index === (cardPosition === 'left' ? 0 : 4)">
            <ng-container *ngIf="categories[products?.id] as category">
              <category-card class="d-item" [category]="category" fxFlex="20%" fxFlex.sm="25%" fxFlex.xs="50%">
              </category-card>
            </ng-container>
        </ng-container>
  
      </ng-container>
      <ng-container *ngIf="product?.isBasket;then basketProduct;else normalProduct" ></ng-container>
  
      <ng-template #normalProduct>
        <p-tile class="d-item" [product]="product" fxFlex="20%" fxFlex.sm="25%" fxFlex.xs="50%">
        </p-tile>
      </ng-template>
      <ng-template #basketProduct>
        <basket-tile class="d-item" [product]="product" fxFlex="20%" fxFlex.sm="25%" fxFlex.xs="50%">
        </basket-tile>
      </ng-template>
  
  
    </ng-container>
  </div>
</ng-container>