<ng-container *ngIf="notifications?.length">
  <div>
    <app-card
      *ngFor="let notification of notifications"
      [class.read]="notification?.isRead"
      [avatarSize]="60"
      [avatarUrl]="notification?.notificationImageUrl"
      [routerLink]="notification?.deepLinkUrl"
      [noScaleImage]="false"
      (click) = "read(false,notification)"
    >
      <div class="text">
        <span class="title">{{ notification?.title }}</span>
        <p>{{ notification?.body }}</p>
      </div>
    </app-card>
  </div>
  <div class="readAll">
    <a (click)="read(true)">Mark all as read</a>
  </div>
</ng-container>

<div class="no-notifications" *ngIf="!notifications?.length">
  <div class="wrapper">
    <mat-icon>notifications_off</mat-icon>
    <p>No notifications yet</p>
    <button mat-raised-button routerLink="/">Continue Shopping</button>
  </div>
</div>