<h5 mat-dialog-title fxLayoutAlign="space-between center">
    <span translate>Confirm to place order</span>
    <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h5>

<mat-dialog-content>
    <div class="dialog-content">
        <div *ngIf="canAssist" fxLayout="column" fxLayoutGap="12px" class="mb-25">
            <div class="fw-500 font-12" fxLayoutAlign="start center"><span class="section-title" translate>Assisted By</span>
                <div class="border-bottom ml-10" fxFlex></div>
            </div>
            <div>{{ assistantUserdata?.firstName | capitalize }}</div>
        </div>

        <div fxLayout="column" fxLayoutGap="12px">
            <div class="fw-500 font-12" fxLayoutAlign="start center"><span class="section-title" translate>Product Summary</span>
                <div class="border-bottom ml-10" fxFlex></div>
            </div>
            <table class="table m-0">
                <thead>
                    <tr>
                        <th scope="col" translate>Product</th>
                        <th scope="col" translate>Quantity</th>
                        <th scope="col" fxLayoutAlign="end" translate>Price</th>
                    </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let store of checkoutDetails?.storeMap | keyvalue">
                    <ng-container *ngFor="let product of store?.value?.productList">
                      <tr>
                        <td>{{product?.productLevel3Title}}</td>
                        <td>
                            {{ product?.productQuantity }}
                        </td>
                        <td class="text-right">
                            <currency [actualPrice]="product?.pricePerUnit"></currency>
                        </td>
                    </tr>
                    </ng-container>
                  </ng-container>


                    <tr class="fw-500" *ngIf="checkoutDetails?.totalShippingCost">
                        <td translate>
                            Shipping charges
                        </td>
                        <td></td>
                        <td class="text-right">{{ (checkoutDetails?.totalShippingCost ||  0) | currency: checkoutDetails?.currencySymbol || 'INR':"symbol":"1.2-2"}}</td>
                    </tr>
                    <tr class="fw-500" *ngIf="checkoutDetails?.totalPackagingCost">
                        <td translate>
                            Packaging cost
                        </td>
                        <td></td>
                        <td class="text-right">{{ (checkoutDetails?.totalPackagingCost || 0) | currency: checkoutDetails?.currencySymbol || 'INR':"symbol":"1.2-2"}}</td>
                    </tr>
                    <tr class="fw-500" *ngIf="checkoutDetails?.totalHandlingCost">
                        <td translate>
                            Handling cost
                        </td>
                        <td></td>
                        <td class="text-right">{{ (checkoutDetails?.totalHandlingCost || 0) | currency: checkoutDetails?.currencySymbol || 'INR':"symbol":"1.2-2"}}</td>
                    </tr>
                    <tr class="fw-500" *ngIf="checkoutDetails?.assistantCommissionCharge">
                        <td translate>
                            Assistant charge
                        </td>
                        <td></td>
                        <td class="text-right">{{ (checkoutDetails?.assistantCommissionCharge) | currency: checkoutDetails?.currencySymbol || 'INR':"symbol":"1.2-2"}}</td>
                    </tr>
                    <tr class="fw-500" *ngIf="checkoutDetails?.totalDiscounts">
                        <td translate>
                            Discount
                        </td>
                        <td></td>
                        <td class="text-right">{{ -checkoutDetails?.totalDiscounts | currency: checkoutDetails?.currencySymbol || 'INR':"symbol":"1.2-2"}}</td>
                    </tr>
                    <tr class="fw-500" *ngIf="checkoutDetails?.couponDiscount">
                        <td translate>
                            Coupon Discount
                        </td>
                        <td></td>
                        <td class="text-right">{{ -checkoutDetails?.couponDiscount| currency: checkoutDetails?.currencySymbol || 'INR':"symbol":"1.2-2"}}</td>
                    </tr>
                    <tr class="fw-500" *ngIf="checkoutDetails?.grandTotal">
                        <td translate>
                            Total
                        </td>
                        <td></td>
                        <td class="text-right">{{ checkoutDetails?.grandTotal  | currency: checkoutDetails?.currencySymbol || 'INR':"symbol":"1.2-2"}}</td>
                    </tr>
                   
                </tbody>
            </table>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" class="mt-10">
            <div [fxFlex]="data?.paymentMode === 'COD' ? '50%' : '100%'">
                <div fxLayout="column" fxLayoutGap="12px" class="mb-25">
                    <div class="fw-500 font-12" fxLayoutAlign="start center"><span class="section-title" translate>Delivery
              Address</span>
                        <div class="border-bottom ml-10" fxFlex></div>
                    </div>
                    <div class="address" fxLayout="column" fxLayoutGap="5px" fxLayoutGap.xs="3px">
                        <p class="fw-500">{{checkoutDetails?.buyerAddress?.name | capitalize}}&nbsp; ({{checkoutDetails?.buyerAddress?.phone}})
                        </p>
                        <p>{{checkoutDetails?.buyerAddress?.addressLine1}}</p>
                        <p *ngIf="checkoutDetails?.buyerAddress?.addressLine2">{{checkoutDetails?.buyerAddress?.addressLine2}}</p>
                        <p>{{checkoutDetails?.buyerAddress?.city}}</p>
                        <p>{{checkoutDetails?.buyerAddress?.state}}</p>
                        <p class="m-0" translate>Pincode: <span>{{checkoutDetails?.buyerAddress?.postalCode}}</span></p>
                    </div>
                </div>
            </div>



            <ng-container *ngIf="data?.paymentMode === 'COD'">
                <div fxFlex="calc(50% - 10px)" class="input-fields">
                    <div fxLayout="column" fxLayoutGap="12px">
                        <div class="fw-500 font-12" fxLayoutAlign="start center">
                            <span class="section-title" translate>Payment Info</span>
                            <div class="border-bottom ml-10" fxFlex></div>
                        </div>

                        <form class="payment-info-form" [formGroup]="paymentInfoForm">
                            <div fxLayout="row" fxLayoutGap="10px" class="payment">
                                <div fxFlex="calc(50% - 10px)">
                                    <span>Paid Through</span>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select formControlName="mode" placeholder="Paid through">
                                            <mat-option *ngFor="let paidThroughMode of paidThroughModes" [value]="paidThroughMode" required>
                                                {{paidThroughMode}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div fxFlex="50%">
                                    <span translate>Payment Ref. Id</span>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <input matInput required formControlName="bankRefId" placeholder="Payment Ref. Id" required>
                                    </mat-form-field>
                                </div>
                            </div>

                            <kl-image-picker-form-control
                                class="d-inline-block"
                                formControlName="paymentConfirmationImages"
                                [showChipButton]="true"
                                buttonIcon="photo"
                                [allowMultiple]="true"
                                buttonLabel="Add invoice image"
                                [showAttachments]="true"
                                [s3Category]="s3Category"
                                [acceptedFileTypes]="acceptedImageFileTypes">
                            </kl-image-picker-form-control>

                            <small class="d-block text-danger">*Please fill the above fields</small>
                        </form>


                    </div>
                </div>
            </ng-container>
        </div>

    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close><span translate>Cancel</span></button>
    <button mat-raised-button class="confirm-btn" *ngIf="data.paymentMode !== 'COD'" [mat-dialog-close]="true"><span
      translate>Confirm</span></button>
    <button mat-raised-button class="confirm-btn" *ngIf="data.paymentMode === 'COD'" (click)="submit()" [disabled]="paymentInfoForm?.invalid">
    <span translate >Submit</span></button>
</mat-dialog-actions>
