import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // We will not append userName and other details to http headers for this pincode service
    if(request.url.includes('https://api.postalpincode.in/pincode')) {      
      return next.handle(request);
    }

    const userName = localStorage.getItem('dXNlcm5hbWU=') ? atob(localStorage.getItem('dXNlcm5hbWU=')) : '';
    const password = localStorage.getItem('cGFzc3dvcmQ=') ? atob(localStorage.getItem('cGFzc3dvcmQ=')) : '';
    
    if(!(request.body instanceof FormData)) {
      request = request.clone({
        headers: request.headers.append('Content-Type', 'application/json')
      });
    }
    request = request.clone({
      headers: request.headers.append('version', environment.version)
    });
    request = request.clone({
      headers: request.headers.append('source', environment.source)
    });

    if(userName && password) {
      request = request.clone({
        headers: request.headers.append('userName', userName)
      });
      request = request.clone({
        headers: request.headers.append('password', password)
      });
    }

    return next.handle(request);
  }
  
}
