import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { fromEvent } from 'rxjs';
import { debounceTime, finalize, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { KalgudiDestroyable } from '@kalgudi/core';

import { SpinnerService } from '../../../app/project-common/services/spinner.service';
import { StorefrontService } from '../storefront.service';


interface CategorySet {
    loaded: boolean;
    combine?: boolean;
    combinedCategoryId?: string;
    combinedCategoryName?: string;
    categoryIds: string[];
}

class CategoryData {
    constructor(public id: string, public value: string, public listOfLevel2Cards: any[] = []) { }
}


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends KalgudiDestroyable implements OnInit {

    public isCategoryLoading: boolean;

    recommendedProductList: any[];

    categoriesDisplayOrder = [
        'FOOD_PRODUCTS',
        'HANDICRAFTS',
        'SPICES',
        'TEXTILES',
        'HEALTH_AND_PERSONAL_CARE',
        //'FRUITS_AND_VEGETABLES',
        'JEWELLERY',
        'HOME_AND_KITCHEN',
        'JEWELRY',
        'HERBAL_OTHERS'
    ]

    categorySets: CategorySet[] = [
        {
            loaded: false,
            combine: false,
            categoryIds: [
                //"FRUITS_AND_VEGETABLES",
                "FOOD_PRODUCTS",
                "HANDICRAFTS",
            ]
        },
        
        {
            loaded: false,
            combine: false,
            categoryIds: [
                "SPICES",
                "TEXTILES",
                "HEALTH_AND_PERSONAL_CARE",
            ]
        },
        
        {
            loaded: false,
            combine: false,
            categoryIds: [
                "JEWELLERY",
                "HOME_AND_KITCHEN",
                "JEWELRY",
            ]
        },

        {
            loaded: false,
            combine: true,
            combinedCategoryId: 'HERBAL_OTHERS',
            combinedCategoryName: 'Herbal & Others',
            categoryIds: [
                "TOYS",
                "PAPERS_AND_PLASTIC",
                "HERBAL_PRODUCTS",
            ]
        },

        {
            loaded: false,
            combine: true,
            combinedCategoryId: 'BEVERAGES_OTHERS',
            combinedCategoryName: 'Beverages & Others',
            categoryIds: [
                "BEVERAGES",
                "FOREST_PRODUCTS"
            ]
        },

    ];

    constructor(
        private spinner: SpinnerService,
        private storefrontService: StorefrontService,
        @Inject(KL_NOTIFICATION) private notificationService: KalgudiNotification
    ) {
        super();
    }

    ngOnInit() {
        this.getRecommendedProducts();

        const content = document.getElementById('app-content');

        fromEvent(content, 'scroll', { passive: true })
            .pipe(
                startWith(0),
                debounceTime(600),
                takeUntil(this.destroyed$),
                map(e => content.scrollTop),
                // tap(console.log)
            )
            .subscribe(scrollHeight => {
                this.categorySets.forEach((cs, index) => {
                    if (scrollHeight < (index * 600) || cs.loaded) return;


                    this.getCategories(cs);
                    cs.loaded = true;
                })
            });

    }


    allCategoriesDataMap: { [x: string]: CategoryData } = {};

    getCategories(categorySet: CategorySet) {
        this.isCategoryLoading = true;
        this.storefrontService.getCategoriesFromCategoryIds(categorySet.categoryIds)
            .pipe(
                finalize(() => this.isCategoryLoading = false)
            )
            .subscribe(
                (categoriesList: CategoryData[]) => {
                    if (!categoriesList || !categoriesList.length) return;

                    if (!categorySet.combine)
                        categoriesList.forEach(c => this.allCategoriesDataMap[c.id] = c);
                    else
                        this.allCategoriesDataMap[categorySet.combinedCategoryId] = this.combineCategories(categorySet, categoriesList);

                    // console.log('Categories Map , ', this.allCategoriesDataMap);
                }
            );
    }

    combineCategories(categorySet: CategorySet, categories: CategoryData[]) {
        if (!categorySet || !categorySet.combine) return;

        const combinedCategory = new CategoryData(categorySet.combinedCategoryId, categorySet.combinedCategoryName);

        combinedCategory.listOfLevel2Cards = categories.reduce((a, c) => [...a, ...(c && c.listOfLevel2Cards.length ? c.listOfLevel2Cards : [])], []);

        return combinedCategory;
    }

    /**
     * Calls api method to fetch recommended products 
     */
    getRecommendedProducts() {
        this.storefrontService.getRecommendedProducts()
            .pipe()
            .subscribe(
                res => {
                    if(res)
                    this.recommendedProductList = res.listOfLevel2Cards;
                },
                // err => {
                //     this.notificationService.showMessage
                // }
            )
    }

    protected onDestroyed(): void { }


}
