import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { KalgudiNotification, KL_NOTIFICATION } from "@kalgudi/core/config";

import { LocalStorage } from "@ngx-pwa/local-storage";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class MinimumCartValueGuard implements CanActivate {

    cart:any;
    grandTotal:number = 0;

    constructor(private localStorage: LocalStorage,
        @Inject(KL_NOTIFICATION) private notification: KalgudiNotification) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean { 

            return this.localStorage.getItem('cart').pipe(
                map(res => {
                    this.cart = res;
                    this.grandTotal = 0;
                    this.cart.products.forEach((item, index) => {
                        this.grandTotal = this.grandTotal + (Number(item.mrp) * Number(item.productQuantity));
                    });
                    if(this.grandTotal >= 250) {
                        return true;
                    } else {                        
                        this.notification.showSweetAlert({
                            title: 'Minimum cart value should be ₹250',                             
                            imageUrl: "assets/svgs/shopping-cart.svg",                            
                            imageWidth: 60,
                            imageHeight: 60,                            
                            
                        })
                        return false;
                    }                    
                })
            )
        }

}