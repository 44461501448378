import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { StorefrontService } from 'src/app/storefront/storefront.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SpinnerService } from '../../services/spinner.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pre-book',
  templateUrl: './pre-book.component.html',
  styleUrls: ['./pre-book.component.scss']
})
export class PreBookComponent implements OnInit {

  preBookForm: FormGroup;

  products = {
    banganpalli: {
      "productLevel3Title":"Bangenpalli / Beneshan",
      "pricePerUnit": 450,
      "productLevel1Id":"31t4",
      "productLevel2Id":"31t5",
      "productLevel3Id":"31t6",
      "productQuantity":1,
      "sku":"5 kg"
      },
    lime: {
      "productLevel3Title":"Sweet Lime (Mosambi)",
      "pricePerUnit": 400,
      "productLevel1Id":"3290",
      "productLevel2Id":"3291",
      "productLevel3Id":"3292",
      "productQuantity":1,
      "sku":"10 Kg"
      },
    himayat: {
      "productLevel3Title":"Himayat Mango",
      "pricePerUnit": 1250,
      "productLevel1Id":"32el",
      "productLevel2Id":"32em",
      "productLevel3Id":"32en",
      "productQuantity":1,
      "sku":"5 kg"
      }
  }

  constructor(
    private fb: FormBuilder,
    private storage: LocalStorage,
    private storeService: StorefrontService,
    private snackbar: MatSnackBar,
    private router: Router,
    private spinner: SpinnerService
  ) { }

  ngOnInit() {
    this.preBookForm = this.fb.group({
      banganpalliQty: [0],
      limeQty: [0],
      himayatQty: [0],
      userDetails: this.fb.group({
        firstName: ["", [Validators.required]],
        email: ["", [Validators.email]],
        mobile: ["", [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^[6-9]\d{9}$/)]],
        profileKey: [""]
      })
    });

    this.prefillFields();
  }

  prefillFields() {
    this.storage.getItem('userdata')
    .subscribe(userdata => {
      if(!userdata) return;

      let {emailId ="", mobileNo="", profileKey, firstName } = userdata;

      let emailControl = this.preBookForm.get('userDetails.email');
      let mobileControl = this.preBookForm.get('userDetails.mobile');
      let profileKeyControl = this.preBookForm.get('userDetails.profileKey');
      let firstNameControl = this.preBookForm.get('userDetails.firstName');

      firstNameControl.patchValue(firstName);
      profileKeyControl.patchValue(profileKey);

      if(emailId) {
        emailControl.patchValue(emailId);
        emailControl.disable();
        return;
      } 

      if(mobileNo) {
        mobileNo = +mobileNo.replace('+91', '');
        mobileControl.patchValue(mobileNo);
        mobileControl.disable();
        return;
      }
    });
  }

  bookNow() {
    if(!this.isValid) return;
    
    const products = this.getSelectedProducts();
    const { userDetails } = this.formValue;

    const payload = {
      products,
      totalAmount: this.totalValue,
      userDetails
    }

    this.spinner.start();
    this.storeService.preBook(payload)
    .pipe(finalize(() => this.spinner.stop()))
    .subscribe(res => {
      const snackbarConfig: MatSnackBarConfig = { duration: 4000 };
      if(res.code !== 201)
        return this.snackbar.open(`Booking failed, try after some time.`, `Ok`, snackbarConfig);
        
      this.snackbar.open(`Thank you for submitting the booking. We will get back to you soon.`, `Ok`, snackbarConfig);
      this.router.navigate(['/']);
    });
  }
  
  getSelectedProducts() {
    const finalProducts = [];
    const { banganpalliQty, limeQty, himayatQty } = this.formValue;
    const { banganpalli, lime, himayat }  = this.products;
    if(+banganpalliQty) {
      banganpalli.productQuantity = +banganpalliQty;
      finalProducts.push(banganpalli);
    }

    if(+limeQty) {
      lime.productQuantity = +limeQty;
      finalProducts.push(lime);
    }

    if(+himayatQty) {
      himayat.productQuantity = +himayatQty;
      finalProducts.push(himayat);
    }
    return finalProducts;
  }

  get isValid() {
    if(!this.preBookForm || !this.preBookForm.value) return false;

    const mobileControl = this.preBookForm.get('userDetails.mobile');
    const emailControl = this.preBookForm.get('userDetails.email');

    return (
      (this.totalValue > 0) 
      && this.preBookForm.valid 
      && (mobileControl.valid || emailControl.valid)
      && (mobileControl.value || emailControl.value)
    );
  }

  get formValue() {
    return this.preBookForm.getRawValue();
  }

  get totalValue() {
    if(!this.preBookForm || !this.preBookForm.value) return 0;

    const { banganpalliQty, limeQty, himayatQty } = this.preBookForm.value;

    const { banganpalli, lime, himayat }  = this.products;

    return banganpalli.pricePerUnit * banganpalliQty + 
    lime.pricePerUnit * limeQty +
    himayat.pricePerUnit * himayatQty;
  }

}
