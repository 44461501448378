import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { TranslateService } from "@ngx-translate/core";
import { UserProfile } from "src/app/project-common/models/user.model";
import { SpinnerService } from "src/app/project-common/services/spinner.service";
import { UserSubscriberService } from "src/app/project-common/services/user-subscriber.service";
import { Storefront } from 'src/app/project-common/properties/constants';
import { interval } from "rxjs";
import { take } from "rxjs/operators";
@Component({
  selector: "subscribe-us",
  templateUrl: "./subscribe-us.component.html",
  styleUrls: ["./subscribe-us.component.scss"],
})
export class SubscribeUsComponent implements OnInit, AfterViewInit {
  email = new FormControl("", [Validators.required, Validators.email]);
  userProfile: UserProfile;
  countryDetails: any;
  emailRegEx: RegExp;
  backgroundImage: string = '';
  constructor(
    public router: Router,
    private subscriberService: UserSubscriberService,
    private snackbar: MatSnackBar,
    private translator: TranslateService,
    private spinner: SpinnerService,
    private storage: LocalStorage
  ) { }

  ngOnInit() {
    this.storage.getItem("userdata").subscribe((userData) => {
      this.userProfile = userData;
    });
    this.storage.getItem("countryDetails").subscribe((country) => {
      this.countryDetails = country;
    });
    this.emailRegEx = Storefront.emailRegEx;
  }

  ngAfterViewInit () {
    interval(3000).pipe(take(1)).subscribe(() => {
      this.backgroundImage = `assets/apgreen/subscribe-us-bg.jpg`;
    })
  }

  subscribeUs() {
    const first = `Please enter a valid e-mail address`;
    const last = `OK`;
    if (this.email.invalid) {
      return this.translator.get([first, last]).subscribe((translatedText) => {
        this.snackbar.open(
          `${translatedText[first]}`,
          `${translatedText[last]}`,
          {
            duration: 4000,
            panelClass: ["snakbar-color"],
          }
        );
      });
    }

    this.spinner.start();
    this.subscriberService.subscribeToNewsLetters(this.email.value).subscribe(
      (res) => {
        this.spinner.stop();
        if (res.code == 201) {
          this.email.reset();
          this.translator.get(['Subscribed successfully', "OK"]).subscribe(translatedText => {
            return this.snackbar.open(translatedText['Subscribed successfully'], translatedText["OK"], {
              duration: 6000,
              panelClass: ["snakbar-color"],
            });
          })
        }
      },
      (error) => {
        this.spinner.stop();
        this.snackbar.open(error.info, "", {
          duration: 6000,
          panelClass: ["snakbar-color"],
        });
      }
    );
  }
  public clearEmailInputField() {
    this.email.reset();
  }
}
