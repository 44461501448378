import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogComponent } from './../components/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmUserService {

  constructor(private dialog: MatDialog) { }


  confirm(message: string, execute: () => void) {
    this.dialog.open(ConfirmDialogComponent, {
        data: {
            message,
            okText: 'Yes',
            hideCancel: false
        }
    })
    .afterClosed()
    .subscribe(hasConfirmed => {
        if(!hasConfirmed) return;

        execute();
    })
  }
}
