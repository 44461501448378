<div class="py-30 px-40">
  <div fxLayoutAlign="center center">
    <div class="pincode-input">
      <i class="fa fa-map-marker"></i>
      <input [formControl]="deliveryPincodeInput" type="tel" (keypress)="isNumber($event)" minlength="6" maxlength="6" placeholder="{{'Enter pincode'| translate}}" fxFlex>
      <ng-container *ngIf="!loading">
        <i class="fa fa-paper-plane" (click)="setPincode()"></i>
      </ng-container>
     <ng-container *ngIf="loading">
      <i class="fa fa-spinner fa-spin"></i>
     </ng-container>
    </div>
  </div>

  <div class="mt-20" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div class="border-bottom" fxFlex></div>
    <span class="font-20" translate>Or select your address</span>
    <div class="border-bottom" fxFlex></div>
  </div>
</div>

<ng-container *ngIf="isUserLoggedIn; then loggedInTemplate; else loggedOutTemplate"></ng-container>

<ng-template #loggedInTemplate>
  <ng-container *ngIf="(addresses$ | async) as addresses">

    <ng-container *ngIf="addresses?.length; then addressesTemplate; else noAddressesTemplate"></ng-container>
    <ng-template #addressesTemplate>
      <ng-container *ngFor="let address of addresses;let first = first">
        <div class="del-address" [class.selected]="address?.id === (deliveryLocationSelected$ | async)?.id"
          [class.first]="first" (click)="setDeliveryAddress(address)">
          <p class="mb-5">
            {{address.name}} ({{ address.phone }})
          </p>
          <span>
            {{ address.addressLine1 }}, {{ address.addressLine2 }}, {{ address.city }}, {{ address.state }} -
            {{ address.postalCode }}
          </span>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #noAddressesTemplate>
      <div class="text-center p-10">
        <a routerLink="/account/address" mat-ripple class="add-address" (click)="onChange.emit()">{{ 'Add address' | translate}}</a>
      </div>
    </ng-template>
    


  </ng-container>
</ng-template>
<ng-template #loggedOutTemplate>
  <div class="text-center">
    <a routerLink="/auth/login" class="text-info border-bottom" (click)="onChange.emit()">{{'Login' | translate}}</a>
    <p class="mt-10 m-0" translate>To see your saved addresses</p>
  </div>
</ng-template>