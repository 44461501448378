import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ProfileService } from 'src/app/profile/profile.service';
import { FileMimeTypes, KalgudiUser } from '@kalgudi/types';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KALGUDI_S3_POLICY_MAP } from '@kalgudi/core';

@Component({
  selector: 'confirm-order-dialog',
  templateUrl: './confirm-order-dialog.component.html',
  styleUrls: ['./confirm-order-dialog.component.scss']
})
export class ConfirmOrderDialogComponent implements OnInit {

  checkoutDetails: any;
  public canAssist: boolean;
  paymentModeLabelsMap = {
    'CASH_PAYMENT': 'Cash Payment',
    'COD': 'Bank Transfer',
    'wallets': 'Online Payment'
  };

  paymentInfoForm: FormGroup;

  assistantUserdata: KalgudiUser;

  readonly paidThroughModes = [
    'UPI',
    'Gpay',
    'PhonePe',
    'Paytm',
    'Others'
  ];

  s3Category = KALGUDI_S3_POLICY_MAP.DEFAULT;
  readonly acceptedImageFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE ];



  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ConfirmOrderDialogComponent>,
    private profileService: ProfileService,
    private fb: FormBuilder,
  ) {
    this.paymentInfoForm = this.newPaymentInfoForm;
  }

  ngOnInit() {
    this.canAssist = JSON.parse(localStorage.getItem('Y2FuQXNzaXN0') || 'false');
    this.checkoutDetails = this.data.data;

    this.profileService.getUserDataFromLocalStorage()
    .subscribe(userdata => {
      this.assistantUserdata = userdata;
    })

  }

  /**
   * Form group for payment form
   */
  private get newPaymentInfoForm(): FormGroup {

    return this.fb.group({
      bankRefId: ['', Validators.required],
      mode: ['', Validators.required],
      paymentConfirmationImages: ['', Validators.required],
    });
  }

  submit() {
    const payload = this.paymentInfoForm.value;

    this.dialogRef.close(payload);
  }

}
