import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from 'src/environments/environment';
declare var FB: any;
@Component({
  selector: 'app-social-sharing',
  templateUrl: './social-sharing.component.html',
  styleUrls: ['./social-sharing.component.scss']
})

export class SocialSharingComponent implements OnInit {

  @Input() socialDetails: any;
  @Input('p-tile') isForProductTile = false;
  @Input('hideLabels') hideLables = true;

  shareUrl: any;
  mailTo: string;
  whatsAppMobile: string;
  whatsAppDesktop: string;
  whatsApp: string;

  constructor(private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.mailTo = `mailto:?body=${environment.bizStoreDomain}${this.socialDetails.url}&subject=Kalgudi - ${this.socialDetails.title} by ${this.socialDetails.by}`;
    this.whatsAppMobile = `https://api.whatsapp.com/send?text=${environment.bizStoreDomain}${this.socialDetails.url}`;
    this.whatsAppDesktop = `https://web.whatsapp.com/send?text=${environment.bizStoreDomain}${this.socialDetails.url}`;
    // console.log(this.whatsApp);

  }

  shareCurrentPage(socialSite) {

    if (socialSite === 'fb') {

      this.shareUrl = window.open('https://www.facebook.com/sharer/sharer.php?u=' + environment.bizStoreDomain + escape(this.socialDetails.url) + '&t=' + this.socialDetails.title,

        '_blank');


    } else if (socialSite === 'twitter') {

      this.shareUrl = window.open('http://twitter.com/share?url=' + environment.bizStoreDomain + encodeURIComponent(this.socialDetails.url), 'toolbar=0,status=0,width=626,height=436');
    } else if (socialSite === 'linkedIn') {
      this.shareUrl = window.open(
        `http://www.linkedin.com/shareArticle?mini=true&url=${environment.bizStoreDomain}${this.socialDetails.url}`, '_blank');
    } else if (socialSite === 'tumblr') {
      this.shareUrl = window.open(`http://tumblr.com/widgets/share/tool?canonicalUrl=${environment.bizStoreDomain}${this.socialDetails.url}`, '_blank');
    }

    return this.shareUrl;
  }

  copyProductUrl() {
    const productUrl = `${environment.bizStoreDomain}${this.socialDetails.url}`
    navigator.clipboard.writeText(productUrl).then().catch(e => console.error(e));
  }

  displayCopyMessage() {
    this.snackbar.open('URL successfully copied.', 'OK', { duration: 3000 });
  }
}
