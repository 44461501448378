import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({

  providedIn: 'root',

})

export class AssistanceGuardService implements CanActivate {


  constructor(private router: Router) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

    return new Promise (resolve => {

      const canAssist = JSON.parse(localStorage.getItem('Y2FuQXNzaXN0') || 'false');

      const isAssistanceChecked = JSON.parse(sessionStorage.getItem('isAssistanceChecked') || 'false');

      if (canAssist && !isAssistanceChecked) {

        this.router.navigate(['/checkout', 'assist']);

      }

      resolve(!canAssist || isAssistanceChecked);

    });

  }

}