import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo, map } from 'rxjs/operators';
import { HttpWithAuthService } from './http-interceptor.service';
import { environment } from '../../../environments/environment';
import { NotificationService } from './notification.service';
import { AppNotification } from '../models/storeNotification.model';
import { UtilityService } from './utility.service';
import { Storefront } from '../properties/constants';

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  public notifications: AppNotification;
  public isMobileDevice: boolean;

  constructor(
    private afMessaging: AngularFireMessaging,
    private httpWithAuth: HttpWithAuthService,
    private utility: UtilityService,
    private notify: NotificationService) { 
    this.resetNotification();  
  }

  /**
   * To reset the store notifiction object
   */
  public resetNotification () {
    this.notifications = {
      notifications:[],
      unreadCount:0,
      totalCount:0
    }
  }

  /**
   * It will process all the new FCM notification if app is in forground
   * @param notification Incomming FCM notification object
   */
  public processNewNotification (notification) {
    this.isMobileDevice = this.utility.isMobileDevice();
    // If logedIn then process
    if ( JSON.parse(window.localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || false) ) {

        const notificationData = notification.data;

        // If notification to update store notification
        if ( notificationData && JSON.parse(notificationData.isStoreNotification || false) ) {
          this.isMobileDevice ? this.notify.handleNewNotifications(true) : null;
          this.getStoreNotification();
        }
        // If notification for live chat
        if ( notificationData && JSON.parse(notificationData.isLiveChat || false) && notificationData.deepLinkUrl ) {
          notificationData.color = '#f88685',
            this.notify.sendNotification(
                notificationData.title,
                notificationData.body,
                notificationData.icon,
                notificationData.deepLinkUrl,
                notificationData.color,
                'liveChat'


            );                
        }

        // If notification for RFQ Enquiry
        if ( notificationData && JSON.parse(notificationData.isEnquiryRFQ || false)) {
          notificationData.color = '#35ac1a',
          this.notify.sendNotification(
              notificationData.title,
              notificationData.body,
              notificationData.icon,
              notificationData.deepLinkUrl,
              notificationData.color,
              'rfqEnquiry'
          );                
        }
    }
  }

  /**
   * It will ask for permission to send notification and generate fcm token
   */
  public requestToken () {
    const _this = this;
    return new Promise(function (resolve, reject) {
      _this.afMessaging.requestPermission
        .pipe(mergeMapTo(_this.afMessaging.tokenChanges))
        .subscribe(
          (token) => { console.log('Permission granted! Save to the server!', token); resolve(token); },
          (error) => { /* console.error(error); */ reject(error);},  
      );
    });
  }

  /**
   * This will get triggered on each new FCM notification if app is in forground.
   */
  public listenForMessage() : Observable<any>{
     return new Observable(observer => {
       this.afMessaging.messages.subscribe((message) => { 
        console.log(message); 
        observer.next(message);
        // observer.complete();
      });
    });
  }

  /**
   * Service to map user profileKey and fcm token
   * @param req required payload for put service
   */
  public updateDeviceDetails (req: any): Observable<any> {
    var reqPayload = {
      'os'          : navigator.platform, 
      "LUT"         : new Date().toISOString(),
      'fcmId'       : req.refreshedToken,
      'appType'     : 'web',
      'profileKey'  : req.profileKey ? req.profileKey : '',
      'sourcePortal': Storefront.APP_NAME,
      'isMobileDevice': this.utility.isMobileDevice()
    }
    return this.httpWithAuth.put(`${environment.baseUrl}/rest/v2/store/notification/registerdevice`,
      reqPayload).pipe(map(response => {
        const result = response;
        return result;
    }, error => {
      console.log(error);
    }));
  }

  /**
   * To get user store notification
   */
  public getStoreNotification () {
    const url = `${environment.baseUrl}/v2/store/notification/BIZ`;
      this.httpWithAuth.get(url)
      .subscribe(
          (response: any) => {
              if (response.data.notifications)
                  this.notifications = response.data;
          },
          ({error}) => {throw new Error(error.info)} 
      );
  }

  /**
   * To mark store notification as read
   * @param reqPayload required payload for read notification
   */
  public readStoreNotification ( reqPayload ): Observable<any> {
    const url = `${environment.baseUrl}/v2/store/notification/read/BIZ`
    return this.httpWithAuth.put(url,reqPayload).pipe(map(response => {
        if (response.data.notifications)
                  this.notifications = response.data;
        const result = response;
        return result;
    }, error => {
      console.log(error);
    }));
  }

}
