import { Component, OnInit } from '@angular/core';
import { DbUpdateService } from 'src/app/project-common/services/db-update.service';

@Component({
  selector: 'logout-handler',
  template: '',
  styleUrls: []
})
export class LogoutHandlerComponent implements OnInit {

  constructor(
    private dbService: DbUpdateService ) { }

  ngOnInit() {
    this.dbService.clearUserData('/auth/login');
  }

}
