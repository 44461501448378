import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  private scripts: ScriptModel[] = [];

  constructor() {

   }

    public loadScript(script: string): Observable<any> {
        return new Observable(observer => {
			var existingScript = this.scripts.find(s => s.src == script);

            // Complete if already loaded
            if (existingScript && existingScript.loaded) {
				observer.next(existingScript);
                observer.complete();
            } else {
                // Load the script
                let scriptElement = document.createElement("script");
				scriptElement.type = "text/javascript";
				scriptElement.async = true;
                scriptElement.src = script;

                scriptElement.onload = () => {
					this.scripts.push({src: script, loaded: true});
                    observer.next(script);
                    observer.complete();
                };

                scriptElement.onerror = (error: any) => {
					this.scripts.push({src: script, loaded: false});
                    observer.error("Couldn't load script " + script);
                };

                document.getElementsByTagName('body')[0].appendChild(scriptElement);
            }
        });
    }

    public loadStyle(script: string): Observable<any> {
      return new Observable(observer => {
        var existingScript = this.scripts.find(s => s.src == script);

          // Complete if already loaded
          if (existingScript && existingScript.loaded) {
              observer.next(existingScript);
              observer.complete();
          } else {
              // Load the script
              let scriptElement = document.createElement("link");
              scriptElement.type = 'text/css';
              scriptElement.rel = 'stylesheet';
              scriptElement.href = script;

              scriptElement.onload = () => {
                this.scripts.push({src: script, loaded: true});
                observer.next(script);
                observer.complete();
              };

              scriptElement.onerror = (error: any) => {
                this.scripts.push({src: script, loaded: false});
                observer.error("Couldn't load script " + script);
              };

              document.getElementsByTagName('body')[0].appendChild(scriptElement);
          }
      });
  }

}

export interface ScriptModel {
    src: string,
    loaded: boolean
}
