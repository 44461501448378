import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppNotifierService, AppNotify } from '../../services/app-notifier.service';

@Component({
  selector: 'notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss'],
  animations: [
    trigger('notify-anim', [

      state('void', style({ opacity: 0, transform: 'translateY(-25px)' })),

      transition('* <=> void', [animate('200ms ease-in-out')])

    ])
  ]
})
export class NotifierComponent implements OnInit {


  notifications$: Observable<AppNotify[]>;

  constructor(
    private notifier: AppNotifierService
  ) { }

  ngOnInit() {
    this.notifications$ = this.notifier.notifications$;
  }

}
