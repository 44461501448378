import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';
import { DeliveryAddress } from '../../models/shipping-form.model';
import { ProfileService } from '../../../profile/profile.service';
import { SpinnerService } from '../../services/spinner.service';
import { NgForm } from '@angular/forms';
import { GooglePlacesApiService } from '../../../modules/google-places/services/google-places-api.service';
import { GoogleLocation } from '../../models/geo-location';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {

  @Input() addressForm: DeliveryAddress;
  @Output() closeForm = new EventEmitter();

  @ViewChild('shippingForm') shippingForm: NgForm;
  @ViewChild('search') googleSearchInput: ElementRef;

  Counter = 0;
  userData: any;
  listOfAddress: any[];
  newAddressFormFlag: any;
  stateList: string[];
  hasSelectedAddress: boolean;

  constructor(
    private profileService: ProfileService,
    private spinner: SpinnerService,
    private placesApi: GooglePlacesApiService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.addressForm = this.addressForm || new DeliveryAddress();
    if (this.addressForm.residence) {
      this.hasSelectedAddress = true;
    }
    this.profileService.getUserDataFromLocalStorage().subscribe(userDataOnStorage => {
      this.userData = userDataOnStorage;
      this.autoFillUserDetails(this.userData);
    });
    this.newAddressFormFlag = false;
    this.getStateList();
    const mapOptions = {
      componentRestrictions: { country: ["in"] }
    };
    setTimeout(() => {
      this.placesApi.registerListener(this.googleSearchInput, mapOptions);
      this.placesApi.placeChange().subscribe(this.placeChanged);
      this.shippingForm.form.controls['google-search'].markAsTouched();
    }, 100);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public check(event: KeyboardEvent) {
    if ((event.keyCode === 8 || event.keyCode === 48) && this.hasSelectedAddress) {
      this.hasSelectedAddress = false;
      this.addressForm.residence = '';
      this.addressForm = new DeliveryAddress();
      this.autoFillUserDetails(this.userData);
    }
  }

  saveAddress() {
    this.spinner.changeStatus(true, true);
    if (!this.addressForm.addressLine1.includes(this.addressForm.residence)) {
      this.addressForm.addressLine1 += `, ${this.addressForm.residence}`;
    }
    this.profileService.saveAddress(this.userData.profileKey, this.addressForm).subscribe(response => {
      this.spinner.changeStatus(false, false);
      if (response.code === 200 || response.code === 201) {
        this.listOfAddress = JSON.parse(response.data);
        this.closeForm.emit({ addressList: this.listOfAddress });
        /* this.newAddressFormFlag = false; */
      }
    }, error => {
      this.spinner.changeStatus(false, false);
    });
  }

  resetAddressForm() {
    this.addressForm = new DeliveryAddress();
    this.addressForm.phone = this.getUserMobileNumber(this.userData);
    this.newAddressFormFlag = !this.newAddressFormFlag;
    this.closeForm.emit(true);
  }

  autoFillUserDetails(user) {
    if (!this.addressForm.name) {
      this.addressForm.name = user.firstName;
    }
    if (!this.addressForm.phone) {
      this.addressForm.phone = this.getUserMobileNumber(user);
    }
    /* if (this.addressForm.city) {
      this.shippingForm.controls['city'].disable();
    } */
  }

  getUserMobileNumber(user) {
    let mobile = '';
    if (!user.mobileNo.includes(user.profileKey.substring(1, 7))) {
      mobile = user.mobileNo.replace(user.mobileCode || '+91', '');
    } else if (user.alternateMobileNo) {
      mobile = user.alternateMobileNo;
    }
    return mobile;
  }

  private placeChanged = (locationSelected) => {
    this.ngZone.run(() => {
      const google = this.shippingForm.form.controls['google-search'];
      if (!this.isValidGoogleLocation(locationSelected.geoLocation.googleLocationTo)) {
        google.setErrors({
          'incorrect': true
        });

      } else {
        google.setErrors(null);
        this.hasSelectedAddress = true;
        this.addressForm.googleLocation = locationSelected.geoLocation.googleLocationTo;
        this.addressForm.postalCode = this.addressForm.googleLocation.zipcode || '';
        const city = this.addressForm.googleLocation.locality || '';
        this.addressForm.city = city;
        const currentState = this.stateList.find(s => s.toLocaleLowerCase() === (this.addressForm.googleLocation.adminLevel1 || '').toLocaleLowerCase());
        this.addressForm.state = currentState || '';
        this.addressForm.addressLine2 = locationSelected.subLocality || '';
        this.addressForm.residence = locationSelected.place.name || '';
        this.shippingForm.form.controls['address-line-1'].markAsTouched();
      }
      google.updateValueAndValidity({ emitEvent: true, onlySelf: false });
    })
  }

  getStateList() {
    this.stateList = [
      'Andhra Pradesh',
      'Arunachal Pradesh',
      'Assam',
      'Bihar',
      'Chhattisgarh',
      'Delhi',
      'Goa',
      'Gujarat',
      'Haryana',
      'Himachal Pradesh',
      'Jammu and Kashmir',
      'Jharkhand',
      'Karnataka',
      'Kerala',
      'Madhya Pradesh',
      'Maharashtra',
      'Manipur',
      'Meghalaya',
      'Mizoram',
      'Nagaland',
      'Odisha',
      'Punjab',
      'Rajasthan',
      'Sikkim',
      'Tamil Nadu',
      'Telangana',
      'Tripura',
      'Uttar Pradesh',
      'Uttarakhand',
      'West Bengal'
    ];
  }

  private isValidGoogleLocation(location: GoogleLocation): boolean {
    return !!(location.zipcode && location.locality && location.adminLevel1);
  }

}
