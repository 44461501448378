<section>
	<div
		class="app-container subscribe d-flex flex-column  justify-content-center d-flex align-items-center"
		[style.backgroundImage]="
			'url(' +
			(backgroundImage | scaleImage: 'format') +
			')'
		"
	>
		<!-- <div -->

		<!-- > -->
		<div class="d-flex flex-column justify-content-center align-items-center">
			<h1 class="fw-700 subscribe-title" translate>Get Notified !!!</h1>
			<!-- <p class="subscribe-text" translate>Subscribe to the newsletter for latest products</p> -->
		</div>

		<div
			class="subscribe-input justify-content-center d-flex flex-column flex-md-row"
		>
			<div class="input-sec">
				<input
					type="email"
					[pattern]="emailRegEx"
					[formControl]="email"
					placeholder="{{ 'Your email address...' | translate }}"
				/>
				<img
					class="close-icon cursor-pointer"
					[src]="'assets/icons/close-icon.png'"
					alt=""
					height="10"
					loading="lazy"
					(click)="clearEmailInputField()"
				/>
			</div>
			<div class="d-flex align-items-center justify-content-center">
				<button type="submit" (click)="subscribeUs()">
					<B translate>Subscribe</B>
				</button>
			</div>
		</div>
		<!-- </div> -->
	</div>
</section>
