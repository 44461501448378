<a class="card-container">
  <div
    class="image"
    [style.height]="avatarSize + 'px'"
    fxFlex="{{ avatarSize }}px"
  >
    <img [src]="avatarUrl | scaleImage: (!noScaleImage ? '' : 'pic150')" />
  </div>
  <div
    class="card-right"
    fxLayout="column"
    fxLayoutGap="5px"
    fxFlex="calc(100% - {{ avatarSize }}px)"
  >
    <ng-content></ng-content>
  </div>
</a>
