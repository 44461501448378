import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { CustomeNotification } from '../models/storeNotification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private userDataChangedSubject = new Subject<any>();
  userDataChanges = this.userDataChangedSubject.asObservable();

  private languageSubject = new Subject<string>();
  languageChanges = this.languageSubject.asObservable();


  private bulkOrderRequest = new Subject<any>();
  public notificationActive = new Subject<CustomeNotification>();
  public liveChat = new Subject<any>();
  public isNewMsg = new Subject<any>();
  public isNewNotification = new Subject<any>();

  constructor() { }

  public notifyForUserDataChange() {
    this.userDataChangedSubject.next();
  }

  public notifyForLanguageChange(language: string) {
    localStorage['lang'] = language;

    this.languageSubject.next(language);

    const bodyElement = document.getElementById('app-body');

    (language === 'en')
      ? bodyElement.classList.remove('other-language')
      : bodyElement.classList.add('other-language')

  }

  public sendBulkOrderProducts(productList: any[]) {
    this.bulkOrderRequest.next(productList);
  }

  public getBulkOrderProducts(): Observable<any> {
    return this.bulkOrderRequest.asObservable();
  }

  public sendNotification(title: string, message: string, icon?: string, deepLinkUrl?: string, color?: string, type?: string): void {

    this.notificationActive.next({
      title,
      message,
      icon,
      deepLinkUrl,
      color,
      type
    });
  }

  public liveChatNotification(isLiveChat: boolean) {
    this.liveChat.next(isLiveChat);
  }

  public handleNewNotifications(newNotification: boolean) {
    this.isNewNotification.next(newNotification)
  }
}
