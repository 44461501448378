<ng-container #dynamicTemplate></ng-container>
<div class="help-container">

  <div class="app-container">
      <div fxLayout="column" fxLayoutGap="35px" fxLayoutGap.xs="15px" class="footer-link-template">
          <h3 class="title" [innerHtml]="subheader">
          </h3>
          <div [innerHtml]="helperHtml"></div>
      </div>
  </div>
</div>
