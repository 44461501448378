

<section class="item other-apps-sec">
<div class="all-app cursor-pointer" fxLayoutAlign="start center">
  <span class="title d-flex flex-row align-item-center"> <svg-icon src="assets/aboutus/app.svg" [svgStyle]="{'width.px': '15', 'height.px': '15'}"
    [applyCss]="true" class="mr-9 store-products-icon"></svg-icon> <span class="mt-2" translate>{{otherAppsList?.title}}</span> </span>
</div>
  <div class="apps-sec menu-data">
    <div class="apps-wrapper d-flex p-35 flex-row justify-content-between">
     <div class="d-flex flex-column" fxFlex="50%">
      <div class="align-items-center d-flex heading flex-row">
        <img height="24px" width="24px" src={{otherAppsList?.storeImageUrl}} alt="Logo">
        <div class="d-flex flex-column">
          <span class="font-20 store-name ml-10" translate>{{otherAppsList?.storeTitle}}</span>
        </div>
        
    </div>
    <p class="mt-10 desc font-14" translate>{{otherAppsList?.storeDescription}}</p>
     </div>
     <div class="d-flex flex-column right-section"  fxFlex="50%">
    <ng-container *ngFor="let app of otherAppsList?.storeDetails">
      <a target="_blank" [href]="app?.url">
        <div class=" d-flex heading flex-row">
          <img height="24px" src={{app?.imageUrl}} alt="Logo">
          <div class="d-flex flex-column">
            <span class="font-16 store-name ml-8" translate>{{app?.name}}</span>
            <span class="font-13 mt-5 store-detail ml-8" translate>{{app?.description}}</span>
          </div>
      </div>
      </a>
    </ng-container>
     </div>
    </div>
  </div>
</section>