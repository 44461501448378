import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { GooglePlacesApiService } from 'src/app/modules/google-places/services/google-places-api.service';
import { GoogleAutocompleteComponent } from './components/google-autocomplete/google-autocomplete.component';
import { TranslateModule } from '@ngx-translate/core';
import { GeoApiService } from './services/geo-api.service';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { LocationAccessErrorDialogComponent } from './components/locatio-access-error-dialog/locatio-access-error-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    GoogleAutocompleteComponent,
    GoogleMapComponent,
    LocationAccessErrorDialogComponent
  ],
  imports: [
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ["places", "geometry"],
    }),
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    FlexLayoutModule,
    TranslateModule,
    MatSnackBarModule

  ],
  exports: [
    GoogleAutocompleteComponent,
    GoogleMapComponent
  ],
  entryComponents: [
    LocationAccessErrorDialogComponent
  ],
  providers: [
    GooglePlacesApiService,
    GeoApiService
  ]
})
export class GooglePlacesModule { }
