import { Component, OnDestroy, OnInit } from '@angular/core';
import { Cart, CartProduct } from '@app/models';
import { KalgudiDestroyable } from '@kalgudi/core';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CartService } from 'src/app/project-common/services/cart.service';
import { SideBarService } from 'src/app/side-bar.service';
import { STORE_LABELS_MAP } from 'src/app/store-mapping';

@Component({
  selector: 'cart-side-bar',
  templateUrl: './cart-side-bar.component.html',
  styleUrls: ['./cart-side-bar.component.scss']
})
export class CartSideBarComponent extends KalgudiDestroyable implements OnInit {

  storeLabelsMap = STORE_LABELS_MAP;

  cart: Cart;
  storeSet: { [key: string]: CartProduct[] };

  cartItemsTotal: number;


  constructor(
    private sidebarService: SideBarService,
    private cartService: CartService
  ) {
    super();
  }

  ngOnInit() {

    this.cartService.cartUpdates()
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(() => this.cartService.getCartData())
      )
      .subscribe(cart => {
        this.cart = cart;

        this.getStoreSet();
      })

  }

  private getStoreSet() {
    if (!this.cart) return;

    this.storeSet = this.cart.products.reduce((acc, curr) => {
      let store = acc[curr.originStore];

      if (store) store.push(curr);
      else acc[curr.originStore] = [curr];

      return acc;
    }, {});

    this.cartItemsTotal = this.cart.products.reduce((total, p) => (total + (p.pricePerUnit * +p.productQuantity)), 0);
    
  }

  // Emit value on click of side cart

  public close(): void {

    this.sidebarService.closeCartSidebar();
  }

  onDestroyed() { }



}
