import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { CategoryService } from '../../services/category.service';

@Component({
  selector: 'two-row-product-section',
  templateUrl: './two-row-product-section.component.html',
  styleUrls: ['./two-row-product-section.component.scss']
})
export class TwoRowProductSectionComponent implements OnInit {

  @Input() products: any;

  @Input() cardPosition: 'right' | 'left' = 'left';

  categoryCards$: Observable<any>;

  constructor(
    private categoryService: CategoryService
  ) { }

  ngOnInit() {
    this.categoryCards$ = this.categoryService.categoryCards$;
  }

}
