import { MapsAPILoader } from '@agm/core';
// import { google, Geocoder, GeocoderResult, GeocoderStatus } from '@agm/core/services/google-maps-types';
import { ElementRef, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, map, switchMap, takeLast } from 'rxjs/operators';
import { GeoApiService } from './geo-api.service';
declare var google: any;
@Injectable()
export class GooglePlacesApiService {

  private address$: Subject<any>

  private currentLocation$: BehaviorSubject<any>;

  constructor(
    private mapApiLoader: MapsAPILoader,
    private ngZone: NgZone,
    private geoApi: GeoApiService
  ) {
    this.address$ = new Subject();
  }


  /**
   * To register google autocomplete service for an input field
   * 
   * @param searchElement Input field 
   * @param options 
   */
  public registerListener(searchElement: ElementRef, options = {}): void {
    setTimeout(() => {
      /**
       *  This method will give to the google maps address results
       *
       */
      this.mapApiLoader && this.mapApiLoader.load().then(
        () => {
          const autocomplete = new google.maps.places.Autocomplete(searchElement.nativeElement, options);
          autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
              const place: google.maps.places.PlaceResult = autocomplete.getPlace();
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }

              place.address_components = this.addressComponentFromGoogleGeoCode(place.address_components);
              place.address_components['formatted_address'] = place.formatted_address;
              const geoLocation = {
                googleLocationTo: {
                  locality: place.address_components['locality'],
                  adminLevel2: place.address_components['administrative_area_level_2'],
                  adminLevel1: place.address_components['administrative_area_level_1'],
                  countryName: place.address_components['country'],
                  zipcode: place.address_components['postalCode'] || place.address_components['postal_code'],
                  latitude: place.geometry['location'].lat().toString(),
                  longitude: place.geometry['location'].lng().toString()
                },
                formatted_address: place.address_components['formatted_address']
              };
              let subLocality = place.address_components['sublocality_level_2'] ? place.address_components['sublocality_level_2'] : '';
              if (subLocality && place.address_components['sublocality_level_1']) {
                subLocality += ', ' + place.address_components['sublocality_level_1'];

              } else {
                subLocality = place.address_components['sublocality_level_1'] || '';
              }

              // If didn't got any locality from google then check for other available addresses
              if (!geoLocation.googleLocationTo.locality) {
                geoLocation.googleLocationTo.locality = (
                  (place.address_components['sublocality']) || (place.address_components['sublocality_level_1'])
                  || (place.address_components['sublocality_level_2']) || (place.address_components['sublocality_level_3']) ||
                  (place.address_components['sublocality_level_4']) || (place.address_components['sublocality_level_5'])
                );

              }
              if (!geoLocation.googleLocationTo.adminLevel2 && geoLocation.googleLocationTo.locality) {
                geoLocation.googleLocationTo.adminLevel2 = geoLocation.googleLocationTo.locality;
              }
              this.address$.next({
                searchFor: '',
                place: place,
                subLocality: subLocality,
                geoLocation: geoLocation
              });
            });
          });
        }
      );
    });
  }

  /**
   * To listen place changes
   */
  public placeChange() {
    return this.address$.asObservable();
  }

  /**
   * Will read lat long for current location and search for it in google map, 
   * Will return an address if found any;
   */
  public fetchCurrentLocation(showErrorMessage = true) {
    // if (!this.currentLocation$) {
    return this.geoApi.readCurrentGeoLocation(showErrorMessage).pipe(
      // takeLast(1),
      delay(300),
      map(res => {
        this.currentLocation$ = new BehaviorSubject(res ? res.coords : null);
        return res ? res.coords : null
      }));

    // } else {
    //   return this.currentLocation$.asObservable();
    // }
  }

  /**
   * To search for the location on google based on zip code of the area
   * 
   * @param pincode zipcode
   */
  public searchForPinCode(pincode: string) {
    return this.searchOnGoogle({ pincode });
  }

  /**
   * To use google places api to find a place based on given parameters
   * 
   * @param searchFields search parameters
   */
  public searchOnGoogle(searchFields) {
    return new Observable(observer => {
      setTimeout(() => {
        this.mapApiLoader && this.mapApiLoader.load().then(
          () => {
            if (typeof google !== 'object' || typeof google.maps !== 'object') {
              observer.error(`google map is blocked by the client browser`);
              return;
            }
            const geoCoder = new google.maps.Geocoder();
            let params: any = { region: 'IN' };
            if (searchFields.pincode) {
              params.address = searchFields.pincode || '';
            } else {
              params.location = {
                lat: searchFields.latitude || '',
                lng: searchFields.longitude || ''
              }
            }
            geoCoder.geocode(params, (response, status) => {
              if (status === 'OK') {
                response = response[0];
                const address = this.addressComponentFromGoogleGeoCode(response.address_components);
                const geoLocation = {
                  googleLocationTo: {
                    locality: response['locality'] || address.locality,
                    adminLevel2: response['administrative_area_level_2'] || address.administrative_area_level_2,
                    adminLevel1: response['administrative_area_level_1'] || address.administrative_area_level_1,
                    countryName: address.country,
                    zipcode: response['postalCode'] || address['postal_code'],
                    latitude: response.geometry['location'].lat().toString(),
                    longitude: response.geometry['location'].lng().toString(),
                    accuracy: searchFields.accuracy,
                    subLocality: address.route || address.political,
                    political: address.political
                  },
                  formatted_address: response.formatted_address
                };
                observer.next(geoLocation);
              } else {
                observer.error(`Unable to find the location, please try alternate option`);
              }
              observer.complete();
            });
          });
      });

    })
  }

  private addressComponentFromGoogleGeoCode(address_components) {
    const result: any = [];
    for (let i = address_components.length - 1; i >= 0; i--) {
      const component = address_components[i];
      result[component.types[0]] = component.long_name;
    }
    return result;
  }
}
