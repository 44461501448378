export const STATE_LIST = [
    {"id":"an", "value":"Andaman and Nicobar Islands"},
    {"id":"ap", "value":"Andhra Pradesh"},
    {"id":"ar", "value":"Arunachal Pradesh"},
    {"id":"as", "value":"Assam"},
    {"id":"br", "value":"Bihar"},
    {"id":"ch", "value":"Chandigarh"},
    {"id":"ct", "value":"Chhattisgarh"},
    {"id":"dn", "value":"Dadra and Nagar Haveli"},
    {"id":"dd", "value":"Daman and Diu"},
    {"id":"dl", "value":"Delhi"},
    {"id":"ga", "value":"Goa"},
    {"id":"gj", "value":"Gujarat"},
    {"id":"hr", "value":"Haryana"},
    {"id":"hp", "value":"Himachal Pradesh"},
    {"id":"jk", "value":"Jammu and Kashmir"},
    {"id":"jh", "value":"Jharkhand"},
    {"id":"ka", "value":"Karnataka"},
    {"id":"kl", "value":"Kerala"},
    {"id":"ld", "value":"Lakshadweep"},
    {"id":"mp", "value":"Madhya Pradesh"},
    {"id":"mh", "value":"Maharashtra"},
    {"id":"mn", "value":"Manipur"},
    {"id":"ml", "value":"Meghalaya"},
    {"id":"mz", "value":"Mizoram"},
    {"id":"nl", "value":"Nagaland"},
    {"id":"or", "value":"Odisha"},
    {"id":"py", "value":"Puducherry"},
    {"id":"pb", "value":"Punjab"},
    {"id":"rj", "value":"Rajasthan"},
    {"id":"sk", "value":"Sikkim"},
    {"id":"tn", "value":"Tamil Nadu"},
    {"id":"ts", "value":"Telangana"},
    {"id":"tr", "value":"Tripura"},
    {"id":"up", "value":"Uttar Pradesh"},
    {"id":"ut", "value":"Uttarakhand"},
    {"id":"wb", "value":"West Bengal"}
]


export const TIME_LINE = [
    {
      'id': '1st week of April',
      'value': new Date("2021-04-1")
    },
    {
      'id': '2nd week of April',
      'value': new Date("2021-04-8")
    },
    {
      'id': '3rd week of April',
      'value': new Date("2021-04-15")
    },
    {
      'id': '4th week of April',
      'value': new Date("2021-04-22")
    },
    {
      'id': '1st week of May',
      'value': new Date("2021-04-1")
    },
    {
      'id': '2nd week of May',
      'value': new Date("2021-04-8")
    },
    {
      'id': '3rd week of May',
      'value': new Date("2021-04-15")
    },
    {
      'id': '4th week of May',
      'value': new Date("2021-04-22")
    },
]