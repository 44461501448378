<div class="app-row">
    <button class="add-new-address-btn"  *ngIf="!newAddressFormFlag" (click)="resetAddressForm()" fxLayout fxLayoutAlign="start center">
        <mat-icon>add</mat-icon>
        <span translate>Add new address</span>
    </button>
</div>
<div class="app-row">
    <app-address-form 
        *ngIf="newAddressFormFlag"
        [addressForm]="addressForm" 
        (closeForm)='closeForm($event)'>
    </app-address-form>
</div>
<div class="app-row">
    <div class="addresses" fxLayout="column">
        <ng-container *ngFor="let address of listOfAddress; index as index">
            <app-address-form fxFlex="100%" *ngIf="address.isEditingEnabled" [addressForm]="address" (closeForm)='closeForm($event)'></app-address-form>
            <div 
                *ngIf="!address.isEditingEnabled"
                [class.active]="selectedAddress == address"
                fxFlex="100%" 
                fxFlex.xs="100%" 
                class="address">
                <label [for]="address.id" 
                    (click)="selectAddress(address)">
                    <div class="name">{{address.name}} ({{ address.phone }})</div>
                    <input [id]="address.id" [checked]="address === selectedAddress" [(ngModel)]="selectedAddress" type="radio" [value]="address">
                    <div fxLayout fxLayout.xs="column" >
                        <div *ngIf="address.addressLine1">{{ address.addressLine1 }},</div>
                        <div *ngIf="address.addressLine2">{{ address.addressLine2 }},</div>
                        <div *ngIf="address.landmark">{{ address.landmark }},</div>
                        <div *ngIf="address.city">{{ address.city }}</div>
                        <div> {{ address.state }} - {{ address.postalCode }}</div>
                    </div>
                </label>
                <a class="edit" (click)="updateAddress(address)" translate>EDIT</a>
                <mat-icon (click)="removeAddress(address)">delete</mat-icon>
            </div>
        </ng-container>
    </div>
</div>
<!-- <div class="form-wrapper row" fxLayoutGap.xs="10px" fxLayout="column">
    <div *ngIf="listOfAddress?.length">
        <div class="address-list-header" fxLayout="column" fxLayoutGap="10px">
            <div *ngFor="let address of listOfAddress" fxLayoutGap.xs="10px">
                <div *ngIf="!address.isEditingEnabled" class="address-list" fxLayoutGap="30px" fxLayoutGap.xs="8px" fxLayout  [class.active]="address === selectedAddress">
                    <mat-radio-group>
                        <mat-radio-button value="true" [checked]="address === selectedAddress" (click)="selectAddress(address)"></mat-radio-button>
                    </mat-radio-group>
                    <div fxFlex (click)="selectAddress(address)">
                        <div fxLayout fxLayoutAlign="space-between">
                            <div fxLayout fxLayoutGap="25px" fxLayoutGap.xs="6px" fxLayoutAlign="center center">
                                <p [innerHtml]="address.name"></p>
                                <p [innerHtml]="address.phone"></p>
                                <p class="default" *ngIf="address.isDefault">Default</p>
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="3px">
                            <p>
                                <span [innerHtml]="address.addressLine1"></span>
                                <span *ngIf="address.addressLine1">, </span>
                                <span *ngIf="address.addressLine2" [innerHtml]="address.addressLine2"></span>
                                <span *ngIf="address.addressLine2">, </span>
                                <span *ngIf="address.landmark" [innerHtml]="address.landmark"></span>
                                <span *ngIf="address.landmark">, </span>
                                <span [innerHtml]="address.city"></span>
                                <span *ngIf="address.city">, </span>
                                <span [innerHtml]="address.state"></span> -
                                <span [innerHtml]="address.postalCode"></span>
                            </p>
                        </div>
                    </div>
                    <div fxLayoutGap="10px">
                        <mat-icon matTooltip="Edit this address" (click)="editAddress(address)" class="edit-icon">edit</mat-icon>
                        <mat-icon matTooltip="Delete this address" (click)="removeAddress(address)" class="edit-icon">delete</mat-icon>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div> -->