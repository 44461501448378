<div class="options" *ngIf="!isForProductTile">
    <div fxLayout="row wrap" class="BXkahK" fxLayoutGap="10px" fxLayoutGap.xs="0">
        <div class="zUGA5b mb-5">
            <a target="_blank" (click)="copyProductUrl(); displayCopyMessage()" title="Copy product URL">
                <div class="zUGA5b">
                    <div class="icon" fxLayout fxLayoutAlign="center center">
                        <i class="fa fa-copy"></i>
                    </div>
                </div>
            </a>
        </div>
        <div class="zUGA5b mb-5" fxHide.gt-xs>
            <a [href]="whatsAppMobile" target="_blank">
                <div class="zUGA5b">
                    <div class="icon" fxLayout fxLayoutAlign="center center">
                        <i class="fa fa-whatsapp"></i>
                    </div>
                </div>
            </a>
        </div>
        <div class="zUGA5b cursor-pointer mb-5" fxHide.xs>    
            <a [href]="whatsAppDesktop" target="_blank"> 
                <div class="zUGA5b">
                    <div class="icon" fxLayout fxLayoutAlign="center center">
                        <i class="fa fa-whatsapp"></i>
                    </div>
                </div>
            </a>
        </div>
        <div class="zUGA5b cursor-pointer mb-5">
            <div (click)="shareCurrentPage('fb')">
                <div class="zUGA5b">
                    <div class="icon" fxLayout fxLayoutAlign="center center">
                        <i class="fa fa-facebook-f"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="zUGA5b mb-5">
            <div (click)="shareCurrentPage('linkedIn')" title="linkedIn">
                <div class="zUGA5b">
                    <div class="icon cursor-pointer" [ngClass]="{'iconShadow' : hideLabels}" fxLayout
                        fxLayoutAlign="center center">
                        <i class="fa fa-linkedin"></i>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="zUGA5b mb-5">
            <div (click)="shareCurrentPage('tumblr')" title="Tumblr">
                <div class="zUGA5b">
                    <div class="icon cursor-pointer" fxLayout fxLayoutAlign="center center">
                        <i class="fa fa-tumblr"></i>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="zUGA5b cursor-pointer mb-5">    
            <div (click)="shareCurrentPage('twitter')" title="Tweet">
                <div class="zUGA5b">
                    <div class="icon" fxLayout fxLayoutAlign="center center">
                        <i class="fa fa-twitter"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="zUGA5b cursor-pointer mb-5">    
            <a [href]="mailTo">
                <div class="zUGA5b">
                    <div class="icon" fxLayout fxLayoutAlign="center center">
                        <i class="fa fa-envelope"></i>
                    </div>
                </div>
            </a>
        </div>
    </div> 
</div>

<div class="options" *ngIf="isForProductTile">
    <a target="_blank" rel="noopener" (click)="copyProductUrl(); displayCopyMessage()" 
        title="Copy product URL"><i class="p-8 fa fa-copy"></i></a>
    <a fxHide.xs [href]="whatsAppDesktop" target="_blank" rel="noopener"><i class="p-8 fa fa-whatsapp"></i></a>
    <a fxHide.gt-xs [href]="whatsAppMobile" target="_blank" rel="noopener"><i class="p-8 fa fa-whatsapp"></i></a>
    <i class="p-8 fa fa-facebook-f" (click)="shareCurrentPage('fb')"></i>
    <i class="p-8 fa fa-linkedin" (click)="shareCurrentPage('linkedIn')"></i>    
    <i class="p-8 fa fa-twitter" (click)="shareCurrentPage('twitter')"></i>
    <a [href]="mailTo"><i class="p-8 fa fa-envelope"></i></a>
</div>