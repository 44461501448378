import { Component, OnInit } from "@angular/core";

@Component({
  selector: "popular-category",
  templateUrl: "./popular-category.component.html",
  styleUrls: ["./popular-category.component.scss"],
})
export class PopularCategoryComponent implements OnInit {
  slideConfig: any;

  popularcategory = [
    {
      image: "assets/apgreen/veggies.png",
      categories: "Food Products",
      url: "/categories/FOOD_PRODUCTS",
      // queryParams: { category: "FOOD_PRODUCTS" },
    },
    {
      image: "assets/apgreen/handicrafts.jpeg",
      categories: "Handicrafts",
      url: "/categories/HANDICRAFTS",
      // queryParams: { category: "HANDICRAFTS" },
    },
    {
      image: "assets/apgreen/spices.jpg",
      categories: "Spices",
      url: "/categories/SPICES",
      // queryParams: { category: "SPICES" },
    },
    // {
      //   image: "assets/apgreen/toys.jpeg",
    //   categories: "Toys",
    //   url: "/categories/TOYS",
    //   queryParams: {
    //     category: "TOYS"
    //   },
    // },
    {
      image: "assets/apgreen/Textiles.jpeg",
      categories: "Textiles",
      url: "/categories/TEXTILES",
      // queryParams: { category: "TEXTILES" },
    },

    {
      image: "assets/apgreen/herbal.jpg",
      categories: "Health & Personal Care Health",
      url: "/categories/HEALTH_AND_PERSONAL_CARE",
      // queryParams: { category: "HEALTH_AND_PERSONAL_CARE" },
    },
    // {
    //   image: "assets/apgreen/fv.jpeg",
    //   categories: "Fruits & Vegetables",
    //   url: "/categories/FRUITS_AND_VEGETABLES",
    //   // queryParams: { category: "FOOD_PRODUCTS", l2Id: "FRUITS_AND_VEGETABLES" },
    // },
    {
      image: "assets/apgreen/jwellery.png",
      categories: "Jewellery",
      url: "/categories/JEWELLERY",
      // queryParams: { category: "JEWELLERY" },
    },
    {
      image: "assets/apgreen/Home-Kitchen.jpg",
      categories: "Home & Kitchen",
      url: "/categories/HOME_AND_KITCHEN",
      // queryParams: { category: "HOME_AND_KITCHEN" },
    },
    
    
    // {
    //   image: "assets/apgreen/beauty.jpg",
    //   categories: "Health & Beauty",
    //   url: "/categories/Skin",
    //   queryParams: {
    //     category: "HEALTH_AND_PERSONAL_CARE",
    //     l2Id: "HERBAL_PRODUCTS",
    //     l3Id: "BODY_SKIN_AND_HAIR_CARE",
    //   },
    // },

    // {
    //   // image: "assets/apgreen/others.jpg",
    //   others: "Others",
    //   categories: "Others",
    //   url: "/categories/Others",
      // queryParams: { category: "OTHERS" },
    // },
  ];
  constructor() {
    this.slideConfig = {
      slidesToShow: 8,
      slidesToScroll: 1,
      infinite: false,
      prevArrow: `<div class="s-prev"><i class="fa fa-angle-left" aria-hidden="true"></i><div>`,
      nextArrow: `<div class="s-next"><i class="fa fa-angle-right" aria-hidden="true"></i><div>`,
      responsive: [
        {
          breakpoint: 599,
          settings: {
            slidesToShow: 4.5,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
          },
        },
      ],
    };
  }

  ngOnInit() { }
}
