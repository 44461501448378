<div class="mango-address">
  <div class="addresses" fxLayout="row wrap">
    <ng-container *ngIf="listOfAddress?.length">

      <ng-container *ngFor="let address of listOfAddress; index as index">
          <div 
              [class.active]="selectedAddress == address"
              fxFlex="49%" 
              fxFlex.xs="100%" 
              [style.marginRight]="index % 2 == 0 ? '2%' : 0"
              class="address">
              <label [for]="address?.id" 
                  (click)="selectAddress(address)">
                  <div class="name">{{address?.name}} ({{ address?.phone }})</div>
                  <input [id]="address?.id" [checked]="address === selectedAddress" 
                  [(ngModel)]="selectedAddress" type="radio" [value]="address">
                  <div *ngIf="address?.addressLine1">{{ address?.addressLine1 }},</div>
                  <div *ngIf="address?.addressLine2">{{ address?.addressLine2 }},</div>
                  <div *ngIf="address?.landmark">{{ address?.landmark }},</div>
                  <div *ngIf="address?.city">{{ address?.city }}</div>
                  <div> {{ address?.state }} - {{ address?.postalCode }}</div>
              </label>
          </div>
      </ng-container>

    </ng-container>
    

    <ng-container *ngIf="!listOfAddress?.length">
      <div fxLayoutAlign="center center" class="w-100">
        <kl-no-content 
          matIcon="sentiment_dissatisfied" 
          text="No address added yet!" 
        >
        </kl-no-content>
      </div>

    </ng-container>
    

  </div>
</div>