
<div class="p-container">
  <a class="p" [class.in-slider]="isInSlider" [routerLink]="productPath"
    [target]="utility.isMobileDevice() ? '_self' : '_blank'" [title]="product?.productName_level3"
    [class.offer]="isPromotionItem">
    <div class="p-detail" fxLayout="column">
      <div class="img-placeholder position-relative" fxLayoutAlign="center center">
        <ng-container *ngIf="avgRating">
          <div class="rating-label" fxLayoutGap="3px" fxLayout fxLayoutAlign="center center">
            <rating [value]="avgRating"></rating>
          </div>
        </ng-container>
  
        <div class="bg-img p-10" fxLayoutAlign="center center">
          <img loading="lazy" [src]="(product?.productPicURL_level3 || 'assets/icons/No_Image.jpg' | scaleImage: 'md')"
            alt="">
        </div>
  
        <div class="stock-info"
          *ngIf="product?.category === 'Fruits' && (product?.baseProductName !== 'Mango' && product?.baseProductName !=='Banganpalli')"
          [hidden]="product?.pricePerSKU < 1" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Stock</span>
          <img src="assets/apgreen/high-stock.png" alt="" loading="lazy">
        </div>
  
        <span class="offer-percent" *ngIf="isPromotionItem &&  offerPercent">
          {{ offerPercent | number:'1.0-0' }}%
          <span translate>off</span>
        </span>
      </div>
  
      <div class="all-details" fxLayout="column" fxLayoutGap="7px">
  
  
        <div class="product-name text-center">
          <span>{{ product?.productName_level3 }}</span>
        </div>
  
        <div class="price-detail" fxLayoutAlign="center center">
          <!-- <span>
            {{product?.pricePerSKU | currency:'INR':'symbol':'1.0'}}
            <ng-container *ngIf="product?.mrp && product?.mrp > product?.pricePerSKU">
              <span class="mrp ml-5">{{ product?.mrp | currency:'INR':'symbol':'1.0' }}</span>
  
            </ng-container>
          </span> -->
  
          <span class="p-tile-tag" *ngIf="product?.isBasket && product?.basketItems?.length">
            <span>{{ product?.basketItems?.length }} Items</span> <i class="fa fa-info-circle text-warning ml-3" aria-hidden="true"></i>
            <span class="mx-10 separator">|</span> 
            <ng-container *ngFor="let smartElement of product?.selectedSKU | slice: 0:1">
              <span class="sku" *ngIf="smartElement !== 'dimensions'">
                {{ (product?.SKUSmartElements)[smartElement].value }}
                {{ (product?.SKUSmartElements)[smartElement].unit }}
              </span>
            </ng-container>
            <span class="mx-10 separator">|</span>
             {{product?.pricePerSKU | currency:'INR':'symbol':'1.0'}}
          </span>
        </div>
  
        <div class="m--3 pb-7 basket-products" fxHide.xs fxLayout="row wrap">
          <ng-container *ngFor="let item of basketItems">
            <div class="p-3" fxFlex="50%">

              <div class="text-truncate tile-tag">{{item?.productLevel3Title}} <inline-sku [smartElement]="item?.smartElements"></inline-sku></div>
              
            </div>
          </ng-container>
        </div>
  
        <div fxLayoutAlign="center center" fxLayoutGap="5px">
  
  
          <!-- <div class="skus-list" fxLayoutAlign="end center" fxLayout="row wrap">
            <ng-container *ngFor="let smartElement of product?.selectedSKU | slice: 0:1">
              <span class="sku" *ngIf="smartElement !== 'dimensions'">
                {{ (product?.SKUSmartElements)[smartElement].value }}
                {{ (product?.SKUSmartElements)[smartElement].unit }}
              </span>
            </ng-container>
          </div> -->
  
  
          <button [disabled]="product?.pricePerSKU < 1 || !canShipToLocation" class="view-detail-btn"
            fxLayoutAlign="center center" translate (click)="addToCart($event)">
            <i class="fa fa-shopping-bag font-12 mr-5" *ngIf="!(product?.pricePerSKU < 1)" aria-hidden="true"></i>
            <span translate>Add</span>
          </button>
  
        </div>
  
  
      </div>
  
      <!-- Footer/Bottom shipping charge message -->
      <div class="shipping-cost-message" *ngIf="canShowShippingMessage">
        <!-- <shipping-charge-message [product]="product"></shipping-charge-message> -->
  
        <span class="message" fxLayout="row">
  
          <i class="fa fa-truck mr-5" aria-hidden="true"></i>
  
          <div>
            <span *ngIf="product?.shipping">
              <ng-container *ngIf="selectedLanguage !=='te'">Ships<span class="highlight ml-2">free</span> on
                <span class="highlight">{{ deliveryDate | date:'MMM d'}}</span></ng-container>
              <ng-container *ngIf="selectedLanguage ==='te'">డెలివరీ <span class="highlight">ఉచితం</span>
              </ng-container>
            </span>
  
  
            <ng-container *ngIf="!product?.shipping && product?.freeShippingEligible">
  
              <ng-container *ngIf="product?.shippingCostPerUnit">
                <ng-container *ngIf="selectedLanguage !=='te'">Shipping charges
                  <span class="highlight">{{product?.shippingCostPerUnit | currency:'INR':'symbol':'1.2-2'}}</span>
                </ng-container>
                <ng-container *ngIf="selectedLanguage ==='te'">డెలివరీ ఛార్జీలు
                  <span class="highlight">{{product?.shippingCostPerUnit | currency:'INR':'symbol':'1.2-2'}}</span>
                </ng-container>
  
                <span class="px-5">|</span>
              </ng-container>
  
              <!-- <ng-container *ngIf="product?.shippingCostPerUnit || !product?.productQuantity">
                <ng-container *ngIf="selectedLanguage !=='te'">
                  Ships <span class="highlight mr-2">free</span>on <span
                    class="highlight">{{ deliveryDate | date:'MMM d'}}</span> over <span
                    class="highlight">{{999  | currency:'INR':'symbol':'1.2-2'}}</span>
                </ng-container>
                <ng-container *ngIf="selectedLanguage ==='te'">
                  <span class="highlight">{{999  | currency:'INR':'symbol':'1.2-2'}}</span> కంటే ఎక్కువ ఆర్డర్‌తో <span
                    class="highlight">ఉచిత</span> డెలివరీ
                </ng-container>
  
              </ng-container> -->
  
              <ng-container *ngIf="product?.productQuantity && !product?.shippingCostPerUnit">
                <ng-container *ngIf="selectedLanguage !=='te'">Ships<span class="highlight ml-2">free</span> on <span
                    class="highlight">{{ deliveryDate | date:'MMM d'}}</span></ng-container>
                <ng-container *ngIf="selectedLanguage ==='te'">డెలివరీ <span class="highlight">ఉచితం</span>
                </ng-container>
              </ng-container>
  
            </ng-container>
  
            <span *ngIf="!product?.shipping && !product?.freeShippingEligible">
              <ng-container *ngIf="selectedLanguage !=='te'">Ships on <span
                  class="highlight">{{ deliveryDate | date:'MMM d'}}</span>, charges</ng-container>
              <ng-container *ngIf="selectedLanguage ==='te'">డెలివరీ ఛార్జీలు </ng-container>
              <ng-container *ngIf="product?.shippingCostPerUnit; else noCharges">
                <span class="highlight">
                  {{product?.shippingCostPerUnit | currency:'INR':'symbol':'1.2-2'}}</span>
              </ng-container>
  
  
              <ng-template #noCharges>
                <span translate> applicable</span>
              </ng-template>
            </span>
          </div>
        </span>
  
      </div>
      <!-- / Footer/Bottom shipping charge message -->
  
      <!-- WISHLIST BTN -->
      <!-- <div class="special-btn-container wishlist" fxLayout fxLayoutAlign="center center"
            (click)="callWishListService(product);$event.preventDefault();$event.stopPropagation();">
            <i class="fa {{ product?.isInWishList ? 'fa-heart' : 'fa-heart-o'}}" aria-hidden="true"
                [style.color]="product?.isInWishList ? 'indianred' : ''"></i>
        </div> -->
  
      <!-- SHARE BTN -->
      <div class="special-btn-container share" fxLayout fxLayoutAlign="center center" fxHide.xs
        (click)="$event.preventDefault(); $event.stopPropagation()" [matMenuTriggerFor]="shareMenu">
        <i class="fa fa-share" aria-hidden="true"></i>
      </div>
  
      <mat-menu #shareMenu="matMenu" [overlapTrigger]="false">
        <ng-template matMenuContent>
          <app-social-sharing [p-tile]="true" [socialDetails]="product.socialDetails"></app-social-sharing>
        </ng-template>
      </mat-menu>
    </div>
  </a>
</div>