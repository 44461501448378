import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { KalgudiDialogsModule, KalgudiMobileDialogModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { GooglePlacesModule } from '../google-places/google-places.module';
import { AddressFormDialogComponent } from './components/address-form-dialog/address-form-dialog.component';
import {
  AddressFormMobileDialogComponent,
} from './components/address-form-mobile-dialog/address-form-mobile-dialog.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { CurrentLocationComponent } from './components/current-location/current-location.component';
import { MapDialogComponent } from './components/map-dialog/map-dialog.component';
import { AddressApiService } from './services/address-api.service';
import { AddressService } from './services/address.service';
import { PincodePickerComponent } from './components/pincode-picker/pincode-picker.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

@NgModule({
  declarations: [
    AddressFormComponent,
    CurrentLocationComponent,
    MapDialogComponent,
    AddressFormDialogComponent,
    AddressFormMobileDialogComponent,
    PincodePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    FlexLayoutModule,
    GooglePlacesModule,

    // Material modules
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatTabsModule,
    MatBottomSheetModule,

    KalgudiMobileDialogModule,
    KalgudiDialogsModule,
    KalgudiButtonModule,

    AngularSvgIconModule

  ],
  providers: [
    AddressApiService,
    AddressService
  ],
  exports: [
    AddressFormComponent
  ],
  entryComponents: [
    MapDialogComponent,
    AddressFormComponent,
    AddressFormDialogComponent,
    AddressFormMobileDialogComponent,
    PincodePickerComponent
  ]
})
export class AddressModule { }
