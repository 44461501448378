import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { expandCollapse } from '../../../animations';

@Component({
    selector: 'app-mobile-cat',
    template: `
    <div class="category-container">
        <div (click)="isExpanded = !isExpanded" class="category" fxLayoutAlign="space-between center">
            <div class="name" translate>{{category.name | lowercase}}</div>
            <i class="fa" [class.fa-angle-right]="!isExpanded" [class.fa-angle-down]="isExpanded"></i>
        </div>
        <div [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'">
            <div class="sub-category-container" *ngFor="let sub of category.subCategory">
                <div class="category" fxLayoutAlign="space-between center" *ngIf="!sub.subCategory">
                    <div class="name" translate>{{ sub.name | lowercase }}</div>
                </div>
                <app-mobile-sub-cat [sub-cat]="sub" (category)="selectCategory($event)" *ngIf="sub.subCategory"></app-mobile-sub-cat>
            </div>
            <div class="sub-category-container" (click)="selectCategory(category.id)">
                <div class="category" fxLayoutAlign="space-between center">
                    <div class="name" translate><span translate>All</span> {{category.name | lowercase  }}</div>
                </div>
            </div>

        </div>
    </div>
    `,
    styles: [`
        .category {
            padding: 15px 20px;
            border-bottom: 1px solid #dfdfdf;
        }
        .sub-category-container {
            margin-left: 25px;
        }
        .name{
                text-transform: capitalize;
            }
    `],
    animations: [
        expandCollapse
    ]
})


export class MobileCatComponent implements OnInit {

    @Input() category: any;
    @Output() subCategory = new EventEmitter();

    isExpanded = false;

    constructor() { }

    ngOnInit() {

    }

    selectCategory(event) {
        let request = event;
        if (typeof event === 'object') {
            request.category = this.category.id;
        } else {
            request = {
                'keyword': event,
                'category': this.category.id,
                'isBaseCategory': true
            }
        }
        this.subCategory.emit(request);
    }

}
