import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { KalgudiCommonDirectivesModule, KalgudiDialogsModule, KalgudiMobileDialogModule } from '@kalgudi/common';
import { KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AddressModule } from '../modules/address/address.module';
import { AttachmentsDialogComponent } from '../quotation/attachments-dialog/attachments-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { CartInfoComponent } from '../storefront/header/cart-info/cart-info.component';
import { MobileSidenavComponent } from '../storefront/header/mobile-sidenav/mobile-sidenav.component';
import { NoUpdatesComponent } from './../seller/new-seller-catalogue/no-updates/no-updates.component';
import { SellerActivityComponent } from './../seller/new-seller-catalogue/seller-activity/seller-activity.component';
import { PhotoSwipeComponent } from './../storefront/product-full-view/photo-swipe/photo-swipe.component';
import { AppCardComponent } from './app-card/app-card.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AppToastComponent } from './components/app-toast/app-toast.component';
import { BannerSliderComponent } from './components/banner-slider/banner-slider.component';
import { BasketTileComponent } from './components/basket-tile/basket-tile.component';
import { BookingsDialogComponent } from './components/bookings-dialog/bookings-dialog.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BuyerInboxTileComponent } from './components/buyer-inbox-tile/buyer-inbox-tile.component';
import { CategoryCardComponent } from './components/category-card/category-card.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ContactCsTeamDialogComponent } from './components/contact-cs-team-dialog/contact-cs-team-dialog.component';
import { CurrencyComponent } from './components/currency/currency.component';
import {
    CustomNotificationPopupComponent,
} from './components/custom-notification-popup/custom-notification-popup.component';
import {
    DeliveryAddressSelectorComponent,
} from './components/delivery-address-selector/delivery-address-selector.component';
import {
    DeliveryPincodeSelectorComponent,
} from './components/delivery-pincode-selector/delivery-pincode-selector.component';
import { DesignBannerComponent } from './components/design-banner/design-banner.component';
import { DisplayGalleryDialogComponent } from './components/display-gallery-dialog/display-gallery-dialog.component';
import { DisplayUserDetailsComponent } from './components/display-user-details/display-user-details.component';
import { FooterHelpComponent } from './components/footer-help/footer-help.component';
import { LoaderComponent } from './components/loader.component';
import { MobileCatComponent } from './components/mobile-cat.component';
import { MobileSubCatComponent } from './components/mobile-sub-cat.component';
import { NestedMenuComponent } from './components/nested-menu/nested-menu.component';
import { NewPTileComponent } from './components/new-p-tile/new-p-tile.component';
import { NotifierComponent } from './components/notifier/notifier.component';
import { NotifyComponent } from './components/notify/notify.component';
import { OrderTileComponent } from './components/order-tile/order-tile.component';
import { PShowcaseComponent } from './components/p-showcase/p-showcase.component';
import { PSkeletonComponent } from './components/p-skeleton/p-skeleton.component';
import { PSliderNavComponent } from './components/p-slider-nav/p-slider-nav.component';
import { PTileComponent } from './components/p-tile/p-tile.component';
import { PincodeRequestDialogComponent } from './components/pincode-request-dialog/pincode-request-dialog.component';
import { PreBookComponent } from './components/pre-book/pre-book.component';
import { PromotionalItemComponent } from './components/promotional-item/promotional-item.component';
import { RatingComponent } from './components/rating/rating.component';
import { RecommendedProductsComponent } from './components/recommended-products/recommended-products.component';
import { RouteProgressBarComponent } from './components/route-progress-bar/route-progress-bar.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { SellerProductTileComponent } from './components/seller-product-tile/seller-product-tile.component';
import { ShippingChargeMessageComponent } from './components/shipping-charge-message/shipping-charge-message.component';
import { ShippingDetailDialogComponent } from './components/shipping-detail-dialog/shipping-detail-dialog.component';
import { ShopByCategoryComponent } from './components/shop-by-category/shop-by-category.component';
import { ShopByRegionComponent } from './components/shop-by-region/shop-by-region.component';
import { SideLinksComponent } from './components/side-links/side-links.component';
import { DimensionSkuComponent } from './components/sku-components/dimension-sku/dimension-sku.component';
import { InlineSkuComponent } from './components/sku-components/inline-sku/inline-sku.component';
import { StoreNotificationsComponent } from './components/store-notifications/store-notifications.component';
import { TimerComponent } from './components/timer/timer.component';
import { TwoRowProductSectionComponent } from './components/two-row-product-section/two-row-product-section.component';
import { AttachmentDirective } from './directives/attachment.directive';
import { DetectSwipeDirective } from './directives/detect-swipe.directive';
import { FxIfDirective } from './directives/fx-if.directive';
import { ValidLocationDirective } from './directives/valid-location.directive';
import { ValidPinDirective } from './directives/valid-pin.directive';
import { ProductReviewImageViewerComponent } from './product-review-image-viewer/product-review-image-viewer.component';
import { HttpWithAuthService } from './services/http-interceptor.service';
import { StoreInfoCardsComponent } from './store-info-cards/store-info-cards.component';

// import { CheckPasswordDirective } from './directives/check-password.directive';




@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        SlickCarouselModule,

        // Kalgudi modules
        KalgudiCommonDirectivesModule,
        KalgudiPipesModule,
        KalgudiMobileDialogModule,
        KalgudiDialogsModule,

        AddressModule,
        AngularSvgIconModule,
        MatButtonModule
    ],
    exports: [
        CurrencyComponent,
        SlickCarouselModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        AddressFormComponent,
        AppToastComponent,
        InlineSkuComponent,
        DimensionSkuComponent,
        PTileComponent,
        SearchbarComponent,
        DeliveryAddressSelectorComponent,
        PShowcaseComponent,
        TimerComponent,
        DetectSwipeDirective,
        PSliderNavComponent,
        PSkeletonComponent,
        RatingComponent,
        BreadcrumbComponent,
        NoUpdatesComponent,
        SellerActivityComponent,
        PhotoSwipeComponent,
        AttachmentsDialogComponent,
        AttachmentDirective,
        AppCardComponent,
        OrderTileComponent,
        ContactCsTeamDialogComponent,
        SideLinksComponent,
        SellerProductTileComponent,
        DisplayUserDetailsComponent,
        StoreNotificationsComponent,
        CustomNotificationPopupComponent,
        RouteProgressBarComponent,
        ValidPinDirective,
        ValidLocationDirective,
        BookingsDialogComponent,
        DisplayGalleryDialogComponent,
        ProductReviewImageViewerComponent,
        NewPTileComponent,
        CartInfoComponent,
        MobileSidenavComponent,
        PromotionalItemComponent,
        ShippingChargeMessageComponent,
        StoreInfoCardsComponent,
        LoaderComponent,
        ShopByRegionComponent,
        ShopByCategoryComponent,
        NestedMenuComponent,
        NotifyComponent,
        NotifierComponent,
        CategoryCardComponent,
        TwoRowProductSectionComponent,
        BannerSliderComponent,
        MobileCatComponent,
        MobileSubCatComponent,
        DeliveryPincodeSelectorComponent,
        FxIfDirective,
        BasketTileComponent,
        DesignBannerComponent,
        RecommendedProductsComponent
    ],
    declarations: [
        ConfirmDialogComponent,
        AddressFormComponent,
        AppToastComponent,
        FooterHelpComponent,
        InlineSkuComponent,
        DimensionSkuComponent,
        PTileComponent,
        SearchbarComponent,
        ShippingDetailDialogComponent,
        DeliveryAddressSelectorComponent,
        PShowcaseComponent,
        TimerComponent,
        DetectSwipeDirective,
        PSliderNavComponent,
        PSkeletonComponent,
        RatingComponent,
        BreadcrumbComponent,
        NoUpdatesComponent,
        SellerActivityComponent,
        PhotoSwipeComponent,
        AttachmentsDialogComponent,
        AttachmentDirective,
        AppCardComponent,
        BuyerInboxTileComponent,
        OrderTileComponent,
        ContactCsTeamDialogComponent,
        SideLinksComponent,
        SellerProductTileComponent,
        DisplayUserDetailsComponent,
        StoreNotificationsComponent,
        CustomNotificationPopupComponent,
        RouteProgressBarComponent,
        ValidPinDirective,
        ValidLocationDirective,
        BookingsDialogComponent,
        PreBookComponent,
        DisplayGalleryDialogComponent,
        ProductReviewImageViewerComponent,
        NewPTileComponent,
        CartInfoComponent,
        MobileSidenavComponent,
        PincodeRequestDialogComponent,
        PromotionalItemComponent,
        ShippingChargeMessageComponent,
        StoreInfoCardsComponent,
        LoaderComponent,
        ShopByRegionComponent,
        ShopByCategoryComponent,
        NestedMenuComponent,
        NotifyComponent,
        NotifierComponent,
        CategoryCardComponent,
        TwoRowProductSectionComponent,
        BannerSliderComponent,
        MobileCatComponent,
        MobileSubCatComponent,
        DeliveryPincodeSelectorComponent,
        FxIfDirective,
        BasketTileComponent,
        DesignBannerComponent,
        CurrencyComponent,
        // CheckPasswordDirective,
        RecommendedProductsComponent
    ],
    providers: [
        HttpWithAuthService,
    ],
    entryComponents: [
        ConfirmDialogComponent,
        ContactCsTeamDialogComponent,
        AttachmentsDialogComponent,
        BookingsDialogComponent,
        PincodeRequestDialogComponent,
        DisplayGalleryDialogComponent
    ]
})
export class ProjectCommonModule { }
