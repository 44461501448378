<div id="icici-payment">
    <div class="bg-color">
      <div class="container pt-3" *ngIf="url">
        <div class="row col-12 col-md-10 col-lg-7 m-auto">
          <div class="order-container-card w-100 mb-3 mr-3 bg-white" ngClass.xs="mt-5 m-3">
            <span class="order-heading font-weight-bold">Order Details</span>
            <hr />
            <div fxLayoutAlign="space-between center">
              <div>
                <span>Order # : </span>
                <span class="font-weight-bold ml-1">{{ orderDetails?.orderId }}</span>
              </div>
              <div>|</div>
              <div>
                <span>Amount : </span>
                <span class="font-weight-bold ml-1">₹ {{ orderDetails?.amount?.toFixed(2) }}</span>
              </div>
            </div>
          </div>
  
          <iframe
            #externalIframe
            width="99%"
            height="800px"
            [src]="url"
            frameborder="0"
            allowfullscreen
            scrolling="no"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  