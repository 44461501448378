<div fxFlex.gt-xs class="selected-pin mr-10" (click)="toggleShowSelectPincodeDialog()">
    <mat-icon>room</mat-icon>


    <ng-container
        *ngIf="(deliveryLocationSelected$ | async) as deliveryLocationSelected; else noDeliveryLocationSelected">

        <div>
            <small class="font-11">Delivering to</small>
            <p class="m-0 font-weight-bold font-15" fxLayout *ngIf="deliveryLocationSelected?.postalCode"><span
                    class="one-line">{{deliveryLocationSelected?.city}}</span>,
                {{deliveryLocationSelected?.postalCode}}</p>
            <p class="m-0 font-weight-bold font-15" *ngIf="!deliveryLocationSelected?.postalCode">
                {{deliveryLocationSelected}}</p>
        </div>
    </ng-container>
    <ng-template #noDeliveryLocationSelected>
        <div>
            <small class="font-11">Hello</small>
            <p class="m-0 font-weight-bold font-15">Select your address</p>
        </div>
    </ng-template>

    <div class="loc-select" [class.show]="showSelectPincodeDialog" (click)="$event.stopPropagation();$event.preventDefault();">
        <div class="loc-select-container" #pincodeBox>
            <div class="scrollable">
                <i class="material-icons close-popup" (click)="showSelectPincodeDialog = false">cancel</i>
                <delivery-pincode-selector (onChange)="showSelectPincodeDialog = false" [isUserLoggedIn]="isUserLoggedIn">
                </delivery-pincode-selector>
            </div>
        </div>
    </div>



</div>
<div [fxFlex.gt-xs]="isInputFocused ? '450px' : '200px'" fxFlex.sm="180px" [class.search-bar-change]="isInputFocused"
    class="searchBar-focus large-screen">
    <div class="search-wrapper" fxLayoutAlign="center center">
        <div class="search-container" fxFlex>
            <form>
                <input type="text" placeholder="{{ 'Search' | translate }}" class="searchbar" id="mySearchInput"
                    [(ngModel)]="searchText" (keyup.enter)="$event.target.blur();keyEnter()" [formControl]="myControl"
                    [matAutocomplete]="auto" (keyup)="autoComplete(searchText, $event)" matInput
                    (focus)="onSearchBarFocus($event)" (blur)="onSearchBarBlur($event)" (keydown)="keydown()" />
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event)" class="AC"
                    [panelWidth]="isInputFocused ? '450px' : '180px'">
                    <div *ngIf="isTrue">
                        <mat-option *ngFor="let option of products" [value]="option" (click)="check(option)">
                            <span [innerHTML]="option.productLevel2Title"></span> in
                            <span style="color: #0060a9;">{{
                                option?.unifiedStore?.baseCategory?.value?.toLowerCase()}}</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
            </form>
        </div>
        <div class="categories">
            <!-- <span fxLayoutAlign="center center">
                <span translate class="selected-category">{{ selectedCategory?.value || 'All Categories'}}</span> <i class="fa fa-angle-down ml-5" aria-hidden="true"></i>
            </span>
            <div class="categories-list mat-elevation-z5">
                <div matRipple class="cat-list-item" (click)="selectedCategory = {}" translate>All Categories</div>
                <ng-container *ngFor="let category of categories$ | async">
                    <div matRipple class="cat-list-item" (click)="selectedCategory = category" translate>
                        {{ category?.value | lowercase }}
                    </div>
                </ng-container>
            </div>-->
        </div>
        <div class="search-btn" fxLayout (click)="search(searchText)">
            <mat-icon>search</mat-icon>
        </div>



       
    </div>


</div>



<div class="select-pin-design  shadow-sm" *ngIf="bottomPinSelectDialog">
    <div class="select-pin-wrapper">
        <img height="60" class="mr-10" src="assets/images/select-pin.png" alt="">

        <div>
            <p class="mb-5 font-weight-bold">Let us know your <span class="kl-primary">shipping/delivery</span></p>
            <p class="m-0 font-weight-bold"><span class="kl-primary">Pincode</span> to check the availability & pricing
            </p>
        </div>

        <form class="select-pin" (ngSubmit)="setPincode()">
            <input [formControl]="pincodeControl" type="tel" (keypress)="isNumber($event)" minlength="6" maxlength="6" placeholder="Delivery Pincode">
            <button [class.spinner]="loading" [disabled]="loading">CHECK</button>
        </form>

        <mat-icon class="kl-primary close" (click)="bottomPinSelectDialog = false">close</mat-icon>
    </div>
</div>

<div class="select-pin-design  shadow-sm"  *ngIf="showPincodeChangedDialog">
    <div class="select-pin-wrapper">
        <img height="60" class="mr-10" src="assets/images/select-pin.png" alt="">

        <div>
            <p class="mb-5 font-weight-bold kl-primary">
                Showing prices & availability to
                <ng-container *ngIf="(deliveryLocationSelected$ | async) as deliveryLocationSelected">
                    <span *ngIf="deliveryLocationSelected?.postalCode"><span
                            class="one-line">{{deliveryLocationSelected?.city}}</span>,
                        {{deliveryLocationSelected?.postalCode}}</span>
                    <span *ngIf="!deliveryLocationSelected?.postalCode"> {{deliveryLocationSelected}}</span>
                </ng-container>
            </p>
            <p class="m-0 font-weight-bold">To check to a different location, <a (click)="hideChangedDialogAndPopupUpdateDialog()">Click here</a>.</p>
        </div>

        <mat-icon class="kl-primary close" (click)="showPincodeChangedDialog = false">close</mat-icon>
    </div>
</div>

<div class="search-overlay-section medium-screen" *ngIf="showSearch">
    <div class="search-placeholder" fxLayout>
        <div class="back-nav" fxLayoutAlign="center center">
            <mat-icon (click)="showSearch = false">arrow_back</mat-icon>
        </div>
        <input id="search-input" type="text" style="padding: 0 16px;" [(ngModel)]="searchText"
            (keyup.enter)="keyEnter()" placeholder="Search for products..." fxFlex [matAutocomplete]="auto"
            (keyup)="autoComplete(searchText, $event)" matInput (keydown)="keydown()" />
        <div class="back-nav" fxLayoutAlign="center center" (click)="searchText = ''">
            <i class="fa fa-times-circle" style="font-size: 20px;" aria-hidden="true"></i>
        </div>
        <mat-autocomplete #auto="matAutocomplete">
            <div *ngIf="isTrue">
                <mat-option *ngFor="let option of products" [value]="option.doc.productLevel2Title"
                    (click)="check(option.doc)">
                    <div fxLayout fxLayoutGap="5px;">
                        <div>
                            <mat-icon style="font-size: 20px;width: 24px;color: #777">search</mat-icon>
                        </div>
                        <div class="cut-text pl-10">
                            <span class="search-text" [innerHTML]="option.doc.productLevel2Title"></span> in
                            <span style="color: #0060a9;">{{
                                option.doc.baseCategory.value.toLowerCase()
                                }}</span>
                        </div>
                    </div>
                </mat-option>
            </div>
        </mat-autocomplete>
    </div>
    <div mat-subheader *ngIf="recentSearches">
        Recent Search History
    </div>
    <mat-list dense>
        <div matRipple class="p-9" *ngFor="let search of recentSearches" (click)="searchText = search; keyEnter()">
            {{search}}
        </div>
    </mat-list>
    <div mat-subheader>
        Trending
    </div>
    <mat-list dense>
        <div matRipple class="p-9" fxLayoutAlign="space-between" *ngFor="let trending of suggestedCategory"
            (click)="searchText = trending; keyEnter()">
            {{trending}}
            <img src="assets/icons/north-west.svg" width="20">
        </div>
    </mat-list>
</div>