import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Router } from '@angular/router';
import { KalgudiGooglePlaceMap } from '@kalgudi/third-party/google-places';
import { KalgudiGoogleLocationTo, KalgudiUser } from '@kalgudi/types';
import { of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AuthMediatorService } from 'src/app/authentication/auth-mediator.service';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { UtilityService } from 'src/app/project-common/services/utility.service';

import { fade } from '../../../animations';
import { SpinnerService } from '../../project-common/services/spinner.service';
import { CartService } from './../../project-common/services/cart.service';

@Component({
  selector: 'assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss'],
  animations: [
    fade
  ]
})
export class AssistanceComponent implements OnInit {

  assistanceForm: AssistanceForm;
  isUserVerified: boolean;
  isExistingUser: boolean;
  hasAccount: boolean;
  assistedUser: any;
  cart: any;

  googleLocationTo = new FormControl();
  processing = false;
  assistingBuyerUserdata: KalgudiUser;
  otpResendCountDown = 0;
  otpInterval: number;

  mobileInputReadonly = false;

  constructor(private router: Router, private utility: UtilityService,
    private authService: AuthenticationService, private cartService: CartService,
    private spinner: SpinnerService,
    private snackbar: MatSnackBar,
    private authMediatorService: AuthMediatorService
    ) { }

  ngOnInit() {
    this.assistanceForm = new AssistanceForm();
    // this.isExistingUser = true;

    this.cartService.getCartData().subscribe(cart => this.cart = cart);

    // Prefilling in case of rfq product
    const assistingTo = JSON.parse(sessionStorage.getItem('assistingTo') || '{}');
    if (assistingTo && assistingTo.profileKey) {
      this.mobileInputReadonly = true;

      this.assistanceForm.emailId = assistingTo.email;
      this.assistanceForm.mobileNumber = assistingTo.mobile.replace(assistingTo.mobileCode, '');
      this.verifyAndAuthenticate();
    }
  }

  isNumber(event) {
    // this.isUserVerified = false;
    return this.utility.numberOnly(event);
  }

  verifyAndAuthenticate() {
    if (!this.isUserVerified) {
      this.verfiyRegistration();
    } else if (!this.isExistingUser) {
      this.doSignup();
    } else {
      this.verifyOtp();
    }
  }

  onBuyerNumberChange() {
    this.isUserVerified = false;

    this.assistingBuyerUserdata = null;

    this.googleLocationTo.enable();
    this.googleLocationTo.reset();

    this.resetResendOtpTimeout();
  }

  verfiyRegistration() {
    this.assistanceForm.firstName = '';
    this.processing = true;
    this.authService.verifyRegistration( { mobileNo:this.assistanceForm.mobileNumber, emailId: this.assistanceForm.emailId }, "OTP")
      .pipe(finalize(() => this.processing = false))
      .subscribe(response => {
        if (response.code === 200) {
          
          this.assistedUser = JSON.parse(response.data);

          if(this.assistedUser.guestRegistration) {
            this.assistedUser.mobileNumber = this.assistanceForm.mobileNumber;
            this.setAssistanceDetails(this.assistedUser);
            return;
          }

          this.isUserVerified = true;

          this.isExistingUser = true;
          this.hasAccount = true;
          
          this.prefillInputs();
          
          this.setResendOtpTimeout();
        } else if (response.code === 404) {
          this.isUserVerified = true;
          this.isExistingUser = false;
        }
      });
  }

  prefillInputs() {
    // Prefilling name
    this.assistanceForm.firstName = this.assistedUser.firstName;

    // Prefilling location
    this.getBuyerUserData(this.assistedUser.profileKey)
      .subscribe(profileData => {
        if (!profileData) return;

        const locationTo = profileData.lstOfUserBusinessDetailsInfo[0].locationTo;
        this.googleLocationTo.setValue(locationTo);
        this.assistanceForm.googleLocationTo = locationTo;
        this.googleLocationTo.disable(); // TO make it readonly
      });

  }

  doSignup() {
    if (!this.assistanceForm.googleLocationTo)
      return this.snackbar.open(`Location is required`, `OK`, { duration: 6000 });

    this.processing = true;

    const guestLoginRequest = {
      name: this.assistanceForm.firstName,
      phone: this.assistanceForm.mobileNumber,
      googleLocation: this.assistanceForm.googleLocationTo,
      createAccount: false
    }

    this.authMediatorService.guestLogin(guestLoginRequest)
    .subscribe(res => {
      if(!res) return;

      try {
        this.assistingBuyerUserdata = res.loginResponse.data.userBasicDetail;
        this.assistedUser = this.assistingBuyerUserdata;
        this.setAssistanceDetails(this.assistedUser);
      } catch (error) {}

    });

    // this.authService.assistedSignup(this.assistanceForm, true)
    //   .pipe(finalize(() => this.processing = false))
    //   .subscribe(response => {
    //     if (response.code === 200) {
    //       this.setResendOtpTimeout();

    //       this.assistingBuyerUserdata = response.data.userData;
    //       this.assistedUser = this.assistingBuyerUserdata;
    //       this.isExistingUser = true;
    //     }
    //   });
  }

  verifyOtp() {
    let request: any = {};
    Object.assign(request, this.assistanceForm);
    request.mobileNumber = `+91${this.assistanceForm.mobileNumber}`;
    if (this.hasAccount) {
      request.mobileTelecomCode = `+91`;
      this.processing = true;
      this.authService.verifyOtpForExistingUser(request)
        .pipe(finalize(() => this.processing = false))
        .subscribe(response => {
          if (response.code === 200) {
            this.assistedUser.mobileNumber = this.assistanceForm.mobileNumber;
            this.setAssistanceDetails(this.assistedUser);
          } else {
            this.snackbar.open(`OTP is incorrect`, ``, { duration: 6000 });
          }
        });
    } else {
      this.processing = true;
      this.authService.verifyOtp(request)
        .pipe(finalize(() => this.processing = false))
        .subscribe(response => {
          if (response.code === 200) {
            this.assistedUser = JSON.parse(response.data);
            this.assistedUser.mobileNumber = this.assistanceForm.mobileNumber;
            this.setAssistanceDetails(this.assistedUser);
          } else {
            this.snackbar.open(`OTP is incorrect`, ``, { duration: 6000 });
          }

        });
    }
  }

  resendOtp() {
    if (this.otpResendCountDown || !this.assistanceForm.mobileNumber) return;

    const mobileNo = `+91${this.assistanceForm.mobileNumber}`;
    if (this.hasAccount) {

      this.authService.resendOtpForAssistance(mobileNo)
        .subscribe(this.otpResendResponseHandler);

    } else {

      const request = { mobileNumber: mobileNo };

      this.authService.generateOtpToRecoverPassword(request)
        .subscribe(this.otpResendResponseHandler);

    }
  }

  private otpResendResponseHandler = res => {
    if (res.code !== 200) return;

    this.snackbar.open(`OTP resent successfully`, ``, { duration: 2000 });

    this.setResendOtpTimeout();
  }

  setResendOtpTimeout() {
    this.otpResendCountDown = 30;
    this.otpInterval = window.setInterval(() => {
      this.otpResendCountDown--;
      if (!this.otpResendCountDown) {
        window.clearInterval(this.otpInterval);
      }
    }, 1000);
  }

  resetResendOtpTimeout() {
    if (this.otpResendCountDown) this.otpResendCountDown = 0;
    if (this.otpInterval) window.clearInterval(this.otpInterval);
  }

  getBuyerUserData(profileKey) {
    if (this.assistingBuyerUserdata) return of(this.assistingBuyerUserdata);

    this.spinner.start();
    return this.authService.getProfileDataFromDynamoDB(profileKey)
      .pipe(
        finalize(() => this.spinner.stop()),
        tap(profileData => this.assistingBuyerUserdata = profileData)
      );
  }


  setAssistanceDetails(data: any = {}) {

    if (!data.profileKey) {
      sessionStorage.setItem('isAssistanceChecked', 'true');
      sessionStorage.setItem('assistingTo', JSON.stringify(data));
      this.router.navigate(['/checkout', 'shipping'], { replaceUrl: true });
      return;
    }


    this.getBuyerUserData(data.profileKey)
      .subscribe(profileData => {
        if (!profileData) return;

        sessionStorage.setItem('assistingToUserData', JSON.stringify(profileData));

        const assistingTo = JSON.stringify(data);

        sessionStorage.setItem('isAssistanceChecked', 'true');
        sessionStorage.setItem('assistingTo', assistingTo);
        this.router.navigate(['/checkout', 'shipping'], { replaceUrl: true });
      });
  }

  cureLocation(locationTo: KalgudiGoogleLocationTo) {
    if (!locationTo.adminLevel2)
      locationTo.adminLevel2 = locationTo.locality;
  }

  onLocationChange({ kalgudiGoogleLocationTo: googleLocationTo }: KalgudiGooglePlaceMap) {
    this.cureLocation(googleLocationTo);
    this.assistanceForm.googleLocationTo = googleLocationTo;
  }

}

class AssistanceForm {
  firstName: string;
  mobileNumber: string;
  googleLocationTo: KalgudiGoogleLocationTo;
  otp: string;
  emailId?: string;
}
