import { Injectable } from '@angular/core';
import { DeliveryAddress } from '@app/models';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { asyncScheduler, scheduled } from 'rxjs';

import { AddressApiService } from './address-api.service';

@Injectable()
export class AddressService {

  private addresses: { [key: string]: DeliveryAddress[] };

  constructor(
    private apiService: AddressApiService,
    private storage: LocalStorage
  ) { }


  /**
   * To save or update an address to user's address list. 
   * 
   * It checks if payload has an ID. If available, it processes the payload for update,
   * else it will request for address save
   * 
   * @param payload address payload
   * @param profileKey user profileKey for whom address list has to be fetched
   */
  public saveAddress(payload: DeliveryAddress, profileKey?: string) {
    return this.apiService.addAddress(payload, profileKey);
  }

  /**
   * To get all saved addresses for a user based on profile key
   * 
   * @param profileKey user profileKey for whom address list has to be fetched
   * 
   */
  public getAddress(profileKey?) {
    if (!this.addresses[profileKey]) {
      return this.apiService.getAddress(profileKey);
    } else {
      const addresses = this.addresses[profileKey];
      return scheduled([addresses], asyncScheduler);
    }
  }

  /**
   * To get users profile json from localStorage
   */
  public getUserProfile() {
    return this.storage.getItem('userdata');
  }

  /**

   * To create new profile/user with address

   */

  createProfileWithAddress(address: DeliveryAddress) {

    return this.apiService.createProfileWithAddressApi(address);

  }

  /**
   * fetch pincode from third party API
   */
  getAddressFromThirdPartyAPI(pincode:string) {
    return this.apiService.getAddressFromThirdPartyAPI(pincode);
  }
}
