import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'seasonal-products-home-page',
  templateUrl: './seasonal-products-home-page.component.html',
  styleUrls: ['./seasonal-products-home-page.component.scss']
})
export class SeasonalProductsHomePageComponent implements OnInit {

  itemList = [
    {id: '1', label: 'All'},
    {id: '2', label: 'Mango'},
    {id: '3', label: 'Lemon'},
    {id: '4', label: 'Tomato'},
    {id: '5', label: 'Drumstick'},
    {id: '6', label: 'Gongura'}
  ];

  constructor() { }

  ngOnInit() {
  }

}
