import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class CartGaurdService implements CanActivate {

  constructor(private cartService: CartService, private router: Router) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise(resolve => {
      this.cartService.getCartData().subscribe(cart => {
        if(!cart.productIds.length) this.router.navigate(['/checkout/cart']);
  
        resolve(cart.productIds.length ? true : false);
      })
    })
  }
}
