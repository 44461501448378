import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'otp-confirmation-dialog',
  templateUrl: './otp-confirmation-dialog.component.html',
  styleUrls: ['./otp-confirmation-dialog.component.scss']
})
export class OtpConfirmationDialogComponent implements OnInit {

  message: String; //Message to be display after otp verification

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    /* this.message = 'We have created a free account for you in Kalgudi. Your password is sent to your '
    if(this.data.signupType === 'E-mail'){
      this.message += 'email:' + this.data.userId;
    }else{
      this.message += 'mobile:' + this.data.userId;
    } */
  }

}
