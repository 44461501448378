
<div class="kalgudi-core-theme overflow-hidden mango-address-dialog">
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{ dialogData?.title }}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div class="address-info">
    <address-info 
    [listOfAddress]="listOfAddress"
    [selectedAddress]="selectedAddress" 
    (selectedAddressEvent)="onAddressSelection($event)"></address-info>
  </div>
  

  <div class="pt-0 mx-15 mb-15" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <kl-link-button [label]="dialogData?.rejectButtonTitle" buttonColor="warn" (clicked)="cancel()"></kl-link-button>
    <kl-link-button [label]="dialogData?.acceptButtonTitle" buttonColor="primary" (clicked)="ok()"></kl-link-button>
  </div>

</div>

