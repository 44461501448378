import { Injector } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { KalgudiDialogsService } from "@kalgudi/common";
import { KalgudiDialogConfig, KalgudiDialogResult } from "@kalgudi/types";
import { Observable, ReplaySubject } from "rxjs";
import { takeUntil, take, tap, filter } from "rxjs/operators";
import { ChargesAndOtherDetails, CheckoutService } from "../checkout/checkout.service";
import { GiftMessageDialogComponent } from "../checkout/gift-message-dialog/gift-message-dialog.component";
import { PriceUpdateDialogComponent } from "../checkout/payment/price-update-dialog/price-update-dialog.component";
import { Cart, CartProduct } from "../project-common/models/cart-product.model";
import { CartService } from "../project-common/services/cart.service";
import { GoogleAnalyticsService } from "../project-common/services/google-analytics.service";
import { UtilityService } from "../project-common/services/utility.service";
import { STORE_LABELS_MAP, STORE_NAME_AND_LOGO } from "../store-mapping";

export class CartPage {

    public cart: Cart;
    public cartValue: number;
    public hasLoadingCompleted: boolean;
    public chargesAndOtherDetails: ChargesAndOtherDetails;
    public storeSet: {
        [key: string]: {
            itemsWithShippingChargesIncluded: CartProduct[],
            itemsWithShippingOffers: CartProduct[],
            itemsWithNoOffers: CartProduct[]
        }
    }

    storeLabelsMap = STORE_LABELS_MAP;

    public productWithUpdatedPrice: CartProduct[];
    public isUserLoggedIn: boolean;

    public destroyer$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    public isGift: boolean = false;

    public giftMessage: string;


    public readonly cartService: CartService;
    public readonly checkout: CheckoutService;
    public readonly dialog: MatDialog;
    public readonly gaTrack: GoogleAnalyticsService;
    public readonly snackbar: MatSnackBar;
    public readonly util: UtilityService;
    public readonly dialogsService: KalgudiDialogsService;

    constructor(
        protected injector: Injector,


    ) {
        this.cartService = injector.get(CartService);
        this.checkout = injector.get(CheckoutService);
        this.dialog = injector.get(MatDialog);
        this.gaTrack = injector.get(GoogleAnalyticsService);
        this.snackbar = injector.get(MatSnackBar);
        this.util = injector.get(UtilityService);
        this.dialogsService = injector.get(KalgudiDialogsService);

        const gift = this.cartService.getGiftData();

        if (gift) {
            this.isGift = gift.isGift;
            this.giftMessage = gift.giftMessage
        }
    }



    public getCartData(): void {

        this.cartService.getCartData()
            .pipe(takeUntil(this.destroyer$))
            .subscribe(cartData => {
                this.hasLoadingCompleted = true;
                if (!cartData || cartData.itemCount === 0) {
                    this.chargesAndOtherDetails = null;
                }
                this.cart = cartData;
                const storeSet = cartData.products.reduce((acc, curr) => {
                    let store = acc[curr.originStore];
                    if (store) {
                        store.push(curr);
                    } else {
                        acc[curr.originStore] = [curr];
                    }
                    return acc;
                }, {});

                this.filterIntoDisplayLists(storeSet);
                this.getShippingCharges();
                let totalAmount = 0;
                this.cart.products.forEach(element => {
                    totalAmount = (element.pricePerUnit * element.productQuantity) + totalAmount;
                });
                this.gaTrack.updateViewCartToAnalytics(this.cart, totalAmount);

            });
    }

    /* public getSavedProducts(): void {
        this.wishListService.getSavedProductsFromLocalDb().then((products: any) => {
            this.savedProducts = products;
        });
    } */


    public getShippingCharges(pinCode?: string): void {

        // Stopping execution when no product is added to cart
        if (!this.cart.products || !this.cart.products.length) {
            this.checkout.cartSummary.emit({
                products: 0,
                totalAmount: 0
            });
            return;
        }

        this.checkout.getShippingCharges(this.cart.products, 'CART').subscribe(response => {
            this.hasLoadingCompleted = false;
            this.chargesAndOtherDetails = response;

            // this.handleInvalidProducts(response);
            this.filterIntoDisplayLists(this.chargesAndOtherDetails.storeMap);

            // response.productList.forEach(product => {
            //     const index = this.cart.productIds.indexOf(product.productLevel3Id);
            //     this.cart.products[index].shippingCost = product.shippingCost;
            // });

            // this.doPriceVerification(response);
        });

    }

    /**
     * To handle the scenario when user has some invalid items in cart
     * 
     * @param response transportCharges service data response
     */
    public handleInvalidProducts(response): void {

        let removedItems: any[] = Object.values<any>(response.storeMap).reduce((a,c) => (c.invalidProducts && c.invalidProducts.length) ? [...a, ...c.invalidProducts] : a, []);

        // When there are invalid products in cart
        if (removedItems && removedItems.length) {
            const idsToBeRemoved = removedItems.map(item => item.productLevel3Id);
            removedItems = removedItems.map(item => item.productLevel3Title);
            this.snackbar.open(`${removedItems.length} items (${removedItems.join(', ')}) are unavailable currently and removed from cart`, 'OK', {
                duration: 10000, panelClass: ['snakbar-color']
            });
            this.cartService.cart.products = this.cartService.cart.products.filter(p => !idsToBeRemoved.includes(p.productLevel3Id));//  Object.values<any>(response.storeMap).reduce((a,c) => [...a, ...c.productList], []);
            this.cartService.cart.productIds = this.cartService.cart.products.map(i => i.productLevel3Id);
            this.cartService.setCartData();
            this.cartService.dispatchCartUpdate();
            if (this.isUserLoggedIn) {
                this.cartService.batchRemoveFromCart(idsToBeRemoved).subscribe();
            }
        }
    }

    public filterIntoDisplayLists(storeSet) {
        for (const storeName in storeSet) {
            if (Object.prototype.hasOwnProperty.call(storeSet, storeName)) {
                let storeProducts = storeSet[storeName];
                if (!Array.isArray(storeProducts)) {
                    storeProducts = storeProducts.productList;
                }
                const displayLists = {
                    itemsWithNoOffers: storeProducts.filter(i => !i.shipping && !i.freeShippingEligible),
                    itemsWithShippingOffers: storeProducts.filter(i => !i.shipping && i.freeShippingEligible),
                    itemsWithShippingChargesIncluded: storeProducts.filter(i => i.shipping)
                }
                /**
                 * To Group each seller products and put a flag for items which will be shipped for free along with other 
                 * items from same seller. 
                 * 
                 * It will also sort cart items.
                 */
                if (displayLists.itemsWithShippingOffers.length) {
                    const groupedProducts = this.util.groupBy(displayLists.itemsWithShippingOffers, 'sellerId');
                    let sortedList: CartProduct[] = [];
                    for (const id in groupedProducts) {
                        if (Object.prototype.hasOwnProperty.call(groupedProducts, id)) {
                            let items: CartProduct[] = groupedProducts[id];
                            for (const item of items) {
                                if (!item.shippingCostPerUnit && items.find(i => !!i.shippingCostPerUnit)) {
                                    item.shippingCostPerUnit = 0;
                                    item.shippingFreeWithOtherItems = true;
                                }
                            }
                            items.sort((a, b) => b.shippingCostPerUnit - a.shippingCostPerUnit);
                            sortedList = sortedList.concat(items);
                        }
                    }
                    // console.log(sortedList);

                    displayLists.itemsWithShippingOffers = sortedList;
                }
                storeSet[storeName] = displayLists;
            }
        }
        this.storeSet = storeSet;
    }

    public doPriceVerification(response) {
        let isChargesUpdated: boolean;

        for (const i in response.productList) {
            const product = response.productList[i];
            if (product.isPriceUpdate) {
                if (!this.productWithUpdatedPrice) {
                    this.productWithUpdatedPrice = [];
                }

                this.productWithUpdatedPrice.push(product);
                for (const id in this.cart.productIds) {
                    if (this.cart.productIds[id] === product.productLevel3Id) {
                        this.cart.products[id].pricePerUnit = product.updatedPrice;
                    }
                }

            }
            if (product.valuesUpdated) {
                isChargesUpdated = true;
                let cartItem = this.cart.products.find(item => item.productLevel3Id === product.productLevel3Id);
                if (product.updatedValues['HANDLING_DETAILS']) {
                    cartItem.handlingDetails.cost = product.updatedValues['HANDLING_DETAILS'].newValue.toString();
                }
                if (product.updatedValues['PACKAGING_DETAILS']) {
                    cartItem.packagingDetails.cost = product.updatedValues['PACKAGING_DETAILS'].newValue.toString();
                }
                if (product.updatedValues['SHIPPING_DETAILS']) {
                    cartItem.shippingDetails.cost = product.updatedValues['SHIPPING_DETAILS'].newValue.toString();
                }
            }
        }
        if (this.productWithUpdatedPrice && this.productWithUpdatedPrice.length) {
            this.openPriceUpdateDialog();
        }
        if (isChargesUpdated) {
            this.cartService.setCartData(this.cart);
        }

    }


    openPriceUpdateDialog() {

        this.dialog.open(PriceUpdateDialogComponent, {
            data: {
                productList: this.productWithUpdatedPrice
            }
        }).afterClosed().subscribe(result => {
            this.productWithUpdatedPrice = [];
            this.cartService.setCartData(this.cart);
            this.cartService.dispatchCartUpdate();
        });
    }

    public registerListeners(): void {

        this.cartService.cartUpdates()
            .pipe(takeUntil(this.destroyer$))
            .subscribe(cart => {
                this.getCartData();
            });

        /* this.wishListService.valueChange.on('true', response => {
            this.getSavedProducts();
        }); */
    }



    moveToSavedItems() {
        var savedItems = document.getElementById('saved-items');
        savedItems.scrollIntoView();
    }

    /**
     * Open gift message
     */
    public openGiftMessageDialog() {

        // Dialog UI configuration
        const dialogDetails: KalgudiDialogConfig = {
            title: this.giftMessage ? `Update message` : `Enter message`,
            acceptButtonTitle: 'OK',
            rejectButtonTitle: 'Close',
            data: {
                giftMessage: this.giftMessage || ''
            }
        };

        // Material dialog configuration
        const dialogConfig: MatDialogConfig = {
            width: '700px',
            hasBackdrop: true,
            disableClose: true,
            autoFocus: false,
        };

        // Show Gift message dialog
        this.showGiftMessageDialog(dialogDetails, dialogConfig)
            .pipe(

                // Take items from the stream only till the instance is alive
                takeUntil(this.destroyer$),

                tap(r => {
                    if (!r.accepted && !this.giftMessage) {
                        this.isGift = false;
                    }
                }),

                // Do operation only if dialog is not closed successfully
                // User has clicked the accept button
                filter(r => r.accepted),
            )
            .subscribe(res => {
                this.giftMessage = res.data.giftMessage;
                // this.gift.isGift = this.isGift;
                const gift = {
                    isGift: this.isGift,
                    giftMessage: this.giftMessage
                };
                this.cartService.setGiftData(gift);
            });
    }

    /**
     * Shows the update order dialog for web or mobile dialog
    */
    private showGiftMessageDialog(
        dialogConfig: KalgudiDialogConfig,
        matDialogConfig: MatDialogConfig<any>
    ): Observable<KalgudiDialogResult> {

        return this.dialogsService.openDialog(GiftMessageDialogComponent, dialogConfig, matDialogConfig);

    }

    unCheckGift() {
        this.giftMessage = '';
        this.cartService.removeGiftData();
    }


}