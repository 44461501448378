<span *ngIf="smartElement?.weight?.enabled">
    <span class="m0"> ({{smartElement?.weight?.value}} {{smartElement?.weight?.unit}})</span>
</span>
<span *ngIf="smartElement?.sizeByName?.enabled">
    <span class="m0"> ({{smartElement?.sizeByName?.value}})</span>
</span>
<span *ngIf="smartElement?.sizeByNumber?.enabled">
    <span class="m0"> ({{smartElement?.sizeByNumber?.value}} {{smartElement?.sizeUnit?.value}})</span>
</span>
<span *ngIf="smartElement?.packagingType?.enabled">
    <span class="m0"> ({{smartElement?.packagingType?.value}})</span>
</span>
<span *ngIf="smartElement?.itemType?.enabled">
    <span class="m0"> ({{smartElement?.itemType?.value}})</span>
</span>
<span *ngIf="smartElement?.shape?.enabled">
    <span class="m0"> ({{smartElement?.shape?.value}})</span>
</span>
<span *ngIf="smartElement?.patterAndDesign?.enabled">
    <span class="m0"> ({{smartElement?.patterAndDesign?.value}})</span>
</span>
<span *ngIf="smartElement?.materialAndFabric?.enabled">
    <span class="m0"> ({{smartElement?.materialAndFabric?.value}})</span>
</span>
<span *ngIf="smartElement?.itemCount?.enabled">
    <span class="m0"> ({{smartElement?.itemCount?.value}})</span>
</span>
<span *ngIf="smartElement?.colour?.enabled">
    <span class="m0"> ({{smartElement?.colour?.value}})</span>
</span> 