<div class="seasonal-products">
  <div class="m-auto">
    <img class="banner-img" src="/assets/images/small-banner for inside page.jpg" alt="">
  </div>
  <div class="app-container">
    <!-- <mat-button-toggle-group class="mat-options" fxLayout="row wrap" fxLayoutGap="10px">
      <mat-button-toggle class="toggle-button" [disableRipple]="true" *ngFor="let item of itemList">
        {{ item?.label }}
      </mat-button-toggle>
    </mat-button-toggle-group> -->
    <seasonal-product-tile></seasonal-product-tile>
  </div>
</div>

