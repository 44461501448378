import { Component, Inject, NgZone, OnInit } from "@angular/core";
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA } from "@kalgudi/common";
import { KalgudiDialogResult } from "@kalgudi/types";
import { finalize } from "rxjs/operators";
import { GooglePlacesApiService } from "src/app/modules/google-places/services/google-places-api.service";

@Component({
	selector: "map-dialog",
	templateUrl: "./map-dialog.component.html",
	styleUrls: ["./map-dialog.component.scss"],
})
export class MapDialogComponent extends KalgudiMobileDialog implements OnInit {
	googleResponse: any;

	loading = false;

	constructor(
		@Inject(KL_MOBILE_DIALOG_DATA) public dialogData: any,
		private ngZone: NgZone,
		protected dialogRef: KalgudiMobileDialogRef,
		private googlePlacesService: GooglePlacesApiService
	) {
		super(dialogRef);
	}

	ngOnInit() {
		this.googleResponse = this.dialogData.data;
	}

	public positionChanged(position) {
		position.latitude = JSON.parse(position.latitude);
		position.longitude = JSON.parse(position.longitude);

		this.loading = true;
		this.googlePlacesService.searchOnGoogle(position).subscribe(
			(res: any) => {
				this.googleResponse = res;
				this.ngZone.run(() => {
					this.loading = false;
				});
			},
			(err) => (this.loading = false)
		);
	}

	ok(): void {
		const result: KalgudiDialogResult = {
			accepted: true,
			data: this.googleResponse,
		};
		this.ngZone.run(() => {
			this.dialogRef.close(result);
		});
	}

	cancel(): void {
		const result: KalgudiDialogResult = {
			accepted: false,
			data: null,
		};
		this.ngZone.run(() => {
			this.dialogRef.close(result);
		});
	}
}
