import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import {
    delay,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    skip,
    startWith,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { KalgudiDestroyable } from '@kalgudi/core';
import {
    PincodeRequestDialogComponent,
} from 'src/app/project-common/components/pincode-request-dialog/pincode-request-dialog.component';
import { DeliveryAddress } from 'src/app/project-common/models/shipping-form.model';
import { ThirdPartyLibraries } from 'src/app/project-common/properties/constants';
import { DeliveryAddressService } from 'src/app/project-common/services/delivery-address.service';
import { ScriptLoaderService } from 'src/app/project-common/services/script-loader.service';
import { UtilityService } from 'src/app/project-common/services/utility.service';
import { SideBarService } from 'src/app/side-bar.service';

import { fade, notifyAnimation } from '../../../animations';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../project-common/services/notification.service';
import { StorefrontService } from '../storefront.service';
import { AuthenticationService } from './../../authentication/authentication.service';
import { Assistant } from './../../project-common/models/requirement.model';
import { CartService } from './../../project-common/services/cart.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [
        fade,
        notifyAnimation,
    ],
})
export class HeaderComponent extends KalgudiDestroyable implements OnInit {
    selectedLanguage: string;
    availableLanguage: any;
    isUserLoggedIn: boolean;
    loggedInUserName: string;
    userData: any;

    cart: any;
    productQuantityInCart: number;
    bulkCartItems: number;

    searchPlaceHolder = 'Search here ... ';
    searchText: string;
    showSearch = false;

    showSideNav = false;
    showTopHeader = false;

    headerCollapseChange$: Observable<boolean>;

    mobileHeaderCollapse$: Observable<boolean>;

    isSaleOpen: boolean;

    isMobileDevice: boolean;

    otherAboutDetails = {
        title: 'About Us',
        storeImageUrl: 'assets/aboutus/kalgudi-outline-logo.png',
        storeTitle: 'Kalgudi',
        storeDescription: 'Kalgudi is a digital convergence platform that enables all ecosystem players to focus on their tasks while kalgudi creates much visibility and reach of their activities to a larger section of the users.',
        coreService: [
            {
                service: 'Kalgudi Core',
                url: 'https://core.kalgudi.com/index.html'
            },
            {
                service: 'Input Store',
                url: 'https://inputs.kalgudi.com/'
            },
            {
                service: 'Output Store',
                url: 'https://outputs.kalgudi.com/'
            },
            {
                service: 'Consumer Store',
                url: 'https://store.kalgudi.com/store/home'
            },
            {
                service: 'ConnectX',
                url: 'https://connectx.kalgudi.com/app/home'
            },
            {
                service: 'SeedX',
                url: 'https://corporate.kalgudi.com/sites/seedx.html'
            }
        ],
        forWhoms: [
            {
                service: 'Farmer',
                url: 'https://corporate.kalgudi.com/farmer'
            },
            {
                service: 'FPO',
                url: 'https://corporate.kalgudi.com/fpo'
            },
            {
                service: 'Self-Help Groups',
                url: 'https://corporate.kalgudi.com/micro-enterprises'
            },
            {
                service: 'Impact Organization',
                url: 'https://corporate.kalgudi.com/impact-org'
            },
            {
                service: 'Input Players',
                url: 'https://corporate.kalgudi.com/input-players'
            },
            {
                service: 'Output Business',
                url: 'https://corporate.kalgudi.com/output-businesses'
            }
        ]
    }

    otherAppsList = {
        title: 'Our Stores',
        storeImageUrl: 'assets/images/kl-red-logo.png',
        storeTitle: 'Kalgudi Rural India Store',
        storeDescription: 'Kalgudi is your one-stop-shop for regional, nostalgic, authentic and unadulterated direct-from-producer products from across India.',
        storeDetails: [
            // {
            //     name: 'Andhragreens',
            //     url: 'https://andhragreens.com/',
            //     imageUrl: 'assets/aboutus/ag-logo.jpg',
            //     description: 'Andhra Pradesh\'s most nostalgic, authentic fresh Fruits, Vegetables, and Food items directly from the producers.'
            // },
            {
                name: 'eMahila',
                // url: 'http://beta.emahila.org/',
                url: 'https://emahila.org/',
                imageUrl: 'assets/aboutus/em-logo.jpg',
                description: 'Buy India\'s unique and specialty rural products directly from the makers.'
            },
            // {
            //     name: 'TGreens',
            //     url: 'https://tgreens.in/',
            //     imageUrl: 'assets/aboutus/tg-logo.jpg',
            //     description: 'Telangana brings you its wide varieties of fresh Fruits, Veggies, and other food items directly from the makers.'
            // },
            {
                name: 'Swayam Sikkim',
                url: 'https://swayamsikkim.com/',
                imageUrl: 'assets/aboutus/ss-logo.png',
                description: 'Swayam Sikkim brings to your doorstep, genuine products handmade by rural women of Sikkim.'
            }




        ]

    }


    private readonly SHRINK_TOP_SCROLL_POSITION = this.util.isMobileDevice() ? 100 : 136;

    newNotification: boolean;

    validPins: string[];
    supportTeam: string[];
    canAccessSupportPage: boolean;
    isKalgudiTeam = false;

    isTopHeader = true;

    isFocusChanged = false;

    loadSideNav = false;

    deliveryCheckForm: { pinCode: string };

    @ViewChild('headerSec') headerSec: ElementRef;

    @ViewChild('pincodeBox') pincodeBox: ElementRef;

    @Output() toggleCartSideBar = new EventEmitter();

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.pincodeBox && !this.pincodeBox.nativeElement.contains(event.target) && window.innerWidth > 1024) {

            this.showDeliveryLocationSelection = false;
        }
    }

    constructor(
        private eRef: ElementRef,
        private dialog: MatDialog,
        private storage: LocalStorage,
        private cartService: CartService,
        private notification: NotificationService,
        private authService: AuthenticationService,
        private router: Router,
        private location: Location,
        private util: UtilityService,
        private storeService: StorefrontService,
        private deliveryAddressService: DeliveryAddressService,
        private translator: TranslateService,
        private loader: ScriptLoaderService,
        private cartSidebar: SideBarService

    ) {
        super();
        this.loadRequiredLibraries();
    }

    showDeliveryLocationSelection = false;
    deliveryLocationSelected$: Observable<DeliveryAddress | string>;

    shippingMsgDialog = new BehaviorSubject(false);
    shippingMessage = "";

    ngOnInit() {
        window.addEventListener('scroll', this.scroll, true);

        this.isMobileDevice = this.util.isMobileDevice();

        this.availableLanguage = {
            en: 'English',
            hi: 'हिन्दी',
            te: 'తెలుగు',
            ka: 'ಕೆನಡಾ'
        };
        this.selectedLanguage = localStorage['lang'];

        this.getUserData();

        this.notification.userDataChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.getUserData();
            });

        this.cartService.cartUpdates()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response) => {
                this.updateCartData();
            });

        this.storeService
            .isSaleOpen()
            .subscribe((status) => (this.isSaleOpen = status));

        /**
         * Notifier to indicate user got a new notification
         */
        this.notification.isNewNotification.subscribe((res) => {
            this.newNotification = res;
            setTimeout(() => {
                this.newNotification = false;
            }, 10000);
        });

        this.deliveryCheckForm = {
            pinCode: '',
        };

        /* if (!localStorage.getItem('isVisitedBefore')) {
                this.showTopHeader = true;
                setTimeout(() => {
                    this.showTopHeader = false;
                }, 10000);
                localStorage.setItem('isVisitedBefore', 'visited');
            } */

        this.updateBulkCartItemCount();

        this.notification.getBulkOrderProducts().subscribe((products) => {
            this.updateBulkCartItemCount();
        });
        // delete window.localStorage['offers'];
        // this.hasValidOffers = this.util.checkOfferValidity();

        this.notification.languageChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((language) => {
                this.selectedLanguage = language;
            });
        // this.getCategoriesMenu();

        this.initHeaderCollapse();

        this.handleDeliveryLocationChanges();
    }

    scroll = (event: any): void => {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.showDeliveryLocationSelection = false;
        }
    }

    closePincodePopUp() {
        this.showDeliveryLocationSelection = false;
    }

    togglePopup(event) {
        event.stopPropagation();
        this.showDeliveryLocationSelection = !this.showDeliveryLocationSelection;
    }
    /**
     * To load dependency for page
     */
    loadRequiredLibraries() {
        this.loader.loadStyle(ThirdPartyLibraries.jostFont).subscribe();
    }

    toggleTopHeader() {
        this.isTopHeader = false;
    }
    handleDeliveryLocationChanges() {

        this.deliveryLocationSelected$ = this.deliveryAddressService.deliveryLocationSelected$;

        let deliveryPin: any = '';
        this.deliveryLocationSelected$
            .pipe(
                takeUntil(this.destroyed$),

                skip(1),

                distinctUntilChanged(),

                switchMap((deliveryLocationSelected: DeliveryAddress) => {
                    deliveryPin = deliveryLocationSelected.postalCode || deliveryLocationSelected;
                    return this.storeService.deliverablePinCodes$
                }),

                filter(validPins => !!(validPins && validPins.length)),

                tap(validPins => {

                    const allProductsDeliverable = validPins.includes(deliveryPin);
                    this.translator.get(['Yay ! Complete range of Kalgudi products including Fruits & Vegetables are available in your delivery location', 'All Kalgudi products except Fruits & Vegetables are available in your delivery location']).subscribe(translatedText => {
                        this.shippingMessage = allProductsDeliverable
                            ? translatedText['Yay ! Complete range of Kalgudi products including Fruits & Vegetables are available in your delivery location']
                            : translatedText['All Kalgudi products except Fruits & Vegetables are available in your delivery location'];
                    })
                    localStorage.setItem(
                        `canShipToLocation`,
                        JSON.stringify(allProductsDeliverable)
                    );

                    this.shippingMsgDialog.next(true)
                }),

                delay(5000),

                tap(() => this.shippingMsgDialog.next(false))
            ).subscribe();
    }

    initHeaderCollapse() {
        const content = document.getElementById('app-content');
        const scrollEvent$ = fromEvent(content, 'scroll', { passive: true });

        this.headerCollapseChange$ = merge(...[scrollEvent$, this.router.events]).pipe(
            startWith(false),

            map(() => {
                if (!content) return false;

                const { scrollTop: scrollHeight } = content;

                // const { scrollY: scrollHeight } = window;

                if(this.util.isMobileDevice() && this.loginPage) return true;

                return scrollHeight > this.SHRINK_TOP_SCROLL_POSITION;
            }),

            distinctUntilChanged(),
            shareReplay()
        );

        // this.mobileHeaderCollapse$ = merge(...[scrollEvent$, this.router.events]).pipe(
        //     startWith(false),

        //     map(() => {
        //         const { scrollY: scrollHeight } = window;

        //         return (scrollHeight > 80) || !this.homePage;
        //     }),

        //     distinctUntilChanged()
        // )
    }

    protected onDestroyed() { }

    // toggle cart side bar

    public onToggleCart(): void {

        this.cartSidebar.toggleCartSidebar();


    }
    // convertToArray (data) {

    //     const arr = [];
    //     for (const key in data) {
    //         if (data[key].subCategories) {
    //             data[key].subCategories = this.convertToArray(data[key].subCategories);
    //         }
    //         const obj = {
    //             'id': data[key].id,
    //             'subCategories' : data[key].subCategories,
    //             'showSubCategories': false,
    //             'name': data[key].value
    //         };
    //         arr.push(obj);
    //     }
    //     return arr;
    // }

    private updateBulkCartItemCount() {
        const bulkCart = JSON.parse(localStorage['bulkCart'] || '[]');
        let itemCount = 0;
        bulkCart.forEach((shgGroup) => {
            itemCount += shgGroup.products.length;
        });
        this.bulkCartItems = itemCount;
    }

    goToLogin() {
        this.util.isGuestLoginPage.next(false);
        this.router.navigate(['auth', 'login']);
    }

    getSwitchLanguageImage(languageCode) {
        switch (languageCode) {
            case 'en':
                return `url(assets/icons/header/english.svg)`;
            case 'te':
                return `url(assets/icons/header/telugu.png)`;
        }
    }

    get loginPage() {

        return this.router.url.includes('/auth/login');

    }
    
    /**
     * To scroll into an anchor
     *
     * @param categoryId unique id for category
     */
    public navigateTo(categoryId: string) {
        // this.scroller.scrollToAnchor(categoryId);
        if (this.router.url.length > 1) {
            setTimeout(() => {
                this.startScroll(categoryId);
            }, 3000);
            this.router.navigate(['/']);
        } else {
            this.startScroll(categoryId);
        }
    }

    private startScroll(categoryId: string) {
        const top = document.getElementById(categoryId).offsetTop;
        window.scrollTo({
            top: top - 150,
            behavior: 'smooth',
            left: 0,
        });
    }

    switchLanguage(languageCode) {
        this.selectedLanguage = languageCode;
        this.notification.notifyForLanguageChange(languageCode);
    }

    searchForCategoryMobile(request) {
        this.showSideNav = false;
        if (request.isBaseCategory) {
            this.router.navigateByUrl('product-list/' + request.category);
        } else {
            const navigationExtras: NavigationExtras = {
                queryParams: request,
            };
            navigationExtras.queryParams.limit = 20;
            navigationExtras.queryParams.view = 'grid';
            this.router.navigate(['/search', request.keyword], navigationExtras);
        }
    }

    updateCartData() {
        this.cartService.getCartData().subscribe((cartData) => {
            this.cart = cartData;
            this.cartCount();
        });
    }

    search(request) {
        // console.log(request.keyword);
        if (!request.keyword) return;
        const navigationExtras: NavigationExtras = {
            queryParams: {
                category: request.category,
                limit: 20,
                view: 'grid',
            },
        };
        this.router.navigate(['/search', request.keyword], navigationExtras);
    }

    onFocusChange(event) {
        this.isFocusChanged = event;
    }

    getUserData() {
        this.storage.getItem('userdata').subscribe((userData) => {
            this.userData = {};
            this.isUserLoggedIn = false;
            this.isKalgudiTeam = false;
            if (userData && userData.firstName) {
                const regex = /(@vasudhaika\.net|@kalgudi\.com)$/;
                this.isKalgudiTeam = userData.emailId && regex.test(userData.emailId);
                this.userData = userData;
                this.canAccessSupportPage = this.checkCustomerSupportContributor;
                this.isUserLoggedIn = true;
                this.loggedInUserName = userData.firstName;
                const url = `${environment.baseUrl}/data/profilepics/${this.userData.profileKey}.png`;
                if (this.userData.profilePicUrl === url) {
                    this.userData.profilePicUrl = '/assets/icons/header/user.svg';
                }
            }
        });
    }

    logout() {
        this.authService.logout();
        this.loggedInUserName = '';
    }

    openSidebarOrNavigateBack() {
        this.isHome ? (this.showSideNav = true) : this.location.back();
    }

    cartCount() {
        this.productQuantityInCart = 0;
        this.cart &&
            this.cart.products.forEach((product) => {
                this.productQuantityInCart += parseInt(product.productQuantity);
            });
    }

    deleteProduct(product) {
        this.cartService.removeProductFromCart(product);
    }

    get isHome() {
        const pages = ['login', 'signup'];
        const url = this.router.url;

        return pages.reduce((a, p) => url.includes(p) || url === '/', false);
    }

    get assistant(): Assistant | null {
        return JSON.parse(localStorage['assistant'] || null);
    }

    get isDashboard() {
        return this.router.url.includes('dashboard');
    }

    get storeNotifications() {
        return []; // this.fcm.notifications;
    }

    get homePage() {
        return this.router.url === '/';
    }

    get isProductFullView() {
        return this.router.url.includes('/p/c');
    }

    get isCheckoutPage() {
        return this.router.url.includes('/checkout');
    }

    get isAdminPage() {
        return this.router.url.includes('/support');
    }
    get categories$(): Observable<{ id?: string; value?: string }[]> {
        return this.storeService.categories$;
    }

    public isNumber(event: KeyboardEvent) {
        return this.util.numberOnly(event);
    }
    /**
     * To check if delivery is enabled for some pin code
     */
    public checkPinCode() {
        if (isNaN(+this.deliveryCheckForm.pinCode)) return;

        const canShipToLocation = this.validPins.includes(
            this.deliveryCheckForm.pinCode
        );
        this.dialog.open(PincodeRequestDialogComponent, {
            panelClass: 'position-relative',
            autoFocus: false,
            data: {
                pincode: this.deliveryCheckForm.pinCode,
                pincodeAvalilable: canShipToLocation,
                userData: this.userData,
            },
        });
        localStorage.setItem(`pin`, this.deliveryCheckForm.pinCode);
        localStorage.setItem(
            `canShipToLocation`,
            JSON.stringify(canShipToLocation)
        );
    }

    /*
     * Checks the logged in user is contributor of Kalgudi Support Program
     */
    private get checkCustomerSupportContributor() {
        let isSupportPage = false;
        const listOfPageDetails = this.userData.listOfPageDetails;
        if (listOfPageDetails) {
            listOfPageDetails.forEach((l) => {
                if (
                    (l.pageId === 'O00k4jn1PROCRE2018111425291806UNH001' ||
                        l.pageId === 'O00000u1PROCRE2018111424408497UNH001') &&
                    (l.relation === 'CONTRIBUTOR' || l.relation === 'FOLLOWER')
                ) {
                    isSupportPage = true;
                    return;
                }
            });
        }
        return isSupportPage;
    }

    ngOnDestroy() {
        window.removeEventListener('scroll', this.scroll, true);
    }
}
