import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mango-tile-skeleton',
  templateUrl: './mango-tile-skeleton.component.html',
  styleUrls: ['./mango-tile-skeleton.component.scss']
})
export class MangoTileSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
