import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { expandCollapse } from '../../../animations';

@Component({
    selector: 'app-mobile-sub-cat',
    template: `
    <div class="category-container">
        <div (click)="isExpanded = !isExpanded" class="category" fxLayoutAlign="space-between center">
            <div class="name" translate>{{ subCategory.name | lowercase }}</div>
            <i class="fa" [class.fa-angle-right]="!isExpanded" [class.fa-angle-down]="isExpanded"></i>
        </div>
        <div [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'">
            <div class="sub-category-container" *ngFor="let sub of subCategory.subCategory">
                
                <div class="category" fxLayoutAlign="space-between center" (click)="selectCategory(sub)" *ngIf="!sub.subCategory">
                <div class="name" translate>{{ sub.name |lowercase }}</div>
                </div>

                <div class="category-container">
                    <div (click)="sub.isExpanded = !sub.isExpanded" class="category" fxLayoutAlign="space-between center">
                    <div class="name" translate>{{ sub.name | lowercase }}</div>
                        <i class="fa fa-angle-{{ sub.isExpanded ? 'down': 'right'}}"></i>
                    </div>
                    <div [@expandCollapse]="sub.isExpanded ? 'expanded' : 'collapsed'">
                        <div class="sub-category-container" *ngFor="let c of sub.subCategory" (click)="selectCategory(c, 4, sub, subCategory)">
                            <div class="category" fxLayoutAlign="space-between center">
                            <div class="name" translate>{{ c.name |lowercase }}</div>
                            </div>
                        </div>
                        <div class="sub-category-container" (click)="selectCategory(sub, 3, subCategory)">
                            <div class="category" fxLayoutAlign="space-between center">
                            <div class="name" translate><span translate>All</span> {{ sub.name |lowercase  }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sub-category-container" (click)="selectCategory(subCategory, 2)">
                <div class="category" fxLayoutAlign="space-between center">
                <div class="name" translate><span translate>All</span> {{ subCategory.name | lowercase }}</div>
                </div>
            </div>
        </div>
    </div>
    `,
    styles: [
        `
            .category {
                padding: 15px 20px;
                border-bottom: 1px solid #dfdfdf;
            }
            .sub-category-container {
                margin-left: 25px;
            }
            .name{
                text-transform: capitalize;
            }
        `
    ],
    animations: [
        expandCollapse
    ]
})
export class MobileSubCatComponent implements OnInit {

    @Input('sub-cat') subCategory: any;
    @Output() category = new EventEmitter();

    isExpanded = false;

    constructor() { }

    ngOnInit() {

    }

    selectCategory(category, level?, parent?, superParent?) {
        const params = {
            l4Id: '',
            l3Id: '',
            l2Id: '',
            keyword: category.name
        };
        switch (level) {
            case 2:
                params.l2Id = category.id;
                break;
            case 3:
                params.l3Id = category.id;
                params.l2Id = parent.id;
                break;
            case 4:
                params.l4Id = category.id;
                params.l3Id = parent.id;
                params.l2Id = superParent.id;
                break;
        }
        this.category.emit(params);
    }

}
