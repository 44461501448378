



<div fxLayout="column" class="h-100 d-flex">


  <div class="d-flex map-section" fxFlex fxLayout="column">

    <address-form [formData]="dialogData?.data" (cancelClicked)="cancel()" (submitClicked)="submitClicked($event)"></address-form>

  </div>

</div>





