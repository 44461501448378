import { BehaviorSubject, Observable } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-route-progress-bar',
  template: `<mat-progress-bar color="warn" *ngIf="(routeLoading$ | async)" mode="query"></mat-progress-bar>`,
  styles: [`
    mat-progress-bar {
      position: fixed;
      z-index: 10;
      top: 0;
    }
  `]
})
export class RouteProgressBarComponent implements OnInit {

  private routeLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.routeLoading.next(true);
      } else  if (e instanceof NavigationEnd) {
        this.routeLoading.next(false);
      } else if (e instanceof NavigationError) {
        this.routeLoading.next(false);
      } else if (e instanceof NavigationCancel) {
        this.routeLoading.next(false);
      }
    });
  }

  get routeLoading$(): Observable<boolean> {
    return this.routeLoading.asObservable();
  }

}
