import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  @Input('cart') cart: any;
  @Input('charges') charges: any;

  constructor() { }

  ngOnInit() {
  }

}
