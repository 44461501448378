import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../../services/utility.service';
// import { FcmService } from '../../services/fcm.service';
import { Notifications } from '../../models/storeNotification.model';

@Component({
  selector: 'store-notifications',
  templateUrl: './store-notifications.component.html',
  styleUrls: ['./store-notifications.component.scss']
})
export class StoreNotificationsComponent implements OnInit {

  constructor(private util: UtilityService/* , private notification: FcmService */) { }

  ngOnInit() {
    this.util.isMobileDevice() && window.scroll(0, 0);
  }

  get notifications(): Notifications[] {
    let notifications = [];
    /* Object.values(this.notification.notifications.notifications).forEach( notification => {
      notifications.push(notification);
    }); */
    return notifications;
  }

  read ( isReadAll, notification ) {
    let payload = {
      readAllFlag: isReadAll || false,
      notificationIds: notification ? [notification.id] : []
    }
    /* this.notification.readStoreNotification(payload).subscribe(res => {
      // console.log('res =',res);
    }); */
  }

}
