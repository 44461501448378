import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@app/env';
import { finalize } from 'rxjs/operators';
import { StorefrontService } from 'src/app/storefront/storefront.service';

@Component({
  selector: 'design-banner',
  templateUrl: './design-banner.component.html',
  styleUrls: ['./design-banner.component.scss']
})
export class DesignBannerComponent implements OnInit {

  progress: boolean;

  currentSlide = 0;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    fade: true,
    prevArrow: ``,
    nextArrow: ``
  };
  
  bannerSlideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    fade: true, 
    dots: false,
    prevArrow: `<div class="s-prev"><i class="fa fa-angle-left" aria-hidden="true"></i><div>`,
    nextArrow: `<div class="s-next"><i class="fa fa-angle-right" aria-hidden="true"></i><div>`
  };

  bannerImagesList: any[] = [];

  constructor( private storefrontService: StorefrontService) { }

  ngOnInit() {
    this.getBannersList();

    if(window.innerWidth < 600) {
      this.bannerSlideConfig.dots = true;
      this.bannerSlideConfig.nextArrow = ``;
      this.bannerSlideConfig.prevArrow = ``
    }
  }

  afterChange(event) {    
    this.currentSlide = event.currentSlide;
  }

  /**
   * Fetch banners from s3 data
   */
  getBannersList() {
    this.progress = true;

    const storeTypeBannerId = window.innerWidth < 600 ? `${environment.APP_NAME}_BANNER_MOBILE` :  `${environment.APP_NAME}_BANNER_WEB`;    

    this.storefrontService.getBannersList(storeTypeBannerId)
        .pipe(
            finalize(() => this.progress = false)
        ) 
        .subscribe(
            res => {

                this.bannerImagesList = res.sorted_Active;

            }
        )
  }

}
