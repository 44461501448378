<section fxLayout="column" class="mango-side-bar">
	<div class="sticky-top bg-white">
		<div class="pre-heading">
			<span class="pre-book-heading">Pre booking request</span>
			<span class="item-count" *ngIf="numOfItems > 0">{{numOfItems}} items</span>
		</div>
	</div>
	<div class="close-icon d-flex align-items-center justify-content-center" (click)="close()">
		<i class="material-icons">close</i>
	</div>
	<!-- items -->
	<div fxLayout="column" fxFlex="100%" class="products-list">

		<ng-container *ngIf="!isPreBookingForm && !isOtpFormSuccess">

			<div fxLayout="column" fxLayoutGap="10px">
				<ng-container *ngFor="let product of selectedProductList | keyvalue">
					<mango-cart-item [product]="product?.value"></mango-cart-item>
				</ng-container>
			</div>

			<ng-container *ngIf="numOfItems <= 0">

				<kl-no-content text="No products added for pre booking"> </kl-no-content>
				
			</ng-container>

		</ng-container>
		
		<div class="products-list pre-book-form" *ngIf="isPreBookingForm && !isOtpFormSuccess">
			<pre-booking-form 
			(onVerifyOtp)="onOtpVerification($event)"
			(isExisting)="onExistingAd($event)"
			(onExistingAddressSelect)="onExistingAddressSelection($event)"
			></pre-booking-form>
		</div>
		
		<ng-container *ngIf="isOtpFormSuccess && preBookInfo">
			<thank-you-card [preBookInfo]="preBookInfo" (onPreBookSuccess)="onSuccessOfPrebook($event)"></thank-you-card>
		</ng-container>

	</div>
	
	
	<!-- /items -->

	<div class="d-flex justify-content-end py-10 pr-15 btn-sec" *ngIf="!isOtpFormSuccess">
		<button *ngIf="!isPreBookingForm"
		mat-button 
		class="mango-next-btn"
		[disabled]="numOfItems <= 0"
		(click)="onNext()"
		>Next</button>

		<ng-container *ngIf="isPreBookingForm">

			<kl-link-button 
				[label]="'Back'" 
				buttonColor="warn" 
				(clicked)="onBack()">
				</kl-link-button>
			
			<ng-container *ngIf="!isExistingUser">

				<kl-flat-button 
					[type]="'button'" 
					[label]="'SUBMIT'" 
					[progress]="isOtpVerified" 
					[disabled]="!verificationInfo?.otp"
					(clicked)="verifyPreBooking()">
				</kl-flat-button>

			</ng-container>
			

		</ng-container>
		
	</div>
</section>

