import { Component, OnInit, Input } from '@angular/core';
import { WishListService } from './../../project-common/services/wish-list.service';
import { Router } from '@angular/router';
import { CartService } from 'src/app/project-common/services/cart.service';

@Component({
  selector: 'saved-item',
  templateUrl: './saved-item.component.html',
  styleUrls: ['./saved-item.component.scss']
})
export class SavedItemComponent implements OnInit {

  @Input('isOnCart') isOnCart: boolean;

  savedProducts : any;
  
  constructor(private wishListService: WishListService,
    private router: Router,
    private cartService: CartService) { }

  ngOnInit() {
    this.wishListService.getSavedProductsFromLocalDb().then((products: any) =>{
      this.savedProducts = (products);
    });

    this.wishListService.valueChange.on('true',response => {
        this.wishListService.getSavedProductsFromLocalDb().then((products: any) =>{
            this.savedProducts = (products);
        });
    });
  }

}
