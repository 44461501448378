<div class="location-selection">
    <div class="header-seperator d-flex">
    <div>
        <h6 class="postOfficeHeading">
        We have found multiple locations matching {{ pincode }} pincode. Choose appropriate location for your delivery.
    </h6>
    </div>
    <div class="justify-content-end">
        <mat-icon (click)="cancel()">close</mat-icon>
    </div>
</div>

<div class="recommendedPostOffice" *ngIf="recommendedPostOffice!= 'notFound' ">

    <div class="location">
        <p> <strong>Suggested location</strong></p>
    </div>

    <div class="recommendedBlock" (click)="selectPostOffice(recommendedPostOffice)">
        <label class="align-radioBtn-with-postOffice" for="chosen_{{recommendedPostOffice}}">
            <input id="chosen_{{recommendedPostOffice}}" [value]='recommendedPostOffice' type="radio" name="postOfficesList"> {{ recommendedPostOffice }}
        </label>
    </div>

    <div class="other-location">
    <p><strong>Other locations</strong></p>
    </div>

    <div class="notRecommendedBlock" *ngFor="let postOffice of uniquePostOfficeNames" (click)="selectPostOffice(postOffice)">
        <label class="align-radioBtn-with-postOffice" for="chosen_{{postOffice}}">
            <input id="chosen_{{postOffice}}" [value]='postOffice' type="radio" name="postOfficesList"> {{ postOffice }}
        </label>
    </div>
</div>

<div class="recommendedPostOffice" *ngIf="recommendedPostOffice=='notFound'">

    <div class="location">
        <p> <strong>Available locations</strong></p>
    </div>

    <div class="notRecommendedBlock" *ngFor="let postOffice of uniquePostOfficeNames" (click)="selectPostOffice(postOffice)">
        <label class="align-radioBtn-with-postOffice" for="chosen_{{postOffice}}">
            <input id="chosen_{{postOffice}}" [value]='postOffice' type="radio" name="postOfficesList">
            {{ postOffice }}
        </label>
    </div>

</div>    

<div class="selected-location" fxLayout fxLayoutAlign="center center">
    <div fxFlex="100%">
        <button mat-button color="primary" (click)="ok()">OK</button>
    </div>
</div>

