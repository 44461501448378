<div fxLayout="column">

  <!-- Dialog title -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{dialogData?.title}}</h1>
    <button id="close-btn" class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!--/ Dialog title -->

  <div class="dialog-content" fxLayout="column" fxLayoutAlign="space-between stretch">

    <!-- Section for mat dialog content -->
      <div class="dialog-container">

        <address-form [formData]="dialogData?.data" (cancelClicked)="cancel()" (submitClicked)="submitClicked($event)"></address-form>
      </div>

    <!-- /Section for mat dialog content -->
  </div>
</div>