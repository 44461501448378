<div class="isLoading" fxHide.lt-md>
  <div fxLayout="row wrap" class="p-container">
    <div class="p-5" fxFlex="20%" fxFlex.sm="25%" fxFlex.xs="50%"
      *ngFor="let x of [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]">

      <div class="loader p-tile" fxLayout="column" fxLayoutGap="5px">
        <div fxFlex="100px"  style="border-radius: 5px;align-items: center;" class="elm"></div>
        
        <div fxLayout="row" fxLayoutAlign="space-between" class="loader h-25">
          <div fxFlex="25" class="elm"></div>
        <div fxFlex="25" class="elm"></div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="isLoading" fxHide fxShow.lt-md>
  <div fxLayout="column" class="p-container">
    <div class="p-5" fxFlex="100%"
      *ngFor="let x of [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]">
      <div class="loader p-tile" fxLayout="row" fxLayoutGap="5px">
        <div fxFlex="25"  style="border-radius: 5px;align-items: center;" class="elm"></div>
        
        <div fxFlex="100" class="elm"></div>
  
      </div>

    </div>
  </div>
</div>