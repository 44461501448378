<!-- Side nav in mobile -->
<div class="overlay medium-screen" @fade *ngIf="showSideNav" (click)="toggle.emit()" detectSwipe (swipe-left)="toggle.emit()"></div>
<div [@side-nav]="showSideNav ? 'opened' : 'closed'" class="side-nav-sec medium-screen">
    <div class="profile-sec" (click)="toggle.emit()">
        <div class="profile" fxLayoutGap="15px">
            <div class="profile-img" fxFlex="50px">
                <img [src]="(userData?.profilePicUrl | scaleImage: 'pic150') || 'assets/icons/user.png'" height="100%" width="100%">
            </div>
            <div class="detail" fxFlex fxLayout="column" fxLayoutAlign="space-around start">
                <div class="greet" translate>Hello</div>
                <div class="name">
                    <div *ngIf="userData?.firstName">{{userData?.guestRegistration ? 'Guest' : userData?.firstName}}</div>
                    <div *ngIf="!userData?.firstName">
                        <span class="sign-in" translate (click)="goToLoginPage()">Login</span>
                        <!-- <span> | </span>
                        <span class="register" routerLink="/auth/signup" translate>Register</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Will show all the categories list -->
    <!-- <div class="side-title" translate>CATEGORIES</div> -->
    <!-- <div *ngFor="let category of allCategories">
        <app-mobile-cat [category]="category" (subCategory)="searchForCategoryMobile($event)"></app-mobile-cat>
    </div> -->
    <!-- End of categories list -->

    <div class="nav-links-sec">
        <div class="nav-links">
            <div class="side-about" (click)="toggle.emit();openAbout()" translate>ABOUT US</div>
            <div class="side-about" (click)="toggle.emit();openStore()" translate>OUR STORES</div>
            <div class="side-title" translate>CATEGORIES</div>
            <!-- <ng-container *ngFor="let category of (categories$ | async)">
                <div class="link" (click)="toggle.emit()" [routerLink]="['/search', category?.name]" [queryParams]="{category: category?.id }" translate>{{category.name | lowercase}}</div>
            </ng-container> -->
            <div (click)="openAllCategories()" 
                style="padding: 15px 20px; border-bottom: 1px solid #dfdfdf;" fxLayoutAlign="space-between center">
                <span>All</span>
                <i class="fa" [class.fa-angle-right]="true"></i>
            </div>
            <div *ngFor="let category of (categories$ | async)">
                <app-mobile-cat [category]="category" (subCategory)="searchForCategoryMobile($event)"></app-mobile-cat>
            </div>

        </div>
        <div *ngIf="isUserLoggedIn">
            <div class="nav-links">
                <div class="side-title" translate>{{userData?.guestRegistration ? 'orders' : 'orders and reviews'}}</div>
                <div class="link" (click)="toggle.emit()" routerLink="account/my-orders" translate>Orders</div>
                <ng-container *ngIf="!userData?.guestRegistration">
                    <div class="link" (click)="toggle.emit()" *ngIf="canAccessSupportPage" routerLink="/support/orders">
                        Fulfillment</div>
                    <div class="link" (click)="toggle.emit()" routerLink="/account/my-reviews" translate>Reviews</div>
                </ng-container>
            </div>
            <div class="nav-links" *ngIf="!userData?.guestRegistration">
                <div class="side-title" translate>MY ACCOUNT</div>
                <div class="link" (click)="toggle.emit()" routerLink="account/profile" translate>My profile</div>
                <!-- <div class="link" (click)="toggle.emit()" routerLink="/account/wish-list" translate>My wishlist
                </div> -->
                <div class="link" (click)="toggle.emit()" routerLink="account/address" translate>My addresses
                </div>
                <div class="link" (click)="toggle.emit()" routerLink="account/change-password" translate>Change password
                </div>
            </div>

            <!-- Buy Requirements -->
            <!-- <div class="nav-links">
                <div class="side-title" translate>Buy Requirements</div>
                
                <div class="link" (click)="toggle.emit()" routerLink="account/inbox" translate>Inbox
                </div>
            </div> -->
        </div>
        <div *ngIf="!isUserLoggedIn">
            <div class="nav-links">
                <div class="side-title" translate>MY ACCOUNT</div>
                <div class="link" (click)="toggle.emit()" routerLink="account/profile" translate>My profile</div>
                <div class="link" (click)="toggle.emit()" routerLink="account/my-orders" translate>Orders</div>
            </div>
        </div>



        <div class="logout-link" *ngIf="isUserLoggedIn" (click)="toggle.emit();logout.emit()" translate>Logout</div>

    </div>

    <div class="change-language" fxLayout>
        <div  class="lang disabled" fxFlex> <span translate>తెలుగు</span><!--(click)="switchLanguage('te')" [class.active]="selectedLanguage == 'te'"-->
        </div>
        <div (click)="switchLanguage('en')" class="lang" [class.active]="selectedLanguage == 'en'" fxFlex> <span translate>English</span>
        </div>
        <div class="lang disabled" fxFlex> <span translate>हिन्दी</span><!--(click)="switchLanguage('hi')"  [class.active]="selectedLanguage == 'hi'"-->
        </div>
        <!-- <div (click)="switchLanguage('ka')" class="lang" [class.active]="selectedLanguage == 'ka'" fxFlex> <span translate>ಕೆನಡಾ</span>
        </div> -->
    </div>

</div>


<div class="swipe-area" detectSwipe (swipe-right)="toggle.emit()" fxHide fxShow.xs></div>


<div id="allAbout" class="sidenav">
    <div class="closebtn" (click)="closeAbout()"><i class="fa fa-times" aria-hidden="true"></i></div>
    <div class="d-flex flex-column apps">
        <div class="d-flex flex-column">
            <div class="align-items-center d-flex heading flex-row">
              <img height="30px" width="30px" src={{otherAboutDetails?.storeImageUrl}} alt="Logo">
              <div class="d-flex flex-column">
                <span class="font-20 store-name ml-8" translate>{{otherAboutDetails?.storeTitle}}</span>
              </div>
              
          </div>
          <p class="mt-10 desc" translate>{{otherAboutDetails?.storeDescription}} <a class="readmore" target="_blank" href="https://corporate.kalgudi.com/" translate>Read More..</a></p>
           </div>

           <div class="d-flex flex-column  right-section">
            <div class="d-flex flex-column service">
              <span class="heading font-18" translate>Core services</span>
              <ng-container *ngFor="let service of otherAboutDetails?.coreService">
                <a target="_blank" [href]="service?.url" translate>{{service?.service}}</a>
              </ng-container>
            </div>
            <div class="d-flex flex-column service">
              <span class="heading font-18 mt-15" translate>Users</span>
              <ng-container *ngFor="let whoms of otherAboutDetails?.forWhoms">
                <a target="_blank" [href]="whoms?.url" translate>{{whoms?.service}}</a>
              </ng-container>
            </div>
           </div>

    </div>
</div>


<div id="allStore" class="sidenav">
    <div class="closebtn" (click)="closeStore()"><i class="fa fa-times" aria-hidden="true"></i></div>
    <div class="apps d-flex flex-column">
        <div class="d-flex flex-column">
         <div class="align-items-center d-flex heading flex-row">
           <img height="30px" width="30px" src={{otherAppsList?.storeImageUrl}} alt="Logo">
           <div class="d-flex flex-column">
             <span class="font-20 store-name ml-8" translate>{{otherAppsList?.storeTitle}}</span>
           </div>
           
       </div>
       <p class="mt-10 desc" translate>{{otherAppsList?.storeDescription}}</p>
        </div>
        <div class="d-flex flex-column right-section">
       <ng-container *ngFor="let app of otherAppsList?.storeDetails">
         <a target="_blank" [href]="app?.url">
           <div class=" d-flex heading flex-row">
             <img height="30px" width="30px" src={{app?.imageUrl}} alt="Logo">
             <div class="d-flex flex-column">
               <span class="font-18 store-title ml-8" translate>{{app?.name}}</span>
               <span class="font-14 mt-5 store-detail ml-8" translate>{{app?.description}}</span>
             </div>
         </div>
         </a>
       </ng-container>
        </div>
       </div>
</div>