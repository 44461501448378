<!-- Saved product list -->
<div class="saved-items" [ngClass]="{'p20 app-container': !isOnCart}">
  <div class="header">
    <h2 translate fxFlex="22%" fxFlex.xs="32%">Saved for later ({{savedProducts?.products?.length}})</h2>
    <hr fxFlex>
  </div>
  <ng-container *ngFor="let product of savedProducts?.products">

    <!-- <cart-item [item]="product"></cart-item> -->
    <item [product]="product" [isSavedItem]="true" class="savedItem"></item>

  </ng-container>
  <div class="action-btn" *ngIf="!isOnCart">
    <button mat-flat-button routerLink="/checkout/cart">
      <span translate>Go to cart</span>
    </button>
  </div>
</div>