import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
