import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { environment } from 'src/environments/environment';

import { Cart } from '../models/cart-product.model';
import {
  AnalyticsAddShippingInfo,
  AnalyticsAddToCart,
  AnalyticsProductCheckout,
  AnalyticsProductCheckoutOption,
  AnalyticsProductClick,
  AnalyticsProductDetails,
  AnalyticsProductImpressions,
  AnalyticsPurchaseProduct,
  AnalyticsRemoveFromCart,
  AnalyticsViewCart,
  UserAnalytics
} from '../models/google-analytics.model';

declare var gtag: any;
declare var dataLayer: any;
@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService {

  productCheckout: AnalyticsProductCheckout;
  purchaseObject: AnalyticsPurchaseProduct;
  productCheckoutOption: AnalyticsProductCheckoutOption;
  productClickObject: AnalyticsProductClick;
  addToCartObject: AnalyticsAddToCart;
  viewCartObject: AnalyticsViewCart;
  removeFromCartObject: AnalyticsRemoveFromCart;
  productImpressionObject: AnalyticsProductImpressions;
  productDetailsObject: AnalyticsProductDetails;
  addShippingInfoObject: AnalyticsAddShippingInfo;
  loggedInUserData: any;
  userAnalytics: UserAnalytics;
  userData:any;

  constructor(private storage: LocalStorage) {
    this.storage.getItem('userdata').pipe().subscribe(res => {
      this.userData = res.mobileNo && !(/[a-zA-Z]/g.test(res.mobileNo)) ? res.mobileNo : res.emailId;
    })
  }

  /**
  * To track current page to google analytics 
  * 
  * @param {string} pagePath Path of the page which user views
  * @param {string} pageTitle Title of the page
  */
  public configPageViewToAnalytics(pagePath: string, pageTitle: string) {
    if (environment.production) {
      gtag("config", "UA-73308485-11", {

        page_path: pagePath,
        page_title: pageTitle

      });

      /* gtag('event', 'page_view', {
        'send_to': 'AW-936859343',
        'user_id': localStorage['dXNlcm5hbWU='] ? atob(localStorage['dXNlcm5hbWU=']) : 'Guest_user'
      }); */
    }
  }

  /**
   * 
   * @param cartDetails of buyer
   */
  public updateCheckoutStepOnAnalytics(cart: Cart, totalAmount?: number) {
    this.productCheckout = {
      'event': 'begin_checkout',
      'userId': this.userData,
      'currency': "INR",
      'value': totalAmount,
      'ecommerce': {
        'items': cart.products.map(product => {
          return {
          'item_name': product.productLevel3Title,
          'item_id': product.productLevel3Id,
          'price': +product.pricePerUnit,
          'item_brand': product.sellerName,
          'item_category':'',
          'item_variant': product.productLevel2Id,
          'quantity': product.productQuantity,     
          }  
        })
      }
    }
      this.pushToDataLayer(this.productCheckout);
  }

  /**
   *  Updates user Id on google analytics
   * @param event name of the event
   * @param userId logged in user id
   */
  public getUserAnalytics(userId: string) {
    this.userAnalytics = {
      'event': 'login',
      'userId': userId,
    }
    this.pushToDataLayer(this.userAnalytics);
  } 

  /**
   * 
   * @param orderDetails of buyer
   */
  public updateOrderSuccessInfoOnAnalytics(orderDetails) {
    const products : any[] =  [];
    Object.values<any>(orderDetails.storeMap).reduce((a, c) => [...a, ...c.productList], []).forEach(product => {
      products.push(product);
    });
    this.purchaseObject = {
      'event': 'purchase',
      'userId': this.userData,
      'ecommerce': {
        'purchase': {
            'transaction_id': orderDetails.paymentInfo.txnid,                         // Transaction ID. Required for purchases and refunds.
            'affiliation': 'Kalgudi',
            'value': orderDetails.grandTotal,                     // Total transaction value (incl. tax and shipping)
            'tax': 0,
            'shipping': orderDetails.totalShippingCost,
            'currency' : 'INR',
            'coupon': '',
            'items': products.map(product => {
              return {
                'item_name': product.productLevel3Title,
                'item_id': product.productLevel3Id,
                'price': +product.pricePerUnit,
                'item_brand': product.sellerName,
                'item_category': '',
                'item_variant': product.productLevel2Id,
                'quantity': product.productQuantity,
              }
          })
        }
      }
    };
    this.pushToDataLayer(this.purchaseObject);
  }

  /**
   * 
   * @param {number} step position of checkout process
   * @param option activity occurs in that step
   */
  public updateCheckoutOptionOnAnalytics(step, option) {
    this.productCheckoutOption = {
      'event': 'checkoutOption',
      'ecommerce': {
        'checkout_option': {
          'actionField': {}
        }
      }
    }
    this.productCheckoutOption.ecommerce.checkout_option.actionField.step = step;
    this.productCheckoutOption.ecommerce.checkout_option.actionField.option = option;
    this.pushToDataLayer(this.productCheckoutOption);
  }
  /**
   * 
   * @param clickedProduct Product clicked by user
   * @param listType product is clicked on search page or home page
   * @param position position of product
   */
  public updateProductClickToAnalytics(clickedProduct?: any, listType?: any, position?: any) {
    this.productClickObject = {
      'event': 'view_item',
      'userId': this.userData,
      'currency': "INR",
      'value': +clickedProduct.level3ProductsList[0].pricePerUnit,
      'ecommerce': {
        'items': []
      }
    }

    this.productClickObject.ecommerce.items.push({
      'item_name': clickedProduct.level3ProductsList[0].productLevel3Title,
      'item_id': clickedProduct.level3ProductsList[0].productLevel3Id,
      'price': +clickedProduct.level3ProductsList[0].pricePerUnit,
      'item_brand': clickedProduct.manufacturerDetails.firstName,
      'item_category': clickedProduct.categories[0].mainCategory.id,
      'item_variant': clickedProduct.level3ProductsList[0].productLevel2Id,
      'quantity': 1, 
    })
  
    this.pushToDataLayer(this.productClickObject);
  }
  
  /**
   * 
   * @param selectedProduct products added to cart 
   */
  public updateAddToCartToAnalytics(selectedProduct, quantity?) {
    this.addToCartObject = {
      'event': 'add_to_cart',
      'userId': this.userData,
      'currency': "INR",
      'value': quantity ? +selectedProduct.pricePerUnit * quantity : +selectedProduct.pricePerUnit,
      'ecommerce': {
        'items': []
      }
    }
    //  adding  products to shopping cart.
    this.addToCartObject.ecommerce.items.push({
      'item_name': selectedProduct.productLevel3Title,
      'item_id': selectedProduct.productLevel3Id,
      'price': +selectedProduct.pricePerUnit,
      'item_brand': selectedProduct.sellerName ,
      'item_category': selectedProduct.productCategory,
      'item_variant': selectedProduct.productLevel2Id,
      'quantity': quantity ? +quantity : 1
    })
    this.pushToDataLayer(this.addToCartObject);
  }

  /**
   * 
   * @param cart view cart 
   */
  public updateViewCartToAnalytics(cart, totalAmount?: number ) {
    this.viewCartObject = {
      'event': 'view_cart',
      'userId': this.userData,
      'currency': "INR",
      'value': totalAmount,
      'ecommerce': {
        'items': cart.products.map(product => {
          return {
          'item_name': product.productLevel3Title,
          'item_id': product.productLevel3Id,
          'price': +product.pricePerUnit,
          'item_brand': product.sellerName,
          'item_category':'',
          'item_variant': product.productLevel2Id,
          'quantity': product.productQuantity,     
          }  
        })
      }
    }
    this.pushToDataLayer(this.viewCartObject);
  }

  /**
   * 
   * @param cart add shipping information
   */
  public updateAddShippingInfoToAnalytics(cart, totalAmount?: number, pinCode?: number) {
    this.addShippingInfoObject = {
      'event': 'add_shipping_info',
      'userId': this.userData,
      'currency': "INR",
      'value': totalAmount,
      'pin': pinCode,
      'shipping_tier': 'Ground',
      'ecommerce': {
        'items': cart.products.map(product => {
          return {
          'item_name': product.productLevel3Title,
          'item_id': product.productLevel3Id,
          'price': +product.pricePerUnit,
          'item_brand': product.sellerName,
          'item_category':'',
          'item_variant': product.productLevel2Id,
          'quantity': product.productQuantity,     
          }  
        })
      }
    }
    this.pushToDataLayer(this.addShippingInfoObject);
  }

  /**
   * 
   * @param selectedProduct products removed from cart
   */
  public updateRemoveFromCartToAnalytics(selectedProduct, quantity?) {
    this.removeFromCartObject = {
      'event': 'remove_from_cart',
      'userId': this.userData,
      'currency': "INR",
      'value': quantity ? +selectedProduct.pricePerUnit * quantity : +selectedProduct.pricePerUnit,
      'ecommerce': {
        'items': []
      }
    }
    // Removing products from shopping cart.
    try {
      this.removeFromCartObject.ecommerce.items.push({
        'item_name': selectedProduct.productLevel3Title,
        'item_id': selectedProduct.productLevel3Id,
        'price': +selectedProduct.pricePerUnit,
        'item_brand': selectedProduct.sellerName ,
        'item_category': selectedProduct.productCategory,
        'item_variant': selectedProduct.productLevel2Id,
        'quantity':  quantity ? +quantity : 1
      });
    } catch (error) {
      console.error('Unable to send remove from cart to google analytics');
    }
    this.pushToDataLayer(this.removeFromCartObject);
  }

  /**
   * 
   * @param productLevel2Data  product level2 data
   * @param selectedLevel3Product selected level3 product details
   */
  public updateProductViewsToAnalytics(productLevel2Data, selectedLevel3Product) {
    this.productDetailsObject = {
      'event': 'productViews',
      'ecommerce': {
        'detail': {
          'actionField': {},    // 'detail' actions have an optional list property.
          'products': []
        }
      }
    }
    this.productDetailsObject.ecommerce.detail.actionField.list = productLevel2Data.baseCategory.value;
    this.productDetailsObject.ecommerce.detail.products.push({
      'name': selectedLevel3Product.productLevel3Title,         // Name or ID is required.
      'id': selectedLevel3Product.productLevel3Title,
      'price': selectedLevel3Product.pricePerUnit,
      'brand': productLevel2Data.manufacturerDetails.firstName,
      'category': productLevel2Data.baseCategory.id,
      'variant': ''
    });
    this.pushToDataLayer(this.productDetailsObject);
  }

  /**
   * 
   * @param productImpression Product seen by customer
   * @param baseCategory category of product
   * @param position position 
   * @param list list
   */
  public updateProductImpressionOnAnalytics(productImpression, baseCategory, position, list) {
    this.productImpressionObject = {
      'event': 'productImpression',
      'ecommerce': {
        impressions: [],
        currencyCode: 'INR'
      }
    }
    productImpression.forEach(product => {
      this.productImpressionObject.ecommerce.impressions.push({
        'name': product.productName_level3,       // Name or ID is required.
        'id': product.productId_level3,
        'price': product.pricePerSKU,
        'brand': product.sellerName,
        'category': baseCategory,
        'variant': '',
        'list': list,
        'position': position++
      })
    });
    this.pushToDataLayer(this.productImpressionObject);
  }

  /**
   * Prepares click event for analytics
   * @param event name of the event
   * @param userData logged in user profile data
   */
  private prepareClickEventForAnalytics(event, userData) {
    let userDetails = 'Guest user';
    if (userData) {
      userDetails = `${userData.firstName} ${userData.mobileNo || userData.emailId || userData.profileKey}`;
    }
    switch (event) {
      case 'createRFQ-event':
        this.sendClickEventToAnalytics(event, 'Create RFQ', `${userDetails} wants to create a RFQ`)
        break;

      default:
        return;
    }
  }
  /**
   * 
   * @param {string} eventAction  It is literally what the user does
   * @param {string} eventLabel Provides a bit more information about the user's action
   * @param {string} eventCategory Way to bundle user activity together.
   */
  private sendClickEventToAnalytics(eventAction, eventLabel, eventCategory) {
    if (environment.production) {
      gtag('event', eventAction, {
        'event_label': eventLabel,
        'event_category': eventCategory,
        'non_interaction': true
      });
    }
  }

  //Get userData from local storage
  private getUserData(event) {
    this.storage.getItem('userdata').subscribe(data => {
      if (data) {
        this.loggedInUserData = data;
      }
      else {
        this.loggedInUserData = '';
      }
      this.prepareClickEventForAnalytics(event, this.loggedInUserData);
    });
  }

  public clickEventToAnalytics(event) {
    this.getUserData(event);
  }

  /**
   * 
   *  @param {any} activity The activity which will push to dataLayer
   */
  public pushToDataLayer(activity: any) {
    if (environment.production && location.origin.includes(environment.baseUrl)) {
      dataLayer.push(activity);
    } else {
      dataLayer.push(activity);
    }
  }

}
