import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CategoryService } from 'src/app/project-common/services/category.service';
import { NotificationService } from 'src/app/project-common/services/notification.service';
import { UtilityService } from 'src/app/project-common/services/utility.service';

import { fade } from '../../../../animations';
import { StorefrontService } from '../../storefront.service';

@Component({
  selector: 'mobile-sidenav',
  templateUrl: './mobile-sidenav.component.html',
  styleUrls: ['./mobile-sidenav.component.scss'],
  animations: [
    fade,
    trigger('side-nav', [

      state('closed', style({ transform: 'translateX(130%)' })),
      state('opened', style({ transform: 'translateX(25%)' })),

      transition('closed <=> opened', animate('0.2s ease-in-out'))

    ])
  ]
})
export class MobileSidenavComponent implements OnInit {

  categories$: Observable<any[]>;

  otherAppsList = {
    title: 'Our Stores',
    storeImageUrl: 'assets/images/kl-red-logo.png',
    storeTitle: 'Kalgudi Rural India Store',
    storeDescription: 'Kalgudi is your one-stop-shop for regional, nostalgic, authentic and unadulterated direct-from-producer products from across India.',
    storeDetails: [
      // {
      //   name: 'Andhragreens',
      //   url: 'https://andhragreens.com/',
      //   imageUrl: 'assets/aboutus/ag-logo.jpg',
      //   description: 'Andhra Pradesh\'s most nostalgic, authentic fresh Fruits, Vegetables, and Food items directly from the producers.'
      // },
      {
        name: 'eMahila',
        url: 'https://emahila.org/',
        imageUrl: 'assets/aboutus/em-logo.jpg',
        description: 'Buy India\'s unique and specialty rural products directly from the makers.'
      },
      // {
      //   name: 'TGreens',
      //   url: 'https://tgreens.in/',
      //   imageUrl: 'assets/aboutus/tg-logo.jpg',
      //   description: 'Telangana brings you its wide varieties of fresh Fruits, Veggies, and other food items directly from the makers.'
      // },
      {
        name: 'Swayam Sikkim',
        url: 'https://swayamsikkim.com/',
        imageUrl: 'assets/aboutus/ss-logo.png',
        description: 'Swayam Sikkim brings to your doorstep, genuine products handmade by rural women of Sikkim.'
      }




    ]
  }

  otherAboutDetails = {
    title: 'About Us',
    storeImageUrl: 'assets/images/kalgudi_blue.png',
    storeTitle: 'Kalgudi',
    storeDescription: 'Kalgudi is a digital convergence platform that enables all ecosystem players to focus on their tasks while kalgudi creates much visibility and reach of their activities to a larger section of the users.',
    coreService: [
      {
        service: 'Kalgudi Core',
        url: 'https://core.kalgudi.com/index.html'
      },
      {
        service: 'Input Store',
        url: 'https://inputs.kalgudi.com/'
      },
      {
        service: 'Output Store',
        url: 'https://outputs.kalgudi.com/'
      },
      {
        service: 'Consumer Store',
        url: 'https://store.kalgudi.com/store/home'
      },
      {
        service: 'ConnectX',
        url: 'https://connectx.kalgudi.com/app/home'
      },
      {
        service: 'SeedX',
        url: 'https://corporate.kalgudi.com/sites/seedx.html'
      }
    ],
    forWhoms: [
      {
        service: 'Farmer',
        url: 'https://kalgudi.com/farmer'
      },
      {
        service: 'FPO',
        url: 'https://kalgudi.com/fpo'
      },
      {
        service: 'Self-Help Groups',
        url: 'https://kalgudi.com/micro-enterprises'
      },
      {
        service: 'Impact Organization',
        url: 'https://kalgudi.com/impact-org'
      },
      {
        service: 'Input Players',
        url: 'https://kalgudi.com/input-players'
      },
      {
        service: 'Output Business',
        url: 'https://kalgudi.com/output-businesses'
      }
    ]
  }


  @Input() userData: any;
  @Input() readonly showSideNav: boolean;
  @Input() isUserLoggedIn: boolean;
  @Output() logout = new EventEmitter();
  @Output() toggle = new EventEmitter();

  selectedLanguage: string;

  constructor(private storefrontService: StorefrontService,
    private notification: NotificationService,
    private categoryService: CategoryService,
    private router: Router,
    private util: UtilityService
  ) { }

  ngOnInit() {
    this.categories$ = this.categoryService.categories$;
    this.selectedLanguage = localStorage['lang'];
  }

  switchLanguage(languageCode) {
    this.selectedLanguage = languageCode;
    this.notification.notifyForLanguageChange(languageCode);
  }
  // replace(/_/g, '-')
  searchForCategoryMobile(request) {
    if (request.isBaseCategory) {
      this.router.navigateByUrl('categories/' + request.category.replace(/_/g, '-').toLowerCase());
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: request
      };
      navigationExtras.queryParams.limit = 20;
      navigationExtras.queryParams.view = 'grid';
      this.router.navigate(['/categories', request.category.replace(/_/g, '-').toLowerCase(), request.l2Id.replace(/_/g, '-').toLowerCase(), request.l3Id.replace(/_/g, '-').toLowerCase(), request.l4Id.replace(/_/g, '-').toLowerCase()]);
    }

    this.toggle.emit();
  }

  goToLoginPage() {
    this.util.isGuestLoginPage.next(false);
    this.router.navigate(['auth', 'login'])
  }

  closeAbout() {
    document.getElementById("allAbout").style.width = "0";
  }

  openAbout() {
    document.getElementById("allAbout").style.width = "300px";
  }

  openStore() {
    document.getElementById("allStore").style.width = "300px";
  }

  closeStore() {
    document.getElementById("allStore").style.width = "0";
  }

  openAllCategories() {
    this.router.navigate(['p/categories']);
    this.toggle.emit();
  }
}
