import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './app-card.component.html',
  styleUrls: ['./app-card.component.scss']
})
export class AppCardComponent implements OnInit {

  @Input('avatarUrl') avatarUrl: string;

  @Input('noScaleImage') noScaleImage: boolean = false;

  @Input('avatarSize') avatarSize: number = 80;

  constructor() { }

  ngOnInit() {
  }

}
