import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DeliveryAddress } from '@app/models';

@Component({
  selector: 'current-location',
  templateUrl: './current-location.component.html',
  styleUrls: ['./current-location.component.scss']
})
export class CurrentLocationComponent implements OnInit, OnChanges {

  @Input() location: DeliveryAddress;

  @Output() positionChanged: EventEmitter<{ latitude: number; longitude: number }> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    console.log(this.location);

  }

  public reposition(event) {
    this.positionChanged.emit(event);
  }

}
