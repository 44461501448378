import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeoLocationPosition } from '@app/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UtilityService } from 'src/app/project-common/services/utility.service';
import { LocationAccessErrorDialogComponent } from '../components/locatio-access-error-dialog/locatio-access-error-dialog.component';

@Injectable()
export class GeoApiService {


  constructor(private snackbar: MatSnackBar, private util: UtilityService, private dialog: MatDialog) { }

  /**
   * To fetch device current location
   */
  public readCurrentGeoLocation(showErrorMessage = true) {

    return new Observable<any>(observer => {
      
      window.navigator.geolocation.getCurrentPosition(geoLocationPosition => {
        observer.next(geoLocationPosition);
        // this.currentLocation.complete();
      }, (error: any) => {
        let message;
        switch (error.code) {
          case error.PERMISSION_DENIED:
            message = "Please allow us to read your current location!";
            break;
          case error.POSITION_UNAVAILABLE:
            message = "Location information is unavailable.";
            break;
          case error.TIMEOUT:
            message = "The request to get user location timed out.";
            break;
          default:
            message = "Unable to read your location, please use pincode";
            break;
        }
        console.error(message);
        if (!this.util.isMobileDevice()) {
          observer.next(null);

          observer.complete();
          return showErrorMessage && this.snackbar.open(message, 'OK', {
            duration: 8000
          });
        }
        else {
          if(!showErrorMessage) {
            observer.next(null);
            observer.complete();
            return;
          }
          return this.dialog.open(LocationAccessErrorDialogComponent, {
            width: '600px',
            autoFocus: false
          })
          .afterClosed()

          .subscribe(() => {

            observer.next(null);

            observer.complete();

          });
        }
      
        
      }, {
        enableHighAccuracy: true
      })
    });
  }

}


