import { UtilityService } from './../../project-common/services/utility.service';
import { AuthenticationService } from './../../authentication/authentication.service';
import { NavigationExtras, Router } from '@angular/router';
import { SpinnerService } from './../../project-common/services/spinner.service';
import { CheckoutService } from './../checkout.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToasterService } from '../../project-common/services/toaster.service';
import { MatDialog } from '@angular/material/dialog';
import { CartService } from '../../../app/project-common/services/cart.service';
import { PaymentConformationDilogComponent } from 'src/app/checkout/payment-conformation-dilog/payment-conformation-dilog.component';
import { GoogleAnalyticsService } from 'src/app/project-common/services/google-analytics.service';
import { OnlinePaymentComponent } from '../online-payment/online-payment.component';
import { DeliveryMessageDialogComponent } from '../delivery-message-dialog/delivery-message-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Storefront } from 'src/app/project-common/properties/constants';
import { ConfirmOrderDialogComponent } from '../confirm-order-dialog/confirm-order-dialog.component';
import { KL_NOTIFICATION, KalgudiNotification } from '@kalgudi/core/config';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

    disablePayments = this.util.shouldPaymentsDisable(); // Flag to enable / disable payments

    @ViewChild('onlinePayment') onlinePayment: OnlinePaymentComponent;

    paymentForm: FormGroup;
    paymentTab: string;
    transactionRequest: any;
    formControls: any;
    productWithUpdatedPrice: any[];
    loggedInUser: any;
    orderId: string;
    paymentRequest: any;
    orderList: any;
    groupProductsBySellerIds: any;
    sellers: string[];
    defaulteMailId: any;
    amountDifferenceForFreeShipping: number;
    charges: any;
    fromDate: Date;
    toDate: Date;
    isBulkOrder: boolean;
    public isKalgudiTeam: boolean;

    public isProduction: boolean;
    public isCouponApplied: boolean;
    public couponCode: string;
    private copyTransactionRequest: any;

    public isGift: boolean;

    constructor(private checkout: CheckoutService, private spinner: SpinnerService,
        private router: Router, private cart: CartService, private util: UtilityService,
        private authService: AuthenticationService, private toast: ToasterService,private translator: TranslateService, private dialog: MatDialog,
        private gaTrack: GoogleAnalyticsService,
        @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,) { }

    ngOnInit() {

        // Defaulting bank transfer payment if bulk cart.
        if(this.cart.cart.products[0].isBulkOrder) {
            this.isBulkOrder = true;
            this.paymentTab = 'CASH_PAYMENT'
        } else {
            this.paymentTab = 'RBL_ONLINE';
        }

        this.paymentRequest = {};
        this.defaulteMailId = 'info@kalgudi.com';
        this.isProduction = environment.production;

        const gift = this.cart.getGiftData();

        this.isGift = gift ? true : false;

        this.checkout.getShippingDetails().subscribe(response => {
            // this.gaTrack.updateCheckoutStepOnAnalytics(this.cart.cart, 3)
            this.processCharges(response);
            this.isKalgudiTeam = this.util.isKalgudiTeam();

            // if(this.isKalgudiTeam) this.paymentTab = 'COD';
        });
        this.initPaymentForm();
    }
    /* End of ngOnInit */

    applyCoupon(option) {
        if (option === 'APPLY') {
            this.copyTransactionRequest = JSON.parse(JSON.stringify(this.transactionRequest));
            this.transactionRequest.couponCode = this.couponCode;

        } else if (option === 'REMOVE') {
            this.transactionRequest = this.copyTransactionRequest;
            this.isCouponApplied = !this.isCouponApplied;
        }
        this.checkout.storeShippingDetails(this.transactionRequest, 'PAYMENT').subscribe(response => {
            if (response.couponDiscount > 0) {
                this.isCouponApplied = !this.isCouponApplied;
            }
            this.processCharges(response);
        });
    }


    get canAssist() {
        return JSON.parse(localStorage.getItem('Y2FuQXNzaXN0') || 'false');
    }

    private processCharges(response) {
        this.transactionRequest = response;
        this.couponCode = this.transactionRequest.couponCode || '';
        if (!response.grandTotal) {
            this.cart.setCartData({ products: [], productIds: [] });
            this.cart.dispatchCartUpdate();
            this.router.navigateByUrl('/checkout/cart');
            return;
        }
        let cart = this.cart.cart;
        this.checkout.cartSummary.emit({
            products: cart.products.length,
            totalAmount: response.grandTotal
        });
        
        
        /*
        * To show the products in the cart grouped by seller Id's in payment screen
        */
        /* this.groupProductsBySellerIds = {};
        response.productList.forEach(product => {
            if (this.groupProductsBySellerIds[product.sellerId]) {
                this.groupProductsBySellerIds[product.sellerId].products.push(product);
            } else {
                this.groupProductsBySellerIds[product.sellerId] = { name: product.sellerName, products: [product] };
            }
        }); */

        /* this.sellers = Object.keys(this.groupProductsBySellerIds);
        for (const i in response.productList) {
            if (response.productList[i].isPriceUpdate) {
                if (!this.productWithUpdatedPrice) {
                    this.productWithUpdatedPrice = [];
                }
                this.productWithUpdatedPrice.push(response.productList[i]);
                for (const id in cart.productIds) {
                    if (cart.productIds[id] === response.productList[i].productLevel3Id) {
                        cart.products[id].pricePerUnit = response.productList[i].updatedPrice;
                    }
                }
            }
        } */

        // this.cart.setCartData(cart);
        // this.cart.dispatchCartUpdate();
        this.updateUserData();
    }

    onInpuText(inputText: string, type: string) {
        const text = new RegExp(' ', 'g');
        inputText = inputText.replace(text, '');
        if (type === 'MOBILE') {
            this.paymentForm.get('phoneNumber').patchValue(inputText);
        } else if (type === 'EMAIL') {
            // this.paymentForm.get('email').patchValue(inputText);
        }
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }



    initPayUBizHiddenForm() {

        this.paymentRequest = {
            key: '7rnFly',
            txnid: '',
            amount: this.transactionRequest.amount,
            productinfo: 'great value for money',
            firstname: this.transactionRequest.buyerName,
            lastname: 'kalgudi user',
            surl: `https://kalgudi.com/rest/v1/apgreen/orders/paymentStatus`,
            furl: `https://kalgudi.com/rest/v1/apgreen/orders/paymentStatus`,
            curl: `https://kalgudi.com/rest/v1/apgreen/orders/paymentStatus`,
            hash: ''
        }
    }

    /**
     * TO change payment mode
     */
    changeTab = (tab) => {
        if (tab === 'COD' && !this.isKalgudiTeam) {
            return;
        }
        this.paymentTab = tab;
    }

    confirmation() {
        const dialogRef = this.dialog.open(PaymentConformationDilogComponent, {
            autoFocus: false,
            data: { message: `Click on "Place order" to place your order` }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.changeTab('COD');
            };
        });
    };

    confirmationCashPayment(tab) {

        // if (!this.transactionRequest.productList[0].isBulkOrder) return;

        const dialogRef = this.dialog.open(PaymentConformationDilogComponent, {
            autoFocus: false,
            data: { message: `Cash Payment`, tabName: 'CASH_PAYMENT' },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.transactionRequest.cashPaymentRemarks = result;
                this.changeTab('CASH_PAYMENT');
            };
        });
    };

    initPaymentForm() {
        this.paymentForm = new FormBuilder()
            .group({
                email: new FormControl(this.defaulteMailId),
                phoneNumber: new FormControl('', [Validators.minLength(10), Validators.maxLength(10), Validators.required])
            });
        this.formControls = this.paymentForm.controls;

    }


    doTransaction() {
        /* this.dialog.open(ConfirmDialogComponent,{
            autoFocus: false,
            data: { 
                message: 'We are facing some techincal issues, please try after some time.',
                hideCancel: true, 
                okText: 'Ok'
            }
        });
    
        return false; */

        if (this.disablePayments) return; // disabling payments

        if (!this.isFormValid() || !this.authService.userData) {
            return false;
        }

        this.transactionRequest.buyerAddress.phone = this.paymentForm.value.phoneNumber;

        if(this.paymentTab !== 'COD') return this.checkToShowCovidDialogAndCompleteTransaction(); 

        const confirmDialogRef = this.dialog.open(ConfirmOrderDialogComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            data: {
                data: this.transactionRequest,
                paymentMode: this.paymentTab
            },
            autoFocus: false,
            width: this.paymentTab !== 'COD' ? '500px' : '700px'
        })

        confirmDialogRef.afterClosed().subscribe(res => {
            if(res) {
                this.checkStore(res);
                this.checkToShowCovidDialogAndCompleteTransaction()
            }
        });




        // this.checkout.postOrderPayload(this.transactionRequest).subscribe(response => {
        //     this.spinner.changeStatus(false, false);
        //     if (response.code === 202) {
        //         window.location.href = response.data;
        //     } else {
        //         this.doPayment(response.data);
        //     }
        // }, error => {

        // })
    }

    checkToShowCovidDialogAndCompleteTransaction() {
         // Showing dialog only if non fruits / vegetagbles products are there in cart
        //  if(

        //     !Object.values<any>(this.transactionRequest.storeMap).every(store => store.productList.every(product => Storefront.limitedShippingCategory.includes(product.productCategory)))
        // ) {
        //     const dialogRef = this.dialog.open(DeliveryMessageDialogComponent, {
        //         backdropClass: 'popupBackdropClass',
        //         width: '500px',
        //         data: {
        //             message: `Due to logistic challenges of procurement from farmers and delivery during the COVID - 19 situation, your order may take longer than the published delivery date. Please continue to place order, only if you accept the same.`
        //         }
        //     });
        //     dialogRef.afterClosed().subscribe(result => {
        //         if (result) {
        //             this.completeTransaction();
        //         };
        //     });
        // } else {
        //     this.completeTransaction();
        // }
        this.completeTransaction();
    }

    checkStore(res) {
        if(this.transactionRequest.storeMap && this.transactionRequest.storeMap.TGREENS ){
            this.transactionRequest.storeMap.TGREENS['paymentConfirmationInfo'] = res;
        } 
        if(this.transactionRequest.storeMap && this.transactionRequest.storeMap.EMAHILA ) {
            this.transactionRequest.storeMap.EMAHILA['paymentConfirmationInfo'] = res;
        } 
        if(this.transactionRequest.storeMap && this.transactionRequest.storeMap.APGREEN ) {
            this.transactionRequest.storeMap.APGREEN['paymentConfirmationInfo'] = res;
        } 
    }

    private completeTransaction() {

        this.spinner.changeStatus(true, true);
        delete this.transactionRequest.productId;
        this.transactionRequest.eMailId = this.defaulteMailId;
        this.cart.removeGiftData();
        this.gaTrack.updateCheckoutOptionOnAnalytics(3, { paymentMode: this.paymentTab });
        if (this.paymentTab === 'COD' || this.paymentTab === 'CASH_PAYMENT') {
            if (this.paymentTab === 'CASH_PAYMENT') {
                this.transactionRequest.cashPayment = true;
            }
            this.checkout.placeOrder(JSON.stringify(this.transactionRequest)).subscribe(response => {
                this.spinner.changeStatus(false, false);
                this.openOrderDetails(response);
            }, error => {
                this.spinner.changeStatus(false, false);
                this.translator.get(['Server busy!', 'Unable to process your transaction at moment, please try after some time.', 'error']).subscribe(translatedText=>{
                //this.toast.showMessage(translatedText['Server busy!'],translatedText['Unable to process your transaction at moment, please try after some time.'], translatedText['error'], 8000);
                this.notification.showSweetAlert({
                    title: 'Server busy!',
                    icon: 'warning',
                  });
                })
            });
        } else if (this.onlinePayment) {

            if (this.paymentTab === 'NEW_ONLINE') {
                this.onlinePayment.newOnlineTransaction(this.transactionRequest);
            } else if (this.paymentTab === 'ICICI_ONLINE') {
                this.onlinePayment.iciciOnlineTransaction(this.transactionRequest);
            } else if (this.paymentTab === 'RBL_ONLINE') {
                this.onlinePayment.rblOnlineTransaction(this.transactionRequest);
            } else {
                this.onlinePayment.onlineTransaction(this.transactionRequest);
            }
        }
    }

    isFormValid() {
        return this.paymentForm.valid;
    }

    openOrderDetails(orderId?: string) {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                'OrderStatus': true
            }
        };
        this.router.navigate(['order_summary', this.orderId || orderId], navigationExtras);
    }


    updateUserData() {
        this.loggedInUser = this.authService.userData;
        let mobile = this.transactionRequest.buyerAddress.phone;
        let email = this.loggedInUser.emailId || this.loggedInUser.altemailId;

        if (!mobile) {
            mobile = this.util.getUserMobileNumber(this.loggedInUser);
        }

        this.paymentForm.setValue({ phoneNumber: mobile, email: email || '' });
    }

    get otherCharges() {
        if (this.transactionRequest) {
            let handlingCost = this.transactionRequest.handlingCost || 0;
            let packagingCost = this.transactionRequest.packagingCost || 0;
            return handlingCost + packagingCost;
        }
        return 0;
    }
}


/*
* Secure Hash Algorithm (SHA512)
* http://www.happycode.info/
*/

function SHA512(str) {
    function int64(msint_32, lsint_32) {
        this.highOrder = msint_32;
        this.lowOrder = lsint_32;
    }

    var H = [new int64(0x6a09e667, 0xf3bcc908), new int64(0xbb67ae85, 0x84caa73b),
    new int64(0x3c6ef372, 0xfe94f82b), new int64(0xa54ff53a, 0x5f1d36f1),
    new int64(0x510e527f, 0xade682d1), new int64(0x9b05688c, 0x2b3e6c1f),
    new int64(0x1f83d9ab, 0xfb41bd6b), new int64(0x5be0cd19, 0x137e2179)];

    var K = [new int64(0x428a2f98, 0xd728ae22), new int64(0x71374491, 0x23ef65cd),
    new int64(0xb5c0fbcf, 0xec4d3b2f), new int64(0xe9b5dba5, 0x8189dbbc),
    new int64(0x3956c25b, 0xf348b538), new int64(0x59f111f1, 0xb605d019),
    new int64(0x923f82a4, 0xaf194f9b), new int64(0xab1c5ed5, 0xda6d8118),
    new int64(0xd807aa98, 0xa3030242), new int64(0x12835b01, 0x45706fbe),
    new int64(0x243185be, 0x4ee4b28c), new int64(0x550c7dc3, 0xd5ffb4e2),
    new int64(0x72be5d74, 0xf27b896f), new int64(0x80deb1fe, 0x3b1696b1),
    new int64(0x9bdc06a7, 0x25c71235), new int64(0xc19bf174, 0xcf692694),
    new int64(0xe49b69c1, 0x9ef14ad2), new int64(0xefbe4786, 0x384f25e3),
    new int64(0x0fc19dc6, 0x8b8cd5b5), new int64(0x240ca1cc, 0x77ac9c65),
    new int64(0x2de92c6f, 0x592b0275), new int64(0x4a7484aa, 0x6ea6e483),
    new int64(0x5cb0a9dc, 0xbd41fbd4), new int64(0x76f988da, 0x831153b5),
    new int64(0x983e5152, 0xee66dfab), new int64(0xa831c66d, 0x2db43210),
    new int64(0xb00327c8, 0x98fb213f), new int64(0xbf597fc7, 0xbeef0ee4),
    new int64(0xc6e00bf3, 0x3da88fc2), new int64(0xd5a79147, 0x930aa725),
    new int64(0x06ca6351, 0xe003826f), new int64(0x14292967, 0x0a0e6e70),
    new int64(0x27b70a85, 0x46d22ffc), new int64(0x2e1b2138, 0x5c26c926),
    new int64(0x4d2c6dfc, 0x5ac42aed), new int64(0x53380d13, 0x9d95b3df),
    new int64(0x650a7354, 0x8baf63de), new int64(0x766a0abb, 0x3c77b2a8),
    new int64(0x81c2c92e, 0x47edaee6), new int64(0x92722c85, 0x1482353b),
    new int64(0xa2bfe8a1, 0x4cf10364), new int64(0xa81a664b, 0xbc423001),
    new int64(0xc24b8b70, 0xd0f89791), new int64(0xc76c51a3, 0x0654be30),
    new int64(0xd192e819, 0xd6ef5218), new int64(0xd6990624, 0x5565a910),
    new int64(0xf40e3585, 0x5771202a), new int64(0x106aa070, 0x32bbd1b8),
    new int64(0x19a4c116, 0xb8d2d0c8), new int64(0x1e376c08, 0x5141ab53),
    new int64(0x2748774c, 0xdf8eeb99), new int64(0x34b0bcb5, 0xe19b48a8),
    new int64(0x391c0cb3, 0xc5c95a63), new int64(0x4ed8aa4a, 0xe3418acb),
    new int64(0x5b9cca4f, 0x7763e373), new int64(0x682e6ff3, 0xd6b2b8a3),
    new int64(0x748f82ee, 0x5defb2fc), new int64(0x78a5636f, 0x43172f60),
    new int64(0x84c87814, 0xa1f0ab72), new int64(0x8cc70208, 0x1a6439ec),
    new int64(0x90befffa, 0x23631e28), new int64(0xa4506ceb, 0xde82bde9),
    new int64(0xbef9a3f7, 0xb2c67915), new int64(0xc67178f2, 0xe372532b),
    new int64(0xca273ece, 0xea26619c), new int64(0xd186b8c7, 0x21c0c207),
    new int64(0xeada7dd6, 0xcde0eb1e), new int64(0xf57d4f7f, 0xee6ed178),
    new int64(0x06f067aa, 0x72176fba), new int64(0x0a637dc5, 0xa2c898a6),
    new int64(0x113f9804, 0xbef90dae), new int64(0x1b710b35, 0x131c471b),
    new int64(0x28db77f5, 0x23047d84), new int64(0x32caab7b, 0x40c72493),
    new int64(0x3c9ebe0a, 0x15c9bebc), new int64(0x431d67c4, 0x9c100d4c),
    new int64(0x4cc5d4be, 0xcb3e42b6), new int64(0x597f299c, 0xfc657e2a),
    new int64(0x5fcb6fab, 0x3ad6faec), new int64(0x6c44198c, 0x4a475817)];

    var W = new Array(64);
    var a, b, c, d, e, f, g, h, i, j;
    var T1, T2;
    var charsize = 8;

    function utf8_encode(str) {
        return unescape(encodeURIComponent(str));
    }

    function str2binb(str) {
        var bin = [];
        var mask = (1 << charsize) - 1;
        var len = str.length * charsize;

        for (var i = 0; i < len; i += charsize) {
            bin[i >> 5] |= (str.charCodeAt(i / charsize) & mask) << (32 - charsize - (i % 32));
        }

        return bin;
    }

    function binb2hex(binarray) {
        var hex_tab = '0123456789abcdef';
        var str = '';
        var length = binarray.length * 4;
        var srcByte;

        for (var i = 0; i < length; i += 1) {
            srcByte = binarray[i >> 2] >> ((3 - (i % 4)) * 8);
            str += hex_tab.charAt((srcByte >> 4) & 0xF) + hex_tab.charAt(srcByte & 0xF);
        }

        return str;
    }

    function safe_add_2(x, y) {
        var lsw, msw, lowOrder, highOrder;

        lsw = (x.lowOrder & 0xFFFF) + (y.lowOrder & 0xFFFF);
        msw = (x.lowOrder >>> 16) + (y.lowOrder >>> 16) + (lsw >>> 16);
        lowOrder = ((msw & 0xFFFF) << 16) | (lsw & 0xFFFF);

        lsw = (x.highOrder & 0xFFFF) + (y.highOrder & 0xFFFF) + (msw >>> 16);
        msw = (x.highOrder >>> 16) + (y.highOrder >>> 16) + (lsw >>> 16);
        highOrder = ((msw & 0xFFFF) << 16) | (lsw & 0xFFFF);

        return new int64(highOrder, lowOrder);
    }

    function safe_add_4(a, b, c, d) {
        var lsw, msw, lowOrder, highOrder;

        lsw = (a.lowOrder & 0xFFFF) + (b.lowOrder & 0xFFFF) + (c.lowOrder & 0xFFFF) + (d.lowOrder & 0xFFFF);
        msw = (a.lowOrder >>> 16) + (b.lowOrder >>> 16) + (c.lowOrder >>> 16) + (d.lowOrder >>> 16) + (lsw >>> 16);
        lowOrder = ((msw & 0xFFFF) << 16) | (lsw & 0xFFFF);

        lsw = (a.highOrder & 0xFFFF) + (b.highOrder & 0xFFFF) + (c.highOrder & 0xFFFF) + (d.highOrder & 0xFFFF) + (msw >>> 16);
        msw = (a.highOrder >>> 16) + (b.highOrder >>> 16) + (c.highOrder >>> 16) + (d.highOrder >>> 16) + (lsw >>> 16);
        highOrder = ((msw & 0xFFFF) << 16) | (lsw & 0xFFFF);

        return new int64(highOrder, lowOrder);
    }

    function safe_add_5(a, b, c, d, e) {
        var lsw, msw, lowOrder, highOrder;

        lsw = (a.lowOrder & 0xFFFF) + (b.lowOrder & 0xFFFF) + (c.lowOrder & 0xFFFF) + (d.lowOrder & 0xFFFF) + (e.lowOrder & 0xFFFF);
        msw = (a.lowOrder >>> 16) + (b.lowOrder >>> 16) + (c.lowOrder >>> 16) + (d.lowOrder >>> 16) + (e.lowOrder >>> 16) + (lsw >>> 16);
        lowOrder = ((msw & 0xFFFF) << 16) | (lsw & 0xFFFF);

        lsw = (a.highOrder & 0xFFFF) + (b.highOrder & 0xFFFF) + (c.highOrder & 0xFFFF) + (d.highOrder & 0xFFFF) + (e.highOrder & 0xFFFF) + (msw >>> 16);
        msw = (a.highOrder >>> 16) + (b.highOrder >>> 16) + (c.highOrder >>> 16) + (d.highOrder >>> 16) + (e.highOrder >>> 16) + (lsw >>> 16);
        highOrder = ((msw & 0xFFFF) << 16) | (lsw & 0xFFFF);

        return new int64(highOrder, lowOrder);
    }

    function maj(x, y, z) {
        return new int64(
            (x.highOrder & y.highOrder) ^ (x.highOrder & z.highOrder) ^ (y.highOrder & z.highOrder),
            (x.lowOrder & y.lowOrder) ^ (x.lowOrder & z.lowOrder) ^ (y.lowOrder & z.lowOrder)
        );
    }

    function ch(x, y, z) {
        return new int64(
            (x.highOrder & y.highOrder) ^ (~x.highOrder & z.highOrder),
            (x.lowOrder & y.lowOrder) ^ (~x.lowOrder & z.lowOrder)
        );
    }

    function rotr(x, n) {
        if (n <= 32) {
            return new int64(
                (x.highOrder >>> n) | (x.lowOrder << (32 - n)),
                (x.lowOrder >>> n) | (x.highOrder << (32 - n))
            );
        } else {
            return new int64(
                (x.lowOrder >>> n) | (x.highOrder << (32 - n)),
                (x.highOrder >>> n) | (x.lowOrder << (32 - n))
            );
        }
    }

    function sigma0(x) {
        var rotr28 = rotr(x, 28);
        var rotr34 = rotr(x, 34);
        var rotr39 = rotr(x, 39);

        return new int64(
            rotr28.highOrder ^ rotr34.highOrder ^ rotr39.highOrder,
            rotr28.lowOrder ^ rotr34.lowOrder ^ rotr39.lowOrder
        );
    }

    function sigma1(x) {
        var rotr14 = rotr(x, 14);
        var rotr18 = rotr(x, 18);
        var rotr41 = rotr(x, 41);

        return new int64(
            rotr14.highOrder ^ rotr18.highOrder ^ rotr41.highOrder,
            rotr14.lowOrder ^ rotr18.lowOrder ^ rotr41.lowOrder
        );
    }

    function gamma0(x) {
        var rotr1 = rotr(x, 1), rotr8 = rotr(x, 8), shr7 = shr(x, 7);

        return new int64(
            rotr1.highOrder ^ rotr8.highOrder ^ shr7.highOrder,
            rotr1.lowOrder ^ rotr8.lowOrder ^ shr7.lowOrder
        );
    }

    function gamma1(x) {
        var rotr19 = rotr(x, 19);
        var rotr61 = rotr(x, 61);
        var shr6 = shr(x, 6);

        return new int64(
            rotr19.highOrder ^ rotr61.highOrder ^ shr6.highOrder,
            rotr19.lowOrder ^ rotr61.lowOrder ^ shr6.lowOrder
        );
    }

    function shr(x, n) {
        if (n <= 32) {
            return new int64(
                x.highOrder >>> n,
                x.lowOrder >>> n | (x.highOrder << (32 - n))
            );
        } else {
            return new int64(
                0,
                x.highOrder << (32 - n)
            );
        }
    }

    str = utf8_encode(str);
    let strlen = str.length * charsize;
    str = str2binb(str);

    str[strlen >> 5] |= 0x80 << (24 - strlen % 32);
    str[(((strlen + 128) >> 10) << 5) + 31] = strlen;

    for (let i = 0; i < str.length; i += 32) {
        a = H[0];
        b = H[1];
        c = H[2];
        d = H[3];
        e = H[4];
        f = H[5];
        g = H[6];
        h = H[7];

        for (let j = 0; j < 80; j++) {
            if (j < 16) {
                W[j] = new int64(str[j * 2 + i], str[j * 2 + i + 1]);
            } else {
                W[j] = safe_add_4(gamma1(W[j - 2]), W[j - 7], gamma0(W[j - 15]), W[j - 16]);
            }

            T1 = safe_add_5(h, sigma1(e), ch(e, f, g), K[j], W[j]);
            T2 = safe_add_2(sigma0(a), maj(a, b, c));
            h = g;
            g = f;
            f = e;
            e = safe_add_2(d, T1);
            d = c;
            c = b;
            b = a;
            a = safe_add_2(T1, T2);
        }

        H[0] = safe_add_2(a, H[0]);
        H[1] = safe_add_2(b, H[1]);
        H[2] = safe_add_2(c, H[2]);
        H[3] = safe_add_2(d, H[3]);
        H[4] = safe_add_2(e, H[4]);
        H[5] = safe_add_2(f, H[5]);
        H[6] = safe_add_2(g, H[6]);
        H[7] = safe_add_2(h, H[7]);
    }

    var binarray = [];
    for (let i = 0; i < H.length; i++) {
        binarray.push(H[i].highOrder);
        binarray.push(H[i].lowOrder);
    }
    return binb2hex(binarray);
}
