import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  ActivationEnd,
  ActivationStart,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { KalgudiDestroyable } from '@kalgudi/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { fade } from '../animations';
import { AuthenticationService } from './authentication/authentication.service';
import { BookingsDialogComponent } from './project-common/components/bookings-dialog/bookings-dialog.component';
import { UserProfile } from './project-common/models/user.model';
import { ThirdPartyLibraries } from './project-common/properties/constants';
import { AutoAuthorizeService } from './project-common/services/auto-authorize.service';
import { FcmService } from './project-common/services/fcm.service';
import { GoogleAnalyticsService } from './project-common/services/google-analytics.service';
import { NotificationService } from './project-common/services/notification.service';
import { ScriptLoaderService } from './project-common/services/script-loader.service';
import { SmartsuppService } from './project-common/services/smartsupp.service';
import { SpinnerService } from './project-common/services/spinner.service';
import { UtilityService } from './project-common/services/utility.service';
import { SideBarService } from './side-bar.service';

declare var universalLinks: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [fade],
  /* changeDetection: ChangeDetectionStrategy.OnPush */
})
export class AppComponent
  extends KalgudiDestroyable
  implements OnInit, OnDestroy {
  @ViewChild("cartSideNav", { static: true }) public cartSideBar: MatSidenav;

  @ViewChild("mangoCartSideNav", { static: true }) public mangoCartSideNav: MatSidenav;
  /**
   * Pages where smartsupp has to be hidden
   */
  smartSuppRestrictedPages: string[];

  /**
   * Tracks if smartsupp has loaded; To avoid multi load
   */
  isSmartsuppInitialized: boolean;

  isSpinnerActive: boolean;
  isBlockingSpinner: boolean;
  authToken: string;
  // footerColor: string;

  spinnerSub: Subscription;
  userData: UserProfile;
  isUserLoggedIn: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private autoAuthorizeService: AutoAuthorizeService,
    private notification: NotificationService,
    private gaTrack: GoogleAnalyticsService,
    private translate: TranslateService,
    private loader: ScriptLoaderService,
    private smartSupp: SmartsuppService,
    private localStorage: LocalStorage,
    private spinner: SpinnerService,
    private utility: UtilityService,
    private titleService: Title,
    private fcm: FcmService,
    private router: Router,
    private dialog: MatDialog,
    private sidebarService: SideBarService
  ) {
    super();
    // this.showBookingsDialog();
    console.log('hello loading changes');
    this.watchCartSidebar();

    this.watchMangoCartSidebar();

    this.smartSuppRestrictedPages = ["checkout"];

    this.isSpinnerActive = false;
    this.isBlockingSpinner = true;

    this.setLanguage();

    this.router.events.subscribe((event) => {
      this.handleSmartSuppVisibility(event);

      // if(this.router.url.includes('/mangoes')) {

      // }

      if (
        event instanceof NavigationStart &&
        this.router.url !== "/" &&
        !this.router.url.includes("auth")
      ) {
        window.localStorage.setItem("previousUrl", this.router.url);
      }
      if (
        event instanceof ActivationStart &&
        event.snapshot instanceof ActivatedRouteSnapshot
      ) {
        if (event.snapshot.queryParams["token"]) {
          this.authToken = event.snapshot.queryParams["token"];
        }
        const defaultTitle = `Buy Fruits, Groceries, Vegetables from Our Exclusive Online Store | Kalgudi`;
        this.titleService.setTitle(event.snapshot.data.title || defaultTitle);
        // this.footerColor = event.snapshot.data.footerColor || '#f0f2f6';
        this.scrollToTop();
        if (
          event.snapshot.data.submitGTag &&
          event.snapshot.routeConfig.path != "/"
        ) {
          this.gaTrack.configPageViewToAnalytics(
            event.snapshot.routeConfig.path,
            event.snapshot.data.title
          );
        }
        this.changeVisibilityOfElements(event.snapshot.routeConfig.path);
      }
      if (event instanceof NavigationEnd) {
        if (this.authToken) {
          this.autoAuthorizeService.autoSignIn(this.authToken, this.router.url);
        }


        const content = document.getElementById('app-content');
        if(!content) return;
        content.scrollTo({left:0, top: 0, behavior: 'smooth'});
      }
    });
  }

  ngOnInit(): void {
    this.localStorage.getItem("userdata").subscribe((userData) => {
      this.isUserLoggedIn = false;
      if (userData && userData.firstName) {
        this.isUserLoggedIn = true;
      }
    });
    // this.localStorage.getItem('countryId').subscribe(id => {
    //   if (!id) {
    this.authenticationService.getCountryCode().subscribe();
    //   }
    // });

    this.spinnerSub = this.spinner.currentStatus.subscribe((data) => {
      this.isSpinnerActive = data.enableSpinner; // Current spinner status
      this.isBlockingSpinner = data.blocking; // Whether spinner blocks UI
    });
    this.notification.languageChanges.subscribe((languageCode) => {
      this.switchLanguage(languageCode);
    });
    /* Enable below method when building APK  */
    // this.initializeCordova ();
    if (environment.production) {
      setTimeout(() => {
        this.processFcm(); // process FCM only in production env
        // this.getStoreNotifications();
      }, 5 * 1000);
    }
  }

   showSmartsUp() {
    this.smartSupp.showSmartSupport();
  }

  hideSmartsUp() {
    this.smartSupp.hideSmartSupport();
  } 

  private setLanguage() {
    if (!window.localStorage["lang"]) {
      window.localStorage["lang"] = "en";
    }
    const defaultLanguage = window.localStorage["lang"];

    const bodyElement = document.getElementById("app-body");

    defaultLanguage === "en"
      ? bodyElement.classList.remove("other-language")
      : bodyElement.classList.add("other-language");

    this.translate.setDefaultLang(defaultLanguage);
  }

  private handleSmartSuppVisibility(event: any) {
    if (event instanceof ActivationEnd) {
      if (
        this.utility.isMobileDevice() &&
        this.smartSuppRestrictedPages.some((page) =>
          this.router.url.includes(page)
        )
      ) {
        this.smartSupp.hideSmartSupport();
      } else if (!this.isSmartsuppInitialized) {
        this.isSmartsuppInitialized = true;
        setTimeout(() => {
          this.smartSupp.registerSmartSupport();
          this.smartSupp.closeChatWindow();
        }, 1000 * 8);
      } else {
        this.smartSupp.showSmartSupport();
      }
    }
  } 

  private scrollToTop() {
    if (!this.utility.isMobileDevice()) {
      setTimeout(() => {
        document.getElementById('app-content').scrollTo(0, 0)
      }, 400);
    }
  }

  private changeVisibilityOfElements(routerPath) {
    const footer = document.getElementById("footer-sec-2");
    const copyRights = document.getElementById("footer-sec-3");

    if (footer) {
      if (
        this.utility.isMobileDevice() &&
        (routerPath.includes("login") ||
          routerPath.includes("signup") ||
          routerPath.includes("bulk-req") ||
          routerPath.includes("cart") ||
          routerPath.includes("shipping") ||
          routerPath.includes("payment"))
      ) {
        footer.style.display = "none";
      } else {
        footer.style.display = "block";
      }
    }

    if (copyRights) {
      if (
        this.utility.isMobileDevice() &&
        (routerPath.includes("cart") ||
          routerPath.includes("shipping") ||
          routerPath.includes("payment"))
      ) {
        copyRights.style.display = "none";
      } else {
        copyRights.style.display = "block";
      }
    }
  }

  switchLanguage(language: string) {
    this.isSpinnerActive = true;
    this.translate.use(language).subscribe((language) => {
      this.isSpinnerActive = false;
    });
  }

  initializeCordova() {
    this.loader
      .loadScript(ThirdPartyLibraries.cordova)
      .subscribe((response) => {
        console.log(response, " loaded");
        this.initializeDeepLink();
      });
  }

  initializeDeepLink() {
    var appComponent = this;
    if (typeof universalLinks !== "undefined") {
      universalLinks &&
        universalLinks.subscribe("storefront", (eventData) => {
          const navigationUrl = eventData.url.split("store")[1];
          console.log(navigationUrl);
          appComponent.router.navigateByUrl(navigationUrl);
        });
    } else {
      setTimeout(() => {
        this.initializeDeepLink();
      }, 500);
    }
  }

  ngOnDestroy(): void {
    this.spinnerSub.unsubscribe();
  }

  /**
   * This method will call service to get the latest notification object if user logedIn
   */
  /* getStoreNotifications() {
    if (JSON.parse(window.localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || false)) {
      this.fcm.getStoreNotification();
    }
  } */

  /**
   * This method will process the FCM
   * It will request permission for notification
   * Generate FCM token
   * Call service to store token
   * Lisen for notiication and process notification
   */
  private processFcm() {
    this.fcm
      .requestToken()
      .then((tokenKey) => {
        let profileKey = this.authenticationService.userData
          ? this.authenticationService.userData.profileKey
          : "";
        /* if (!window.localStorage['fcmToken']) { } */
        this.fcm
          .updateDeviceDetails({
            refreshedToken: tokenKey,
            profileKey: profileKey,
          })
          .subscribe((res) => {
            window.localStorage["fcmToken"] = tokenKey;
            // console.log('device registred with fcm',res);
          });
      })
      .catch((err) => {
        console.log("request denied");
      });

    this.fcm.listenForMessage().subscribe((message) => {
      console.log("Incoming FCM notification");
      this.fcm.processNewNotification(message);
    });
  }

  showBookingsDialog() {
    if (localStorage["bookingDialogShown"]) return;

    this.dialog.open(BookingsDialogComponent, {
      backdropClass: "popupBackdropClass",
      panelClass: "booking-cls",
    });
    localStorage["bookingDialogShown"] = true;
  }

  private watchCartSidebar(): void {
    this.sidebarService.cartSidebarStatus

      .pipe(takeUntil(this.destroyed$))

      .subscribe((flag) => {
        if (!this.cartSideBar) return;

        switch (flag) {
          case "OPEN":
            this.cartSideBar.open();

            break;

          case "CLOSE":
            this.cartSideBar.close();

            break;

          case "TOGGLE":
            this.cartSideBar.toggle();
        }
      });
  }


  private watchMangoCartSidebar(): void {
    this.sidebarService.mangoCartSidebarStatus

      .pipe(takeUntil(this.destroyed$))

      .subscribe((flag) => {
        if (!this.mangoCartSideNav) return;

        switch (flag) {
          case "OPEN":
            this.mangoCartSideNav.open();

            break;

          case "CLOSE":
            this.mangoCartSideNav.close();

            break;

          case "TOGGLE":
            this.mangoCartSideNav.toggle();
        }
      });
  }

  onDestroyed(): void { }
}
