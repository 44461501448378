import { Directive } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { StorefrontService } from 'src/app/storefront/storefront.service';

import { Storefront } from '../properties/constants';
import { CartService } from '../services/cart.service';


@Directive({
  selector: '[validLocation][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ValidLocationDirective, multi: true }
  ]
})
export class ValidLocationDirective implements Validator {

  validator: ValidatorFn;
  validPins: string[];
  cartItems: any[];

  constructor(
    // private cartService: CartService,
    // private storeService: StorefrontService
  ) {
    this.validator = this.deliveryLocationValidator();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

  deliveryLocationValidator(): ValidatorFn {
    return (c: AbstractControl) => {

      // console.log({isValid, hasFruitItems});
      if (!c.errors || !Object.keys(c.errors).length) {
        return null;
      } else {
        return {
          validLocation: {
            valid: false,
          }
        };
      }
    }

  }
}