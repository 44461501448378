
import { Component, OnInit, Input, Injector } from '@angular/core';
import { expandCollapse } from '../../../animations';;
import { CartItem } from 'src/app/classes/cart-item';
@Component({
    selector: 'item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
    animations: [
        expandCollapse
    ]
})
export class ItemComponent extends CartItem implements OnInit {

    constructor(
        protected injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.isSavedItem) {
            this.saveForLaterFlag = true;
        }
        if (window.localStorage['YUdGelRHOW5aMlZrU1c0PQ==']) {
            this.isUserLogedIn = JSON.parse(window.localStorage.getItem('YUdGelRHOW5aMlZrU1c0PQ=='));
        }

        // Comment this line to enable customization for products
        delete this.product.customOrders;

        const cartItem = this.cart.products.find(p => p.productLevel3Id === this.product.productLevel3Id);
        if (cartItem && cartItem.basketItems) this.basketItemsCount = cartItem.basketItems.length;
    }

}
