import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { fade } from '../../../animations';
import { CartPage } from 'src/app/classes/cart-page';


@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
    animations: [
        fade
    ]
})
export class CartComponent extends CartPage implements OnInit, OnDestroy {

    constructor(
        protected injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {
        this.cartValue = 0;
        this.isUserLoggedIn = !!localStorage[btoa('profileKey')];
        this.registerListeners();
    }

    ngOnDestroy() {
        this.destroyer$.next(true);
        this.destroyer$.complete();
    }



}
