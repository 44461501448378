import { GoogleLocation } from './geo-location';


export class DeliveryAddress {
  name: string;
  addressLine1: string;
  addressLine2: string;
  landmark: string;
  city: string;
  state: string;
  postalCode: string;
  phone: string;
  id: string;
  addressType?: string;
  isDefault: boolean;
  googleLocation?: GoogleLocation;
  pincodeDetails: any;
  residence?: string;
  email?: string;
  constructor(
    public pinCode?: string,
    public district?: string,
    public phoneNumber?: string,
    public address?: string,
    public locality?: string,
    public country = 'India'
  ) { }

}