import { Inject, Injector } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'gift-message-dialog',
  templateUrl: './gift-message-dialog.component.html',
  styleUrls: ['./gift-message-dialog.component.scss']
})
export class GiftMessageDialogComponent extends KalgudiDialog<GiftMessageDialogComponent> implements OnInit {

  giftMessage: string = '';

  constructor(
    protected injector: Injector,
    protected dialogRef: MatDialogRef<GiftMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig
  ) { 
    super(dialogRef);
  }

  ngOnInit() {
    
    this.giftMessage = this.data.data.giftMessage;
    
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };

    this.closeDialog(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
        giftMessage: this.giftMessage
      }
    };

    this.dialogRef.close(result);
  }
  
}
