<section class="about-1 ">
    <div class="app-container">
        <h4 fxLayoutAlign.xs="center" translate>About Kalgudi</h4>
    </div>
</section>

<section class="about-2">
    <div fxLayout="row" class="app-container" fxLayout.xs="column" fxLayoutGap="100px" fxLayoutGap.xs="40px">
        <div fxFlex="40%" fxLayoutAlign="end center">

            <div class="card" fxLayoutAlign="center center">
                <img src="assets/images/ap-map.png" width="300" class="about-img" />
            </div>
        </div>
        <section class="about-3">
            <div class="app-container">
                <div class="title" fxLayout="column" fxLayoutAlign="center center">
                    <ng-container *ngIf="selectedLanguage !=='te'">
                        <h2><span class="title-2">What</span> is Kalgudi?</h2>
                    </ng-container>
                    <ng-container *ngIf="selectedLanguage ==='te'">
                        <h2>కల్గుడి అంటే<span class="title-2">ఏమిటి?</span></h2>
                    </ng-container>
                    <p translate>Kalgudi is your one-stop-shop for regional, authentic and direct-from-producer products. We bring you a wide range of unadultered regional products, responsibly produced and procured from all over Andhra Pradesh. We are specialized
                        in higher quality, healthier, regional fruits and vegetables, rich in flavor and nutritional value.
                    </p>
                </div>
            </div>
        </section>

    </div>
</section>
<section class="about-4">
    <div class="app-container">
        <div class="title">
            <ng-container *ngIf="selectedLanguage !=='te'">
                <h2 translate><span class="title-2">How</span> are we different?</h2>
            </ng-container>
            <ng-container *ngIf="selectedLanguage ==='te'">
                <h2>మేము ఎలా<span class="title-2">భిన్నంగా ఉన్నాము</span></h2>
            </ng-container>
            <p translate>With the support of Department of Horticulture, Andhra Pradesh and FPOs, SHGs and Tribal societies all over Andhra Pradesh, Kalgudi always makes sure that every product you receive is sourced directly from the producers. We are known
                for our pure, fresh, regional products of highest quality. The careful selection and grading process of products is just as you would do for yourself. We encourage regional farmers and small producers to practice non-aggressive cultivation
                methods and traditional natural farming techniques. Our essential target is a distribution chain where the chain of intermediation is shorter, which yields in quality products at affordable prices.</p>
            <h5 translate> Our focus on Traceability, Transparency,and Quality is uncompromised and unmatched.Come, be a part of our mission.</h5>
            <h4 translate>Happy producers - Healthy consumers </h4>
        </div>
    </div>
</section>

<section class="about-5">
    <div class="app-container slider-about">
        <div fxLayout="column" fxLayoutAlign="center center">
            <h4 translate>Gallery</h4>
            <!-- <p translate>Department of Horticulture Government of Andhra Pradesh</p>-->
        </div>
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">

            <ng-container *ngFor="let list of aboutImage">
                <div ngxSlickItem class="slide" fxLayoutAlign="center center">
                    <img [src]="list?.img | scaleImage:'format'" loading="lazy">
                </div>
            </ng-container>

        </ngx-slick-carousel>
    </div>

</section>