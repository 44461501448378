import {
    Injectable
} from "@angular/core";
import {
    BehaviorSubject,
    Observable
} from "rxjs";

import {
    UtilityService
} from "./utility.service";

@Injectable({
    providedIn: "root",
})
export class CategoryService {
    private categoriesSubject = new BehaviorSubject([]);
    categories$: Observable < any[] > ;

    private categoryCardsSubject = new BehaviorSubject(categoryCardMap);
    categoryCards$: Observable < any > ;

    constructor(private util: UtilityService) {
        this.categories$ = this.categoriesSubject.asObservable();

        this.categoriesSubject.next(
            categoriesMenu /* .sort((c1, c2) => c2.count - c1.count) */
        );

        this.categoryCards$ = this.categoryCardsSubject.asObservable();
    }
}

const categoriesMenu = [
    // {
    // "id": "FRUITS_AND_VEGETABLES",
    // "name": "Fruits & Vegetables",
    // "tagLine": "Kalgudi brings you the choicest and most fresh Fruits & Vegetables from the makers directly to your doorstep.",
    // "count": 20,
    // "subCategory": [{
    //     "id": "FRUITS",
    //     "name": "Fruits",
    //     "count": 20,
    //     "metaTitle": "Buy Fresh Fruits Online at Best Prices | Doorstep Delivery| Kalgudi",
    //     "metaKeyword": "buy fruits online,  buy fresh fruits online",
    //     "metaDescription": "Buy fresh fruits online such as mangoes, green apple, banana, strawberry and more at Kalgudi - an exclusive store for horticulture produce. Order Now!",
    //     "subCategory": [{
    //         "id": "MANGOES",
    //         "name": "Mangoes",
    //         "backgroundImage": "assets/images/mango-banner.jpg",
    //         "count": 20,
    //         "metaTitle": "Buy Farm Fresh Mangoes Online: Alphonso, Kesar, Dasheri & more | Kalgudi",
    //         "metaKeyword": "buy mango online, farm fresh mangoes online",
    //         "metaDescription": "Buy Farm Fresh Mangoes online at Kalgudi. We sell carbide-free naturally grown mangoes like Alphonso, Kesar, Dasheri, Totapuri, Banganapalli & more. Traceable to Origin. Shop now!",
    //         "subCategory": []
    //     }]
    // }]
    // },
    {
    "id": "FOOD_PRODUCTS",
    "name": "Food Products",
    "count": 546,
    "subCategory": [{
        "id": "CONFECTIONERIES_AND_SWEETS",
        "name": "Confectioneries & Sweets",
        "count": 50,
        "subCategory": [{
            "id": "INDIAN_SWEETS",
            "name": "Indian Sweets",
            "count": 26,
            "subCategory": [{
                "id": "SPECIALITY_SWEETS",
                "name": "Speciality Sweets",
                "count": 20,
                "subCategory": []
            }]
        }, {
            "id": "SPECIALITY_INDIAN_SWEETS",
            "name": "Speciality Indian Sweets",
            "count": 15,
            "subCategory": [{
                "id": "PEANUT_CHIKKI",
                "name": "Peanut Chikki",
                "count": 1,
                "subCategory": []
            }]
        }]
    }, {
        "id": "GROCERY_AND_GOURMET",
        "name": "Grocery & Gourmet",
        "count": 63,
        "subCategory": [{
            "id": "POWDERED_SPICES_AND_MASALAS",
            "name": "Powdered Spices & Masalas",
            "count": 38,
            "subCategory": [{
                "id": "SPICE_POWDERS",
                "name": "Spice Powders",
                "count": 34,
                "subCategory": []
            }]
        }, {
            "id": "SYRUPS_SUGARS_AND_SWEETENERS",
            "name": "Syrups, Sugars & Sweeteners",
            "count": 3,
            "subCategory": [{
                "id": "JAGGERY",
                "name": "Jaggery",
                "count": 3,
                "subCategory": []
            }]
        }, {
            "id": "POWDERED_DRINK_MIXES",
            "name": "Powdered Drink Mixes",
            "count": 4,
            "subCategory": [{
                "id": "MULTIGRAIN_POWDERS",
                "name": "Multigrain Powders",
                "count": 1,
                "subCategory": []
            }, {
                "id": "RAGI_MALT",
                "name": "Ragi Malt",
                "count": 3,
                "subCategory": []
            }]
        }, {
            "id": "PACKAGED_FOOD",
            "name": "Packaged Food",
            "count": 2,
            "subCategory": [{
                "id": "READY_TO_COOK",
                "name": "Ready to Cook",
                "count": 2,
                "subCategory": []
            }]
        }, {
            "id": "GRAINS_AND_MILLETS",
            "name": "Grains & Millets",
            "count": 9,
            "subCategory": [{
                "id": "FOXTAIL_MILLETS",
                "name": "Foxtail Millets",
                "count": 2,
                "subCategory": []
            }, {
                "id": "ASSORTED_MILLETS",
                "name": "Assorted Millets",
                "count": 1,
                "subCategory": []
            }, {
                "id": "FINGER_MILLETS",
                "name": "Finger Millets",
                "count": 2,
                "subCategory": []
            }, {
                "id": "LITTLE_MILLETS",
                "name": "Little Millets",
                "count": 1,
                "subCategory": []
            }, {
                "id": "FOXTAIL_MILLET_RICE",
                "name": "Foxtail Millet Rice",
                "count": 1,
                "subCategory": []
            }, {
                "id": "BARNYARD_MILLETS",
                "name": "Barnyard Millets",
                "count": 1,
                "subCategory": []
            }, {
                "id": "KODO_MILLETS",
                "name": "Kodo Millets",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "SPECIALITY_FOOD",
            "name": "Speciality Food",
            "count": 1,
            "subCategory": [{
                "id": "FRUIT_JELLY",
                "name": "Fruit Jelly",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "FLOURS_AND_RAWA",
            "name": "Flours & Rawa",
            "count": 4,
            "subCategory": [{
                "id": "FINGER_MILLET_FLOUR",
                "name": "Finger Millet Flour",
                "count": 2,
                "subCategory": []
            }, {
                "id": "SORGHUM_RAWA",
                "name": "Sorghum Rawa",
                "count": 1,
                "subCategory": []
            }, {
                "id": "FOXTAIL_MILLET_RAWA",
                "name": "Foxtail Millet Rawa",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "DRY_FRUITS_AND_NUTS",
            "name": "Dry Fruits & Nuts",
            "count": 1,
            "subCategory": [{
                "id": "RAISINS",
                "name": "Raisins",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "FRYUMS_AND_PAPADS",
            "name": "Fryums & Papads",
            "count": 1,
            "subCategory": [{
                "id": "PAPADS",
                "name": "Papads",
                "count": 1,
                "subCategory": []
            }]
        }]
    }, {
        "id": "PICKLES_AND_CHUTNEYS",
        "name": "Pickles & Chutneys",
        "count": 101,
        "subCategory": [{
            "id": "VEGETARIAN_PICKLES",
            "name": "Vegetarian Pickles",
            "count": 82,
            "subCategory": [{
                "id": "RED_CHILI_PICKLE",
                "name": "Red Chili Pickle",
                "count": 7,
                "subCategory": []
            }, {
                "id": "GINGER_PICKLE",
                "name": "Ginger Pickle",
                "count": 7,
                "subCategory": []
            }, {
                "id": "TOMATO_PICKLE",
                "name": "Tomato Pickle",
                "count": 8,
                "subCategory": []
            }, {
                "id": "RED_CHILI_GONGURA_PICKLE",
                "name": "Red Chili Gongura Pickle",
                "count": 3,
                "subCategory": []
            }, {
                "id": "GONGURA_PICKLE",
                "name": "Gongura Pickle",
                "count": 7,
                "subCategory": []
            }, {
                "id": "LEMON_PICKLE",
                "name": "Lemon Pickle",
                "count": 8,
                "subCategory": []
            }, {
                "id": "DRUMSTICK_PICKLE",
                "name": "Drumstick Pickle",
                "count": 2,
                "subCategory": []
            }, {
                "id": "MIXED_VEGETABLE_PICKLE",
                "name": "Mixed Vegetable Pickle",
                "count": 1,
                "subCategory": []
            }, {
                "id": "MANGO_PICKLE",
                "name": "Mango Pickle",
                "count": 13,
                "subCategory": []
            }, {
                "id": "GREEN_TAMARIND_PICKLE",
                "name": "Green Tamarind Pickle",
                "count": 2,
                "subCategory": []
            }, {
                "id": "AMLA_PICKLE",
                "name": "Amla Pickle",
                "count": 7,
                "subCategory": []
            }, {
                "id": "MAGAYA_MANGO_PICKLE",
                "name": "Magaya (Mango) Pickle",
                "count": 1,
                "subCategory": []
            }, {
                "id": "TAPIOCA_PICKLE",
                "name": "Tapioca Pickle",
                "count": 1,
                "subCategory": []
            }, {
                "id": "BRINJAL_PICKLE",
                "name": "Brinjal Pickle",
                "count": 1,
                "subCategory": []
            }, {
                "id": "BITTER_GOURD_PICKLE",
                "name": "Bitter Gourd Pickle",
                "count": 2,
                "subCategory": []
            }, {
                "id": "TAMARIND_PICKLE",
                "name": "Tamarind Pickle",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "NON_VEG_PICKLES",
            "name": "Non Veg Pickles",
            "count": 17,
            "subCategory": [{
                "id": "CHICKEN_PICKLE",
                "name": "Chicken Pickle",
                "count": 7,
                "subCategory": []
            }, {
                "id": "PRAWN_PICKLE",
                "name": "Prawn Pickle",
                "count": 3,
                "subCategory": []
            }, {
                "id": "ASSORTED_NON_VEG_PICKLES",
                "name": "Assorted Non Veg Pickles",
                "count": 3,
                "subCategory": []
            }, {
                "id": "MUTTON_PICKLE",
                "name": "Mutton Pickle",
                "count": 1,
                "subCategory": []
            }, {
                "id": "FISH_PICKLE",
                "name": "Fish Pickle",
                "count": 2,
                "subCategory": []
            }]
        }]
    }, {
        "id": "PICKLES_AND_PRESERVES",
        "name": "Pickles & Preserves",
        "count": 12,
        "subCategory": [{
            "id": "VEGETARIAN_PICKLES",
            "name": "Vegetarian Pickles",
            "count": 82,
            "subCategory": [{
                "id": "RED_CHILI_PICKLE",
                "name": "Red Chili Pickle",
                "count": 7,
                "subCategory": []
            }, {
                "id": "GINGER_PICKLE",
                "name": "Ginger Pickle",
                "count": 7,
                "subCategory": []
            }, {
                "id": "TOMATO_PICKLE",
                "name": "Tomato Pickle",
                "count": 8,
                "subCategory": []
            }, {
                "id": "RED_CHILI_GONGURA_PICKLE",
                "name": "Red Chili Gongura Pickle",
                "count": 3,
                "subCategory": []
            }, {
                "id": "GONGURA_PICKLE",
                "name": "Gongura Pickle",
                "count": 7,
                "subCategory": []
            }, {
                "id": "LEMON_PICKLE",
                "name": "Lemon Pickle",
                "count": 8,
                "subCategory": []
            }, {
                "id": "DRUMSTICK_PICKLE",
                "name": "Drumstick Pickle",
                "count": 2,
                "subCategory": []
            }, {
                "id": "MIXED_VEGETABLE_PICKLE",
                "name": "Mixed Vegetable Pickle",
                "count": 1,
                "subCategory": []
            }, {
                "id": "MANGO_PICKLE",
                "name": "Mango Pickle",
                "count": 13,
                "subCategory": []
            }, {
                "id": "GREEN_TAMARIND_PICKLE",
                "name": "Green Tamarind Pickle",
                "count": 2,
                "subCategory": []
            }, {
                "id": "AMLA_PICKLE",
                "name": "Amla Pickle",
                "count": 7,
                "subCategory": []
            }, {
                "id": "MAGAYA_MANGO_PICKLE",
                "name": "Magaya (Mango) Pickle",
                "count": 1,
                "subCategory": []
            }, {
                "id": "TAPIOCA_PICKLE",
                "name": "Tapioca Pickle",
                "count": 1,
                "subCategory": []
            }, {
                "id": "BRINJAL_PICKLE",
                "name": "Brinjal Pickle",
                "count": 1,
                "subCategory": []
            }, {
                "id": "BITTER_GOURD_PICKLE",
                "name": "Bitter Gourd Pickle",
                "count": 2,
                "subCategory": []
            }, {
                "id": "TAMARIND_PICKLE",
                "name": "Tamarind Pickle",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "NON_VEG_PICKLES",
            "name": "Non Veg Pickles",
            "count": 17,
            "subCategory": [{
                "id": "CHICKEN_PICKLE",
                "name": "Chicken Pickle",
                "count": 7,
                "subCategory": []
            }, {
                "id": "PRAWN_PICKLE",
                "name": "Prawn Pickle",
                "count": 3,
                "subCategory": []
            }, {
                "id": "ASSORTED_NON_VEG_PICKLES",
                "name": "Assorted Non Veg Pickles",
                "count": 3,
                "subCategory": []
            }, {
                "id": "MUTTON_PICKLE",
                "name": "Mutton Pickle",
                "count": 1,
                "subCategory": []
            }, {
                "id": "FISH_PICKLE",
                "name": "Fish Pickle",
                "count": 2,
                "subCategory": []
            }]
        }]
    }, {
        "id": "INDIAN_SNACKS_AND_SAVOURIES",
        "name": "Indian Snacks & Savouries",
        "count": 12,
        "subCategory": [{
            "id": "BISCUITS",
            "name": "Biscuits",
            "count": 9,
            "subCategory": [{
                "id": "RAGI_FINGER_MILLET_BISCUITS",
                "name": "Ragi (Finger Millet) Biscuits",
                "count": 1,
                "subCategory": []
            }, {
                "id": "KORRA_FOXTAIL_MILLET_BISCUITS",
                "name": "Korra (Foxtail Millet) Biscuits",
                "count": 2,
                "subCategory": []
            }, {
                "id": "SORGHUM_BISCUITS",
                "name": "Sorghum Biscuits",
                "count": 1,
                "subCategory": []
            }, {
                "id": "MULTIGRAIN_BISCUITS",
                "name": "Multigrain Biscuits",
                "count": 1,
                "subCategory": []
            }, {
                "id": "PEARL_MILLET_BISCUITS",
                "name": "Pearl Millet Biscuits",
                "count": 2,
                "subCategory": []
            }, {
                "id": "MULTI_MILLET_BISCUITS",
                "name": "Multi Millet Biscuits",
                "count": 1,
                "subCategory": []
            }, {
                "id": "RAGIFINGER_MILLET__BISCUITS",
                "name": "Ragi(Finger Millet ) Biscuits",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "CAKES",
            "name": "Cakes",
            "count": 2,
            "subCategory": [{
                "id": "JONNA_SORGHUM_CAKE",
                "name": "Jonna (Sorghum) Cake",
                "count": 1,
                "subCategory": []
            }, {
                "id": "RAGI_FINGER_MILLET__CAKE",
                "name": "Ragi (Finger Millet ) Cake",
                "count": 1,
                "subCategory": []
            }]
        }]
    }]
}, {
    "id": "SPICES",
    "name": "Spices",
    "count": 63,
    "subCategory": []
}, {
    "id": "HEALTH_AND_PERSONAL_CARE",
    "name": "Health & Personal Care",
    "count": 120,
    "subCategory": [{
        "id": "TERRACOTTA",
        "name": "Terracotta",
        "count": 1803,
        "subCategory": [{
            "id": "HOME_AND_DECOR",
            "name": "Home & Decor",
            "count": 1632,
            "subCategory": [{
                "id": "RELIGIOUS_IDOLS",
                "name": "Religious Idols",
                "count": 675,
                "subCategory": []
            }, {
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "WIND_CHIMES",
                "name": "Wind Chimes",
                "count": 130,
                "subCategory": []
            }, {
                "id": "FLOWER_VASES",
                "name": "Flower Vases",
                "count": 99,
                "subCategory": []
            }, {
                "id": "FLOWER_POTS",
                "name": "Flower Pots",
                "count": 18,
                "subCategory": []
            }, {
                "id": "LAMPS_AND_LANTERNS",
                "name": "Lamps & Lanterns",
                "count": 144,
                "subCategory": []
            }, {
                "id": "WALL_BRACKETS",
                "name": "Wall Brackets",
                "count": 4,
                "subCategory": []
            }, {
                "id": "STORAGE_AND_ORGANISATION",
                "name": "Storage & Organisation",
                "count": 22,
                "subCategory": []
            }, {
                "id": "GARLANDS",
                "name": "Garlands",
                "count": 4,
                "subCategory": []
            }, {
                "id": "SCULPTURED_IDOLS",
                "name": "Sculptured Idols",
                "count": 10,
                "subCategory": []
            }, {
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }, {
                "id": "THORAN_DOOR_DECOR",
                "name": "Thoran (Door Decor)",
                "count": 5,
                "subCategory": []
            }]
        }, {
            "id": "HOME_AND_KITCHEN",
            "name": "Home & Kitchen",
            "count": 119,
            "subCategory": [{
                "id": "WATER_BOTTLES",
                "name": "Water Bottles",
                "count": 33,
                "subCategory": []
            }, {
                "id": "TEA_CUPS",
                "name": "Tea cups",
                "count": 54,
                "subCategory": []
            }, {
                "id": "STORAGE_BASKETS",
                "name": "Storage Baskets",
                "count": 5,
                "subCategory": []
            }, {
                "id": "FRUIT_BASKETS",
                "name": "Fruit Baskets",
                "count": 1,
                "subCategory": []
            }, {
                "id": "TRAYS",
                "name": "Trays",
                "count": 36,
                "subCategory": []
            }, {
                "id": "GIFT_BOXES",
                "name": "Gift Boxes",
                "count": 1,
                "subCategory": []
            }, {
                "id": "LAUNDRY_BASKETS",
                "name": "Laundry Baskets",
                "count": 1,
                "subCategory": []
            }, {
                "id": "PUJA_BASKETS",
                "name": "Puja Baskets",
                "count": 4,
                "subCategory": []
            }, {
                "id": "MULTIPURPOSE_BASKETS",
                "name": "Multipurpose Baskets",
                "count": 2,
                "subCategory": []
            }, {
                "id": "LETTER_BOX",
                "name": "Letter Box",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "COIN_BANK",
            "name": "Coin Bank",
            "count": 19,
            "subCategory": []
        }, {
            "id": "JUGS_AND_PITCHERS",
            "name": "Jugs & Pitchers",
            "count": 27,
            "subCategory": [{
                "id": "TERRACOTTA_JUGS",
                "name": "Terracotta Jugs",
                "count": 24,
                "subCategory": []
            }, {
                "id": "COPPER_JUGS",
                "name": "Copper Jugs",
                "count": 2,
                "subCategory": []
            }]
        }, {
            "id": "BODY_SKIN_AND_HAIR_CARE",
            "name": "Body, Skin & Hair Care",
            "count": 53,
            "subCategory": [{
                "id": "FOOT_SCRUBBERS",
                "name": "Foot Scrubbers",
                "count": 37,
                "subCategory": []
            }, {
                "id": "BODY_SCRUBBERS",
                "name": "Body Scrubbers",
                "count": 5,
                "subCategory": []
            }, {
                "id": "BATH_POWDER",
                "name": "Bath Powder",
                "count": 2,
                "subCategory": []
            }, {
                "id": "SOAP",
                "name": "Soap",
                "count": 4,
                "subCategory": []
            }, {
                "id": "HAIR_OIL",
                "name": "Hair Oil",
                "count": 2,
                "subCategory": []
            }, {
                "id": "BHRINGRAJ_LEAVES_POWDER",
                "name": "Bhringraj Leaves Powder",
                "count": 1,
                "subCategory": []
            }, {
                "id": "MULTANI_MITTI_FULLERS_EARTH",
                "name": "Multani Mitti (Fullers Earth)",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "OFFICE_PRODUCTS",
            "name": "Office Products",
            "count": 2,
            "subCategory": [{
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }]
        }]
    }, {
        "id": "CHILDRENS_ACCESSORIES",
        "name": "Childrens Accessories",
        "count": 1,
        "subCategory": [{
            "id": "HAIR_ACCESSORIES",
            "name": "Hair Accessories",
            "count": 1,
            "subCategory": [{
                "id": "HAIRBANDS",
                "name": "Hairbands",
                "count": 1,
                "subCategory": []
            }]
        }]
    }, {
        "id": "HERBAL_PRODUCTS",
        "name": "Herbal Products",
        "count": 29,
        "subCategory": [{
            "id": "BODY_SKIN_AND_HAIR_CARE",
            "name": "Body, Skin & Hair Care",
            "count": 53,
            "subCategory": [{
                "id": "FOOT_SCRUBBERS",
                "name": "Foot Scrubbers",
                "count": 37,
                "subCategory": []
            }, {
                "id": "BODY_SCRUBBERS",
                "name": "Body Scrubbers",
                "count": 5,
                "subCategory": []
            }, {
                "id": "BATH_POWDER",
                "name": "Bath Powder",
                "count": 2,
                "subCategory": []
            }, {
                "id": "SOAP",
                "name": "Soap",
                "count": 4,
                "subCategory": []
            }, {
                "id": "HAIR_OIL",
                "name": "Hair Oil",
                "count": 2,
                "subCategory": []
            }, {
                "id": "BHRINGRAJ_LEAVES_POWDER",
                "name": "Bhringraj Leaves Powder",
                "count": 1,
                "subCategory": []
            }, {
                "id": "MULTANI_MITTI_FULLERS_EARTH",
                "name": "Multani Mitti (Fullers Earth)",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "ALTERNATIVE_MEDICINE",
            "name": "Alternative Medicine",
            "count": 10,
            "subCategory": [{
                "id": "TRIPHALA_CHURNA",
                "name": "Triphala Churna",
                "count": 1,
                "subCategory": []
            }, {
                "id": "TULSI_CHURNA",
                "name": "Tulsi Churna",
                "count": 1,
                "subCategory": []
            }, {
                "id": "DIABETIC_CHURNA",
                "name": "Diabetic Churna",
                "count": 1,
                "subCategory": []
            }, {
                "id": "ASHWAGANDHA_CHURNA",
                "name": "Ashwagandha Churna",
                "count": 1,
                "subCategory": []
            }, {
                "id": "ATHIMADHURAM_CHURNA",
                "name": "Athimadhuram Churna",
                "count": 1,
                "subCategory": []
            }, {
                "id": "SUNAMUKHI_CHURNA",
                "name": "Sunamukhi Churna",
                "count": 1,
                "subCategory": []
            }, {
                "id": "TAMARIND_SEEDS_POWDER",
                "name": "Tamarind seeds Powder",
                "count": 2,
                "subCategory": []
            }]
        }, {
            "id": "HOME_FRAGRANCE",
            "name": "Home Fragrance",
            "count": 21,
            "subCategory": [{
                "id": "INCENSE_STICKS",
                "name": "Incense Sticks",
                "count": 21,
                "subCategory": []
            }]
        }]
    }, {
        "id": "PERFUMES_AND_FRAGRANCES",
        "name": "Perfumes & Fragrances",
        "count": 8,
        "subCategory": [{
            "id": "FRAGRANCES",
            "name": "Fragrances",
            "count": 8,
            "subCategory": [{
                "id": "ROLL_ON_FRAGRANCE",
                "name": "Roll On Fragrance",
                "count": 8,
                "subCategory": []
            }]
        }]
    }, {
        "id": "PAIN_RELIEF_PRODUCTS",
        "name": "Pain Relief Products",
        "count": 4,
        "subCategory": [{
            "id": "MASSAGE_TOOLS",
            "name": "Massage Tools",
            "count": 4,
            "subCategory": []
        }]
    }, {
        "id": "WOMENS_HYGIENE",
        "name": "Women's Hygiene",
        "count": 2,
        "subCategory": [{
            "id": "SANITARY_NAPKINS",
            "name": "Sanitary Napkins",
            "count": 2,
            "subCategory": []
        }]
    }, {
        "id": "HOME_AND_KITCHEN",
        "name": "Home & Kitchen",
        "count": 36,
        "subCategory": [{
            "id": "HOME_FURNISHING",
            "name": "Home Furnishing",
            "count": 6,
            "subCategory": [{
                "id": "CUSHION_COVERS",
                "name": "Cushion Covers",
                "count": 2,
                "subCategory": []
            }, {
                "id": "CURTAINS",
                "name": "Curtains",
                "count": 3,
                "subCategory": []
            }, {
                "id": "APPLIANCE_COVERS",
                "name": "Appliance covers",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "BATH_LINEN",
            "name": "Bath Linen",
            "count": 15,
            "subCategory": [{
                "id": "TOWELS",
                "name": "Towels",
                "count": 15,
                "subCategory": []
            }]
        }, {
            "id": "TABLE_LINEN",
            "name": "Table Linen",
            "count": 4,
            "subCategory": [{
                "id": "TABLE_CLOTH",
                "name": "Table Cloth",
                "count": 4,
                "subCategory": []
            }]
        }, {
            "id": "BED_LINEN",
            "name": "Bed Linen",
            "count": 4,
            "subCategory": [{
                "id": "BEDSHEETS",
                "name": "Bedsheets",
                "count": 4,
                "subCategory": []
            }]
        }, {
            "id": "PUJA_ARTICLES",
            "name": "Puja Articles",
            "count": 5,
            "subCategory": [{
                "id": "TURMERIC_AND_KUMKUM_PLATTER",
                "name": "Turmeric & Kumkum Platter",
                "count": 1,
                "subCategory": []
            }]
        }]
    }]
}, {
    "id": "HANDICRAFTS",
    "name": "Handicrafts",
    "count": 3659,
    "subCategory": [{
        "id": "TERRACOTTA",
        "name": "Terracotta",
        "count": 1803,
        "subCategory": [{
            "id": "HOME_AND_DECOR",
            "name": "Home & Decor",
            "count": 1632,
            "subCategory": [{
                "id": "RELIGIOUS_IDOLS",
                "name": "Religious Idols",
                "count": 675,
                "subCategory": []
            }, {
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "WIND_CHIMES",
                "name": "Wind Chimes",
                "count": 130,
                "subCategory": []
            }, {
                "id": "FLOWER_VASES",
                "name": "Flower Vases",
                "count": 99,
                "subCategory": []
            }, {
                "id": "FLOWER_POTS",
                "name": "Flower Pots",
                "count": 18,
                "subCategory": []
            }, {
                "id": "LAMPS_AND_LANTERNS",
                "name": "Lamps & Lanterns",
                "count": 144,
                "subCategory": []
            }, {
                "id": "WALL_BRACKETS",
                "name": "Wall Brackets",
                "count": 4,
                "subCategory": []
            }, {
                "id": "STORAGE_AND_ORGANISATION",
                "name": "Storage & Organisation",
                "count": 22,
                "subCategory": []
            }, {
                "id": "GARLANDS",
                "name": "Garlands",
                "count": 4,
                "subCategory": []
            }, {
                "id": "SCULPTURED_IDOLS",
                "name": "Sculptured Idols",
                "count": 10,
                "subCategory": []
            }, {
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }, {
                "id": "THORAN_DOOR_DECOR",
                "name": "Thoran (Door Decor)",
                "count": 5,
                "subCategory": []
            }]
        }, {
            "id": "HOME_AND_KITCHEN",
            "name": "Home & Kitchen",
            "count": 119,
            "subCategory": [{
                "id": "WATER_BOTTLES",
                "name": "Water Bottles",
                "count": 33,
                "subCategory": []
            }, {
                "id": "TEA_CUPS",
                "name": "Tea cups",
                "count": 54,
                "subCategory": []
            }, {
                "id": "STORAGE_BASKETS",
                "name": "Storage Baskets",
                "count": 5,
                "subCategory": []
            }, {
                "id": "FRUIT_BASKETS",
                "name": "Fruit Baskets",
                "count": 1,
                "subCategory": []
            }, {
                "id": "TRAYS",
                "name": "Trays",
                "count": 36,
                "subCategory": []
            }, {
                "id": "GIFT_BOXES",
                "name": "Gift Boxes",
                "count": 1,
                "subCategory": []
            }, {
                "id": "LAUNDRY_BASKETS",
                "name": "Laundry Baskets",
                "count": 1,
                "subCategory": []
            }, {
                "id": "PUJA_BASKETS",
                "name": "Puja Baskets",
                "count": 4,
                "subCategory": []
            }, {
                "id": "MULTIPURPOSE_BASKETS",
                "name": "Multipurpose Baskets",
                "count": 2,
                "subCategory": []
            }, {
                "id": "LETTER_BOX",
                "name": "Letter Box",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "COIN_BANK",
            "name": "Coin Bank",
            "count": 19,
            "subCategory": []
        }, {
            "id": "JUGS_AND_PITCHERS",
            "name": "Jugs & Pitchers",
            "count": 27,
            "subCategory": [{
                "id": "TERRACOTTA_JUGS",
                "name": "Terracotta Jugs",
                "count": 24,
                "subCategory": []
            }, {
                "id": "COPPER_JUGS",
                "name": "Copper Jugs",
                "count": 2,
                "subCategory": []
            }]
        }, {
            "id": "BODY_SKIN_AND_HAIR_CARE",
            "name": "Body, Skin & Hair Care",
            "count": 53,
            "subCategory": [{
                "id": "FOOT_SCRUBBERS",
                "name": "Foot Scrubbers",
                "count": 37,
                "subCategory": []
            }, {
                "id": "BODY_SCRUBBERS",
                "name": "Body Scrubbers",
                "count": 5,
                "subCategory": []
            }, {
                "id": "BATH_POWDER",
                "name": "Bath Powder",
                "count": 2,
                "subCategory": []
            }, {
                "id": "SOAP",
                "name": "Soap",
                "count": 4,
                "subCategory": []
            }, {
                "id": "HAIR_OIL",
                "name": "Hair Oil",
                "count": 2,
                "subCategory": []
            }, {
                "id": "BHRINGRAJ_LEAVES_POWDER",
                "name": "Bhringraj Leaves Powder",
                "count": 1,
                "subCategory": []
            }, {
                "id": "MULTANI_MITTI_FULLERS_EARTH",
                "name": "Multani Mitti (Fullers Earth)",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "OFFICE_PRODUCTS",
            "name": "Office Products",
            "count": 2,
            "subCategory": [{
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }]
        }]
    }, {
        "id": "WOOD",
        "name": "Wood",
        "count": 775,
        "subCategory": [{
            "id": "UDAYAGIRI_CUTLERY_AND_CRAFTS",
            "name": "Udayagiri Cutlery & Crafts",
            "count": 160,
            "subCategory": [{
                "id": "LAMPS_AND_LANTERNS",
                "name": "Lamps & Lanterns",
                "count": 144,
                "subCategory": []
            }, {
                "id": "TOYS",
                "name": "Toys",
                "count": 100,
                "subCategory": []
            }, {
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "PEN_STAND",
                "name": "Pen stand",
                "count": 15,
                "subCategory": []
            }, {
                "id": "JARS_AND_CONTAINERS",
                "name": "Jars & Containers",
                "count": 13,
                "subCategory": []
            }, {
                "id": "COMBS",
                "name": "Combs",
                "count": 27,
                "subCategory": []
            }, {
                "id": "TABLE_PLACEMATS",
                "name": "Table Placemats",
                "count": 17,
                "subCategory": []
            }, {
                "id": "PHOTO_FRAMES",
                "name": "Photo Frames",
                "count": 4,
                "subCategory": []
            }, {
                "id": "TRAYS",
                "name": "Trays",
                "count": 36,
                "subCategory": []
            }]
        }, {
            "id": "HOME_AND_DECOR",
            "name": "Home & Decor",
            "count": 1632,
            "subCategory": [{
                "id": "RELIGIOUS_IDOLS",
                "name": "Religious Idols",
                "count": 675,
                "subCategory": []
            }, {
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "WIND_CHIMES",
                "name": "Wind Chimes",
                "count": 130,
                "subCategory": []
            }, {
                "id": "FLOWER_VASES",
                "name": "Flower Vases",
                "count": 99,
                "subCategory": []
            }, {
                "id": "FLOWER_POTS",
                "name": "Flower Pots",
                "count": 18,
                "subCategory": []
            }, {
                "id": "LAMPS_AND_LANTERNS",
                "name": "Lamps & Lanterns",
                "count": 144,
                "subCategory": []
            }, {
                "id": "WALL_BRACKETS",
                "name": "Wall Brackets",
                "count": 4,
                "subCategory": []
            }, {
                "id": "STORAGE_AND_ORGANISATION",
                "name": "Storage & Organisation",
                "count": 22,
                "subCategory": []
            }, {
                "id": "GARLANDS",
                "name": "Garlands",
                "count": 4,
                "subCategory": []
            }, {
                "id": "SCULPTURED_IDOLS",
                "name": "Sculptured Idols",
                "count": 10,
                "subCategory": []
            }, {
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }, {
                "id": "THORAN_DOOR_DECOR",
                "name": "Thoran (Door Decor)",
                "count": 5,
                "subCategory": []
            }]
        }, {
            "id": "ETIKOPPAKA",
            "name": "Etikoppaka",
            "count": 23,
            "subCategory": [{
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "RELIGIOUS_IDOLS",
                "name": "Religious Idols",
                "count": 675,
                "subCategory": []
            }, {
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }, {
                "id": "TOYS",
                "name": "Toys",
                "count": 100,
                "subCategory": []
            }, {
                "id": "JARS_AND_CONTAINERS",
                "name": "Jars & Containers",
                "count": 13,
                "subCategory": []
            }, {
                "id": "FLOWER_VASES",
                "name": "Flower Vases",
                "count": 99,
                "subCategory": []
            }]
        }, {
            "id": "KONDAPALLI",
            "name": "Kondapalli",
            "count": 11,
            "subCategory": [{
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }]
        }, {
            "id": "KITCHEN_AND_DINING",
            "name": "Kitchen & Dining",
            "count": 311,
            "subCategory": [{
                "id": "TABLEWARE_AND_CUTLERY",
                "name": "Tableware & Cutlery",
                "count": 267,
                "subCategory": []
            }, {
                "id": "TRAYS",
                "name": "Trays",
                "count": 36,
                "subCategory": []
            }, {
                "id": "TABLE_PLACEMATS",
                "name": "Table Placemats",
                "count": 17,
                "subCategory": []
            }, {
                "id": "COASTERS",
                "name": "Coasters",
                "count": 1,
                "subCategory": []
            }, {
                "id": "COMBS",
                "name": "Combs",
                "count": 27,
                "subCategory": []
            }]
        }, {
            "id": "STATIONERY_AND_OFFICE_PRODUCTS",
            "name": "Stationery & Office Products",
            "count": 79,
            "subCategory": [{
                "id": "FILES_AND_FOLDERS",
                "name": "Files & Folders",
                "count": 60,
                "subCategory": []
            }, {
                "id": "DESK_ACCESSORIES_AND_STORAGE_PRODUCTS",
                "name": "Desk Accessories & Storage Products",
                "count": 9,
                "subCategory": []
            }]
        }, {
            "id": "UDAYAGIRI_WOODEN_CUTLERY_AND_CRAFTS",
            "name": "Udayagiri Wooden Cutlery & Crafts",
            "count": 26,
            "subCategory": [{
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }, {
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "COMBS",
                "name": "Combs",
                "count": 27,
                "subCategory": []
            }, {
                "id": "TRAYS",
                "name": "Trays",
                "count": 36,
                "subCategory": []
            }, {
                "id": "TABLEWARE_AND_CUTLERY",
                "name": "Tableware & Cutlery",
                "count": 267,
                "subCategory": []
            }, {
                "id": "TOYS",
                "name": "Toys",
                "count": 100,
                "subCategory": []
            }]
        }, {
            "id": "CHILDRENS_TOYS",
            "name": "Children's Toys",
            "count": 5,
            "subCategory": [{
                "id": "TOYS",
                "name": "Toys",
                "count": 100,
                "subCategory": []
            }]
        }, {
            "id": "LACQUERWARE",
            "name": "Lacquerware",
            "count": 25,
            "subCategory": [{
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "TOYS",
                "name": "Toys",
                "count": 100,
                "subCategory": []
            }, {
                "id": "RELIGIOUS_IDOLS",
                "name": "Religious Idols",
                "count": 675,
                "subCategory": []
            }, {
                "id": "JARS_AND_CONTAINERS",
                "name": "Jars & Containers",
                "count": 13,
                "subCategory": []
            }]
        }, {
            "id": "SCULPTURES",
            "name": "Sculptures",
            "count": 2,
            "subCategory": [{
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "RELIGIOUS_IDOLS",
                "name": "Religious Idols",
                "count": 675,
                "subCategory": []
            }]
        }, {
            "id": "OFFICE_PRODUCTS",
            "name": "Office Products",
            "count": 2,
            "subCategory": [{
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }]
        }]
    }, {
        "id": "JUTE",
        "name": "Jute",
        "count": 865,
        "subCategory": [{
            "id": "STATIONERY_AND_OFFICE_PRODUCTS",
            "name": "Stationery & Office Products",
            "count": 79,
            "subCategory": [{
                "id": "FILES_AND_FOLDERS",
                "name": "Files & Folders",
                "count": 60,
                "subCategory": []
            }, {
                "id": "DESK_ACCESSORIES_AND_STORAGE_PRODUCTS",
                "name": "Desk Accessories & Storage Products",
                "count": 9,
                "subCategory": []
            }]
        }, {
            "id": "BAGSWALLETSLUGGAGE",
            "name": "Bags-Wallets-Luggage",
            "count": 832,
            "subCategory": [{
                "id": "WATER_BOTTLE_BAGS",
                "name": "Water Bottle Bags",
                "count": 10,
                "subCategory": []
            }, {
                "id": "MULTIPURPOSE_BAGS",
                "name": "Multipurpose Bags",
                "count": 186,
                "subCategory": []
            }, {
                "id": "GIFT_BAGS",
                "name": "Gift Bags",
                "count": 71,
                "subCategory": []
            }, {
                "id": "HANDBAGS",
                "name": "Handbags",
                "count": 303,
                "subCategory": []
            }, {
                "id": "SLING_BAGS",
                "name": "Sling Bags",
                "count": 129,
                "subCategory": []
            }, {
                "id": "SCHOOL_BAGS",
                "name": "School Bags",
                "count": 24,
                "subCategory": []
            }, {
                "id": "STUDENT_BAGS",
                "name": "Student Bags",
                "count": 13,
                "subCategory": []
            }, {
                "id": "LUNCH_BAGS",
                "name": "Lunch Bags",
                "count": 43,
                "subCategory": []
            }, {
                "id": "LAPTOP_BAGS",
                "name": "Laptop Bags",
                "count": 14,
                "subCategory": []
            }, {
                "id": "HAND_BAGS",
                "name": "Hand Bags",
                "count": 23,
                "subCategory": []
            }, {
                "id": "TRAVEL_BAGS",
                "name": "Travel Bags",
                "count": 9,
                "subCategory": []
            }, {
                "id": "PURSES_AND_CLUTCHES",
                "name": "Purses & Clutches",
                "count": 3,
                "subCategory": []
            }, {
                "id": "CONFERENCE_BAGS",
                "name": "Conference bags",
                "count": 1,
                "subCategory": []
            }, {
                "id": "DIAPER_BAGS",
                "name": "Diaper Bags",
                "count": 1,
                "subCategory": []
            }, {
                "id": "STORAGE_AND_ORGANISATION",
                "name": "Storage & Organisation",
                "count": 22,
                "subCategory": []
            }, {
                "id": "BOTTLE_BAGS",
                "name": "Bottle Bags",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "STORAGE_AND_ORGANISATION",
            "name": "Storage & Organisation",
            "count": 26,
            "subCategory": [{
                "id": "WALL_HANGERS",
                "name": "Wall Hangers",
                "count": 26,
                "subCategory": []
            }]
        }, {
            "id": "HOME_AND_DECOR",
            "name": "Home & Decor",
            "count": 1632,
            "subCategory": [{
                "id": "RELIGIOUS_IDOLS",
                "name": "Religious Idols",
                "count": 675,
                "subCategory": []
            }, {
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "WIND_CHIMES",
                "name": "Wind Chimes",
                "count": 130,
                "subCategory": []
            }, {
                "id": "FLOWER_VASES",
                "name": "Flower Vases",
                "count": 99,
                "subCategory": []
            }, {
                "id": "FLOWER_POTS",
                "name": "Flower Pots",
                "count": 18,
                "subCategory": []
            }, {
                "id": "LAMPS_AND_LANTERNS",
                "name": "Lamps & Lanterns",
                "count": 144,
                "subCategory": []
            }, {
                "id": "WALL_BRACKETS",
                "name": "Wall Brackets",
                "count": 4,
                "subCategory": []
            }, {
                "id": "STORAGE_AND_ORGANISATION",
                "name": "Storage & Organisation",
                "count": 22,
                "subCategory": []
            }, {
                "id": "GARLANDS",
                "name": "Garlands",
                "count": 4,
                "subCategory": []
            }, {
                "id": "SCULPTURED_IDOLS",
                "name": "Sculptured Idols",
                "count": 10,
                "subCategory": []
            }, {
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }, {
                "id": "THORAN_DOOR_DECOR",
                "name": "Thoran (Door Decor)",
                "count": 5,
                "subCategory": []
            }]
        }, {
            "id": "KITCHEN_AND_DINING",
            "name": "Kitchen & Dining",
            "count": 311,
            "subCategory": [{
                "id": "TABLEWARE_AND_CUTLERY",
                "name": "Tableware & Cutlery",
                "count": 267,
                "subCategory": []
            }, {
                "id": "TRAYS",
                "name": "Trays",
                "count": 36,
                "subCategory": []
            }, {
                "id": "TABLE_PLACEMATS",
                "name": "Table Placemats",
                "count": 17,
                "subCategory": []
            }, {
                "id": "COASTERS",
                "name": "Coasters",
                "count": 1,
                "subCategory": []
            }, {
                "id": "COMBS",
                "name": "Combs",
                "count": 27,
                "subCategory": []
            }]
        }]
    }, {
        "id": "LEATHER",
        "name": "Leather",
        "count": 27,
        "subCategory": [{
            "id": "ANDHRA_PRADESH_LEATHER_PUPPETRY",
            "name": "Andhra Pradesh Leather Puppetry",
            "count": 27,
            "subCategory": [{
                "id": "LAMPS_AND_LANTERNS",
                "name": "Lamps & Lanterns",
                "count": 144,
                "subCategory": []
            }]
        }]
    }, {
        "id": "BAMBOO",
        "name": "Bamboo",
        "count": 6,
        "subCategory": [{
            "id": "HOME_AND_DECOR",
            "name": "Home & Decor",
            "count": 1632,
            "subCategory": [{
                "id": "RELIGIOUS_IDOLS",
                "name": "Religious Idols",
                "count": 675,
                "subCategory": []
            }, {
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "WIND_CHIMES",
                "name": "Wind Chimes",
                "count": 130,
                "subCategory": []
            }, {
                "id": "FLOWER_VASES",
                "name": "Flower Vases",
                "count": 99,
                "subCategory": []
            }, {
                "id": "FLOWER_POTS",
                "name": "Flower Pots",
                "count": 18,
                "subCategory": []
            }, {
                "id": "LAMPS_AND_LANTERNS",
                "name": "Lamps & Lanterns",
                "count": 144,
                "subCategory": []
            }, {
                "id": "WALL_BRACKETS",
                "name": "Wall Brackets",
                "count": 4,
                "subCategory": []
            }, {
                "id": "STORAGE_AND_ORGANISATION",
                "name": "Storage & Organisation",
                "count": 22,
                "subCategory": []
            }, {
                "id": "GARLANDS",
                "name": "Garlands",
                "count": 4,
                "subCategory": []
            }, {
                "id": "SCULPTURED_IDOLS",
                "name": "Sculptured Idols",
                "count": 10,
                "subCategory": []
            }, {
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }, {
                "id": "THORAN_DOOR_DECOR",
                "name": "Thoran (Door Decor)",
                "count": 5,
                "subCategory": []
            }]
        }]
    }, {
        "id": "FABRIC",
        "name": "Fabric",
        "count": 93,
        "subCategory": [{
            "id": "BAGSWALLETSLUGGAGE",
            "name": "Bags-Wallets-Luggage",
            "count": 832,
            "subCategory": [{
                "id": "WATER_BOTTLE_BAGS",
                "name": "Water Bottle Bags",
                "count": 10,
                "subCategory": []
            }, {
                "id": "MULTIPURPOSE_BAGS",
                "name": "Multipurpose Bags",
                "count": 186,
                "subCategory": []
            }, {
                "id": "GIFT_BAGS",
                "name": "Gift Bags",
                "count": 71,
                "subCategory": []
            }, {
                "id": "HANDBAGS",
                "name": "Handbags",
                "count": 303,
                "subCategory": []
            }, {
                "id": "SLING_BAGS",
                "name": "Sling Bags",
                "count": 129,
                "subCategory": []
            }, {
                "id": "SCHOOL_BAGS",
                "name": "School Bags",
                "count": 24,
                "subCategory": []
            }, {
                "id": "STUDENT_BAGS",
                "name": "Student Bags",
                "count": 13,
                "subCategory": []
            }, {
                "id": "LUNCH_BAGS",
                "name": "Lunch Bags",
                "count": 43,
                "subCategory": []
            }, {
                "id": "LAPTOP_BAGS",
                "name": "Laptop Bags",
                "count": 14,
                "subCategory": []
            }, {
                "id": "HAND_BAGS",
                "name": "Hand Bags",
                "count": 23,
                "subCategory": []
            }, {
                "id": "TRAVEL_BAGS",
                "name": "Travel Bags",
                "count": 9,
                "subCategory": []
            }, {
                "id": "PURSES_AND_CLUTCHES",
                "name": "Purses & Clutches",
                "count": 3,
                "subCategory": []
            }, {
                "id": "CONFERENCE_BAGS",
                "name": "Conference bags",
                "count": 1,
                "subCategory": []
            }, {
                "id": "DIAPER_BAGS",
                "name": "Diaper Bags",
                "count": 1,
                "subCategory": []
            }, {
                "id": "STORAGE_AND_ORGANISATION",
                "name": "Storage & Organisation",
                "count": 22,
                "subCategory": []
            }, {
                "id": "BOTTLE_BAGS",
                "name": "Bottle Bags",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "TOYS_AND_GAMES",
            "name": "Toys & Games",
            "count": 7,
            "subCategory": [{
                "id": "TOYS",
                "name": "Toys",
                "count": 100,
                "subCategory": []
            }]
        }]
    }, {
        "id": "COPPER",
        "name": "Copper",
        "count": 15,
        "subCategory": [{
            "id": "HOME_AND_DECOR",
            "name": "Home & Decor",
            "count": 1632,
            "subCategory": [{
                "id": "RELIGIOUS_IDOLS",
                "name": "Religious Idols",
                "count": 675,
                "subCategory": []
            }, {
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "WIND_CHIMES",
                "name": "Wind Chimes",
                "count": 130,
                "subCategory": []
            }, {
                "id": "FLOWER_VASES",
                "name": "Flower Vases",
                "count": 99,
                "subCategory": []
            }, {
                "id": "FLOWER_POTS",
                "name": "Flower Pots",
                "count": 18,
                "subCategory": []
            }, {
                "id": "LAMPS_AND_LANTERNS",
                "name": "Lamps & Lanterns",
                "count": 144,
                "subCategory": []
            }, {
                "id": "WALL_BRACKETS",
                "name": "Wall Brackets",
                "count": 4,
                "subCategory": []
            }, {
                "id": "STORAGE_AND_ORGANISATION",
                "name": "Storage & Organisation",
                "count": 22,
                "subCategory": []
            }, {
                "id": "GARLANDS",
                "name": "Garlands",
                "count": 4,
                "subCategory": []
            }, {
                "id": "SCULPTURED_IDOLS",
                "name": "Sculptured Idols",
                "count": 10,
                "subCategory": []
            }, {
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }, {
                "id": "THORAN_DOOR_DECOR",
                "name": "Thoran (Door Decor)",
                "count": 5,
                "subCategory": []
            }]
        }]
    }, {
        "id": "HOME_AND_KITCHEN",
        "name": "Home & Kitchen",
        "count": 36,
        "subCategory": [{
            "id": "HOME_FURNISHING",
            "name": "Home Furnishing",
            "count": 6,
            "subCategory": [{
                "id": "CUSHION_COVERS",
                "name": "Cushion Covers",
                "count": 2,
                "subCategory": []
            }, {
                "id": "CURTAINS",
                "name": "Curtains",
                "count": 3,
                "subCategory": []
            }, {
                "id": "APPLIANCE_COVERS",
                "name": "Appliance covers",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "BATH_LINEN",
            "name": "Bath Linen",
            "count": 15,
            "subCategory": [{
                "id": "TOWELS",
                "name": "Towels",
                "count": 15,
                "subCategory": []
            }]
        }, {
            "id": "TABLE_LINEN",
            "name": "Table Linen",
            "count": 4,
            "subCategory": [{
                "id": "TABLE_CLOTH",
                "name": "Table Cloth",
                "count": 4,
                "subCategory": []
            }]
        }, {
            "id": "BED_LINEN",
            "name": "Bed Linen",
            "count": 4,
            "subCategory": [{
                "id": "BEDSHEETS",
                "name": "Bedsheets",
                "count": 4,
                "subCategory": []
            }]
        }, {
            "id": "PUJA_ARTICLES",
            "name": "Puja Articles",
            "count": 5,
            "subCategory": [{
                "id": "TURMERIC_AND_KUMKUM_PLATTER",
                "name": "Turmeric & Kumkum Platter",
                "count": 1,
                "subCategory": []
            }]
        }]
    }, {
        "id": "HOME_AND_DECOR",
        "name": "Home & Decor",
        "count": 82,
        "subCategory": [{
            "id": "PAINTINGS",
            "name": "Paintings",
            "count": 4,
            "subCategory": [{
                "id": "KALAMKARI_PAINTINGS",
                "name": "Kalamkari Paintings",
                "count": 2,
                "subCategory": []
            }, {
                "id": "BATIK_PAINTINGS",
                "name": "Batik Paintings",
                "count": 2,
                "subCategory": []
            }]
        }, {
            "id": "HOME_DECOR_ACCENTS",
            "name": "Home Decor Accents",
            "count": 34,
            "subCategory": [{
                "id": "RELIGIOUS_IDOLS",
                "name": "Religious Idols",
                "count": 675,
                "subCategory": []
            }, {
                "id": "THORAN_DOOR_DECOR",
                "name": "Thoran (Door Decor)",
                "count": 5,
                "subCategory": []
            }, {
                "id": "ARTIFICIAL_FLOWERS",
                "name": "Artificial Flowers",
                "count": 1,
                "subCategory": []
            }, {
                "id": "FLOWER_VASES",
                "name": "Flower Vases",
                "count": 99,
                "subCategory": []
            }, {
                "id": "LAMP_SHADES",
                "name": "Lamp shades",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "HOME_FRAGRANCE",
            "name": "Home Fragrance",
            "count": 21,
            "subCategory": [{
                "id": "INCENSE_STICKS",
                "name": "Incense Sticks",
                "count": 21,
                "subCategory": []
            }]
        }]
    }, {
        "id": "PALM_LEAF",
        "name": "Palm Leaf",
        "count": 25,
        "subCategory": [{
            "id": "HOME_AND_DECOR",
            "name": "Home & Decor",
            "count": 1632,
            "subCategory": [{
                "id": "RELIGIOUS_IDOLS",
                "name": "Religious Idols",
                "count": 675,
                "subCategory": []
            }, {
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "WIND_CHIMES",
                "name": "Wind Chimes",
                "count": 130,
                "subCategory": []
            }, {
                "id": "FLOWER_VASES",
                "name": "Flower Vases",
                "count": 99,
                "subCategory": []
            }, {
                "id": "FLOWER_POTS",
                "name": "Flower Pots",
                "count": 18,
                "subCategory": []
            }, {
                "id": "LAMPS_AND_LANTERNS",
                "name": "Lamps & Lanterns",
                "count": 144,
                "subCategory": []
            }, {
                "id": "WALL_BRACKETS",
                "name": "Wall Brackets",
                "count": 4,
                "subCategory": []
            }, {
                "id": "STORAGE_AND_ORGANISATION",
                "name": "Storage & Organisation",
                "count": 22,
                "subCategory": []
            }, {
                "id": "GARLANDS",
                "name": "Garlands",
                "count": 4,
                "subCategory": []
            }, {
                "id": "SCULPTURED_IDOLS",
                "name": "Sculptured Idols",
                "count": 10,
                "subCategory": []
            }, {
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }, {
                "id": "THORAN_DOOR_DECOR",
                "name": "Thoran (Door Decor)",
                "count": 5,
                "subCategory": []
            }]
        }, {
            "id": "MENS_ACCESSORIES",
            "name": "Men's Accessories",
            "count": 10,
            "subCategory": [{
                "id": "CAPS_AND_HATS",
                "name": "Caps & Hats",
                "count": 3,
                "subCategory": []
            }, {
                "id": "HANDKERCHIEFS",
                "name": "Handkerchiefs",
                "count": 8,
                "subCategory": []
            }]
        }, {
            "id": "HOME_AND_KITCHEN",
            "name": "Home & Kitchen",
            "count": 119,
            "subCategory": [{
                "id": "WATER_BOTTLES",
                "name": "Water Bottles",
                "count": 33,
                "subCategory": []
            }, {
                "id": "TEA_CUPS",
                "name": "Tea cups",
                "count": 54,
                "subCategory": []
            }, {
                "id": "STORAGE_BASKETS",
                "name": "Storage Baskets",
                "count": 5,
                "subCategory": []
            }, {
                "id": "FRUIT_BASKETS",
                "name": "Fruit Baskets",
                "count": 1,
                "subCategory": []
            }, {
                "id": "TRAYS",
                "name": "Trays",
                "count": 36,
                "subCategory": []
            }, {
                "id": "GIFT_BOXES",
                "name": "Gift Boxes",
                "count": 1,
                "subCategory": []
            }, {
                "id": "LAUNDRY_BASKETS",
                "name": "Laundry Baskets",
                "count": 1,
                "subCategory": []
            }, {
                "id": "PUJA_BASKETS",
                "name": "Puja Baskets",
                "count": 4,
                "subCategory": []
            }, {
                "id": "MULTIPURPOSE_BASKETS",
                "name": "Multipurpose Baskets",
                "count": 2,
                "subCategory": []
            }, {
                "id": "LETTER_BOX",
                "name": "Letter Box",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "WOMENS_ACCESSORIES",
            "name": "Women's Accessories",
            "count": 1,
            "subCategory": [{
                "id": "CAPS_AND_HATS",
                "name": "Caps & Hats",
                "count": 3,
                "subCategory": []
            }]
        }]
    }, {
        "id": "BRASS",
        "name": "Brass",
        "count": 4,
        "subCategory": [{
            "id": "HOME_AND_DECOR",
            "name": "Home & Decor",
            "count": 1632,
            "subCategory": [{
                "id": "RELIGIOUS_IDOLS",
                "name": "Religious Idols",
                "count": 675,
                "subCategory": []
            }, {
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "WIND_CHIMES",
                "name": "Wind Chimes",
                "count": 130,
                "subCategory": []
            }, {
                "id": "FLOWER_VASES",
                "name": "Flower Vases",
                "count": 99,
                "subCategory": []
            }, {
                "id": "FLOWER_POTS",
                "name": "Flower Pots",
                "count": 18,
                "subCategory": []
            }, {
                "id": "LAMPS_AND_LANTERNS",
                "name": "Lamps & Lanterns",
                "count": 144,
                "subCategory": []
            }, {
                "id": "WALL_BRACKETS",
                "name": "Wall Brackets",
                "count": 4,
                "subCategory": []
            }, {
                "id": "STORAGE_AND_ORGANISATION",
                "name": "Storage & Organisation",
                "count": 22,
                "subCategory": []
            }, {
                "id": "GARLANDS",
                "name": "Garlands",
                "count": 4,
                "subCategory": []
            }, {
                "id": "SCULPTURED_IDOLS",
                "name": "Sculptured Idols",
                "count": 10,
                "subCategory": []
            }, {
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }, {
                "id": "THORAN_DOOR_DECOR",
                "name": "Thoran (Door Decor)",
                "count": 5,
                "subCategory": []
            }]
        }, {
            "id": "DHOKRA_ART",
            "name": "Dhokra Art",
            "count": 3,
            "subCategory": [{
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }]
        }]
    }, {
        "id": "PAPER",
        "name": "Paper",
        "count": 8,
        "subCategory": [{
            "id": "ARTS_AND_CRAFTS",
            "name": "Arts & Crafts",
            "count": 6,
            "subCategory": [{
                "id": "QUILLING",
                "name": "Quilling",
                "count": 6,
                "subCategory": []
            }]
        }]
    }, {
        "id": "WOOD_",
        "name": "Wood ",
        "count": 1,
        "subCategory": [{
            "id": "UDAYAGIRI_WOODEN_CUTLERY_AND_CRAFTS",
            "name": "Udayagiri Wooden Cutlery & Crafts",
            "count": 26,
            "subCategory": [{
                "id": "PEN_STANDS",
                "name": "Pen Stands",
                "count": 9,
                "subCategory": []
            }, {
                "id": "HOME_DECOR_ACCENTS",
                "name": "Home Decor Accents",
                "count": 473,
                "subCategory": []
            }, {
                "id": "COMBS",
                "name": "Combs",
                "count": 27,
                "subCategory": []
            }, {
                "id": "TRAYS",
                "name": "Trays",
                "count": 36,
                "subCategory": []
            }, {
                "id": "TABLEWARE_AND_CUTLERY",
                "name": "Tableware & Cutlery",
                "count": 267,
                "subCategory": []
            }, {
                "id": "TOYS",
                "name": "Toys",
                "count": 100,
                "subCategory": []
            }]
        }]
    }]
}, {
    "id": "TEXTILES",
    "name": "Textiles",
    "count": 396,
    "subCategory": [{
        "id": "WOMENS_CLOTHING",
        "name": "Women's Clothing",
        "count": 301,
        "subCategory": [{
            "id": "GADWAL",
            "name": "Gadwal",
            "count": 57,
            "subCategory": [{
                "id": "COTTON_SILK_SAREES",
                "name": "Cotton Silk Sarees",
                "count": 49,
                "subCategory": []
            }, {
                "id": "SILK_SAREES",
                "name": "Silk Sarees",
                "count": 58,
                "subCategory": []
            }, {
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }]
        }, {
            "id": "ETHNIC_WEAR",
            "name": "Ethnic Wear",
            "count": 151,
            "subCategory": [{
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }, {
                "id": "KALAMKARI_COTTON_DUPATTA",
                "name": "Kalamkari Cotton Dupatta",
                "count": 2,
                "subCategory": []
            }, {
                "id": "COTTON_SILK_SAREES",
                "name": "Cotton Silk Sarees",
                "count": 49,
                "subCategory": []
            }, {
                "id": "SILK_SAREES",
                "name": "Silk Sarees",
                "count": 58,
                "subCategory": []
            }, {
                "id": "DHOTIS_MUNDUS_AND_LUNGIS",
                "name": "Dhotis, Mundus & Lungis",
                "count": 18,
                "subCategory": []
            }, {
                "id": "SALWAR_KAMEEZ_FABRIC",
                "name": "Salwar kameez Fabric",
                "count": 10,
                "subCategory": []
            }, {
                "id": "GEORGETTE_SAREES",
                "name": "Georgette Sarees",
                "count": 4,
                "subCategory": []
            }, {
                "id": "COTTON_SAREE",
                "name": "Cotton Saree",
                "count": 5,
                "subCategory": []
            }, {
                "id": "SAREE_BLOUSE_FABRIC",
                "name": "Saree Blouse Fabric",
                "count": 12,
                "subCategory": []
            }, {
                "id": "SALWAR_KAMEEZ",
                "name": "Salwar Kameez",
                "count": 11,
                "subCategory": []
            }, {
                "id": "LINEN_SAREES",
                "name": "Linen Sarees",
                "count": 2,
                "subCategory": []
            }, {
                "id": "SAREE_PETTICOATS",
                "name": "Saree Petticoats",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "KALAMKARI",
            "name": "Kalamkari",
            "count": 27,
            "subCategory": [{
                "id": "FABRIC",
                "name": "Fabric",
                "count": 3,
                "subCategory": []
            }, {
                "id": "SILK_SAREES",
                "name": "Silk Sarees",
                "count": 58,
                "subCategory": []
            }, {
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }, {
                "id": "SAREE_BLOUSE_FABRIC",
                "name": "Saree Blouse Fabric",
                "count": 12,
                "subCategory": []
            }, {
                "id": "SALWAR_KAMEEZ_FABRIC",
                "name": "Salwar kameez Fabric",
                "count": 10,
                "subCategory": []
            }, {
                "id": "COTTON_SILK_SAREES",
                "name": "Cotton Silk Sarees",
                "count": 49,
                "subCategory": []
            }, {
                "id": "SILK_DUPATTAS",
                "name": "Silk Dupattas",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "MANGALAGIRI",
            "name": "Mangalagiri",
            "count": 20,
            "subCategory": [{
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }, {
                "id": "SALWAR_KAMEEZ",
                "name": "Salwar kameez",
                "count": 11,
                "subCategory": []
            }, {
                "id": "COTTON_SILK_SAREES",
                "name": "Cotton Silk Sarees",
                "count": 49,
                "subCategory": []
            }, {
                "id": "SALWAR_KAMEEZ_FABRIC",
                "name": "Salwar Kameez Fabric",
                "count": 10,
                "subCategory": []
            }]
        }, {
            "id": "KOTA",
            "name": "Kota",
            "count": 4,
            "subCategory": [{
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }]
        }, {
            "id": "NIGHTWEAR",
            "name": "Nightwear",
            "count": 4,
            "subCategory": [{
                "id": "NIGHTY",
                "name": "Nighty",
                "count": 4,
                "subCategory": []
            }]
        }, {
            "id": "VENKATAGIRI",
            "name": "Venkatagiri",
            "count": 12,
            "subCategory": [{
                "id": "SILK_SAREES",
                "name": "Silk Sarees",
                "count": 58,
                "subCategory": []
            }, {
                "id": "COTTON_SILK_SAREES",
                "name": "Cotton Silk Sarees",
                "count": 49,
                "subCategory": []
            }, {
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }]
        }, {
            "id": "IKAT",
            "name": "Ikat",
            "count": 3,
            "subCategory": [{
                "id": "SAREE_BLOUSE_FABRIC",
                "name": "Saree Blouse Fabric",
                "count": 12,
                "subCategory": []
            }]
        }, {
            "id": "KANCHIPURAM",
            "name": "Kanchipuram",
            "count": 2,
            "subCategory": [{
                "id": "SILK_SAREES",
                "name": "Silk Sarees",
                "count": 58,
                "subCategory": []
            }, {
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }]
        }, {
            "id": "WESTERN_WEAR",
            "name": "Western Wear",
            "count": 15,
            "subCategory": [{
                "id": "SHIRTS_TOPS_AND_TUNICS",
                "name": "Shirts, Tops & Tunics",
                "count": 5,
                "subCategory": []
            }, {
                "id": "PONCHOS",
                "name": "Ponchos",
                "count": 3,
                "subCategory": []
            }, {
                "id": "SKIRTS",
                "name": "Skirts",
                "count": 6,
                "subCategory": []
            }, {
                "id": "OVERCOATS",
                "name": "Overcoats",
                "count": 5,
                "subCategory": []
            }, {
                "id": "SHRUGS",
                "name": "Shrugs",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "POCHAMPALLY",
            "name": "Pochampally",
            "count": 4,
            "subCategory": [{
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }, {
                "id": "SILK_SAREES",
                "name": "Silk Sarees",
                "count": 58,
                "subCategory": []
            }]
        }, {
            "id": "TIE_AND_DYE",
            "name": "Tie & Dye",
            "count": 1,
            "subCategory": [{
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }]
        }, {
            "id": "UPPADA",
            "name": "Uppada",
            "count": 8,
            "subCategory": [{
                "id": "SILK_SAREES",
                "name": "Silk Sarees",
                "count": 58,
                "subCategory": []
            }, {
                "id": "TISSUE_SAREE",
                "name": "Tissue Saree",
                "count": 1,
                "subCategory": []
            }, {
                "id": "COTTON_SILK_SAREES",
                "name": "Cotton Silk Sarees",
                "count": 49,
                "subCategory": []
            }, {
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }]
        }, {
            "id": "SHIRTS_TOPS_AND_TUNICS",
            "name": "Shirts, Tops & Tunics",
            "count": 3,
            "subCategory": [{
                "id": "SALWAR_KAMEEZ",
                "name": "Salwar Kameez",
                "count": 11,
                "subCategory": []
            }]
        }, {
            "id": "BATIK",
            "name": "Batik",
            "count": 3,
            "subCategory": [{
                "id": "GEORGETTE_SAREES",
                "name": "Georgette Sarees",
                "count": 4,
                "subCategory": []
            }, {
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }, {
                "id": "SILK_SAREES",
                "name": "Silk Sarees",
                "count": 58,
                "subCategory": []
            }]
        }, {
            "id": "MANGALGIRI",
            "name": "Mangalgiri",
            "count": 3,
            "subCategory": [{
                "id": "COTTON_SAREE",
                "name": "Cotton Saree",
                "count": 5,
                "subCategory": []
            }]
        }, {
            "id": "DHARMAVARAM",
            "name": "Dharmavaram",
            "count": 3,
            "subCategory": [{
                "id": "SILK_SAREES",
                "name": "Silk Sarees",
                "count": 58,
                "subCategory": []
            }]
        }, {
            "id": "SEASONAL_WEAR",
            "name": "Seasonal Wear",
            "count": 4,
            "subCategory": [{
                "id": "OVERCOATS",
                "name": "Overcoats",
                "count": 5,
                "subCategory": []
            }, {
                "id": "SKIRTS",
                "name": "Skirts",
                "count": 6,
                "subCategory": []
            }, {
                "id": "FROCKS",
                "name": "Frocks",
                "count": 9,
                "subCategory": []
            }]
        }, {
            "id": "KUPPADAM",
            "name": "Kuppadam",
            "count": 1,
            "subCategory": [{
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }]
        }, {
            "id": "BHAGALPURI",
            "name": "Bhagalpuri",
            "count": 1,
            "subCategory": [{
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }]
        }]
    }, {
        "id": "HOME_AND_KITCHEN",
        "name": "Home & Kitchen",
        "count": 36,
        "subCategory": [{
            "id": "HOME_FURNISHING",
            "name": "Home Furnishing",
            "count": 6,
            "subCategory": [{
                "id": "CUSHION_COVERS",
                "name": "Cushion Covers",
                "count": 2,
                "subCategory": []
            }, {
                "id": "CURTAINS",
                "name": "Curtains",
                "count": 3,
                "subCategory": []
            }, {
                "id": "APPLIANCE_COVERS",
                "name": "Appliance covers",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "BATH_LINEN",
            "name": "Bath Linen",
            "count": 15,
            "subCategory": [{
                "id": "TOWELS",
                "name": "Towels",
                "count": 15,
                "subCategory": []
            }]
        }, {
            "id": "TABLE_LINEN",
            "name": "Table Linen",
            "count": 4,
            "subCategory": [{
                "id": "TABLE_CLOTH",
                "name": "Table Cloth",
                "count": 4,
                "subCategory": []
            }]
        }, {
            "id": "BED_LINEN",
            "name": "Bed Linen",
            "count": 4,
            "subCategory": [{
                "id": "BEDSHEETS",
                "name": "Bedsheets",
                "count": 4,
                "subCategory": []
            }]
        }, {
            "id": "PUJA_ARTICLES",
            "name": "Puja Articles",
            "count": 5,
            "subCategory": [{
                "id": "TURMERIC_AND_KUMKUM_PLATTER",
                "name": "Turmeric & Kumkum Platter",
                "count": 1,
                "subCategory": []
            }]
        }]
    }, {
        "id": "MENS_CLOTHING",
        "name": "Men's Clothing",
        "count": 27,
        "subCategory": [{
            "id": "FABRIC",
            "name": "Fabric",
            "count": 5,
            "subCategory": [{
                "id": "KHADI_SHIRT_FABRIC",
                "name": "Khadi Shirt Fabric",
                "count": 3,
                "subCategory": []
            }, {
                "id": "KHADI_TROUSER_FABRIC",
                "name": "Khadi Trouser Fabric",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "ETHNIC_WEAR",
            "name": "Ethnic Wear",
            "count": 151,
            "subCategory": [{
                "id": "COTTON_SAREES",
                "name": "Cotton Sarees",
                "count": 106,
                "subCategory": []
            }, {
                "id": "KALAMKARI_COTTON_DUPATTA",
                "name": "Kalamkari Cotton Dupatta",
                "count": 2,
                "subCategory": []
            }, {
                "id": "COTTON_SILK_SAREES",
                "name": "Cotton Silk Sarees",
                "count": 49,
                "subCategory": []
            }, {
                "id": "SILK_SAREES",
                "name": "Silk Sarees",
                "count": 58,
                "subCategory": []
            }, {
                "id": "DHOTIS_MUNDUS_AND_LUNGIS",
                "name": "Dhotis, Mundus & Lungis",
                "count": 18,
                "subCategory": []
            }, {
                "id": "SALWAR_KAMEEZ_FABRIC",
                "name": "Salwar kameez Fabric",
                "count": 10,
                "subCategory": []
            }, {
                "id": "GEORGETTE_SAREES",
                "name": "Georgette Sarees",
                "count": 4,
                "subCategory": []
            }, {
                "id": "COTTON_SAREE",
                "name": "Cotton Saree",
                "count": 5,
                "subCategory": []
            }, {
                "id": "SAREE_BLOUSE_FABRIC",
                "name": "Saree Blouse Fabric",
                "count": 12,
                "subCategory": []
            }, {
                "id": "SALWAR_KAMEEZ",
                "name": "Salwar Kameez",
                "count": 11,
                "subCategory": []
            }, {
                "id": "LINEN_SAREES",
                "name": "Linen Sarees",
                "count": 2,
                "subCategory": []
            }, {
                "id": "SAREE_PETTICOATS",
                "name": "Saree Petticoats",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "SHIRTS",
            "name": "Shirts",
            "count": 3,
            "subCategory": [{
                "id": "COTTON",
                "name": "Cotton",
                "count": 2,
                "subCategory": []
            }, {
                "id": "KHADI",
                "name": "Khadi",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "LOUNGEWEAR_AND_NIGHTWEAR",
            "name": "Loungewear & Nightwear",
            "count": 1,
            "subCategory": [{
                "id": "NIGHTWEAR",
                "name": "Nightwear",
                "count": 1,
                "subCategory": []
            }]
        }]
    }, {
        "id": "WOMENS_AND_GIRLS_CLOTHING",
        "name": "Women's & Girls Clothing",
        "count": 12,
        "subCategory": [{
            "id": "WESTERN_WEAR",
            "name": "Western Wear",
            "count": 15,
            "subCategory": [{
                "id": "SHIRTS_TOPS_AND_TUNICS",
                "name": "Shirts, Tops & Tunics",
                "count": 5,
                "subCategory": []
            }, {
                "id": "PONCHOS",
                "name": "Ponchos",
                "count": 3,
                "subCategory": []
            }, {
                "id": "SKIRTS",
                "name": "Skirts",
                "count": 6,
                "subCategory": []
            }, {
                "id": "OVERCOATS",
                "name": "Overcoats",
                "count": 5,
                "subCategory": []
            }, {
                "id": "SHRUGS",
                "name": "Shrugs",
                "count": 1,
                "subCategory": []
            }]
        }]
    }, {
        "id": "CLOTHING_AND_ACCESSORIES",
        "name": "Clothing & Accessories",
        "count": 9,
        "subCategory": [{
            "id": "MENS_ACCESSORIES",
            "name": "Men's Accessories",
            "count": 10,
            "subCategory": [{
                "id": "CAPS_AND_HATS",
                "name": "Caps & Hats",
                "count": 3,
                "subCategory": []
            }, {
                "id": "HANDKERCHIEFS",
                "name": "Handkerchiefs",
                "count": 8,
                "subCategory": []
            }]
        }]
    }, {
        "id": "CHILDRENS_CLOTHING",
        "name": "Children's Clothing",
        "count": 12,
        "subCategory": [{
            "id": "GIRLS_CLOTHING",
            "name": "Girls Clothing",
            "count": 16,
            "subCategory": [{
                "id": "OVERCOATS",
                "name": "Overcoats",
                "count": 5,
                "subCategory": []
            }, {
                "id": "SKIRTS_AND_TOPS",
                "name": "Skirts & Tops",
                "count": 2,
                "subCategory": []
            }, {
                "id": "FROCKS",
                "name": "Frocks",
                "count": 9,
                "subCategory": []
            }, {
                "id": "LANGA_AND_BLOUSE",
                "name": "Langa & Blouse",
                "count": 3,
                "subCategory": []
            }, {
                "id": "SHIRTS_TOPS_AND_TUNICS",
                "name": "Shirts, Tops & Tunics",
                "count": 5,
                "subCategory": []
            }]
        }]
    }, {
        "id": "GIRLS_CLOTHING",
        "name": "Girls Clothing",
        "count": 1,
        "subCategory": [{
            "id": "GIRLS_CLOTHING",
            "name": "Girls Clothing",
            "count": 16,
            "subCategory": [{
                "id": "OVERCOATS",
                "name": "Overcoats",
                "count": 5,
                "subCategory": []
            }, {
                "id": "SKIRTS_AND_TOPS",
                "name": "Skirts & Tops",
                "count": 2,
                "subCategory": []
            }, {
                "id": "FROCKS",
                "name": "Frocks",
                "count": 9,
                "subCategory": []
            }, {
                "id": "LANGA_AND_BLOUSE",
                "name": "Langa & Blouse",
                "count": 3,
                "subCategory": []
            }, {
                "id": "SHIRTS_TOPS_AND_TUNICS",
                "name": "Shirts, Tops & Tunics",
                "count": 5,
                "subCategory": []
            }]
        }]
    }, {
        "id": "INFANTS_CLOTHING",
        "name": "Infants Clothing",
        "count": 5,
        "subCategory": [{
            "id": "GIRLS_CLOTHING",
            "name": "Girls Clothing",
            "count": 16,
            "subCategory": [{
                "id": "OVERCOATS",
                "name": "Overcoats",
                "count": 5,
                "subCategory": []
            }, {
                "id": "SKIRTS_AND_TOPS",
                "name": "Skirts & Tops",
                "count": 2,
                "subCategory": []
            }, {
                "id": "FROCKS",
                "name": "Frocks",
                "count": 9,
                "subCategory": []
            }, {
                "id": "LANGA_AND_BLOUSE",
                "name": "Langa & Blouse",
                "count": 3,
                "subCategory": []
            }, {
                "id": "SHIRTS_TOPS_AND_TUNICS",
                "name": "Shirts, Tops & Tunics",
                "count": 5,
                "subCategory": []
            }]
        }, {
            "id": "SEASONAL_WEAR",
            "name": "Seasonal Wear",
            "count": 4,
            "subCategory": [{
                "id": "OVERCOATS",
                "name": "Overcoats",
                "count": 5,
                "subCategory": []
            }, {
                "id": "SKIRTS",
                "name": "Skirts",
                "count": 6,
                "subCategory": []
            }, {
                "id": "FROCKS",
                "name": "Frocks",
                "count": 9,
                "subCategory": []
            }]
        }]
    }]
}, {
    "id": "HOME_AND_KITCHEN",
    "name": "Home & Kitchen",
    "count": 41,
    "subCategory": [{
        "id": "KITCHEN_AND_DINING_TERRACOTTA",
        "name": "Kitchen & Dining, Terracotta",
        "count": 1,
        "subCategory": [{
            "id": "DRINKWARE",
            "name": "Drinkware",
            "count": 4,
            "subCategory": [{
                "id": "WATER_BOTTLES",
                "name": "Water Bottles",
                "count": 33,
                "subCategory": []
            }, {
                "id": "EARTHEN_TUMBLERS",
                "name": "Earthen Tumblers",
                "count": 1,
                "subCategory": []
            }, {
                "id": "COPPER_TUMBLERS",
                "name": "Copper Tumblers",
                "count": 1,
                "subCategory": []
            }]
        }]
    }, {
        "id": "HOME_AND_DECOR",
        "name": "Home & Decor",
        "count": 82,
        "subCategory": [{
            "id": "PAINTINGS",
            "name": "Paintings",
            "count": 4,
            "subCategory": [{
                "id": "KALAMKARI_PAINTINGS",
                "name": "Kalamkari Paintings",
                "count": 2,
                "subCategory": []
            }, {
                "id": "BATIK_PAINTINGS",
                "name": "Batik Paintings",
                "count": 2,
                "subCategory": []
            }]
        }, {
            "id": "HOME_DECOR_ACCENTS",
            "name": "Home Decor Accents",
            "count": 34,
            "subCategory": [{
                "id": "RELIGIOUS_IDOLS",
                "name": "Religious Idols",
                "count": 675,
                "subCategory": []
            }, {
                "id": "THORAN_DOOR_DECOR",
                "name": "Thoran (Door Decor)",
                "count": 5,
                "subCategory": []
            }, {
                "id": "ARTIFICIAL_FLOWERS",
                "name": "Artificial Flowers",
                "count": 1,
                "subCategory": []
            }, {
                "id": "FLOWER_VASES",
                "name": "Flower Vases",
                "count": 99,
                "subCategory": []
            }, {
                "id": "LAMP_SHADES",
                "name": "Lamp shades",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "HOME_FRAGRANCE",
            "name": "Home Fragrance",
            "count": 21,
            "subCategory": [{
                "id": "INCENSE_STICKS",
                "name": "Incense Sticks",
                "count": 21,
                "subCategory": []
            }]
        }]
    }, {
        "id": "KITCHEN_AND_DINING",
        "name": "Kitchen & Dining",
        "count": 17,
        "subCategory": [{
            "id": "TERRACOTTA",
            "name": "Terracotta",
            "count": 2,
            "subCategory": [{
                "id": "COOKWARE",
                "name": "Cookware",
                "count": 2,
                "subCategory": []
            }]
        }, {
            "id": "DRINKWARE",
            "name": "Drinkware",
            "count": 4,
            "subCategory": [{
                "id": "WATER_BOTTLES",
                "name": "Water Bottles",
                "count": 33,
                "subCategory": []
            }, {
                "id": "EARTHEN_TUMBLERS",
                "name": "Earthen Tumblers",
                "count": 1,
                "subCategory": []
            }, {
                "id": "COPPER_TUMBLERS",
                "name": "Copper Tumblers",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "KITCHEN_TOOLS",
            "name": "Kitchen Tools",
            "count": 5,
            "subCategory": [{
                "id": "WOODEN_PRESS",
                "name": "Wooden Press",
                "count": 2,
                "subCategory": []
            }, {
                "id": "ROLLING_PINS",
                "name": "Rolling Pins",
                "count": 1,
                "subCategory": []
            }, {
                "id": "CHOPPING_BOARD",
                "name": "Chopping Board",
                "count": 1,
                "subCategory": []
            }, {
                "id": "SPATULAS",
                "name": "Spatulas",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "JUGS_AND_PITCHERS",
            "name": "Jugs & Pitchers",
            "count": 27,
            "subCategory": [{
                "id": "TERRACOTTA_JUGS",
                "name": "Terracotta Jugs",
                "count": 24,
                "subCategory": []
            }, {
                "id": "COPPER_JUGS",
                "name": "Copper Jugs",
                "count": 2,
                "subCategory": []
            }]
        }, {
            "id": "COOKWARE",
            "name": "Cookware",
            "count": 2,
            "subCategory": [{
                "id": "TAWA",
                "name": "Tawa",
                "count": 2,
                "subCategory": []
            }]
        }]
    }, {
        "id": "HERBAL_PRODUCTS",
        "name": "Herbal Products",
        "count": 29,
        "subCategory": [{
            "id": "BODY_SKIN_AND_HAIR_CARE",
            "name": "Body, Skin & Hair Care",
            "count": 53,
            "subCategory": [{
                "id": "FOOT_SCRUBBERS",
                "name": "Foot Scrubbers",
                "count": 37,
                "subCategory": []
            }, {
                "id": "BODY_SCRUBBERS",
                "name": "Body Scrubbers",
                "count": 5,
                "subCategory": []
            }, {
                "id": "BATH_POWDER",
                "name": "Bath Powder",
                "count": 2,
                "subCategory": []
            }, {
                "id": "SOAP",
                "name": "Soap",
                "count": 4,
                "subCategory": []
            }, {
                "id": "HAIR_OIL",
                "name": "Hair Oil",
                "count": 2,
                "subCategory": []
            }, {
                "id": "BHRINGRAJ_LEAVES_POWDER",
                "name": "Bhringraj Leaves Powder",
                "count": 1,
                "subCategory": []
            }, {
                "id": "MULTANI_MITTI_FULLERS_EARTH",
                "name": "Multani Mitti (Fullers Earth)",
                "count": 1,
                "subCategory": []
            }]
        }, {
            "id": "ALTERNATIVE_MEDICINE",
            "name": "Alternative Medicine",
            "count": 10,
            "subCategory": [{
                "id": "TRIPHALA_CHURNA",
                "name": "Triphala Churna",
                "count": 1,
                "subCategory": []
            }, {
                "id": "TULSI_CHURNA",
                "name": "Tulsi Churna",
                "count": 1,
                "subCategory": []
            }, {
                "id": "DIABETIC_CHURNA",
                "name": "Diabetic Churna",
                "count": 1,
                "subCategory": []
            }, {
                "id": "ASHWAGANDHA_CHURNA",
                "name": "Ashwagandha Churna",
                "count": 1,
                "subCategory": []
            }, {
                "id": "ATHIMADHURAM_CHURNA",
                "name": "Athimadhuram Churna",
                "count": 1,
                "subCategory": []
            }, {
                "id": "SUNAMUKHI_CHURNA",
                "name": "Sunamukhi Churna",
                "count": 1,
                "subCategory": []
            }, {
                "id": "TAMARIND_SEEDS_POWDER",
                "name": "Tamarind seeds Powder",
                "count": 2,
                "subCategory": []
            }]
        }, {
            "id": "HOME_FRAGRANCE",
            "name": "Home Fragrance",
            "count": 21,
            "subCategory": [{
                "id": "INCENSE_STICKS",
                "name": "Incense Sticks",
                "count": 21,
                "subCategory": []
            }]
        }]
    }]
}, {
    "id": "JEWELLERY",
    "name": "JEWELLERY",
    "count": 108,
    "subCategory": [{
        "id": "WOMENS_JEWELLERY",
        "name": "Womens Jewellery",
        "count": 108,
        "subCategory": [{
            "id": "CHAINS_AND_NECKLACES",
            "name": "Chains & Necklaces",
            "count": 70,
            "subCategory": [{
                "id": "NECKLACES",
                "name": "Necklaces",
                "count": 63,
                "subCategory": []
            }, {
                "id": "CHAINS",
                "name": "Chains",
                "count": 2,
                "subCategory": []
            }]
        }, {
            "id": "BANGLES_AND_BRACELETS",
            "name": "Bangles & Bracelets",
            "count": 18,
            "subCategory": [{
                "id": "BEAD_BRACELETS",
                "name": "Bead Bracelets",
                "count": 12,
                "subCategory": []
            }]
        }, {
            "id": "EARRINGS",
            "name": "Earrings",
            "count": 12,
            "subCategory": [{
                "id": "BEAD_EARRINGS",
                "name": "Bead Earrings",
                "count": 3,
                "subCategory": []
            }, {
                "id": "PEARL_EARRINGS",
                "name": "Pearl Earrings",
                "count": 1,
                "subCategory": []
            }, {
                "id": "EARRINGS_IN_WOOD",
                "name": "Earrings in Wood",
                "count": 5,
                "subCategory": []
            }]
        }]
    }]
}]


//    



// const categoriesMenu = [
//   {
//     id: "HANDICRAFTS",
//     name: "Handicrafts",
//     count: 21,
//     // subCategory: [
//     //   { id: "SWEETS", name: "Sweets", count: 10, subCategory: [] },
//     //   { id: "SNACKS", name: "Snacks", count: 11, subCategory: [] },
//     // ],
//   },
//   {
//     id: "FOOD_PRODUCTS",
//     name: "Food Products",
//     count: 18,
//     // subCategory: [
//     //   { id: "FRUITS", name: "Fruits", count: 13, subCategory: [] },
//     //   { id: "VEGETABLES", name: "Vegetables", count: 5, subCategory: [] },
//     // ],
//   },
//   {
//     id: "SPICES",
//     name: "Spices",
//     count: 68,
//     // subCategory: [
//     //   {
//     //     id: "POWDERED_MASALAS",
//     //     name: "Powder & Masala's",
//     //     count: 53,
//     //     subCategory: [],
//     //   },
//     //   { id: "WHOLE_SPICES", name: "Whole Spices", count: 14, subCategory: [] },
//     // ],
//   },
//   {
//     id: "TEXTILES",
//     name: "Textiles",
//     count: 16,
//     // subCategory: [
//     //   { id: "DRY_FRUITS", name: "Dry Fruits", count: 9, subCategory: [] },
//     //   { id: "EDIBLE_SEEDS", name: "Edible Seeds", count: 7, subCategory: [] },
//     // ],
//   },
//   {
//     id: "HEALTH_AND_PERSONAL_CARE",
//     name: "Health & Personal Care Health",
//     count: 48,
//     // subCategory: [
//     //   { id: "RAVA", name: "Rava/Rawa", count: 11, subCategory: [] },
//     //   { id: "PULSES", name: "Pulses", count: 12, subCategory: [] },
//     //   { id: "CEREALS", name: "Cereals", count: 9, subCategory: [] },
//     //   { id: "MILLETS", name: "Millets", count: 4, subCategory: [] },
//     //   { id: "FLOURS", name: "Flours", count: 4, subCategory: [] },
//     //   { id: "FLAKES", name: "Flakes", count: 8, subCategory: [] },
//     // ],
//   },
//   {
//     id: "JEWELLERY",
//     name: "Jewellery",
//     count: 26,
//     // subCategory: [
//     //   { id: "PICKLES", name: "Pickles", count: 26, subCategory: [] },
//     // ],
//   },
//   {
//     id: "HOME_AND_KITCHEN",
//     name: "Home & Kitchen",
//     count: 40,
//     // subCategory: [
//     //   {
//     //     id: "SOAP_SHAMPOO_AND_BATH_POWDER",
//     //     name: "Soap, Shampoo & Bath Powder",
//     //     count: 25,
//     //     subCategory: [],
//     //   },
//     //   {
//     //     id: "HERBAL_MEDICINE",
//     //     name: "Herbal Medicine",
//     //     count: 10,
//     //     subCategory: [],
//     //   },
//     //   {
//     //     id: "HERBAL_POOJA_ARTICLES",
//     //     name: "Herbal Pooja Articles",
//     //     count: 3,
//     //     subCategory: [],
//     //   },
//     //   { id: "HERBAL_DRINKS", name: "Herbal Drinks", count: 2, subCategory: [] },
//     // ],
//   },
//   {
//     id: "HERBAL_PRODUCTS",
//     name: "Health & Herbal",
//     count: 26,
//     // subCategory: [
//     //   { id: "OILS", name: "Oils", count: 24, subCategory: [] },
//     //   { id: "OIL_SEEDS", name: "Oil Seeds", count: 2, subCategory: [] },
//     // ],
//   },
//   // {
//   //   id: "BEVERAGES",
//   //   name: "Beverages",
//   //   count: 4,
//   //   subCategory: [{ id: "COFFEE", name: "Coffee", count: 4, subCategory: [] }],
//   // },
//   // {
//   //   id: "FOREST_PRODUCTS",
//   //   name: "Forest Products",
//   //   count: 2,
//   //   subCategory: [
//   //     { id: "TAMARIND", name: "Tamarind", count: 1, subCategory: [] },
//   //     { id: "HONEY", name: "Honey", count: 1, subCategory: [] },
//   //   ],
//   // },
// ];

const categoryCardMap = {
    SWEET_SNACKS_AND_SAVORIES: {
        id: "SWEET_SNACKS_AND_SAVORIES",
        name: "Sweets, Snacks & Savories",
        title: "100% Healthy",
        imgUrl: "assets/category-card-bg/biscuits.jpg",
    },
    // FRUITS_AND_VEGETABLES: {
    //     id: "FRUITS_AND_VEGETABLES",
    //     name: "Fruits & Vegetables",
    //     title: "Fruits & Vegetables",
    //     imgUrl: "assets/category-card-bg/others.png",
    //     backgroundColor: "#3bc44f",
    // },
    SPICES: {
        id: "SPICES",
        name: "Spices",
        title: "All spicy flavors available",
        imgUrl: "assets/category-card-bg/spices.jpg",
    },
    DRY_FRUITS_NUTS_AND_SEEDS: {
        id: "DRY_FRUITS_NUTS_AND_SEEDS",
        name: "Dry Fruits, Nuts & Seeds",
        title: "Healthy nuts & seeds",
        imgUrl: "assets/category-card-bg/nuts.jpg",
    },
    CEREALS_PULSES_AND_MILLETS: {
        id: "CEREALS_PULSES_AND_MILLETS",
        name: "Cereals, Pulses, Millets",
        title: "Cereals, Pulses, Millets",
        imgUrl: "assets/category-card-bg/others.png",
        backgroundColor: "#e6ab08",
    },
    PICKLES_AND_PRESERVES: {
        id: "PICKLES_AND_PRESERVES",
        name: "Pickles & Preserves",
        title: "Pickles & Preserves",
        imgUrl: "assets/category-card-bg/others.jpg",
        backgroundColor: "chocolate",
    },
    HEALTH_AND_HERBAL: {
        id: "HEALTH_AND_HERBAL",
        name: "Health & Herbal",
        title: "Pure herbal",
        imgUrl: "assets/category-card-bg/herbal.jpg",
    },
    OIL_AND_OIL_SEEDS: {
        id: "OIL_AND_OIL_SEEDS",
        name: "Oil & Oil Seeds",
        title: "Oil & Oil Seeds",
        imgUrl: "assets/category-card-bg/others.png",
        backgroundColor: "olive",
    },
    BEVERAGES_OTHERS: {
        id: "BEVERAGES",
        name: "Beverages & Others",
        title: "Beverages & Others",
        imgUrl: "assets/category-card-bg/others.jpg",
    },
    HANDICRAFTS: {
        id: "HANDICRAFTS",
        name: "Handicrafts",
        title: "Handicrafts",
        imgUrl: "assets/category-card-bg/others.jpg",
        backgroundColor: "#ffe169",
    },
    TEXTILES: {
        id: "TEXTILES",
        name: "Textiles",
        title: "Textiles",
        imgUrl: "assets/category-card-bg/others.png",
        backgroundColor: "#0000ff66",
    },
    JEWELLERY: {
        id: "JEWELLERY",
        name: "Jewellery",
        title: "Jewellery",
        imgUrl: "assets/category-card-bg/others.png",
        backgroundColor: "olive",
    },
    FOOD_PRODUCTS: {
        id: "FOOD_PRODUCTS",
        name: "Food Products",
        title: "Food Products",
        imgUrl: "assets/category-card-bg/others.png",
        backgroundColor: "#3bc44f",
    },
    JEWELRY: {
        id: "JEWELRY",
        name: "Jewellery",
        title: "Jewellery",
        imgUrl: "assets/category-card-bg/others.png",
        backgroundColor: "#e6ab08",
    },
    HEALTH_AND_PERSONAL_CARE: {
        id: "HEALTH_AND_PERSONAL_CARE",
        name: "Health & Personal Care Health",
        title: "Health & Personal Care Health",
        imgUrl: "assets/category-card-bg/others.jpg",
        backgroundColor: "#ff00008c",
    },
    HOME_AND_KITCHEN: {
        id: "HOME_AND_KITCHEN",
        name: "Home & Kitchen",
        title: "Home & Kitchen",
        imgUrl: "assets/category-card-bg/others.png",
        backgroundColor: "#808080c7",
    },
    TOYS: {
        id: "TOYS",
        name: "Toys",
        title: "Toys",
        imgUrl: "assets/category-card-bg/others.jpg",
    },

    HERBAL_OTHERS: {
        id: "HERBAL_PRODUCTS",
        name: "Herbal & Others",
        title: "Herbal & Others",
        imgUrl: "assets/category-card-bg/others.jpg",
        backgroundColor: "#e6ab08",
    },
};