import { Injector, Input, Directive } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Cart } from "../project-common/models/cart-product.model";
import { CartService } from "../project-common/services/cart.service";
import { UtilityService } from "../project-common/services/utility.service";
import { WishListService } from "../project-common/services/wish-list.service";

@Directive()
export class CartItem {

    @Input('product') product: any;
    @Input('isSavedItem') isSavedItem: any;
    @Input('cart') cart: Cart;

    public saveForLaterFlag: boolean = false;
    public isUserLogedIn: boolean = false;
    public isExpanded: boolean = false;

    public basketItemsCount: number = 0;
    private cartService: CartService;
    private router: Router;
    private wishListService: WishListService;
    private snackbar: MatSnackBar;
    private translator: TranslateService;
    private util: UtilityService

    constructor(
        protected injector: Injector,
    ) {
        this.cartService = injector.get(CartService);
        this.router = injector.get(Router);
        this.wishListService = injector.get(WishListService);
        this.snackbar = injector.get(MatSnackBar);
        this.translator = injector.get(TranslateService);
        this.util = injector.get(UtilityService);




    }

    // constructor(
    //     protected injector: Injector,
    // ) {
    //     this.cartService = injector.get(CartService);
    //     this.notification = injector.get(KL_NOTIFICATION);
    //     this.router = injector.get(Router);
    // }


    saveCustomizationAnswers() {
        this.isExpanded = false;
        this.cartService.setCartData(this.cart);
    }

    openFullView() {
        const product = this.product;
        const url = `/p/cart/${this.util.getEncodedProductName(product.productLevel3Title)}/${product.productLevel2Id}_${product.productLevel3Id}`;
        this.router.navigateByUrl(url);
    }

    addQuantity() {
        if (this.product.isBulkOrder) return;
        if (this.product.productQuantity < 10) {
            this.cartService.increaseQuantity(this.product.productLevel3Id);
        } else {
            this.snackbar.open('Maximum 10 units can be ordered  ', 'OK', {
                duration: 5000, panelClass: ['snakbar-color']
            });
        }

    }



    removeQuantity() {
        if (!this.product.isBulkOrder && this.product.productQuantity > 1) {
            this.cartService.decreaseQuantity(this.product);
        }
    }


    remove() {
        this.cartService.removeProductFromCart(this.product);
    }

    /**
     * This method will remove the product from cart and add to "Save for later" list
     * @param product
     */
    saveForLater(product) {
        /* this.wishListService.addProductIntoSavedList(product);
        this.remove(); */
        this.cartService.moveItemsInSavedList([product.productLevel3Id]);
    }

    /**
     * This method will remove the product from "save for later" list and add to cart
     * @param product
     */
    moveToCart(product) {
        /* const isProductSaved = this.cartService.addProductToCart(product);
        if(isProductSaved && this.cartService.cart && this.cartService.cart.productIds && this.cartService.cart.productIds.length < 6) {
            this.removeProductFromSavedList(product);
        } */
        if (this.cartService.cart.products.length && this.cartService.cart.products[0].isBulkOrder && !product.isBulkOrder) {
            this.translator.get(['Normal products can not be ordered along with bulk products', 'OK']).subscribe(translatedText => {
                this.snackbar.open(translatedText['Normal products can not be ordered along with bulk products'], translatedText['OK'], {
                    duration: 8000, panelClass: ['snakbar-color']
                });
            })
            return;
        }
        this.cartService.moveItemsIncart([product.productLevel3Id]);
    }

    /**
     * This method will move the product to wishlist and remove from "save for later" list
     * @param product
     */
    moveToWishList(product) {
        if (this.saveForLaterFlag) {
            this.removeProductFromSavedList(product);
        } else {
            this.remove();
        }
        this.wishListService.addProductToWishList(product.productLevel2Id, product.productLevel3Id).subscribe();
    }

    /**
     * This method will remove product from "save for later" list
     * @param product
     */
    removeProductFromSavedList(product) {
        this.cartService.removeItemsFromSavedList([product.productLevel3Id]);
    }

    get isCustomisedBefore() {
        if (!this.product.customOrders)
            return;

        let customised = false;
        this.product.customOrders.forEach((q) => {
            if (q.answer) customised = true;
        });

        return customised;
    }
}