import { Injector, Input, Directive } from "@angular/core";
import { environment } from "@app/env";
import { delay, takeUntil, tap } from "rxjs/operators";
import { Storefront } from "../project-common/properties/constants";
import { AppNotifierService } from "../project-common/services/app-notifier.service";
import { CartService } from "../project-common/services/cart.service";
import { DeliveryAddressService } from "../project-common/services/delivery-address.service";
import { UtilityService } from "../project-common/services/utility.service";
import { WishListService } from "../project-common/services/wish-list.service";
import { KalgudiDestroyable } from "@kalgudi/core";
import { STORE_NAME_AND_LOGO } from 'src/app/store-mapping';
import { SideBarService } from "../side-bar.service";
import { BulkOrderService } from "../project-common/services/bulk-order.service";
import { DatePipe } from "@angular/common";
import { combineLatest } from "rxjs";
import { LocalStorage } from "@ngx-pwa/local-storage";

@Directive()
export abstract class ProductCard extends KalgudiDestroyable {

    private disableAddToCart; // set true to disable add to cart in product card

    canShipToLocation: boolean;
    @Input('product') product: any;
    @Input('inside-slider') isInSlider = false;
    @Input('border-btn') borderBtn = false;
    @Input('position') position: number;
    @Input() category: string;

    productPath: string[];
    offerPercent: number;
    isPromotionItem = false;
    avgRating = 0;
    canShowShippingMessage: boolean;
    shippingMessage: string;
    wishedProductList: Array<any> = [];
    userWishList: any;
    // isInWishList = false;

    deliveryDate: Date;

    selectedLanguage: string;
    protected wishListService: WishListService;

    protected cartService: CartService;

    public utility: UtilityService;

    protected notifier: AppNotifierService;

    protected deliveryAddress: DeliveryAddressService;

    private cartSidebar: SideBarService;

    protected bulkOrderService: BulkOrderService;

    private localStorage: LocalStorage;
    storeName: any;

    constructor(
        protected injector: Injector
    ) {
        super();
        this.cartService = injector.get(CartService);

        this.wishListService = injector.get(WishListService);

        this.utility = injector.get(UtilityService);

        this.localStorage = injector.get(LocalStorage);

        this.deliveryAddress = injector.get(DeliveryAddressService);

        this.cartSidebar = injector.get(SideBarService);
        this.bulkOrderService = injector.get(BulkOrderService);

        this.disableAddToCart = this.utility.shouldAddToCartDisable();

        this.localStorage.getItem('userWishList').subscribe(response => {
            if (response) {
                this.userWishList = JSON.parse(response);
                for (var product in this.userWishList) {
                    this.wishedProductList.push(this.userWishList[product]);
                }
            }
        });
    }

    getShippingMessage() {

        /* if (this.product.ships) {
            if (this.product.ships.days) {
                let f_date = this.getDeliveryDateFromProduct();
                return this.shippingMessage = `Delivery by ${f_date}`;
            } else if (this.product.ships.text) {
                return this.shippingMessage = this.product.ships.text;
            }
        } */

        if (Storefront.limitedShippingCategory.includes(this.product.productCategory))
            return this.shippingMessage = 'Ships for free in 2-5 days';

        if (this.product.shipping)
            return this.shippingMessage = 'Ships for free';

        if (!this.product.freeShippingEligible)
            return this.shippingMessage = 'Shipping charges applicable'


        this.shippingMessage = 'Ships free on orders over ₹999';
    }

    /* getDeliveryDateFromProduct() {

        const DAY_TO_BE_ADD = this.product.ships && this.product.ships.days ? this.product.ships.days : 10;

        // console.log(`DAY_TO_BE_ADD ${DAY_TO_BE_ADD}`);
        
        let now = new Date();
        let deliveryDate = now;

        deliveryDate.setDate(now.getDate() + (DAY_TO_BE_ADD));

        var datePipe = new DatePipe('en-IN');
        let f_date = datePipe.transform(deliveryDate, 'MMM d');

        return f_date;

    } */

    get isFruitsOrVegetables() {
        if (!this.product) return false;

        return Storefront.limitedShippingCategory.includes(this.product.productCategory);
    }

    getDeliveryDate() {

        let now = new Date();
        this.deliveryDate = now;

        if (this.product.ships && this.product.ships.days) {

            this.deliveryDate.setDate(now.getDate() + this.product.ships.days);

        }

    }

    protected onf
    _getDeliveryDate() {
        const deliveryDay = 3; // 3 = Wednesday (Products get delivered every wednesday)

        const deliveryDateChangingDay = 1; // 1, 12, 30 = `Monday, 12:30PM` (To change delivery date after this time)
        const deliveryDateChangingHours = 12;
        const deliveryDateChangingMinutes = 30;

        const daysInWeek = 7;
        const now = new Date();
        const today = now.getDay();
        const hours = now.getHours();
        const minutes = now.getMinutes();

        this.deliveryDate = now;

        if (today !== deliveryDateChangingDay) {
            const daysDiff = (deliveryDateChangingDay - today);
            const daysToDeliver = daysDiff < 0
                ? ((daysInWeek + (deliveryDay - deliveryDateChangingDay)) + (daysDiff))
                : (deliveryDay - today);
            return this.deliveryDate.setDate(now.getDate() + daysToDeliver);
        }

        if (today === deliveryDateChangingDay && hours >= deliveryDateChangingHours && minutes > deliveryDateChangingMinutes) {
            this.deliveryDate.setDate(now.getDate() + (daysInWeek + (deliveryDay - deliveryDateChangingDay)));
        } else {
            this.deliveryDate.setDate(now.getDate() + (deliveryDay - deliveryDateChangingDay));
        }
    }


    addToCart(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        const { productId_level3, productId_level2, stores } = this.product;
        this.cartService.addProductToCartById(productId_level2, productId_level3, stores);
        this.cartSidebar.openCartSidebar();


    }

    openBulkOrderForm(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        this.bulkOrderService.sendEnquiryForLevel3Card(this.product);
    }

    private updateShippingAndPricing() {

        combineLatest([
            this.deliveryAddress.selectedPinLocationSubject$,
            this.deliveryAddress.deliveryLocationSelected$
        ])
            .pipe(
                takeUntil(this.destroyed$),
                tap(() => this.updateProductPrice())
            )
            .subscribe(() => {
                this.canShipToLocation =
                    !this.disableAddToCart &&
                    ((JSON.parse(localStorage.getItem('canShipToLocation') || 'true')) || !(Storefront.limitedShippingCategory.includes(this.product.productCategory) || Storefront.limitedShippingProducts.includes(this.product.productLevel3Id)));

                if (!this.product || !this.product.productRules) return;


                const [pin, city, state, country] = this.deliveryAddress.getSelectedLocationTokens();

                const productRules = this.product.productRules;
                const productRuleForLocation = productRules[pin] || productRules[city] || productRules[state] || productRules[country];

                if (!productRuleForLocation) return;

                this.canShipToLocation = this.canShipToLocation && productRuleForLocation.shippingAvailable;
            });
    }

    private updateProductPrice() {

        // console.log(`Rule found ${rule}`);
        const oldPrice = this.product.globalPrice || this.product.pricePerSKU;
        let p = this.product.productRules; // {'500081':200} 
        const updatedPrice = this.utility.getPriceByPin(p, oldPrice);
        if (!this.product.globalPrice) {
            this.product.globalPrice = this.product.pricePerSKU;
            this.product.oldMrp = this.product.mrp;
        }
        this.product.pricePerSKU = updatedPrice;
        try {
            if (this.product.globalPrice != this.product.pricePerSKU) {
                let mrp = updatedPrice;
                mrp *= 1;
                mrp = mrp + (mrp * 0.11);
                mrp = mrp.toFixed().toString();
                this.product.mrp = mrp;
            } else if (this.product.oldMrp) {
                this.product.mrp = this.product.oldMrp;
            }
        } catch (error) {
            // console.log(`Error ${error}`);
        }
    }

    init() {

        this.selectedLanguage = localStorage.lang;

        const productName = this.product.productName_level3;
        this.product.socialDetails = {
            title: productName,
            description: this.product.description,
            url: this.utility.getFullViewLink(this.product, this.position),
            image: environment.baseUrl + this.product.productPicURL_level3,
            by: this.product.sellerName
        }
        this.getDeliveryDate();

        this.updateShippingAndPricing();

        this.productPath = this.utility.getProductFullViewRoutingPath(this.product);
        if(this.product.stores && this.product.stores.length) {
            if (this.product.stores[0].includes('andhra'))
                this.storeName = 'APGREENS';
            else if (this.product.stores[0].includes('ebharathi') || this.product.stores[0].includes('emahila') )
                this.storeName = 'EMAHILA';
            else if (this.product.stores[0].includes('sikkim'))
                this.storeName = 'SIKKIM';
            else 
                this.storeName = this.product.storeName
        } else {
            this.storeName = this.product.storeName
        }
        
        this.offerPercent = 100 - ((100 * this.product.pricePerSKU) / this.product.mrp);

        this.canShowShippingMessage = this.product.shipping !== undefined || this.product.freeShippingEligible !== undefined;
        const { productId_level3 } = this.product;
        const { level3Id } = Storefront.promotionItem;

        this.isPromotionItem = (productId_level3 === level3Id);

        if (this.product.reviewsAndRatingsTo && this.product.reviewsAndRatingsTo.avgRating)
            this.avgRating = this.product.reviewsAndRatingsTo.avgRating;

        this.getShippingMessage();
    }

    /**
     * To add the SKU on wish list
     */
    addToWishList(product) {
        this.wishListService.addProductToWishList(product.productId_level2, product.productId_level3).subscribe(response => {
            if (response.code === 200 || response.code === 201) {
                product.isInWishList = true;
            }
        });
    }

    /**
     * To delete SKU from wish list
     */
    deleteFromWishList(product) {
        let index;
        this.wishedProductList.forEach((element, i) => {
            if(product.productId_level2 === element.productlevel2Id && product.productId_level3 === element.productlevel3Id) {
                index = i;
            }
        });
        this.wishListService.deleteProductFromWishList(product.productId_level3).subscribe(response => {
            if (response.code === 202 || response.code === 201) {
                product.isInWishList = false;
                this.localStorage.setItemSubscribe('userWishList', response.data);
                this.wishedProductList.splice(index,1);
            }
        });
    }

    /**
     * To call wishlist service
     */
    callWishListService(product) {
        if (product.isInWishList) {
            this.deleteFromWishList(product);
        } else {
            this.addToWishList(product);
        }
    }



    get storeLogo() {
        if (!this.product || !this.product.stores) {
            return null;
        }
    
        if (this.product.stores && this.product.stores.length > 0) {
            return STORE_NAME_AND_LOGO[this.product.stores[0]] || null;
        }
    
        if (this.product.storeName === 'MS_NET') {
            return {
                name: 'ms_net',
                logo: 'assets/images/msbbazaar.png'
            };
        }
    
        return null;
    }
}