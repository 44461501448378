import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';
import { HttpWithAuthService } from './http-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class S3UploadService {

  constructor(private http: HttpClient, private httpWithAuth: HttpWithAuthService) { }

  getAttachmentPolicy (streamCategory: string = 'business', contentType: string = 'image/'): Observable<any>  {

    const url = `${environment.baseUrl}/rest/v1/signpolicy?category=${streamCategory}&contentType=${contentType}`;
    return this.httpWithAuth.get(url).pipe(map( response => {
      const data: any = response;
      if (data.code === 200) {
        return JSON.parse(data.data);
      } else {
        throw Error('Unable to get S3 policy for image upload');
      }
    }));
  }

  /**
   *  Use this service to upload the image file to S3 and get S3 Url of the uploaded file.
   *  Internally it call for attachment policy and also it compresses the image file if needed. 
   *
   * @param file Image file object
   * @param fileName Aws file name with location(relative)
   * @param stream Depending on module of kalgudi, we have to send stream Ex: business, profilepic
   */
  uploadImage (file: File, fileName: string, stream?: string) {
    return new Observable<any>(observer => {
      this.getAttachmentPolicy(stream, 'image/').subscribe( response => {
        const s3 = response;
        this.compressImage (file).subscribe( compressedImage => {
          const request = new FormData();
          request.append('key', fileName);
          request.append('AWSAccessKeyId', s3.accesskey);
          request.append('acl', 'public-read');
          request.append('policy', s3.policy);
          request.append('signature', s3.signature);
          request.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
          request.append('file', compressedImage);
          this.http.post(environment.bucketUrl, request).subscribe( s3Response => {
            observer.next(environment.baseUrl + '/' + fileName);
            observer.complete();
          });
        }, error => {
          console.error(error);

          throw new Error('Failed to upload the image');
        });
      }, error => {
        console.error(error);

        throw new Error('Failed to upload the image');
      });

    });
  }

  compressImage (file) {
    return new Observable<any>( observer => {

      const img = document.createElement('img');
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e: any) {
        img.src = e.target.result;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const MAX_WIDTH = 960;
        const MAX_HEIGHT = 960;
        img.onload = function() {
          let width = img.width;
          let height = img.height;
          if (width > 0 || height > 0) {
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            const ctx2 = canvas.getContext('2d');
            ctx2.drawImage(img, 0, 0, width, height);
            const dataurl = canvas.toDataURL('image/jpeg', 0.82);
            let byteString = '';
            if (dataurl.split(',')[0].indexOf('base64') >= 0) {
              try {
                byteString = atob(dataurl.split(',')[1]);
              } catch (e) {
                console.log(e);
                
              }
            } else {
              byteString = unescape(dataurl.split(',')[1]);
            }
            const mimeString = dataurl.split(',')[0].split(':')[1].split(';')[0];
            const ia = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            const tempBlob = new Blob([ia], {type: 'image/jpeg'});
            // tempBlob.name = file.name;
            observer.next(tempBlob);
            observer.complete();
          } else {
            observer.next(file);
            observer.complete();
          }
        };
      };
    });
  }

  getHtmlFromS3 (s3url): Observable<any> {
    return this.http.get(s3url, {responseType: 'text' as 'json'}).pipe(map( response => {
      return response;
    }, error => {
      console.error(error);
      throw new Error('Failed to download html');
    }));
  }

    getJsonDataFromS3 (filePath, sourcePath?: string): Observable<any> {

        const url = sourcePath === 'sellerActivity' ? filePath : `${environment.baseUrl}`+ filePath;
        
        return this.http.get(url).pipe(map( response => {
            return response;
        }, error => {
            console.error(error);
            throw new Error('Failed to download Json');
        }));
    }
}
