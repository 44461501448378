import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { SpinnerService } from './project-common/services/spinner.service';
import { environment } from 'src/environments/environment';

// declare var ga: any;
declare global {
    interface Window { gtag: any; }
}
@Injectable({
    providedIn: 'root'
})
export class StoreErrorHandlerService {

    constructor(private router: Router, private spinner: SpinnerService) { }

    handleError(error) {
        
        this.spinner.changeStatus(false, false);
        let message = '';
        let isFatal = false;
        if (error instanceof HttpErrorResponse) {
            message = 'There was an HTTP error.\n' + error.message + 'Status code:' + (<HttpErrorResponse>error).status;
        } else if (error instanceof TypeError) {
            message = 'There was a Type error.\n' + error.message;
            isFatal = true;
        } else if (error instanceof Error) {
            message = 'There was a general error.\n' + error.message;
        } else {
            message = 'Nobody threw an error but something happened!\n' + error;
        }
        if (environment.production && location.origin.includes(environment.baseUrl)) {
            window.gtag('event', 'exception', {
                'description': this.router.url + ': ' + (error.message || error),
                'exFatal': isFatal
            });
        }
        console.error(error);
    }
}
