<section class="item other-apps-sec">
<div class="all-about cursor-pointer" fxLayoutAlign="start center">
  <span class="title d-flex flex-row align-item-center"> <svg-icon src="assets/aboutus/office-building.svg" [svgStyle]="{'width.px': '15', 'height.px': '15'}"
    [applyCss]="true" class="mr-9 store-products-icon"></svg-icon><span class="mt-2" translate>{{otherAboutList?.title}}</span></span>
</div>
  <div class="apps-sec menu-data">
    <div class="apps-wrapper d-flex p-35 flex-row justify-content-between">
     <div class="d-flex flex-column" fxFlex="100%">
      <div class="align-items-center d-flex heading flex-row">
        <img height="24px" src={{otherAboutList?.storeImageUrl}} alt="Logo">
        <div class="d-flex flex-column">
          <span class="font-18 store-name ml-8" translate>{{otherAboutList?.storeTitle}}</span>
        </div>
        
    </div>
    <p class="mt-10 desc font-13" translate>{{otherAboutList?.storeDescription}} 
      <!-- <a class="readmore" target="_blank" href="https://www.kalgudi.digital/">Read More..</a> -->
    </p>
     </div>
     <!-- <div class="d-flex flex-row justify-content-between right-section"  fxFlex="50%">
      <div class="d-flex flex-column service">
        <span class="heading font-16" translate>Core services</span>
        <ng-container *ngFor="let service of otherAboutList?.coreService">
          <a target="_blank" [href]="service?.url" translate class="font-13">{{service?.service}}</a>
        </ng-container>
      </div>
      <div class="d-flex flex-column whoms">
        <span class="heading font-16" translate>Users</span>
        <ng-container *ngFor="let whoms of otherAboutList?.forWhoms">
          <a target="_blank" [href]="whoms?.url" translate class="font-13">{{whoms?.service}}</a>
        </ng-container>
      </div>
     </div> -->
    </div>
  </div>
</section>