<a class="product-card" [class.in-slider]="isInSlider" [routerLink]="productPath" [queryParams]="{storeName:storeName}"
	[target]="utility.isMobileDevice() ? '_self' : '_blank'" [class.offer]="isPromotionItem">
	<div class="position-absolute card-header" fxLayout>
		<!-- WISHLIST BTN -->
		<div class="special-btn-container wishlist" fxLayout fxLayoutAlign="center center"
            (click)="callWishListService(product);$event.preventDefault();$event.stopPropagation();">
            <i class="fa {{ product?.isInWishList ? 'fa-heart' : 'fa-heart-o'}}" aria-hidden="true"
                [style.color]="product?.isInWishList ? 'indianred' : ''">
			</i>
        </div>

		<!-- SHARE BTN -->
		<div class="special-btn-container share justify-content-center d-flex align-items-center" 
			matTooltip="Forward this product  procured and fulfilled by {{ storeLogo?.name }}"
			(click)="$event.preventDefault(); $event.stopPropagation()" [matMenuTriggerFor]="shareMenu">
			<i class="fa fa-share" aria-hidden="true"></i>
		</div>

		<mat-menu #shareMenu="matMenu" [overlapTrigger]="false">
			<ng-template matMenuContent>
				<app-social-sharing [p-tile]="true" [socialDetails]="product.socialDetails"></app-social-sharing>
			</ng-template>
		</mat-menu>
	</div>

	<div class="d-flex flex-row storelogo-ratings position-absolute">
		<div class="store-img justify-content-center d-flex align-items-center" *ngIf="storeLogo">
			<span class="hover-detail">
				<ng-container *ngIf="storeLogo?.name === 'Andhragreens'">
					<p class="m-0" translate>
					<span translate>Produced in Andhra Pradesh,</span>	
						<br>
					<span translate>fulfilled by Andhragreens</span>	
					</p>
				</ng-container>
				<ng-container *ngIf="storeLogo?.name === 'e-Mahila'">
					<p class="m-0">
						<span translate>Produced by rural women,</span>	 
						<br>
						<span translate>fulfilled by eMahila</span> 
					</p>
				</ng-container>
				<ng-container *ngIf="storeLogo?.name === 'tGreens'">
					<p class="m-0">
					<span translate>Produced in Telangana,</span>	
						<br>
					<span translate>fulfilled by TGreens</span>	
					</p>
				</ng-container>
				<ng-container *ngIf="storeLogo?.name === 'swayamsikkim'">
					<p class="m-0">
					<span translate>Produced in Sikkim,</span>	
						<br>
					<span translate>fulfilled by swayamsikkim</span>	
					</p>
				</ng-container>
				<ng-container *ngIf="storeLogo?.name === 'msb'">
					<p class="m-0">
					<span translate>Produced in MSB,</span>	
						<br>
					<span translate>fulfilled by Msb</span>	
					</p>
				</ng-container>
				<ng-container *ngIf="storeLogo?.name === 'ms_net'">
					<p class="m-0">
					<span translate>Produced in MS_NET,</span>	
						<br>
					<span translate>fulfilled by Ms_net</span>	
					</p>
				</ng-container>
			</span>
			<img src="{{ storeLogo?.logo }}" alt="" width="20" height="20" />
		</div>
		<div class="no-store-img" *ngIf="!storeLogo"></div>
		<ng-container *ngIf="product?.reviewsAndRatingsTo?.isPublished">
			<div class="rating-label justify-content-center d-flex align-items-center flex-row">
				<rating [value]="avgRating"></rating>
			</div>
		</ng-container>
	</div>
	<div class="p-detail d-flex flex-column">
	
		<div class="img-placeholder position-relative justify-content-center d-flex align-items-center flex-column">
						

			<div class="bg-img p-10 justify-content-center d-flex align-items-center"
				[title]="product?.productName_level3">
				<img class="img-responsive" width="100%" height="100%" loading="lazy"
					[src]="product?.productPicURL_level3 || 'assets/icons/No_Image.jpg'| scaleImage: 'md'" alt="" />
			</div>

			<div class="stock-info" *ngIf="
					product?.category === 'Fruits' &&
					product?.baseProductName !== 'Mango' &&
					product?.baseProductName !== 'Banganpalli'
                    " [hidden]="product?.pricePerSKU < 1" fxLayoutAlign="start center" fxLayoutGap="5px">
				<span>Stock</span>
				<img src="assets/apgreen/high-stock.png" alt="" loading="lazy" />
			</div>

			<span class="offer-percent" *ngIf="isPromotionItem && offerPercent">
				{{ offerPercent | number: "1.0-0" }}%
				<span translate>off</span>
			</span>
		</div>

		<div class="all-details d-flex flex-column">
			<div class="product-name d-flex">
				<span>{{ product?.productName_level3 }}</span>
			</div>
			<div class="seller-name d-flex">
				<span title="{{product?.sellerName}}">{{product?.sellerName}}</span>
			</div>
			<div class="price-detail d-flex justify-content-between">
				<span>
					<ng-container *ngIf="product?.pricePerSKU > 1">
						{{ product?.pricePerSKU | currency: "INR":"symbol":"1.0" }}
					</ng-container>
					<ng-container *ngIf="product?.pricePerSKU < 1">
						<span class="price-zero" translate>Coming Soon</span>
					</ng-container>
					<ng-container *ngIf="product?.mrp && product?.mrp > product?.pricePerSKU">
						<span class="mrp ml-5">{{
							product?.mrp | currency: "INR":"symbol":"1.0"
						}}</span>
					</ng-container>
				</span>

				<span class="p-tile-tag" *ngIf="product?.isBasket && product?.basketItems?.length">
					{{ product?.basketItems?.length }} Items
				</span>
				<!-- <span class="d-flex different-logo flex-row ml-4"> -->
					<!-- <span class="icon-delivery custom-product-icons mr-2 mt-1" matTooltip="{{shippingMessage }}"></span> -->
					<!-- <svg-icon src="assets/icons/delivery.svg" matTooltip="{{'Ships free over ₹999.00' | translate }}" [svgStyle]="{'width.px': '18', 'height.px': '15'}"
        [applyCss]="true" class="mr-2 store-products-icon"></svg-icon> -->
					<!-- <ng-container *ngIf="storeLogo?.name != 'e-Mahila'"> -->
						<!-- <svg-icon src="assets/icons/man.svg" matTooltip="{{'Direct from farmers' | translate}}" [svgStyle]="{'width.px': '20', 'height.px': '15'}"
        [applyCss]="true" class="mr-2 store-products-icon"></svg-icon> -->
		<!-- <img src="assets/icons/farmer.svg" matTooltip="{{'Direct from farmers' | translate}}" width="18" height="19" class="ml-2 mr-2"> -->
				<!-- <span class="icon-farmer custom-product-icons mr-2" matTooltip="{{'Direct from farmers' | translate}}"></span> -->
						<!-- <i class="fa fa-male" title="Farmer"></i> -->
					<!-- </ng-container> -->
					<!-- <ng-container *ngIf="storeLogo?.name === 'e-Mahila'"> -->
						<!-- <svg-icon src="assets/icons/women.svg" matTooltip="{{ 'Women empowered' | translate}}" [svgStyle]="{'width.px': '20', 'height.px': '15'}" -->
        <!-- [applyCss]="true" class="mr-2 store-products-icon"></svg-icon> -->
					<!-- <i  aria-hidden="true" class="badge fa fa-female" title="Women empowered"></i> -->
					<!-- </ng-container> -->

					<!-- <span class="icon-pin custom-product-icons mr-2 mt-1" matTooltip="{{ 'Traceability available' | translate}}"></span> -->
					<!-- <svg-icon src="assets/icons/pin.svg" matTooltip="{{ 'Traceability available' | translate}}" [svgStyle]="{'width.px': '20', 'height.px': '15'}"
					[applyCss]="true" class="mr-2 store-products-icon"></svg-icon> -->
					<!-- <i aria-hidden="true" class="badge fa fa-map-marker" title="Traceability available"></i> -->
				<!-- </span> -->
			</div>

			<div class="d-flex sku-list justify-content-between">
				<div class="skus-list d-flex flex-wrap align-content-center">
					<ng-container *ngFor="let smartElement of product?.selectedSKU | slice: 0:1">
						<span class="sku" *ngIf="smartElement !== 'dimensions'">
							{{ (product?.SKUSmartElements)[smartElement].value }}
							{{ (product?.SKUSmartElements)[smartElement].unit }}
						</span>
						<span class="sku" *ngIf="smartElement === 'dimensions'">
							<span *ngFor="let element of smartElementsObject; let last = last">									
									{{element.value}}
									{{element.unit}}
									<span *ngIf="!last">,&nbsp;</span>
							</span>
						</span>
					</ng-container>
				</div>	

				<span *ngIf="isPromotionItem" class="location"
					matTooltip="Only in Vijayawada, Guntur, Hyderabad & Bengaluru"><i class="fa fa-map-marker mr-5"></i>
					<span>Only in <b>Vijayawada, Guntur, Hyderabad & Bengaluru</b></span>
				</span>

				<ng-container *ngIf="product?.pricePerSKU >1 && canShipToLocation && product?.stores[0] !== 'https://andhragreens.com'">
					<button class="addToCart justify-content-center d-flex align-items-center" translate
					(click)="addToCart($event)">
					<i class="fa fa-shopping-bag font-12 mr-5" *ngIf="!(product?.pricePerSKU < 1)"
						aria-hidden="true"></i>
						<span translate>Add</span>
				</button>
				</ng-container>

				<ng-container *ngIf="product?.pricePerSKU < 1 ||  !canShipToLocation">
				<a  class="addToCart bulk-enquiry justify-content-center d-flex align-items-center" translate
				(click)="openBulkOrderForm($event)">
						<span translate>Enquire</span>
				</a>
			</ng-container>
			
			</div>
		</div>
		
		<!-- Not showing shipping message, hence, *ngIf = false -->
		<div class="shipping-cost-message" *ngIf="canShowShippingMessage && false">

            <span class="message d-flex">

                <i class="fa fa-truck mr-5" aria-hidden="true"></i>

                <div>
                    <span *ngIf="!product?.ships && product?.shipping">
                        <ng-container *ngIf="selectedLanguage !=='te'">Ships for <span class="highlight ml-2">free {{
                                isFruitsOrVegetables ? ' in 3-5 days' : ''}}</span>
                        </ng-container>
                        <ng-container *ngIf="selectedLanguage ==='te'">డెలివరీ <span class="highlight">ఉచితం</span>
                        </ng-container>
                    </span>

                    <span *ngIf="product?.ships">

                        <ng-container *ngIf="product?.ships?.days || product?.ships?.days === 0">
                            <span>Ships by </span>
                            <span class="highlight">{{ deliveryDate | date: "d MMM yyy" }} </span>
                        </ng-container>
                  
                        <ng-container *ngIf="product?.ships?.text && !product?.ships?.days">
                            <span>{{product?.ships?.text}} </span>
                        </ng-container>
                  
                    </span>


                    <ng-container *ngIf="!product?.ships && !product?.shipping && product?.freeShippingEligible">

                        <ng-container *ngIf="selectedLanguage !=='te'">
                            Ships <span class="highlight">free</span> on orders over <span class="highlight">₹999</span>
                        </ng-container>

                    </ng-container>

                    <span *ngIf="!product?.ships && !product?.shipping && !product?.freeShippingEligible">
                        <ng-container *ngIf="selectedLanguage !=='te'">Shipping charges</ng-container>
                        <ng-container *ngIf="selectedLanguage ==='te'">డెలివరీ ఛార్జీలు </ng-container>
                        <span translate> applicable</span>
                    </span>
                </div>
            </span>


        </div>

		<!-- <div class="shipping-cost-message" *ngIf="canShowShippingMessage">
			<shipping-charge-message [product]="product"></shipping-charge-message>

			<span class="message" fxLayout="row">
				<i class="fa fa-truck mr-5" aria-hidden="true"></i>

				<div>
					<span *ngIf="product?.shipping">
						<ng-container *ngIf="selectedLanguage !== 'te'"
							>Ships<span class="highlight ml-2">free</span> on
							<span class="highlight">{{ deliveryDate | date: "MMM d" }}</span>
						</ng-container>
						<ng-container *ngIf="selectedLanguage === 'te'"
							>డెలివరీ <span class="highlight">ఉచితం</span>
						</ng-container>
					</span>

					<ng-container
						*ngIf="!product?.shipping && product?.freeShippingEligible"
					>
						<ng-container *ngIf="product?.shippingCostPerUnit">
							<ng-container *ngIf="selectedLanguage !== 'te'"
								>Shipping charges
								<span class="highlight">{{
									product?.shippingCostPerUnit
										| currency: "INR":"symbol":"1.2-2"
								}}</span>
							</ng-container>
							<ng-container *ngIf="selectedLanguage === 'te'"
								>డెలివరీ ఛార్జీలు
								<span class="highlight">{{
									product?.shippingCostPerUnit
										| currency: "INR":"symbol":"1.2-2"
								}}</span>
							</ng-container>

							<span class="px-5">|</span>
						</ng-container>
					</ng-container>

					<span *ngIf="!product?.shipping && !product?.freeShippingEligible">
						<ng-container *ngIf="selectedLanguage !== 'te'"
							>Ships on
							<span class="highlight">{{ deliveryDate | date: "MMM d" }}</span
							>, charges
						</ng-container>
						<ng-container *ngIf="selectedLanguage === 'te'"
							>డెలివరీ ఛార్జీలు
						</ng-container>
						<ng-container *ngIf="product?.shippingCostPerUnit; else noCharges">
							<span class="highlight">
								{{
									product?.shippingCostPerUnit
										| currency: "INR":"symbol":"1.2-2"
								}}</span
							>
						</ng-container>

						<ng-template #noCharges>
							<span translate> applicable</span>
						</ng-template>
					</span>
				</div>
			</span>
		</div> -->
	</div>
</a>
