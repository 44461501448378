import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppNotifierService {


  notifications$: Observable<AppNotify[]>;
  private notificationsSubject = new BehaviorSubject<AppNotify[]>([]);

  constructor() { 
    this.notifications$ = this.notificationsSubject.asObservable();
  }


  pushNotification(notification: AppNotify) {
    const { value: notifications } = this.notificationsSubject;

    notifications.unshift(notification);

    this.sendNotifications(notifications);

    this.onNotificationPush(notification);
  }


  private onNotificationPush(notification: AppNotify) {
    setTimeout(() => {
      
      const { value: notifications } = this.notificationsSubject;

      notifications.splice(notifications.length - 1 , 1);

      this.sendNotifications(notifications);

    }, 5000);
  }

  private sendNotifications(notifications: AppNotify[]) {
    this.notificationsSubject.next(notifications);
  }
}

export interface AppNotify {
  title: string;
  message: string;
  imageUrl: string;
  linkTitle: string;
  linkRoute: any[];
  id?: string;
}
