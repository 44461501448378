import { Component, OnInit } from '@angular/core';
import { CustomeNotification } from '../../models/storeNotification.model';
import { NotificationService } from '../../services/notification.service';
import { Router } from '@angular/router';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'custom-notification-popup',
  templateUrl: './custom-notification-popup.component.html',
  styleUrls: ['./custom-notification-popup.component.scss']
})
export class CustomNotificationPopupComponent implements OnInit {

  notifications: CustomeNotification[] = [];

  defaultNotificationImage = 'https://kalgudi.com/assets/images/kbl.png';
  isMobileDevice: boolean;


  constructor(
    private notificationService: NotificationService,
    private router: Router,
    public utilService: UtilityService
  ) {

    this.notificationService.notificationActive.subscribe(
      (res: CustomeNotification) => {

        if (this.router.url !== res.deepLinkUrl) {
            this.updateNotification(res);
        } else {
            this.notificationService.liveChatNotification(true);
        }
        
      },error =>{
        console.error(error);
      }
    );

  }

  ngOnInit() {
      this.isMobileDevice = this.utilService.isMobileDevice();
  }

  /**
   * This method will update the notifications array
   * If similar notification exist in the array then 
   * override and increace the count
   * 
   * @param { CustomeNotification } newNotification 
   */
  updateNotification ( newNotification ) {
    newNotification.TS = new Date().toISOString();
    newNotification.isActive = true;
    let isExistingNotification = false;
    newNotification.count = 1

    // override existing one by increasing count
    this.notifications.forEach((n,i) => {
      if ( newNotification.deepLinkUrl === n.deepLinkUrl ) {
        newNotification.count +=  n.count;
        this.notifications[i] = newNotification;
        isExistingNotification = true;
      }
    });

    // New notification
    if ( !isExistingNotification ) {
      this.notifications.push(newNotification);
    }

    /* setTimeout(() => {
        let index = this.notifications.length -  1;
        this.removeNotification(index);
      }, 2000); */

  }

  openNotification(notification,index): void {
    notification.isActive = false;
    this.router.navigateByUrl(notification.deepLinkUrl);
    this.removeNotification(index);
  }

  removeNotification (index) {
    if ( this.notifications.length ) this.notifications.splice(index,1); 
  } 

}
