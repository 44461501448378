import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ProfileService } from 'src/app/profile/profile.service';
import { ThirdPartyLibraries } from 'src/app/project-common/properties/constants';
import { ScriptLoaderService } from 'src/app/project-common/services/script-loader.service';
import { CustomErrorStateMatcher, UtilityService } from 'src/app/project-common/services/utility.service';

import { MangoService } from '../../mango.service';
import { STATE_LIST, TIME_LINE } from '../../models/mango.model';
import { ExistingAddressDialogComponent } from '../existing-address-dialog/existing-address-dialog.component';
import {
  ExistingAddressMobileDialogComponent,
} from '../existing-address-mobile-dialog/existing-address-mobile-dialog.component';

@Component({
  selector: 'pre-booking-form',
  templateUrl: './pre-booking-form.component.html',
  styleUrls: ['./pre-booking-form.component.scss']
})
export class PreBookingFormComponent implements OnInit {

  @Output() onVerifyOtp = new EventEmitter();

  @Output() onExistingAddressSelect = new EventEmitter();

  @Output() isExisting = new EventEmitter();

  favoriteAddress: FormControl = new FormControl('NEW');

  /** Form group for main form */
  preBookForm: FormGroup;
  
  /** Request Id to verify the OTP */
  requestId: string;

  /** From Control for OTP */
  otp: FormControl = new FormControl('',Validators.required);

  isOtpRequested = false;

  readonly  expectedTimeLine = TIME_LINE;

  readonly stateList = STATE_LIST;

  isExistingAddress = false;

  isLoggedIn: boolean;

  address: FormGroup;

  selectedAddress: any;

  userData: any;

  listOfAddress = [];

  errorMatcher: ErrorStateMatcher = new CustomErrorStateMatcher();

  constructor(
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,
    private service: MangoService,
    private loader: ScriptLoaderService,
    private dialogsService: KalgudiDialogsService,
    private utility: UtilityService,
    private profileService: ProfileService,
    private storage: LocalStorage,
    ) { 
      this.loader.loadStyle(ThirdPartyLibraries.jostFont).subscribe();
    }

  ngOnInit() {

    this.isLoggedIn = JSON.parse(window.localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || 'false');

    this.getUserDataFromLocalStorage().subscribe(userDataOnStorage => {
      this.userData = userDataOnStorage;
      this.prefillForm();
      this.getAddressList();
    });

    this.address = new FormGroup({
      postalCode: new FormControl('',[Validators.required,Validators.minLength(6),
        Validators.maxLength(6),Validators.pattern(/^[1-9][0-9]{5}$/)]),
      addressLine1: new FormControl('',Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('',Validators.required)
    })

    /** init form */
    this.preBookForm = this.service.getPreBookingForm();

    this.newAddress();

    this.subscribeToOtpFormChanges();

    this.subscribeTofavoriteAddressValueChanges();
  }

  subscribeTofavoriteAddressValueChanges() {
    this.favoriteAddress.valueChanges.subscribe(res => {
      if(res === 'NEW') {
        this.newAddress();
      }else if(res === 'OLD') {
        this.isExistingAddress = true;
      }
    })
  }

  prefillForm() {
      if(this.isLoggedIn) {
        this.preBookForm.get('userDetails').patchValue({
          firstName: this.userData.firstName,
          email: this.utility.getEmailId(this.userData),
          mobile: this.utility.getUserMobileNumber(this.userData),
          profileKey: this.userData.profileKey
      })
    }
  }

  public getUserDataFromLocalStorage() {
    return this.storage.getItem('userdata');
  }

  selectExistingAddress() {
    this.isExistingAddress = true;
    this.isExisting.emit(this.isExistingAddress);
    this.preBookForm.removeControl('address');
    this.preBookForm.updateValueAndValidity();
    this.addressInfoDialog().subscribe(result => {
      if(!result) return;

      this.selectedAddress = result.data;
      // console.log(this.selectedAddress);
    });
  }

  getAddressList(profileKey?: string) {
    this.notification.showSpinner();
    this.profileService.getSavedAddresses(profileKey || this.userData.profileKey)
    .pipe(
      finalize(() => this.notification.hideSpinner())
    )
    .subscribe(response => {
      this.notification.hideSpinner()
        if (response.code === 200) {
            this.listOfAddress = JSON.parse(response.data);
        }
    }, error => {
        //this.notification.showMessage(error.error.info);
        this.notification.showSweetAlert({
          title: error.error.info,
          icon: 'warning',
        });
    });
  }

  newAddress() {
    this.isExistingAddress = false;
    // console.log(this.address);
    
    this.preBookForm.addControl('address', this.address);
    this.preBookForm.updateValueAndValidity();
    this.isExisting.emit(this.isExistingAddress);
  }

  /** To save the pre booking details and generate OTP for verification */
  getPreBookingOTP() {
    this.isOtpRequested = true;
    let payload = this.preBookForm.value;
    if(this.preBookForm.value.deliveryExpectedDate) {
      let timelineObj = this.expectedTimeLine.find(r => r.value === this.preBookForm.value.deliveryExpectedDate);
      payload['expectedDateString']=timelineObj.id || '';
    }
    this.service.postPreBooking(payload)
    .pipe(
      finalize(() => this.isOtpRequested = false)
    )
    .subscribe((res: any) =>{
      if(res.data) {
        this.requestId = res.data.requestId;
        this.preBookForm.disable();
        // console.log(res.data);
      }
    },
    error => {
      //this.notification.showMessage(error.error.info);
      this.notification.showSweetAlert({
        title: error.error.info,
        icon: 'warning',
      });
    });
  }

  submitWithExistingAddress() {
    this.isOtpRequested = true;
    let payload = this.preBookForm.value;
    if(this.preBookForm.value.deliveryExpectedDate) {
      let timelineObj = this.expectedTimeLine.find(r => r.value === this.preBookForm.value.deliveryExpectedDate);
      payload['expectedDateString']=timelineObj.id || '';
    }
    payload['address'] = this.selectedAddress;
    
    this.service.postPreBooking(payload)
    .pipe(
      finalize(() => this.isOtpRequested = false)
    )
    .subscribe((res: any) =>{
      if(res.data) {
        let isVerified = res.data.isVerified;
        let preBookInfo = {
          isVerified,
          ...payload
        }
        if(isVerified) this.onExistingAddressSelect.emit(preBookInfo);
      }
    },
    error => {
      //this.notification.showMessage(error.error.info);
      this.notification.showSweetAlert({
        title: error.error.info,
        icon: 'warning',
      });
    });
  }

  subscribeToOtpFormChanges() {
    this.otp.valueChanges.subscribe(value => {
      if(this.requestId) {
        let obj = {
          requestId: this.requestId,
          otp: this.otp.value
        }
        this.onVerifyOtp.emit(obj);
      }
    })
  }

  verifyWeek(option) {

    let currentDate = new Date();
  
    return (currentDate > option) ? true : false;
    
  }

  private addressInfoDialog(): Observable<KalgudiDialogResult> {
    const dialogConfig: KalgudiDialogConfig = {
      title: 'Select address',
      acceptButtonTitle: 'Confirm',
      rejectButtonTitle: 'Cancel',
      multiSelect: false,
      data: {
        listOfAddress: this.listOfAddress,
        selectedAddress: this.selectedAddress
      }
    };
    if (this.utility.isMobileDevice()) {
      return this.openMobileDialog(dialogConfig)
    } else {
      return this.openWebDialog(dialogConfig);
    }
  }

  private openMobileDialog(
    details: MobileDialogConfig,
    config?: MobileDialogConfig,
  ) {
    return this.dialogsService.openMobileDialog(ExistingAddressMobileDialogComponent, details);
  }

  private openWebDialog(
    details: MobileDialogConfig,
  ): Observable<KalgudiDialogResult> {
    const matDialogConfig: MatDialogConfig<MobileDialogConfig> = {
      width: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };
    return this.dialogsService.openDialog(ExistingAddressDialogComponent, details, matDialogConfig);
  }


}
