<div fxLayout="column" fxLayoutGap="40px" fxLayoutGap.xs="20px">
	<div class="cart-sec" fxLayout fxLayout.xs="column">
		<div class="cart-items-container" fxFlex="calc(100% - 320px)">
			<!-- Gift box -->
			<div>
				<ng-container *ngIf="!giftMessage">
					<mat-checkbox [(ngModel)]="isGift" (change)="openGiftMessageDialog()">
						<div class="cursor-pointer" fxLayoutAlign="start center">
							<mat-icon class="text-success mr-2">redeem</mat-icon>
							<span class="text-success fw-600">Gift message</span>
						</div>
					</mat-checkbox>
				</ng-container>

				<ng-container *ngIf="giftMessage">
					<div fxLayout="row" fxLayoutAlign="start center" class="mb-1">
						<mat-checkbox class="mb-n2 mr-6" [(ngModel)]="isGift" (change)="unCheckGift()"></mat-checkbox>
						<div class="cursor-pointer" fxLayoutAlign="start center" (click)="openGiftMessageDialog()">
							<mat-icon class="text-success mr-1">redeem</mat-icon>
							<span class="text-success fw-600">Gift message</span>
						</div>
					</div>
				</ng-container>
			</div>
			<!-- /Gift box -->
			
			<div class="items-holder">
				<ng-container *ngFor="let store of storeSet | keyvalue">
					<div fxLayout="row" class="bg-light pb-10 rounded" fxLayoutGap="5px">
						<img [src]="storeLabelsMap[store.key]?.logo" width="20" height="20">
						<h5 class="font-13 fw-700 pt-3">
						<span translate>{{storeLabelsMap[store.key]?.name || (store?.key | capitalize)}}</span>	
						<span class="ml-5" translate>products</span>	
						</h5>
					</div>
					<ng-container
						*ngIf="store.value?.itemsWithShippingOffers as products"
					>
						<ng-container *ngIf="products?.length">
							<!-- <checkout-title class="d-block mb-10">
								{{ "Items eligible for free shipping" | translate }}
							</checkout-title>
							<delivery-discount
                                [storeName]="store?.key"
								[chargesAndOtherDetails]="chargesAndOtherDetails"
							></delivery-discount> -->
							<item
								[product]="product"
								[cart]="cart"
								*ngFor="let product of products"
							></item>
						</ng-container>
					</ng-container>

					<ng-container
						*ngIf="store.value?.itemsWithShippingChargesIncluded as products"
					>
						<ng-container *ngIf="products?.length">
							<!-- <checkout-title class="d-block mb-10">
								{{ "Items ship for free" | translate }}
							</checkout-title> -->
							<item
								[product]="product"
								[cart]="cart"
								*ngFor="let product of products"
							></item>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="store.value?.itemsWithNoOffers as products">
						<ng-container *ngIf="products?.length">
							<checkout-title class="d-block mb-10">
								{{ "Items with shipping charges applicable" | translate }}
							</checkout-title>
							<item
								[product]="product"
								[cart]="cart"
								*ngFor="let product of products"
							></item>
						</ng-container>
					</ng-container>
				</ng-container>

				<!-- EMPTY CART STATE -->
				<div
					@fade
					class="empty-cart"
					*ngIf="hasLoadingCompleted && !cart?.products?.length"
				>
					<div class="my-cart-text" fxHide.xs>
						<span translate>MY CART (0)</span>
					</div>
					<div
						class="no-cart-msg"
						fxLayout="column"
						fxLayoutAlign="center center"
					>
						<mat-icon class="cart-icon">remove_shopping_cart</mat-icon>
						<p translate>Your cart is currently empty</p>
						<div class="continue-shopping-btn" routerLink="/home">
							<span translate>Continue shopping</span>
							<span>⟶</span>
						</div>
					</div>
				</div>

				<!-- <ng-container *ngIf="isUserLogedIn && savedProducts?.products && savedProducts?.products?.length">
                    <saved-item [isOnCart]="true" fxShow fxHide.xs></saved-item>
                </ng-container> -->
			</div>

			<!-- <div class="show-saved-items-msg" fxShow.xs fxHide fxLayoutAlign="space-between" *ngIf="savedProducts?.products?.length && cart?.products.length">
                <div fxLayout fxLayoutAlign="center center" fxLayoutGap="10px">
                    <mat-icon>archive</mat-icon>
                    <span>You have {{savedProducts?.products?.length}} <span i18n>{savedProducts?.products?.length, plural, =1 {product} other {products}}</span> saved for later</span>
                </div>
                <a (click)="moveToSavedItems()" style="text-decoration:none;">
                    Show
                </a>
            </div> -->
		</div>

		<div
			class="summary-sec"
			fxFlex="320px"
			fxFlex.xs="none"
			fxFlexAlign="start"
			fxFlexAlign.xs="stretch"
			*ngIf="cart?.products?.length"
		>
			<div fxLayout="column">
				<button
					fxHide.xs
					routerLink="/checkout/shipping"
					class="place-order app-row"
					translate
				>
				<span translate>continue</span>	
				</button>

				<summary class="app-row" [charges]="chargesAndOtherDetails"></summary>

				<a routerLink="/home" class="continue-shopping-link app-row">
				<span translate>< BACK TO STORE</span>	
				</a>
				<button
					fxHide.xs
					routerLink="/checkout/shipping"
					class="place-order app-row"
					translate
				>
				<span translate>continue</span>	
			</button>

				<div fxHide fxShow.xs class="mobile-button" id="placeBtnInBottom">
					<button routerLink="/checkout/shipping" class="place-order">
					<span translate>continue</span>	
					</button>
				</div>
			</div>
		</div>
	</div>

	<!-- <ng-container *ngIf="isUserLogedIn && savedProducts?.products && savedProducts?.products?.length">
        <saved-item [isOnCart]="true" id="saved-items" fxShow.xs fxHide></saved-item>
    </ng-container> -->
</div>
