<div class="layout">
    <form #shippingForm="ngForm" (ngSubmit)="saveAddress()">
        <div class="add-new">
            <span *ngIf="!addressForm?.id" translate>Add a new address</span>
            <span *ngIf="addressForm?.id">Edit {{addressForm.name}}'s address</span>
        </div>
        <div fxLayoutGap="10px" fxLayout="column">
            <div class="" fxLayout fxLayoutGap="10px" fxLayoutGap.xs="5px" fxLayout.xs="column">
                <mat-form-field appearance="outline" fxFlex.xs="100%" fxFlex="calc(50% - 10px)">
                    <mat-label translate>Name</mat-label>
                    <input matInput id="name" name="name" [placeholder]="'Name' | translate" autocomplete="name"
                        #name="ngModel" minlength="3" [(ngModel)]="addressForm.name" required>
                    <mat-error *ngIf="name.invalid && (name.touched || name.dirty)">
                        <div *ngIf="name.errors.required" translate>Person name, who will receive the order</div>
                        <div *ngIf="name.errors.minlength" translate>Name should have at least 3 characters</div>
                    </mat-error>
                </mat-form-field>

                <!-- Input field for Phone Number -->
                <mat-form-field appearance="outline" fxFlex.xs="100%" fxFlex="50%">
                    <mat-label translate>Phone number</mat-label>
                    <input matInput name="phone" (keypress)="numberOnly($event)" [(ngModel)]="addressForm.phone"
                        [placeholder]="'Phone number' | translate" autocomplete="tel-national" required #phone="ngModel"
                        minlength="10" maxlength="10">

                    <mat-error *ngIf="phone.invalid && (phone.dirty || phone.touched)">
                        <div *ngIf="phone.errors.required" translate>Mobile number is required for order tracking</div>
                        <div *ngIf="phone.errors.minlength" translate>Invalid mobile number</div>
                        <div *ngIf="phone.errors.maxlength" translate>Invalid mobile number</div>
                        <div *ngIf="phone.errors.pattern" translate>Invalid mobile number</div>
                    </mat-error>

                </mat-form-field>
            </div>

            <div fxLayout>

                <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label translate>Find Your Residence</mat-label>
                    <input [ngModel]="addressForm.residence" matInput name="google-search" autocomplete="off"
                        [placeholder]="'Select your apartment name / house colony / building name to locate you' | translate"
                        type="search" #search required #google="ngModel" (keydown)="check($event)"
                        (change)="check($event)" validLocation>
                    <mat-error *ngIf="google.invalid && (google.dirty || google.touched)">
                        <div *ngIf="google.errors?.required" translate>Please select your residence</div>
                        <div *ngIf="!google.errors?.required && google.errors?.validLocation" translate>Invalid residence location, choose another one</div>
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout>

                <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label translate>House / Flat Number</mat-label>
                    <textarea matInput name="address-line-1"
                        placeholder="{{ 'House # / Door or Flat # / Block #' | translate}}" #addressLine1="ngModel"
                        [(ngModel)]="addressForm.addressLine1" required autocomplete="shipping street-address"
                        [disabled]="!hasSelectedAddress"></textarea>

                    <mat-error *ngIf="addressLine1.invalid && (addressLine1.dirty || addressLine1.touched)">
                        <div *ngIf="addressLine1.errors.required" translate>Please Fill this field</div>
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout fxLayoutGap="10px" fxLayout.xs="column" fxLayoutGap.xs="5px">

                <!-- Input field for Locality -->
                <mat-form-field appearance="outline" fxFlex.xs="100%" fxFlex="calc(50% - 10px)">
                    <mat-label translate>Locality</mat-label>
                    <input matInput [placeholder]="'Locality' | translate" name="address-line-2" #addressLine2="ngModel"
                        [(ngModel)]="addressForm.addressLine2" autocomplete="address-line2" readonly disabled>

                    <mat-error *ngIf="addressLine2.invalid && (addressLine2.dirty || addressLine2.touched)">
                        <div *ngIf="addressLine2.errors.required" translate>Please Fill this field</div>
                    </mat-error>
                </mat-form-field>

                <!-- Input field for city -->
                <mat-form-field appearance="outline" fxFlex.xs="100%" fxFlex="50%">
                    <mat-label translate>City/ Town/ District</mat-label>
                    <input matInput name="city" [placeholder]="'City/ Town/ District' | translate" disabled
                        [(ngModel)]="addressForm.city" #city="ngModel" autocomplete="shipping locality" required
                        readonly>

                    <mat-error *ngIf="city.invalid && (city.dirty || city.touched)">
                        <div *ngIf="city.errors.required" translate>Please mention your city</div>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="" fxLayout fxLayoutGap="10px" fxLayout.xs="column" fxLayoutGap.xs="10px">

                <!-- Input field for District -->
                <mat-form-field appearance="outline" fxFlex.xs="100%" fxFlex="calc(50% - 10px)">
                    <mat-label translate>State</mat-label>
                    <mat-select [(ngModel)]="addressForm.state" name="state" #state="ngModel" required disabled>
                        <mat-option *ngFor="let state of stateList" [value]="state">{{state}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="state.invalid && (state.dirty || state.touched)">
                        <div *ngIf="state.errors.required" translate>Please mention your state</div>
                    </mat-error>
                </mat-form-field>

                <!-- Input field for Pincode -->
                <mat-form-field appearance="outline" fxFlex.xs="100%" fxFlex="50%">
                    <mat-label translate>Pin code</mat-label>
                    <input matInput id="postalCode" (keypress)="numberOnly($event)" name="postalCode"
                        placeholder="{{ 'Pin code' | translate}}" #postalCode="ngModel"
                        [(ngModel)]="addressForm.postalCode" minlength="6" maxlength="6" required disabled readonly>

                    <mat-error *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
                        <div *ngIf="postalCode.errors?.required" translate>Pin code is mandatory</div>
                        <div *ngIf="postalCode.errors?.minlength || postalCode.errors?.maxlength" translate>Invalid pin
                            code
                        </div>
                    </mat-error>
                </mat-form-field>

            </div>

            <div fxLayout fxLayoutGap="0px" fxLayout.xs="column" fxLayoutGap.xs="5px">

                <!-- Input field for Locality -->
                <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label translate>Landmark</mat-label>
                    <input matInput [placeholder]="'Landmark for easy reach out' | translate" name="landmark"
                        #landmark="ngModel" [(ngModel)]="addressForm.landmark" autocomplete="address-line2">
                </mat-form-field>

            </div>

            <div fxLayout fxLayoutGap="15px" fxLayoutAlign="start center">
                <button class="save"
                    [class.disable]="shippingForm.invalid || !hasSelectedAddress || !addressForm?.googleLocation?.zipcode"
                    [disabled]="shippingForm.invalid || !hasSelectedAddress || !addressForm?.googleLocation?.zipcode"
                    translate>Save</button>
                <button type="reset" class="cancel" (click)="resetAddressForm()" translate>Cancel</button>
            </div>
        </div>
    </form>
</div>