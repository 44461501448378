import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { ProfileService } from '../profile/profile.service';
import { CartProduct } from '../project-common/models/cart-product.model';
import { DeliveryAddress } from '../project-common/models/shipping-form.model';
import { CartService } from '../project-common/services/cart.service';
import { DeliveryAddressService } from '../project-common/services/delivery-address.service';
import { GoogleAnalyticsService } from '../project-common/services/google-analytics.service';
import { HttpWithAuthService } from '../project-common/services/http-interceptor.service';
import { SpinnerService } from '../project-common/services/spinner.service';
import { UtilityService } from '../project-common/services/utility.service';
import { DOMAIN_URL_FOR_STORE } from '../store-mapping';
import { PriceUpdateDialogComponent } from './payment/price-update-dialog/price-update-dialog.component';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  @Output() addressEvent = new EventEmitter();
  @Output() shippingCharge = new EventEmitter();
  @Output() cartSummary: EventEmitter<{ totalAmount: number, products: number }> = new EventEmitter<{ totalAmount: number, products: number }>();


  loggedInUser: any;
  constructor(
    private storage: LocalStorage,
    private http: HttpWithAuthService,
    private spinner: SpinnerService,
    private profile: ProfileService,
    private auth: AuthenticationService,
    private gaTrack: GoogleAnalyticsService,
    private cartService: CartService,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private deliveryService: DeliveryAddressService,
    private router: Router,
    private dialog: MatDialog,
    private httpClient: HttpClient,
    private util: UtilityService
  ) {
    // this.storage.getItem('userdata').subscribe(data => {
    //   if (data) {
    //     this.loggedInUser = data;
    //   }
    // });
  }

  private handleAssistance(shippingDetails) {
    if (!JSON.parse(sessionStorage.getItem('assistingTo') || '{}').profileKey || !this.auth.userData)
      return shippingDetails;

    const {
      firstName,
      profileKey,
      profilePicUrl,
      defaultBusinessKey: businessKey
    } = this.auth.userData;


    const emailId = this.util.getEmailId(this.auth.userData);

    const mobileNo = this.util.getUserMobileNumber(this.auth.userData);

    shippingDetails.assistantDetails = {
      firstName,
      profileKey,
      profilePicUrl,
      mobileNo,
      emailId,
      businessKey
    };


    return shippingDetails;
  }

  storeShippingDetails(shippingDetails, screen: string, storeInDb: boolean = true): Observable<any> {
    console.log('here changes reflected ')
    const url = `${environment.baseUrl}/rest/v1/unified/orders/charges?screen=${screen}`;
    this.spinner.changeStatus(true, true);
    

    if ( !this.loggedInUser ) this.loggedInUser = this.auth.userData;
    shippingDetails = this.handleAssistance(shippingDetails);

    if (!shippingDetails) {
      return;
    }

    /* if(JSON.parse(sessionStorage.getItem('assistingTo') || '{}').profileKey) {
      const businessKey = this.loggedInUser.defaultBusinessKey;
      const emailId = this.loggedInUser.emailId;
      let mobileNumber = '';
      if(!emailId) {
        mobileNumber = this.loggedInUser.mobileNo;
      }
      shippingDetails.assistantDetails = {
        firstName: this.loggedInUser.firstName,
        profileKey: this.loggedInUser.profileKey,
        profilePicUrl: this.loggedInUser.profilePicUrl,
        mobileNo: mobileNumber,
        emailId: emailId,
        businessKey: businessKey
      }
      if(shippingDetails.productList.length && shippingDetails.productList[0].teamInvolved) {
        shippingDetails.teamInvolved = shippingDetails.productList[0].teamInvolved;
        shippingDetails.arfqTemplate = shippingDetails.productList[0].arfqTemplate;
      }
    } */
    /* shippingDetails.productList[0].handlingDetails = {
      cost: '20'
    }; */

    let itemsCount = 0;

    if(shippingDetails.productList) {

      if (shippingDetails.productList.length && shippingDetails.productList[0].teamInvolved) {
  
        shippingDetails.teamInvolved = shippingDetails.productList[0].teamInvolved;
  
        shippingDetails.arfqTemplate = shippingDetails.productList[0].arfqTemplate;
  
      }
  
  
      this.spinner.changeStatus(true, true);
      
      shippingDetails.domainUrl = environment.production ? location.origin : `https://devkalgudi.vasudhaika.net/marketplace`;
  
      const gift = this.cartService.getGiftData();
  
      if (gift) {
        shippingDetails.isGift = gift.isGift;
        shippingDetails.giftMessage = gift.giftMessage;
      }
  
      itemsCount = shippingDetails.productList.length;
  
      let pricingPincode = this.deliveryService.getSelectedPin();
      
      if(shippingDetails.buyerAddress && shippingDetails.buyerAddress.postalCode) {
        pricingPincode = shippingDetails.buyerAddress.postalCode
      }
  
      shippingDetails.pricingPincode =  pricingPincode;  
      
      // Grouping products based on their origin and providing domain URL for the store
      const storeMap = shippingDetails.productList.reduce((acc, curr) => {
        let store = acc[curr.originStore];
        if (store) {
          store.productList.push(curr);
        } else {
          acc[curr.originStore] = JSON.parse(JSON.stringify(shippingDetails));
          acc[curr.originStore].productList = [curr];
          acc[curr.originStore].domainUrl = DOMAIN_URL_FOR_STORE[curr.originStore][environment.production ? 'prod' : 'dev'];
          acc[curr.originStore].pricingPincode = pricingPincode;
        }
        return acc;
      }, {});
      shippingDetails.storeMap = JSON.parse(JSON.stringify(storeMap));
  
      delete shippingDetails.productList;
    }


    return this.http.post(url, shippingDetails).pipe(map(response => {
      const data: any = response;
      if (shippingDetails.couponCode) { // && (data.code === 200 || data.code === 406)
        const couponInfo = JSON.parse(data.data).couponInfo;
        if(couponInfo) 
          this.translate.get(couponInfo).subscribe((res: string) => {
            this.snackbar.open(res, 'OK', { duration: 5000, panelClass: ['snakbar-color'] });
          });
      }

      if (data.code === 201 || data.code === 200) {
        this.spinner.changeStatus(false, false);
        const validationResult = JSON.parse(data.data);

        this.handleInvalidAndUpdatedProducts(validationResult);

        // try {
        //   // let map = JSON.parse(data.data);
        //   let storeMap = validationResult.storeMap;
        //   let isPriceUpdated = false;
        //   Object.keys(storeMap).forEach(store=>{
        //     // console.log(`store ${store}`);
            
        //     storeMap[store].productList.forEach(product => {
        //       console.log(`product ${product.isPriceUpdate}`);
        //       if(product.isPriceUpdate && product.updatedPrice) {
        //         product.pricePerUnit = product.updatedPrice + "";
        //         isPriceUpdated = true;
        //       }
        //       // console.log(product.pricePerUnit);
        //     });
        //   });

        //   if (isPriceUpdated) {
        //     this.snackbar.open(`Prices for product/s have been updated!`, 'OK', {
        //       duration: 7000
        //     });
        //   }
        // } catch (error) {
        //   console.log(`Error while updating price ${error}`);
        // }



        if (storeInDb) this.storage.setItemSubscribe('shippingDetails', validationResult);
        const charges = {
          shippingCharge: validationResult.totalShippingCost,
          packagingCost: validationResult.totalPackagingCost,
          handlingCost: validationResult.totalHandlingCost,
          discounts: validationResult.totalDiscounts,
          itemsValue: validationResult.overAllItemsValue,
          amount: validationResult.grandTotal,
        }
        const summary = {
          totalAmount: validationResult.grandTotal,
          products: itemsCount,
        }
        this.cartSummary.emit(summary);
        this.shippingCharge.emit(charges);
        return validationResult;
      } else if (data.code === 400) {
        const response = JSON.parse(data.data);
        const productList: any[] = response.productList.filter(item => !item.invalid);
        let diff = response.productList.length - productList.length;
        if (diff) {
          const removedItems: string[] = response.productList.filter(item => item.invalid).map(item => item.productLevel3Title);
          this.snackbar.open(`${diff} items (${removedItems.join(', ')}) are unavailable currently and removed from cart`, 'OK', {
            duration: 7000, panelClass: ['snakbar-color']
          });
          this.cartService.cart.products = productList;
          this.cartService.cart.productIds = this.cartService.cart.products.map(i => i.productLevel3Id);
          this.cartService.setCartData();
          this.cartService.dispatchCartUpdate();
          // shippingDetails.productList = productList;
          // this.storeShippingDetails(shippingDetails, screen, storeInDb);
        }
      } else {
        let res: any = response;

        if(res.code === 503)
          this.snackbar.open('Sorry, we are not taking orders currently.', 'Ok', {duration: 6000});

          this.spinner.changeStatus(false, false);
        throw new Error(res.url + ': ' + res._body);
      }
    }));
  }


  private handleInvalidAndUpdatedProducts(chargesResponse) {

    chargesResponse = JSON.parse(JSON.stringify(chargesResponse));

    const invalidRemovedProducts = Object.values<any>(chargesResponse.storeMap)
      .reduce((a,c) => (c.invalidProducts && c.invalidProducts.length) ? [...a, ...c.invalidProducts] : a, []);

    const updatedProducts = Object.values<any>(chargesResponse.storeMap)
      .reduce((a, c) => (c.productList && c.productList.length) ? [...a, ...c.productList.filter(p => p && p.isPriceUpdate)] : a, []);

    if(!invalidRemovedProducts.length && !updatedProducts.length) return;

    this.dialog.closeAll();
    const dialogRef = this.dialog.open(PriceUpdateDialogComponent, {
      disableClose: true,
      data: {
          updatedProducts: updatedProducts,
          invalidRemovedProducts: invalidRemovedProducts
      }
    });

    dialogRef.afterClosed()
    .pipe(
      switchMap(() => this.cartService.getCartData())
    )
    .subscribe((cartData) => {
      const cart = cartData;
  
      cart.products = cart.products.filter(p => !invalidRemovedProducts.some(rp => rp.productLevel3Id === p.productLevel3Id));
      cart.productIds = cart.products.map(p => p.productLevel3Id);
  
      updatedProducts.forEach(up => {
        const cartItem = cart.products.find(p => p.productLevel3Id === up.productLevel3Id);
        if(!cartItem) return;
  
        cartItem.pricePerUnit = up.updatedPrice;
      });

      if(this.auth.userData)
        return this.cartService.persistTheCart({data: cart});
    
      this.cartService.setCartData(cart);
      this.cartService.dispatchCartUpdate();
    });
    

  }

  public getShippingCharges(productList: CartProduct[], screen: string, pinCode?: string): Observable<any> {
    
    const gift = this.cartService.getGiftData();
    
    const request = {
      productList: productList,
      buyerAddress: {
        postalCode: pinCode || this.deliveryService.getSelectedPin()
      },
      isGift: gift ? gift.isGift : false,
      giftMessage: gift ? gift.giftMessage : ''
    }

    if (!request.buyerAddress.postalCode) {
      delete request.buyerAddress;
    }

    if (!request.isGift) {
      delete request.isGift;
      delete request.giftMessage;
    }
    return this.storeShippingDetails(request, screen, false);
  }

  public getShippingDetails(): Observable<any> {
    return this.storage.getItem('shippingDetails');
  }

  placeOrder(request): Observable<any> {
    const url = `${environment.baseUrl}/rest/v1/unified/orders`;
    return this.http.post(url, request).pipe(map(response => {
      const data: any = response;
      if (data.code === 200) {
        return data.data;
      } else {
        throw data.data || 'Faild to create transaction';
      }
    }));

  }

  // getInstamojoLink(request): Observable<any>  {
  //   const url = `${environment.baseUrl}/rest/v1/paymentgateway/getmoneymagnetlink`;
  //   return this.http.post(url, request).pipe(map(response => {
  //     const data: any = response;
  //     if (data.code === 201) {
  //       return data.data;
  //     } else {
  //       throw data || 'Unable to get instaMojo link';
  //     }
  //   }));
  // }

  postOrderPayload(request): Observable<any> {
    const url = `${environment.baseUrl}/rest/v1/unified/orders/prePayments`;
    const encodedRequest = btoa(JSON.stringify(request));
    return this.http.post(url, encodedRequest).pipe(map(response => {
      const data: any = response;
      if (data.code === 201) {
        return data;
      } else {
        throw data || 'payment failed.............';
      }
    }));
  }

  newPostOrderPayload(request): Observable<any> {
    const url = `${environment.baseUrl}/rest/v1/unified/orders/pre-payments`;
    const encodedRequest = btoa(JSON.stringify(request));
    return this.http.post(url, encodedRequest).pipe(map(response => {
      const data: any = response;
      if (data.code === 201) {
        return data;
      } else {
        throw data || 'payment failed.............';
      }
    }));
  }

  raiseAddressEvent(address) {
    this.addressEvent.emit(address);
  }

  paymentRequest(request, orderId) {
    const url = `${environment.baseUrl}/rest/v1/unified/orders/payrequest?orderId=` + orderId;
    return this.http.put(url, request).pipe(map(response => {
      const data = response;
      if (data.code === 201) {
        return data;
      } else {
        throw data || 'Hashing Problem';
      }
    }));
  }

  iciciPaymentRequest(request, orderId) {
    const url = `${environment.restBaseUrlV2}/estore/orders/icici-pay-request?orderId=${orderId}`;

    let headers = {
      headers: { project: 'UNIFIED_STORE' }
    };

    return this.httpClient.put(url, request, headers).pipe(map(response => {
      const data: any = response;
      if (data.code === 200) {
        return data;
      } else {
        throw data || 'Hashing Problem';
      }
    }));
  }

  /**
   * Method for RBL payment request
   * @param request 
   * @param orderId 
   * @returns 
   */
  rblPaymentRequest(request, orderId) {
    let url = `${environment.restBaseUrlV2}/bms/orders/rbl-pay-request?orderId=${orderId}`;
    
    let headers = {
      headers: { project: 'UNIFIED_STORE' }
    };

    return this.httpClient.put(url, request, headers).pipe(map(response => {
      const data: any = response;
      if (data.code === 200) {
        return data;
      } else {
        throw data || 'Hashing Problem';
      }
    }));
  }

  /**
   * Rbl payment status API
   */
  paymentRblPaymentStatus( data: any) {

    let url = `${environment.baseUrl}/rest/v1/unified/orders/rbl-payment-status`;

    return this.http.post(url, data).pipe(
      map((response: any) => {
        let data = JSON.parse(response);

        if (data.code === 200) {
          return data.data;
        } else {
          throw data || 'Something went wrong';
        }
    }));
  }

  newPaymentRequest(request, orderId) {
    const url = `${environment.baseUrl}/rest/v1/unified/orders/pay-request?orderId=` + orderId;
    return this.http.put(url, request).pipe(map(response => {
      const data = response;
      if (data.code === 201) {
        return data;
      } else {
        throw data || 'Hashing Problem';
      }
    }));
  }

}

export interface ChargesAndOtherDetails {
  productList: CartProduct[];
  invalidProducts: CartProduct[];
  storeMap?: { [key: string]: CartProduct[] }
  aggregatedPrice: number;
  amount: number;
  assistantCommissionCharge: number;
  buyerAddress: DeliveryAddress;
  cashPayment: boolean;
  couponDiscount: number;
  couponResponseCode: number;
  discounts: number;
  domainUrl: string;
  handlingCost: number;
  itemsValue: number;
  packagingCost: number;
  shippingCharges: number;
  shippingCost: number;
  overAllItemsValue: number;
}
