import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThirdPartyLibraries } from 'src/app/project-common/properties/constants';
import { ScriptLoaderService } from 'src/app/project-common/services/script-loader.service';
import { SideBarService } from 'src/app/side-bar.service';

@Component({
  selector: 'thank-you-card',
  templateUrl: './thank-you-card.component.html',
  styleUrls: ['./thank-you-card.component.scss']
})
export class ThankYouCardComponent implements OnInit {

  @Output() onPreBookSuccess = new EventEmitter();
  
  @Input() preBookInfo: any;

  constructor(
    private sidebarService: SideBarService,
    private loader: ScriptLoaderService,
  ) { 
    this.loader.loadStyle(ThirdPartyLibraries.jostFont).subscribe();
  }

  ngOnInit() {
  }

  onClose() {
    this.sidebarService.closeMangoCartSidebar();
    this.onPreBookSuccess.emit(true);
  }

}
