<div mat-dialog-content>
  <p translate>{{data?.message}}</p>
</div>

<mat-form-field class="full-width" *ngIf="data?.tabName === 'CASH_PAYMENT'">
    <textarea matInput placeholder="Enter Payment related details" [(ngModel)]="cashPaymentRemarks"></textarea>
</mat-form-field>

<div mat-dialog-actions fxLayoutAlign="center center" fxLayoutGap="10px">
  <button mat-button class="ok"[mat-dialog-close]="cashPaymentRemarks ? cashPaymentRemarks: true" translate>ok</button>
</div> 
