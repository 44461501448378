import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
  pure: false
})
export class CapitalizePipe implements PipeTransform {

  transform(str: string): string {
    if (!str) return str;

    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

}
