import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from '../../services/spinner.service';
import { finalize } from 'rxjs/operators';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Router } from '@angular/router';
import { UserSubscriberService } from '../../services/user-subscriber.service';

@Component({
  selector: 'pincode-request-dialog',
  templateUrl: './pincode-request-dialog.component.html',
  styleUrls: ['./pincode-request-dialog.component.scss']
})
export class PincodeRequestDialogComponent implements OnInit {

  pincodeRequestForm: FormGroup;

  requestedSuccessfully = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private spinner: SpinnerService,
    private subscriberService: UserSubscriberService
  ) { }

  ngOnInit() {
    const { pincodeAvalilable = false } = this.data;
    if(pincodeAvalilable) return;

    this.pincodeRequestForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.email]],
      phone: ['', [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(/^\d{10}$/)]]
    });

    this.prefillFields();
  }

  prefillFields() {
      if(!this.data || !this.data.userData) return;

      let {emailId ="", mobileNo="", firstName, alternateMobileNo="", altemailId=""  } = this.data.userData;

      this.patchValue('name', firstName)

      if(emailId) {
        this.patchValue('email', emailId)

        if(!alternateMobileNo) return;

        this.patchValue('phone', alternateMobileNo)
        return;
      } 

      mobileNo = +mobileNo.replace('+91', '');
      this.patchValue('phone', mobileNo)

      if(!altemailId) return;

      this.patchValue('email', altemailId)
  }

  patchValue(controlName, value) {
    this.pincodeRequestForm.get(controlName).patchValue(value);
  }

  get isValid() {
    if(!this.pincodeRequestForm || !this.pincodeRequestForm.value) return false;

    const mobileControl = this.pincodeRequestForm.get('phone');
    const emailControl = this.pincodeRequestForm.get('email');

    return (
      this.pincodeRequestForm.valid 
      && (mobileControl.valid || emailControl.valid)
      && (mobileControl.value || emailControl.value)
    );
  }

  get formValue() {
    return this.pincodeRequestForm.getRawValue();
  }


  async submitRequest() {
    if(!this.isValid) return;

    const request = Object.assign(PINCODE_REQUEST, this.formValue, this.sessionInfo);
    request.pinCode = this.data.pincode;
    this.spinner.start();
    this.subscriberService.requestForNewPin(request)
    .pipe(finalize(() => this.spinner.stop()))
    .subscribe(
      res => {
        if(res.code !== 201) return;

        this.requestedSuccessfully = true;
      }
    )
  }

  get sessionInfo(){
    if(!this.data || !this.data.userData) 
      return { sessionInfo: PINCODE_REQUEST.sessionInfo };

    let {emailId ="", mobileNo="", profileKey, firstName } = this.data.userData;
    const sessionInfo = Object.assign(PINCODE_REQUEST.sessionInfo, {emailId, mobileNo, profileKey, firstName});

    return { sessionInfo };
  }

}




const PINCODE_REQUEST = {
  "callback": false,
  "email": "",
  "enquiryType": "unified Pincode",
  "name": "",
  "phone": "",
  "pinCode": "",
  "products": [],
  "page":"",
  "countryCodeInfo": null,
  "sessionInfo": {
    "firstName": "",
    "profileKey": "",
    "loginId": "",
    "mobileNo": "",
    "emailId": ""
  }
}