import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { SpinnerService } from './spinner.service';
import { DbUpdateService } from './db-update.service';

@Injectable({
  providedIn: 'root'
})
export class AutoAuthorizeService {

  hasTried: boolean;

  constructor(private router: Router, 
    private authenticator: AuthenticationService,
    private updateDbService: DbUpdateService,
    private spinner: SpinnerService  
  ) { }

  /**
   * To auto login user to store 
   * 
   * @param token encoded string containing username and password, separated by :(colon);
   */
  public async autoSignIn(token: string, currentLocation: string): Promise<void> {
    
    // To avoid multi-processing
    if(this.hasTried) {
      return;
    }
    this.hasTried = true;

    const userDetails = this.extractUserDetails(token);
    
    // Re-writing URL by removing token from URL
    const currentPath = currentLocation.split('?token=')[0];
    await this.router.navigate([currentPath], {
      replaceUrl: true
    });
    
    // Auto login starts
    if(userDetails.userName && userDetails.password) {
      userDetails.sessionId = '';
      this.authenticate(userDetails);
    }

  }


  /**
   * To get decoded user tokens
   * 
   * @param token encoded string containing username and password, separated by :(colon);
   * @returns Decoded user login id and password
   */
  private extractUserDetails (token: string): any {
    
    let userName = '';
    let password = '';

    try {
      userName = atob(token.split(":")[0]);
      password = atob(token.split(":")[1]);
    } catch(e) {
      console.error(`Wrong token`);
    }

    return { userName, password };

  }

  
  private async authenticate (userDetails: {userName: string, password: string, sessionId: string}) {
    this.spinner.start();
    try {
      let result = await this.authenticator.login(userDetails).toPromise();
      
      result = await this.authenticator.getProfileDataFromDynamoDB(JSON.parse(result.data).profileKey, true).toPromise();

      this.updateDbService.updateUserData(result, userDetails);

      this.spinner.stop();

    } catch(e) {
      this.spinner.stop();
      console.error(`Auto authentication failed`);
    }

  }

}
