<main>
	<app-route-progress-bar></app-route-progress-bar>

	<mat-sidenav-container>
		<mat-sidenav
			position="end"
			#cartSideNav
			role="cart-view"
			[autoFocus]="false"
			class="cart-side-nav"
		>
			<mat-nav-list>
				<cart-side-bar
				
				>
			</cart-side-bar>
			</mat-nav-list>
		</mat-sidenav>

		<mat-sidenav-content>
			<mat-sidenav-container>
				<mat-sidenav
				position="end"
				#mangoCartSideNav
				role="cart-view"
				[autoFocus]="false"
				class="mango-cart-side-nav"
			>
				<mat-nav-list>
					<mango-cart-side-bar>
					</mango-cart-side-bar>
				</mat-nav-list>
				</mat-sidenav>

				<mat-sidenav-content id="app-content">
					<div>
						<app-header></app-header>
		
						<div
							class="spinner-wrapper"
							[class.non-blocking]="!isBlockingSpinner"
							*ngIf="isSpinnerActive"
						>
							<div class="spinnner">
								<div class="baar"></div>
								<div class="baar"></div>
								<div class="baar"></div>
								<div class="baar"></div>
							</div>
						</div>
		
						<!-- Notification popUp for lie chat in RFQ -->
						<custom-notification-popup
							fxHide.xs
							*ngIf="isUserLoggedIn"
						></custom-notification-popup>
		
						<div class="fixed-outlet" id="router-content">
							<router-outlet></router-outlet>
						</div>
						<!-- footer -->
						<app-footer></app-footer>
						<ngx-toasta [position]="'left-bottom'"></ngx-toasta>
		
						<!-- <app-back-to-top></app-back-to-top> -->
						<side-links></side-links>
					</div>
				</mat-sidenav-content>
			</mat-sidenav-container>
		</mat-sidenav-content>
	</mat-sidenav-container>

	<!-- Kalgudi photoswipe Don't remove it-->
	<!-- Single place photoswipe injection -->
	<kl-photoswipe></kl-photoswipe>
	<!-- / Kalgudi photoswipe -->

	<notifier fxHide.xs></notifier>
</main>
