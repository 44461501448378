import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig } from '@kalgudi/core/config';
import { KL_ENV } from '@kalgudi/core/config';
import { KalgudiCoreRouteConfig } from '@kalgudi/core/config';
import { PartialData, RouteQueryParams } from '@kalgudi/types';


@Injectable({
    providedIn: 'root'
})
export class CoreRouteConfigService implements KalgudiCoreRouteConfig {

    readonly pageNotFoundPath: string;
    readonly authGuardFailedPath: string;
    readonly noAuthGuardFailedPath: string;

    constructor(
        private router: Router,
        private util: KalgudiUtilityService,
        @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    ) {
        this.pageNotFoundPath = '';
        this.authGuardFailedPath = '/auth/signin';
        this.noAuthGuardFailedPath = '/';
    }

    /**
     * Method called by the auth guards on success route.
     */
    onGuardSuccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {}

    /**
     * Method called by auth guards on failed route.
     */
    onAuthGuardFailure(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {}

    /**
     * Method called by auth guards on failed route.
     */
    onNoAuthGuardFailure(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {}

    /**
     * Routes app to the home page
     */
    toHome(params?: PartialData, queryParams?: RouteQueryParams): string {
        const url = `/`;

        this.route(url, queryParams);

        return url;
    }

    toSocialQaFullview(params?: PartialData, queryParams?: RouteQueryParams): string {
        let url = 'https://core.kalgudi.com/app/social/qa/:questionId';

        url = url.replace(':questionId', params.questionId);

        window.open(url, '_blank')

        return url;
    }

    toProfile(params?: PartialData, queryParams?: RouteQueryParams, openInNewTab?: boolean): string {

        const url = `/`;

        this.route(url, queryParams);

        return url;
    }

    toProgram(params?: PartialData, queryParams?: RouteQueryParams): string {

        const url = `/`;

        this.route(url, queryParams);

        return url;
    }

    toStoreProductView(params?: PartialData, queryParams?: RouteQueryParams): string {

        const { product, store }  = params;

        const productName = this.util.encodeUri(product.productName_level2).toUpperCase();

        const url = `${store || this.environment.storeDomain }/p/c/${productName}/${product.productId_level2}_${product.productId_level3}`;

        this.util.openUrl(url);

        return url;
    }

    toBuyerFullview(params?: PartialData, navigationExtras?: NavigationExtras): string {
        let url = 'rfq/:appName/:requirementId/details/:quotationId';
        
        url = url.replace(':appName', params.appName).replace(':requirementId', params.requirementId).replace(':quotationId', params.quotationId);
        
        this.router.navigate([url], navigationExtras);

        return url;
    }

    /**
   * Routes the app to the specific url
   */
    private route(url: string, queryParams?: RouteQueryParams): void {

        this.router.navigate([url], { queryParams });
    }

}