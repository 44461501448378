import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignGuard implements CanActivate {

   
  constructor(
    private localStorage: LocalStorage,
    private authService: AuthenticationService,
    private router: Router
    ) { }
 
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   
        if(!next.queryParams.p_key) return true;

        const profileKey = atob(next.queryParams.p_key)
        return this.localStorage.getItem('userdata')
        .pipe(
            map(userdata => {

                if(!userdata || (profileKey != userdata.profileKey)){
                    this.authService.logout('/auth/login');
                    return false;

                }

                return true;
            })
        )
  }
}
