import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpWithAuthService } from './http-interceptor.service';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { StorefrontService } from 'src/app/storefront/storefront.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class BulkOrderService {

  requestState: any;

  /**
   * `true` If user has logged in
   */
  isUserAuthorized: boolean;

  constructor(private httpWithAuth: HttpWithAuthService, private snackbar: MatSnackBar,
    private notification: NotificationService, private router: Router,private translator: TranslateService,
    private storefrontService: StorefrontService) {

    this.requestState = {
      BOR_REQUEST: 'Requested',
      BOR_NEGOTIATE: 'Negotiation in progress',
      BOR_SKU_READY: 'Deal finalized',
      BOR_SKU_PUBLISHED: 'Ready to order',
      BOR_ORDERED: 'Order placed',
      BOR_CLOSED: 'Request cancelled'
    }
    this.isUserAuthorized = JSON.parse(localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || 'false');
  }

  /**
   * Service for Bulk order request
   * @param  orderReqOb
   */
  public requestForBulkOrder(orderReqOb): Observable<any> {
    return this.httpWithAuth.post(`${environment.nodeServer}/rest/v2/bizstore/order/req-bulk-order`, orderReqOb).pipe(map(response => {
      const result: any = response;
      return result;
    }));
  }

  public getBulkEnquiries(offset: any = 0, limit: any = 10): Observable<any> {
    const url = `${environment.nodeServer}/rest/v2/bizstore/order/bulkEnquiries?offset=${offset}&limit=${limit}`;
    return this.httpWithAuth.get(url).pipe(map(response => {
      const result: any = response;
      return result;
    }));
  }

  public getBulkEnquiryById(enquiryId): Observable<any> {
    const url = `${environment.nodeServer}/rest/v2/bizstore/order/bulkEnquiry/${enquiryId}`;
    return this.httpWithAuth.get(url).pipe(map(response => {
      const result: any = response;
      return result;
    }));
  }

  public getNegotiationDetails(masterId: string, enquiryId: string) {
    return this.httpWithAuth.get(`${environment.nodeServer}/rest/v2/bulk-orders/requests/biz/${masterId}/${enquiryId}`).pipe(map(response => {
      const result: any = response;
      return result;
    }));
  }

  public updateNegotiation(masterId: string, enquiryId: string, request: any) {
    return this.httpWithAuth.put(`${environment.nodeServer}/rest/v2/bulk-orders/requests/biz/${masterId}/${enquiryId}?action=bor_update`,
      request).pipe(map(response => {
        const result: any = response;
        return result;
      }));
  }

  /**
   * To open enquiry form for level 3 card object
   * 
   * @param product level 3 card object
   */
  public async sendEnquiryForLevel3Card(product: any) {
    if (this.isUserAuthorized) {
      const productLevel2: any = await this.storefrontService.getFullViewData(product.productId_level2).toPromise();
      const productLevel3: any = productLevel2.level3ProductsList.find(l3 => l3.productLevel3Id === product.productId_level3);
      productLevel3.imgUrl = product.productPicURL_level3;
      productLevel3.manufacturerDetails = productLevel2.resellerDetails || productLevel2.manufacturerDetails;;
      productLevel3.baseProduct = productLevel2.baseProduct;
      productLevel3.selectedSKU = productLevel2.selectedSKU;
      productLevel3.description = '';
      this.setBulkOrderReqInLocalStorage(productLevel3);
    } else {
      this.translator.get(['Please login to send enquiry', 'OK']).subscribe(translatedText=>{
      this.snackbar.open(translatedText['Please login to send enquiry'], translatedText['OK'], {
        duration: 4000, panelClass: ['snakbar-color']
      });
    })
    }
  }

  /**
   * This methods will store bulk order request object in local storage
   */
  setBulkOrderReqInLocalStorage(productDetails: any, eventId?: string) {
    const productGroups: any[] = [];/* JSON.parse(window.localStorage['bulkCart'] || '[]'); */
    let queryParams: any = {};
    if (eventId) queryParams.eventId = eventId;
    if (productGroups.length) {
      this.translator.get(['Please complete existing enquiry first', 'OK']).subscribe(translatedText=>{
      this.snackbar.open(translatedText['Please complete existing enquiry first'], translatedText['OK'], {
        duration: 7000, panelClass: ['snakbar-color']
      });
    })
      this.router.navigate(['/product', 'bulk-req'], { queryParams: queryParams });
      return;
    }
    let isProductAddedToList = false; this.addProductToExistingGroup(productGroups, productDetails);
    if (!isProductAddedToList) {
      const shg = JSON.parse(JSON.stringify(productDetails.manufacturerDetails));
      delete productDetails.shgDetails;
      productGroups.push({
        manufacturerDetails: shg,
        products: [productDetails]
      });
      // this.snackbar.open('Added to bulk cart', 'OK', {
      //     duration: 7000
      // });
    }
    window.localStorage['bulkCart'] = JSON.stringify(productGroups);
    this.notification.sendBulkOrderProducts(productGroups);
    this.router.navigate(['/product', 'bulk-req'], { queryParams: queryParams });
  }


  addProductToExistingGroup(shgGroups, product): boolean {
    let isAddedToGroup = false;
    shgGroups.forEach(group => {
      if (group.shgDetails.profileKey === product.shgDetails.profileKey) {
        this.addProductToProductSet(group.products, product);
        isAddedToGroup = true;
      }
    });
    return isAddedToGroup;
  }

  /**
   * Will add new item to array if product is not available in array. 
   * If item is already present is array, it will @return false, true otherwise
   * @param productList array
   * @param newProduct item
   */
  addProductToProductSet(productList, newProduct): boolean {
    const found = productList.some(product => product.productLevel3Id === newProduct.productLevel3Id);
    if (found) {
      this.translator.get(['Product is already added to bulk cart', 'OK']).subscribe(translatedText=>{
      this.snackbar.open(translatedText['Product is already added to bulk cart'], translatedText['OK'], {
        duration: 7000, panelClass: ['snakbar-color']
      });
    })
      return false;
    }
    delete newProduct.shgDetails;
    productList.push(newProduct);
    this.translator.get([' ', 'OK']).subscribe(translatedText=>{
    this.snackbar.open(translatedText['Added to bulk cart'], translatedText['OK'], {
      duration: 7000, panelClass: ['snakbar-color']
    });
  })
    return true;
  }

  getStateMessage(stateCode: string): string {
    return this.requestState[stateCode];
  }
  /**
   * Service for Bulk order request
   * @param  orderReqOb
   */
  public sendEnquiry(request): Observable<any> {
    return this.httpWithAuth.post(`${environment.nodeServer}/rest/v2/bizstore/quotations/enquiry`, request).pipe(map(response => {
      const result = response;
      return result;
    }));
  }
}
