import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProfileService } from 'src/app/profile/profile.service';

import { DeliveryAddress } from '../../models/shipping-form.model';
import { SpinnerService } from '../../services/spinner.service';
import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '../../services/utility.service';
import { KalgudiDialogsService } from '@kalgudi/common';
import { AddressFormMobileDialogComponent } from 'src/app/modules/address/components/address-form-mobile-dialog/address-form-mobile-dialog.component';
import { AddressFormDialogComponent } from 'src/app/modules/address/components/address-form-dialog/address-form-dialog.component';
import { environment } from '@app/env';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'delivery-address-selector',
    templateUrl: './delivery-address-selector.component.html',
    styleUrls: ['./delivery-address-selector.component.scss']
})
export class DeliveryAddressSelectorComponent implements OnInit {

    userData: any;
    listOfAddress: any;
    newAddressFormFlag: boolean;
    addressForm: DeliveryAddress;
    selectedAddress: any;

    // validPins: string[];

    isDevelopment: boolean;

    @Input('loggedInUser') loggedInUser: any;

    @Output('selected-address') selectedAddressEvent = new EventEmitter();
    @Output('requestForCharges') requestForCharges: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private spinner: SpinnerService,
        private profileService: ProfileService, 
        public dialog: MatDialog, 
        private translator: TranslateService,
        private snackbar: MatSnackBar,
        private util: UtilityService,
        private kalgudiDialogsService: KalgudiDialogsService
    ) { }

    ngOnInit() {
        this.profileService.getUserDataFromLocalStorage().subscribe(userDataOnStorage => {
            this.userData = userDataOnStorage;

            const regex = /(@vasudhaika\.net|@kalgudi\.com)$/;

            this.isDevelopment = !environment.production || this.userData.emailId && regex.test(this.userData.emailId);
            this.isDevelopment = this.isDevelopment || this.userData.profileKey === 'M01l3cs0PRFREG2018123132244091UNH001';
    
            this.isDevelopment = location.host.includes('staging') || this.isDevelopment;

            this.isDevelopment = true; // enabling new address form changes to all users

            this.getAddressList(this.loggedInUser.profileKey);
        });
        // this.profileService.getValidPinCodes().subscribe(pins => this.validPins = pins);
        /* this.getCompleteProfileData(); */

    }

    resetAddressForm() {
        this.addressForm = new DeliveryAddress();
        /* this.addressForm.phone = this.userData.mobileNo.replace(this.userData.mobileCode, ''); */


        if (this.isDevelopment) {
            this.openAddressForm(this.addressForm);
          } else {
            this.newAddressFormFlag = !this.newAddressFormFlag;
          }


        // this.resetEditFlag();
        // this.newAddressFormFlag = !this.newAddressFormFlag;
    }



    public openAddressForm(address: DeliveryAddress) {
        const title = address.name ? `Edit ${address.name}'s address` : `Add a new address`;
        if (this.util.isMobileDevice()) {
            this.kalgudiDialogsService.openMobileDialog(AddressFormMobileDialogComponent, {
                title,
                acceptButtonTitle: 'Save',
                rejectButtonTitle: 'Cancel',
                autoFocus: false,
                data: {
                    isEditing: !!address.name,
                    address: address
                }
            }).subscribe(result => {
                if (result && result.accepted) {
                    this.listOfAddress = result.data as any[];
                }
            });
        } else {
            this.kalgudiDialogsService.openDialog(AddressFormDialogComponent, {
                title,
                data: {
                    isEditing: !!address.name,
                    address: address
                }
            }, {
                maxWidth: '600px',
            }).subscribe(result => {
                if (result && result.accepted) {
                    this.listOfAddress = result.data as any[];
                }
            });
        }
    }

    closeForm(event) {
        if (event.addressList) {
            this.listOfAddress = event.addressList;
            this.selectAddress(this.listOfAddress[0]);
        }
        this.resetEditFlag();
    }

    selectAddress(address: any) {
        if (!address.googleLocation) {
            this.translator.get(['Address looks incomplete, please edit it or add a new address.', 'OK']).subscribe(translatedText => {
                this.snackbar.open(translatedText['Address looks incomplete, please edit it or add a new address.'], translatedText['OK'], {
                    duration: 50000, panelClass: ['snakbar-color']
                });
            })
            return false;
        }
        this.selectedAddress = address;
        this.selectedAddressEvent.emit(this.selectedAddress);
    }

    getAddressList(profileKey?: string) {
        this.spinner.changeStatus(true, true);
        this.profileService.getSavedAddresses(profileKey || this.userData.profileKey).subscribe(response => {
            this.spinner.changeStatus(false, false);
            if (response.code === 200) {
                this.listOfAddress = JSON.parse(response.data);
                if (this.listOfAddress.length && profileKey === this.userData.profileKey) {
                    this.listOfAddress.forEach(address => {
                        if (address.isDefault) {
                            this.autoSelectAddress(address);
                        }
                    });
                    if (!this.selectedAddress) {
                        this.autoSelectAddress(this.listOfAddress[0]);
                    }
                } else {

                    // Opening address form automatically if there are no addresses available
                    this.resetAddressForm();

                    // console.log('Default location');


                    this.requestForCharges.emit('500081');
                }
            }
        }, error => {
            this.spinner.changeStatus(false, false);
        });
    }

    private autoSelectAddress(address) {
        if (address.googleLocation ) { // Don't find any data in address (&& address.residence)
            this.selectAddress(address);
        }
    }

    editAddress(address) {
        this.resetEditFlag();
        address.isEditingEnabled = true;
    }

    resetEditFlag() {
        this.listOfAddress && this.listOfAddress.forEach(address => {
            address.isEditingEnabled = false;
        });
        this.newAddressFormFlag = false;
    }

    getBuyersAddress() {
        const form = this.selectedAddress;
        return form.addressLine1 + ', ' + form.addressLine2 + ', ' + (form.landmark + ', \n' || '')
            + form.city + ', ' + form.state + '- ' + form.postalCode;
    }

    getBuyersMobile() {
        let mobile = this.loggedInUser.mobileNo;
        mobile = mobile.replace(this.loggedInUser.mobileCode || this.loggedInUser.mobileTelecomcode, '');
        if (mobile.length !== 10) {
            mobile = '';
        }
        return mobile;
    }

    /* getCompleteProfileData () {
        this.authService.getProfileData(this.authService.userData.profileKey, true).subscribe( response => {
        this.loggedInUser = JSON.parse(response.data);
        });
    } */

    /* 
        * To remove a specific address from the address list.
        */
    removeAddress(address) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            autoFocus: false,
            data: { message: 'Are you sure you want to delete this address?' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.spinner.changeStatus(true, true);
                this.profileService.removeAddress(this.loggedInUser.profileKey || this.userData.profileKey, address.id).subscribe(response => {
                    this.spinner.changeStatus(false, false);
                    if (response.code === 200) {
                        this.listOfAddress = JSON.parse(response.data);
                        this.translator.get(['Address deleted successfully', 'OK']).subscribe(translatedText => {
                            this.snackbar.open(translatedText['Address deleted successfully'], translatedText['OK'], {
                                duration: 3000, panelClass: ['snakbar-color']
                            });
                        })
                    }
                }, error => {
                    this.spinner.changeStatus(false, false);
                    this.translator.get(['OOPS! something went wrong']).subscribe(translatedText => {
                        this.snackbar.open(translatedText['OOPS! something went wrong'], '', {
                            duration: 3000, panelClass: ['snakbar-color']
                        });
                    })
                });
            }
        });
    }


    updateAddress(address) {
        if (!address) {
          address = {};
        }

        if (this.isDevelopment) {
            try {
                address = JSON.parse(JSON.stringify(address));
            } catch (error) {}
          this.openAddressForm(address);
        } else {
            this.resetEditFlag();
            address.isEditingEnabled = true;
        }
    
      }

}
