<!-- CART PAGE -->
<section fxLayout="column" fxLayoutGap="5px">
	<div class="close-icon" (click)="close()">
		<i class="fa fa-times mt-7" aria-hidden="true"></i>
	</div>
	<!-- List of added items in cart -->
	<div fxLayout="column" fxLayoutGap="10px" fxLayoutGap.xs="0px">
		<div class="mb-10">
			<span class="fw-700 heading" translate>SHOPPING CART</span>
		</div>
		<div class="cart-sec" fxLayout="column" fxFlex="100%">
			<div class="items-holder">
				<ng-container *ngFor="let store of storeSet | keyvalue">
					<div fxLayout="row" class="bg-light pb-10 pt-10 rounded mb-10" fxLayoutGap="5px">
						<img
							[src]="storeLabelsMap[store.key]?.logo"
							width="20"
							height="20"
						/>
						<h5 class="font-13 fw-700 pt-3">
						<span translate>{{ storeLabelsMap[store.key]?.name || (store?.key | capitalize) }}</span>	
						<span class="ml-5" translate>products</span>	
						</h5>
					</div>

					<ng-container
						*ngIf="store.value as products"
					>
						<ng-container *ngIf="products?.length">
							<cart-side-bar-item
								[product]="product"
								[cart]="cart"
								*ngFor="let product of products"
							></cart-side-bar-item>
						</ng-container>
					</ng-container>

				</ng-container>
			</div>
		</div>

		<div class="fixed-bottom-sec">
			<section fxLayout="column" fxLayoutGap="10px" class="bottom-sec">
				<div fxLayout="row" fxLayoutAlign="space-between">
					<span class="fw-600" translate>SUBTOTAL:</span>
					<span class="fw-600 price">{{
						cartItemsTotal || 0
							| currency: "INR":"symbol":"1.2-2"
					}}</span>
				</div>
				<p class="info-text" translate>Taxes, shipping and discounts codes calculated at checkout</p>
				<button
					mat-button
					class="view-cart-btn"
					[routerLink]="['/checkout', 'cart']"
					(click)="close()">{{'VIEW CART' | translate}}</button>
				<button
					mat-button
					class="checkout-btn"
					[routerLink]="['/checkout', 'shipping']"
					(click)="close()">{{'CHECKOUT' | translate}}</button>
			</section>
		</div>
		<!-- EMPTY CART STATE -->
		<div class="no-item-sec">
			<ng-container *ngIf="!cart?.products?.length">
				<kl-no-content text="{{'No products in your cart' | translate}}"> </kl-no-content>
			</ng-container>
		</div>
		<!-- /EMPTY CART STATE -->
	</div>
	<!-- /List of added items in cart -->
</section>
<!-- /CART PAGE -->
