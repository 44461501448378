<button class="dialog-close" mat-flat-button (click)="cancel()"><mat-icon>close</mat-icon></button>

<div fxLayout="column" class="h-100 d-flex">


    <div class="d-flex map-section" fxFlex fxLayout="column">

        <current-location class="d-block h-100" [location]="{googleLocation: dialogData?.data?.googleLocationTo}" (positionChanged)="positionChanged($event)"></current-location>


    </div>

    <!-- Action buttons -->
    <div class="fixed-bottom bg-white">
        <div class="p-15" fxLayout="column" fxLayoutGap="6px">

            <small class="text-black-50">
                <i class="fa fa-map-marker mr-5"></i>
                <span>Based on your location</span>
            </small>
            <ng-container *ngIf="googleResponse?.googleLocationTo as loc">
                <strong *ngIf="loading">Loading...</strong>
                <strong *ngIf="!loading">
                    {{loc?.adminLevel2 || loc?.locality}}, {{loc?.adminLevel1}}, {{loc?.countryName}} - {{loc?.zipcode}}
                </strong>
            </ng-container>
            
            <small class="text-info">Drag pin to change location</small>

            <div class="pt-5 action-buttons w-100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">

                <button class="w-100" mat-stroked-button color="warn" (click)="cancel()">Cancel</button>
                <button class="w-100" mat-flat-button color="primary" (click)="ok()">Accept</button>

            </div>
            <!--/ Action buttons -->
        </div>
    </div>

</div>