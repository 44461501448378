<div class="home-container">

    <!-- <category-banner></category-banner> -->

    <design-banner></design-banner>

    <!-- <div class="banner-section">
        <banner-slider></banner-slider>
    </div> -->

    <div class="mt-16">
        <popular-category></popular-category>
    </div>

    <ng-container *ngFor="let categoryId of categoriesDisplayOrder; index as index">

        <ng-container *ngIf="allCategoriesDataMap[categoryId] as category">
            <section>
                <div class="app-container">
                    <two-row-product-section [products]="category">
                    </two-row-product-section>
                </div>
            </section>
        </ng-container>

        <!-- <ng-container *ngIf="index === 1">
            <section>
                <recommended-products [recommendedProductList]="recommendedProductList"></recommended-products>
            </section>
        </ng-container> -->
        

    </ng-container>
    


    <ng-container *ngIf="isCategoryLoading">
        <div class="app-container">
            <p-skeleton></p-skeleton>
        </div>
    </ng-container>
     
     <!-- PRODUCT REVIEW SECTION -->
    <!-- <product-reviews></product-reviews> -->

    <store-info-cards *ngIf="false" fxHide.gt-xs></store-info-cards>

    <!-- <highlight-section></highlight-section> -->


    <!-- features -->
    <!-- <section>
        <bizstore-features></bizstore-features>
    </section> -->

    <div class="section-break"></div>
    
    <subscribe-us></subscribe-us>
    
    <!-- <div class="section-break"></div> -->
    
    <!-- <get-app></get-app> -->
    
    <!-- <store-features></store-features> -->

    <!-- <juicer></juicer> -->

</div>


