import { CoreEnvironmentConfigService } from "./environment.service";
import { CoreRouteConfigService } from "./route-config.service";
import { CoreNotificationConfigService } from "./notification.service";


/**
 * Kalgudi core library configuration object.
 */
export const CORE_LIB_CONFIG = {
    environment: CoreEnvironmentConfigService,
    notification: CoreNotificationConfigService,
    routeConfig: CoreRouteConfigService,
  };