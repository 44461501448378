import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'cart-info',
  templateUrl: './cart-info.component.html',
  styleUrls: ['./cart-info.component.scss']
})
export class CartInfoComponent implements OnInit {

  @Input() cart: any;
  @Input() cartQuantity: number;
  @Output() deleteProduct = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  get cartItemsTotal() {
    if(!this.cart) return 0;

    return this.cart.products.reduce((total, p) => (total + (p.pricePerUnit * +p.productQuantity)), 0);
  }
}
