import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA } from '@kalgudi/common';
import { KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'existing-address-mobile-dialog',
  templateUrl: './existing-address-mobile-dialog.component.html',
  styleUrls: ['./existing-address-mobile-dialog.component.scss']
})
export class ExistingAddressMobileDialogComponent extends KalgudiMobileDialog  implements OnInit {
  
  selectedAddress: any;
  listOfAddress = [];
  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: any,
    protected dialogRef: KalgudiMobileDialogRef,
  ) {
    super(dialogRef);
  }

  public cancel() {
    this.dialogRef.close();
  }
  public ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: this.selectedAddress
    };
    this.dialogRef.close(result)
  }

  ngOnInit(): void {
    this.listOfAddress = this.dialogData.data.listOfAddress || [];
    if(this.dialogData.data.selectedAddress) {
      this.selectedAddress = this.dialogData.data.selectedAddress;
    }
  }

  onAddressSelection(event) {
    this.selectedAddress = event;
  }

}

