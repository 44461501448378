<div class="overflow-hidden gift-message-dialog">
  <!-- Heading for dialog -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title text-white" mat-dialog-title translate>{{data?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon class="text-white">clear</mat-icon>
    </button>
  </div>
  <!-- /Header title for dialog -->

  <div class="overflow-hidden p-16">
    <!-- <ckeditor [config]="config" [editor]="Editor" [(ngModel)]="giftMessage" (ready)="onReady($event)"></ckeditor> -->
    <mat-form-field appearance="outline" class="w-100">
      <textarea matInput
        rows="5"
        placeholder="Enter message" 
        [(ngModel)]="giftMessage">
      </textarea>
    </mat-form-field>

    <div class="text-right mt-10">
      <kl-flat-button
        class="save-btn"
        type="submit"
        [disabled]="!giftMessage"
        (clicked)="ok()"
        label="Save">
      </kl-flat-button>
    </div>
  </div>
</div>
