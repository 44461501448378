<section class="info-cards">
  <div class="app-container">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
      <div fxFlex class="info-card">
        <img width="70" src="assets/apgreen/procure.svg" alt="">
        <div fxLayout="column" fxLayoutGap="8px">
          <span class="info-card-title" translate>Procured from</span>
          <span class="font-19 fw-600" translate>Farmers of Andhra Pradesh</span>
        </div>
      </div>
      <div fxFlex class="info-card">
        <img width="70" src="assets/apgreen/machine.svg" alt="">
        <div fxLayout="column" fxLayoutGap="8px">
          <span class="info-card-title" translate>Processed at</span>
          <span class="font-19 fw-600" translate>Government approved pack houses</span>
        </div>
      </div>
      <div fxFlex class="info-card">
        <img src="assets/tfresh/images/location.png" alt="">
        <div fxLayout="column" fxLayoutGap="8px">
          <span class="info-card-title" translate>Deliveries to</span>
          <span class="font-19 fw-600" translate>Vijayawada, Vizag, Guntur, Hyderabad, Bengaluru</span>
        </div>
      </div>
    </div>
  </div>
</section>