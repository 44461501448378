import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KalgudiEnvironmentConfig } from '@kalgudi/core/config';
import { StoreType } from '@kalgudi/types';
import { environment } from 'src/environments/environment';

import { DOMAIN_URL_FOR_STORE } from '../store-mapping';

@Injectable({
  providedIn: 'root'
})
export class CoreEnvironmentConfigService implements KalgudiEnvironmentConfig {

  production: boolean;
  awsAccessKey: string;
  s3BucketUrl: string;
  domain: string;
  consumerStoreDomain: string;
  bizStoreDomain: string;
  farmerStoreDomain: string;
  restBaseUrl: string;
  restBaseUrlV2: string;
  storeType: StoreType;
  dirUrl:``;
  coreDomain: string;
  APP_NAME: string;
  BASE_STORE_DOMAIN?: string;

  constructor(
    private router: Router
  ) {
    this.production = environment.production;
    this.awsAccessKey = environment.awsAccessKey;
    this.s3BucketUrl = environment.bucketUrl;
    this.domain = environment.baseUrl;
    this.consumerStoreDomain = environment.baseUrl;
    this.bizStoreDomain = environment.bizStoreDomain;
    this.farmerStoreDomain = environment.farmerStoreDomain;
    this.restBaseUrl = environment.restBaseUrl;
    this.restBaseUrlV2 = environment.restBaseUrlV2;
    this.storeType = StoreType.FARM_STORE;
    this.coreDomain = environment.coreDomain;
    this.dirUrl = ``;
    this.APP_NAME = this.getAppName();
    this.BASE_STORE_DOMAIN = this.getBaseStoreDomain();

  }

  getStoreType(): StoreType {
    return StoreType.FARM_STORE;
  }

  getAppName() {
    if(this.router.url.includes('/rfq/APGREEN/')) {
      return 'APGREEN';
    }else if(this.router.url.includes('/rfq/EMAHILA/')) {
      return 'EMAHILA';
    }else if(this.router.url.includes('/rfq/TFRESH/')) {
      return 'TFRESH';
    }else {
      return 'UNIFIED_STORE'
    }
  }

  getBaseStoreDomain() {
    let appName = this.getAppName();
    let envType = environment.production ? 'prod' : 'dev';
    if(appName !== 'UNIFIED_STORE') {
      let baseStore = DOMAIN_URL_FOR_STORE[appName][envType];
      return baseStore;
    }else {
      return '';
    }
  }
}
