import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';

import { UserProfile } from '../project-common/models/user.model';
import { SpinnerService } from '../project-common/services/spinner.service';
import { UserSubscriberService } from '../project-common/services/user-subscriber.service';
import { UtilityService } from '../project-common/services/utility.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  // @Input() footerColor: boolean;
  footerData: any;
  footerKey: any;
  email = new FormControl('', [Validators.required, Validators.email]);
  userProfile: UserProfile;
  countryDetails: any;

  commonFooterData = {
    'footerObject1': {
      'logo': '/assets/images/kl-red-logo.png',
      'url': '',
      'name': 'Kalgudi',
      'tagLine': 'Rural India Store',
      'storeInfo': `Kalgudi is your one-stop-shop for regional, nostalgic, authentic and unadulterated direct-from-producer products from across India.`,
      'storesList': [
        { 'storeName': 'eMahila', 'url': 'http://emahila.org/' },
        // { 'storeName': 'Andhragreens', 'url': 'http://andhragreens.com/' },
        // { 'storeName': 'Telangana Greens', 'url': 'http://tgreens.in/' },
        { 'storeName': 'Swayam Sikkim', 'url': 'https://swayamsikkim.com/' },
        { 'storeName': 'Kalgudi Store', 'url': 'http://store.kalgudi.com/' },
      ],
      'shop': [
        { 'name': 'Privacy Terms', 'url': 'https://store.kalgudi.com/help/Policies_Privacy' },
        { 'name': 'Return Policy', 'url': 'https://store.kalgudi.com/help/Shop_Returns' },
        { 'name': 'Service Terms', 'url': 'https://store.kalgudi.com/help/Policies_Service-terms' },
        { 'name': 'Shipping Terms', 'url': 'https://store.kalgudi.com/help/Policies_Shipping-terms' },
      ],
      'call': {
        'image': '/assets/images/footer-icons/call.png',
        'value': '(+91)  8069107788'
      },
      'emailTo': {
        'image': '/assets/images/footer-icons/mailto.png',
        'value': 'hello@kalgudi.com'
      },
      // 'followUs': [
      //   {'image': '/assets/images/footer-icons/fb.png'},
      //   {'image': '/assets/images/footer-icons/li.png'},
      //   {'image': '/assets/images/footer-icons/tw.png'},
      //   {'image': '/assets/images/footer-icons/in.png'}
      // ]
    },

    'footerObject2': {
      'logo': 'assets/images/kalgudi_blue.png',
      'url': 'https://corporate.kalgudi.com/',
      'name': 'Kalgudi',
      'tagLine': ' Agri Convergence Platform',
      'storeInfo': `Kalgudi is a digital convergence platform that enables all ecosystem players to focus on their tasks while kalgudi creates much visibility and reach of their activities to a larger section of the users. `,
      'storesList': [
        { 'storeName': 'Core Services', 'url': 'http://core.kalgudi.com/' },
        { 'storeName': 'Agri Input & Services Store', 'url': 'http://inputs.kalgudi.com/' },
        { 'storeName': 'Agri Outputs Store', 'url': 'http://outputs.kalgudi.com/' },
        // { 'storeName': 'Consumer Store', 'url': 'http://kalgudi.com/' },
      ],
      'shop': [
        { 'name': 'Farmer App', 'url': 'https://play.google.com/store/apps/details?id=com.vasudhaika.producers' },
        { 'name': 'FPO App', 'url': 'https://play.google.com/store/apps/details?id=com.vasudhaika.fpo' },
        { 'name': 'Seller App', 'url': 'https://play.google.com/store/apps/details?id=com.vasudhaika.sellersapp' },
        { 'name': 'CBO App', 'url': 'https://play.google.com/store/apps/details?id=com.vasudhaika.shg' },
        { 'name': 'Sales Team', 'url': 'https://play.google.com/store/apps/details?id=com.vasudhaika.salesteam' },
        { 'name': 'ConnectX', 'url': 'https://connectx.kalgudi.com/app/home' },
        { 'name': 'SeedX', 'url': 'https://corporate.kalgudi.com/sites/seedx.html' },
      ],
      'call': {
        'image': '/assets/images/footer-icons/call.png',
        'value': '(+91) 8069107777'
      },
      'emailTo': {
        'image': '/assets/images/footer-icons/mailto.png',
        'value': 'hello@kalgudi.com'
      },
      'followUs': [
        { 'image': '/assets/images/footer-icons/fb.png', 'url': 'https://www.facebook.com/Kalgudi' },
        { 'image': '/assets/images/footer-icons/li.png', 'url': 'https://www.linkedin.com/company/kalgudi' },
        { 'image': '/assets/images/footer-icons/tw.png', 'url': 'https://twitter.com/kalgudiapp' },
        { 'image': '/assets/images/footer-icons/in.png', 'url': 'https://www.instagram.com/kalgudiapp/' }
      ]
    }
  }

  constructor(
    // private notification: NotificationService,
    // private s3: S3UploadService,
    public router: Router,
    private utility: UtilityService,
    private subscriberService: UserSubscriberService,
    private snackbar: MatSnackBar,
    private translator: TranslateService,
    private spinner: SpinnerService,
    private storage: LocalStorage
  ) {

  }

  ngOnInit() {
    // this.footerColor = false;
    this.footerData = {};
    /* this.s3.getJsonDataFromS3('/data/footerlinks/footer_link_sections.json').subscribe(data =>{
      if(data){
        this.footerData = data.data;
        this.footerKey = Object.keys(this.footerData);
      }
    }, error => {
      this.footerData = {};
    }); */
    this.storage.getItem('userdata').subscribe(userData => {
      this.userProfile = userData;
    })
    this.storage.getItem('countryDetails').subscribe(country => {
      this.countryDetails = country;
    })
  }

  openHelp(key) {
    if (this.utility.isMobileDevice()) {
      this.router.navigate([
        'help', key,
      ]);
    } else {
      let url = window.location.origin + this.utility.getPathName(window.location.pathname) + 'help/' + key;
      window.open(url, '_blank');
    }
  }

  get isMobileDevice() {
    return this.utility.isMobileDevice();
  }

  get hideFooter() {

    return this.isMobileDevice && this.router.url.includes('/auth/');

  }

  subscribeUs() {
    const first = `Please enter a valid e-mail address`;
    const last = `OK`;
    if (this.email.invalid) {
      return this.translator.get([first, last]).subscribe(translatedText => {
        this.snackbar.open(`${translatedText[first]}`, `${translatedText[last]}`, {
          duration: 4000, panelClass: ['snakbar-color']
        });
      });
    }

    this.spinner.start();
    this.subscriberService.subscribeToNewsLetters(this.email.value).subscribe(res => {
      this.spinner.stop();
      if (res.code == 201) {
        this.email.reset();
        return this.snackbar.open(res.info, 'OK', {
          duration: 6000, panelClass: ['snakbar-color']
        });
      }
    }, error => {
      this.spinner.stop();
      this.snackbar.open(error.info, '', { duration: 6000, panelClass: ['snakbar-color'] });
    });
  }

  get isAdminPage() {
    return this.router.url.includes('/support');
  }

}
