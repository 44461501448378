<section class="assistance">
  <div fxLayout="row wrap">
      <form fxFlex="65%" fxFlex.xs="100%" class="left-sec" (submit)="verifyAndAuthenticate()">
          <div fxLayout="column" class="left-container">
              <h5 class="fw-400 mt-0" translate>Please enter the buyer's {{assistanceForm?.emailId ? 'email' : 'mobile number'}}</h5>
              <label class="mb-20" *ngIf="!assistanceForm?.emailId">
                  <div class="label-txt" translate>Mobile number</div>
                  <input matInput type="tel" autocomplete="tel" placeholder="{{'Enter mobile number' | translate}}"
                      [readonly]="mobileInputReadonly"
                      [maxLength]="10" [minLength]="10" (keypress)="isNumber($event)" (keyup)="onBuyerNumberChange()"
                      [(ngModel)]="assistanceForm.mobileNumber" name="mobileNumber" required />
              </label>

              <label class="mb-20" *ngIf="assistanceForm?.emailId">
                  <div class="label-txt" translate>Email</div>
                  <input matInput type="tel" autocomplete="tel" placeholder="{{'Enter your email' | translate}}"
                      readonly
                      [(ngModel)]="assistanceForm.emailId" name="emailId" required />
              </label>

              <label class="mb-20" *ngIf="isUserVerified" @fade>
                  <div class="label-txt" translate>Name</div>
                  <input matInput placeholder="Name" [readonly]="isExistingUser"
                      [(ngModel)]="assistanceForm.firstName" name="firstName" />
              </label>

              <ng-container *ngIf="isUserVerified">
                  <div class="label-txt" translate>Location</div>
                  <kl-google-places-autocomplete (googlePlaceChange)="onLocationChange($event)"
                      [formControl]="googleLocationTo" [useKalgudiGoogleLocation]="true">
                  </kl-google-places-autocomplete>
              </ng-container>

              <label class="mb-20" *ngIf="isUserVerified && isExistingUser" @fade>
                  <div class="label-txt">Please enter the OTP received by the buyer</div>
                  <input matInput placeholder="{{'Enter 6 digit otp here' | translate}}" type="text"
                      (keypress)="isNumber($event)" [(ngModel)]="assistanceForm.otp" name="otp" />
              </label>

              <div class="mt-5" fxLayoutGap="15px" fxLayout.xs="column">
                  <button type="submit" mat-button [disabled]="processing" class="continue">
                      <div *ngIf="processing" class="spinner-border spinner-grow-sm d-inline-block mr-5"
                          role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                      <span translate>Verify & Authorize</span>
                  </button>

                  <span class="font-14" *ngIf="isUserVerified && isExistingUser">
                      <span translate>Didn't receive OTP yet?</span>
                      <span class="cursor-pointer text-info" [class.blocked]="otpResendCountDown"
                          (click)="resendOtp()" translate>Try again</span>
                      <span *ngIf="otpResendCountDown">{{'in' | translate}} {{otpResendCountDown}}
                          {{'sec' | translate}}</span>
                  </span>

                  <!-- <span fxFlexAlign.xs="center">- or -</span>
      <button mat-stroked-button (click)="setAssistanceDetails()">
        Exit & place order for myself
        <i class="fa fa-long-arrow-right ml-5"></i>
      </button> -->
              </div>
          </div>
      </form>
      <div fxFlex="35%" fxFlex.xs="100%" class="cart-items-section">
          <h5 class="fw-400 mt-0" translate>
              {{ cart && cart.products.length ? "Cart items" : "Your cart is currently empty" }}
          </h5>

          <ng-container *ngIf="cart && cart.products.length; then items; else noItems"></ng-container>

          <ng-template #items>
              <ng-container *ngFor="let item of cart.products">
                  <div class="cart-item">
                      <div fxFlex="40px">
                          <img [src]="item.productImage | scaleImage: 'md'" />
                      </div>
                      <div fxFlex="calc(100% - 40%)" class="details">
                          <div class="name">
                              <span>{{ item?.productLevel3Title }}</span>
                          </div>
                          <div class="price">
                              {{ item?.pricePerUnit | currency: "INR":"symbol":"1.0" }}
                          </div>
                      </div>
                  </div>
              </ng-container>
          </ng-template>
          <ng-template #noItems>
              <div class="text-center">
                  <div class=" pb-20" fxLayoutAlign="center center">
                      <mat-icon class="no-cart">remove_shopping_cart</mat-icon>
                  </div>
                  <button mat-stroked-button [routerLink]="['/']">
                      Continue adding items <i class="fa fa-long-arrow-right ml-5"></i>
                  </button>
              </div>
          </ng-template>
      </div>
  </div>
</section>