const host = 'kalgudi.com';

export const environment = {
    production: window.location.origin.includes('kalgudi.com'),
    awsAccessKey: ``,
    googleApiKey: `AIzaSyAIVfV3tcaZnY1E-YYgjZV0msCT8uy6bf8`,
    nodeServer: location.origin.includes('localhost') ? `https://${host}` : location.origin,
    bucketUrl: `https://kalgudidata.s3.amazonaws.com`,
    consumerStoreDomain: `https://kalgudi.com/store`,
    bizStoreDomain: `https://kalgudi.com`,
    coreDomain: 'https://core.kalgudi.com',
    farmerStoreDomain: `https://${host}`,
    baseUrl: location.origin.includes('localhost') ? `https://${host}` : location.origin,
    restBaseUrl: location.origin.includes('localhost') ? `https://${host}/rest/v1` : `${location.origin}/rest/v1`,
    restBaseUrlV2: location.origin.includes('localhost') ? `https://${host}/v2` : `${location.origin}/v2`,
    version: '190624',
    source: 'WEB',
    sellerProfile: 'M01na730PRFREG2019103031312545UNH001',
    APP_NAME: `UNIFIED_STORE`,
    disableAddToCart: false,
    disablePayments: false,
    disableAddToCartForVasudhaika: false,
    disablePaymentsForVasudhaika: false,
};
