<div mat-dialog-content>
    <p translate>{{data?.message}}</p>

    <!--Fruit items in cart-->
    <div class="item" fxLayout *ngFor="let selectedFruitItem of data?.selectedFruitItems">
        <div class="img-sec" fxFlex.xs="70px" fxFlex="125px" fxLayoutAlign="center center">
            <img [src]="selectedFruitItem.productImage | scaleImage: 'md'" alt="product-image" title="{{selectedFruitItem.productLevel3Title}}" width="100" height="100">
        </div>

        <div class="right-sec" fxFlex="calc(100% - 125px)" fxLayout fxLayoutAlign="center center">
            <div class="item-details-container" fxFlex="100%" fxLayout="column">
                <div class="app-row mb-10" fxFlex fxLayout="column" fxLayout.xs="column">
                    <div class="p-name">{{selectedFruitItem?.productLevel3Title}} </div>
                    <div class="unit">
                        <ng-container *ngFor="let sku of selectedFruitItem?.selectedSKU">
                            <ng-container *ngIf="sku !== 'dimensions'">
                                {{ selectedFruitItem.smartElements[sku]?.value }} {{ selectedFruitItem.smartElements[sku]?.unit }}
                            </ng-container>

                        </ng-container>
                    </div>
                </div>
                <div class="p-price">
                    <span class="qty">Qty:</span> {{ selectedFruitItem?.productQuantity }}
                </div>
            </div>
        </div>
    </div>

</div>

<div mat-dialog-actions fxLayoutAlign="center center" fxLayoutGap="10px">
    <button mat-button class="ok" [mat-dialog-close]="true">{{(data?.okText || 'Yes') | translate}}</button>
    <button *ngIf="!data?.hideCancel" mat-button class="cancel" (click)="onNoClick()">{{ "No" | translate}}</button>
</div>