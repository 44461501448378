import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'bookings-dialog',
  templateUrl: './bookings-dialog.component.html',
  styleUrls: ['./bookings-dialog.component.scss']
})
export class BookingsDialogComponent implements OnInit {

  constructor(private router: Router,
    private dialog: MatDialog
    ) { }

  ngOnInit() {
  }

  preBook() {
    this.router.navigate(['/pre-book']);
    this.dialog.closeAll();
  }


}
