import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';

import { CategoryService } from '../../services/category.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'shop-by-category',
  templateUrl: './shop-by-category.component.html',
  styleUrls: ['./shop-by-category.component.scss']
})
export class ShopByCategoryComponent implements OnInit {

  @Input() menuOpened: 'opened' | 'closed';
  categories$: Observable<any[]>;
  show = 8;
  isCategoriesVisible: boolean;

  constructor(
    private router: Router,
    private util: UtilityService,
    private categoryService: CategoryService

  ) { }

  ngOnInit() {

    this.categories$ = this.categoryService.categories$;

    setTimeout(() => {
      this.menuOpened = 'closed';
      // this.handleMenuOnRouteChange();
      // this.handleMenuBehaviorInHomePage();
    }, 800);
  }

  toggleMenu() {
    this.menuOpened = this.menuOpened === 'closed' ? 'opened' : 'closed';
  }

  handleMenuOnRouteChange() {
    this.router.events
      .pipe(
        filter(e => (e instanceof (NavigationEnd) || e instanceof (NavigationStart))),

        tap((e: NavigationEnd) => {
          this.menuOpened = (e.url !== '/') ? 'closed' : 'opened';
        })
      ).subscribe();


  }

  handleMenuBehaviorInHomePage() {
    fromEvent(window, 'scroll')
      .pipe(
        startWith(false),

        map(() => {

          const { scrollY: scrollHeight } = window;

          return (scrollHeight)
        }),

        distinctUntilChanged(),

        tap(scrollHeight => {
          if (this.router.url !== '/') return;

          this.menuOpened = (scrollHeight > 200) ? 'closed' : 'opened'
        })

      ).subscribe();
  }

  public selectedCategory(category, level?, parent?, superParent?) {
    if (level) {
      this.router.navigate(['/categories', category.replace(/_/g, '-').toLowerCase(), level.replace(/_/g, '-').toLowerCase()
      ]);

    }
    else {
      this.router.navigate(['/categories', category.id.replace(/_/g, '-').toLowerCase()]);
    }

  }

  openAllCategories() {
    this.router.navigate(['p/categories']);
  }

}



  // categories = [
  //   {
  //     categoryName: 'Fruits',
  //     categoryImageUrl: 'assets/categories-menu-svg/fruits.svg',
  //     itemsList: [
  //       {
  //         listName: 'Regular',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       },
  //       {
  //         listName: 'Seasonal fruits',
  //         list: [
  //           'Mango',
  //           'Grapes',
  //           'Sweet Lime',
  //           'Passion Fruit',
  //           'Custard apple',
  //           'Pineapple ',
  //           'Guava',
  //           'Papaya',
  //           'Watermelon'
  //         ]
  //       },
  //       {
  //         listName: 'Exotic fruits',
  //         list: [
  //           'Apricots',
  //           'Lychee',
  //           'Barbados Cherries',
  //           'Black Plum',
  //           'Cherries',
  //           'Peaches ',
  //           'Dragon Fruit',
  //           'Passion Fruit',
  //         ]
  //       }
  //     ],
  //     dealItems: [
  //       {
  //         itemImageUrl: 'https://andhragreens.com/data/p_images/1595316452831_220x220.jpeg',
  //         itemName: 'Taiwan Red Lady Papaya (5kg)',
  //         price: 304
  //       },
  //       {
  //         itemImageUrl: 'https://andhragreens.com/data/p_images/1595316109774_220x220.jpeg',
  //         itemName: 'Sweet Lime (5kg)',
  //         price: 421
  //       },
  //     ]
  //   },
  //   {
  //     categoryName: 'Food & Snacks',
  //     categoryImageUrl: 'assets/categories-menu-svg/food-snacks.svg',
  //     itemsList: [],
  //     dealItems: []
  //   },
  //   {
  //     categoryName: 'Vegetables',
  //     categoryImageUrl: 'assets/categories-menu-svg/vegetbles.svg',
  //     itemsList: [],
  //     dealItems: []
  //   },
  //   {
  //     categoryName: 'Oils',
  //     categoryImageUrl: 'assets/categories-menu-svg/Oil.svg',
  //     itemsList: [],
  //     dealItems: []
  //   },
  //   {
  //     categoryName: 'Nuts & Seeds',
  //     categoryImageUrl: 'assets/categories-menu-svg/nuts-seeds.svg',
  //     itemsList: [],
  //     dealItems: []
  //   },
  //   {
  //     categoryName: 'Pulses',
  //     categoryImageUrl: 'assets/categories-menu-svg/pluses.svg',
  //     itemsList: [
  //       {
  //         listName: 'Regular',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       },
  //       {
  //         listName: 'Seasonal fruits',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       },
  //       {
  //         listName: 'Exotic fruits',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       }
  //     ],
  //     dealItems: [
  //       {
  //         itemImageUrl: 'https://andhragreens.com/data/p_images/1595316452831_220x220.jpeg',
  //         itemName: 'Taiwan Red Lady Papaya (5kg)',
  //         price: 304
  //       },
  //       {
  //         itemImageUrl: 'https://andhragreens.com/data/p_images/1595316109774_220x220.jpeg',
  //         itemName: 'Sweet Lime (5kg)',
  //         price: 421
  //       },
  //     ]
  //   },
  //   {
  //     categoryName: 'Spices',
  //     categoryImageUrl: 'assets/categories-menu-svg/spices.svg',
  //     itemsList: [
  //       {
  //         listName: 'Regular',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       },
  //       {
  //         listName: 'Aromatic spices',
  //         list: [
  //           'Garlic',
  //           'Chilli powder',
  //           'Turmeric powder',
  //           'GCC Araku Valley Vishakhi',
  //           'Giri Gold Black Pepper',
  //           'GCC Kumkuma Powder ',
  //           'Erode Turmeric Powder',
  //           'Girijan Chili Powder',
  //         ]
  //       },
  //       {
  //         listName: 'Exotic fruits',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       }
  //     ],
  //     dealItems: [
  //       {
  //         itemImageUrl: 'https://andhragreens.com/data/p_images/1595316452831_220x220.jpeg',
  //         itemName: 'Taiwan Red Lady Papaya (5kg)',
  //         price: 304
  //       },
  //       {
  //         itemImageUrl: 'https://andhragreens.com/data/p_images/1595316109774_220x220.jpeg',
  //         itemName: 'Sweet Lime (5kg)',
  //         price: 421
  //       },
  //     ]
  //   },
  //   {
  //     categoryName: 'Personal Care',
  //     categoryImageUrl: 'assets/categories-menu-svg/personal-care.svg',
  //     itemsList: [
  //       {
  //         listName: 'Regular',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       },
  //       {
  //         listName: 'Seasonal fruits',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       },
  //       {
  //         listName: 'Exotic fruits',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       }
  //     ],
  //     dealItems: [
  //       {
  //         itemImageUrl: 'https://andhragreens.com/data/p_images/1595316452831_220x220.jpeg',
  //         itemName: 'Taiwan Red Lady Papaya (5kg)',
  //         price: 304
  //       },
  //       {
  //         itemImageUrl: 'https://andhragreens.com/data/p_images/1595316109774_220x220.jpeg',
  //         itemName: 'Sweet Lime (5kg)',
  //         price: 421
  //       },
  //     ]
  //   },
  //   {
  //     categoryName: 'Seasonal Fruits',
  //     categoryImageUrl: 'assets/categories-menu-svg/seasonal-fruits.svg',
  //     itemsList: [
  //       {
  //         listName: 'Regular',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       },
  //       {
  //         listName: 'Seasonal fruits',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       },
  //       {
  //         listName: 'Exotic fruits',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       }
  //     ],
  //     dealItems: [
  //       {
  //         itemImageUrl: 'https://andhragreens.com/data/p_images/1595316452831_220x220.jpeg',
  //         itemName: 'Taiwan Red Lady Papaya (5kg)',
  //         price: 304
  //       },
  //       {
  //         itemImageUrl: 'https://andhragreens.com/data/p_images/1595316109774_220x220.jpeg',
  //         itemName: 'Sweet Lime (5kg)',
  //         price: 421
  //       },
  //     ]
  //   },
  //   {
  //     categoryName: 'Exotic Fruits',
  //     categoryImageUrl: 'assets/categories-menu-svg/exotic-fruits.svg',
  //     itemsList: [
  //       {
  //         listName: 'Regular',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       },
  //       {
  //         listName: 'Seasonal fruits',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       },
  //       {
  //         listName: 'Exotic fruits',
  //         list: [
  //           'Apple',
  //           'Banana',
  //           'Coconut',
  //           'Custard apple',
  //           'Dates',
  //           'Fig ',
  //           'Grapes',
  //           'Lemon, lime',
  //         ]
  //       }
  //     ],
  //     dealItems: [
  //       {
  //         itemImageUrl: 'https://andhragreens.com/data/p_images/1595316452831_220x220.jpeg',
  //         itemName: 'Taiwan Red Lady Papaya (5kg)',
  //         price: 304
  //       },
  //       {
  //         itemImageUrl: 'https://andhragreens.com/data/p_images/1595316109774_220x220.jpeg',
  //         itemName: 'Sweet Lime (5kg)',
  //         price: 421
  //       },
  //     ]
  //   }
  // ];

