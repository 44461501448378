import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from '@app/env';
import { KL_NOTIFICATION, KalgudiNotification } from '@kalgudi/core/config';
import { ApiResponseCommon } from '@kalgudi/types';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Storefront } from '../project-common/properties/constants';
import { CartService } from '../project-common/services/cart.service';
import { DbUpdateService } from '../project-common/services/db-update.service';
import { GoogleAnalyticsService } from '../project-common/services/google-analytics.service';
import { HttpWithAuthService } from '../project-common/services/http-interceptor.service';
import { SpinnerService } from '../project-common/services/spinner.service';
import { ToasterService } from '../project-common/services/toaster.service';
import { WishListService } from '../project-common/services/wish-list.service';
import { AuthenticationService } from './authentication.service';
import { OtpConfirmationDialogComponent } from './otp-confirmation-dialog/otp-confirmation-dialog.component';

// import { FcmService } from '../project-common/services/fcm.service';
// import { FcmService } from '../project-common/services/fcm.service';
// import { FcmService } from '../project-common/services/fcm.service';
@Injectable({
  providedIn: 'root'
})
export class AuthMediatorService {
  loginRequest: any;
  isInProgress: boolean;
  redirectionUrl: any;
  countryCode: any;

  private readonly GUEST_LOGIN_API = `${environment.nodeServer}/v2/store/${Storefront.APP_NAME}/auth/guest-login`;

  constructor(
    public httpWithAuth: HttpWithAuthService, 
    private localStorage: LocalStorage,
    private updateDB: DbUpdateService, 
    private http: HttpClient,
    private cart: CartService, 
    private spinner: SpinnerService, 
    private toaster: ToasterService, 
    private translator: TranslateService,
    private wishlistService: WishListService, 
    private gaTrack: GoogleAnalyticsService, 
    private dbUpdate: DbUpdateService, 
    private router: Router, 
    private snackbar: MatSnackBar, 
    public dialog: MatDialog, 
    public auth : AuthenticationService,
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,
    // private notification: FcmService 
    ) { }

  public processLogin (request: any): Observable<any> {
    request.sessionId = '';
    this.loginRequest = request;
    this.isInProgress = true;
    this.spinner.changeStatus(true, true);
    this.redirectionUrl = this.httpWithAuth.redirectUrl || window.localStorage['blockedUrl'];
    
    return new Observable(observer => {
      this.auth.login(request).subscribe( response => {
        if (response.code === 200) {
              // Changing as per the response of the v2 service

              this.auth.getProfileDataFromDynamoDB(response.data.userBasicDetail.profileKey, true).subscribe( userData => {       

            this.processLoginSuccessResponse(userData);
            observer.next(userData);
            observer.complete();
          });
        } else {          
          this.processLoginFailureResponse(response);
          observer.next(response);
          observer.complete();
        }
      }, error => {        
        this.processLoginFailureResponse(error);
        observer.error(error);
        observer.complete();
      });
    });
  }

  guestLogin(payload: {name: string, phone: string}) {

    return this.http.post<ApiResponseCommon>(this.GUEST_LOGIN_API, payload)

    .pipe(map(res => res.data));

  }

  guestAutoLogin(loginResponse) {

    this.loginRequest = { mobileNo: loginResponse.data.userBasicDetail.mobileNo, password: btoa(loginResponse.password)};

    this.auth.processAfterLogin(loginResponse, this.loginRequest);



    this.auth.getProfileDataFromDynamoDB(loginResponse.data.userBasicDetail.profileKey, true).subscribe( userData => {       

      this.processLoginSuccessResponse(userData);

    });

  }



  /**
   * This will throw a message to user if login fails 
   * @param response error 
   */
  private processLoginFailureResponse (response) {
    this.auth.isInProgress = false;
    this.spinner.changeStatus(false, false);    
    this.translator.get(['Login failed!',response.info  || 'Server is busy, please try after some time', 'error']).subscribe(translatedText=>{
    
    //this.toaster.showMessage(translatedText['Login failed!'],translatedText[response.info]  || translatedText['Server is busy, please try after some time'],translatedText['error'], 8000);
    this.notification.showSweetAlert({
      title: translatedText[response.info],
      icon: 'warning',
    });

    })
  }

  /**
   * This will set multiple required flags to make user use 
   * all authenticated pages. and updates local storage with user profile data
   * @param userData profile data of user
   */
  private processLoginSuccessResponse (userData) {
    this.loginRequest.mobileNo = userData.mobileNo;
    // Encoding password to store in local storage
    this.loginRequest.password = atob(this.loginRequest.password);
    this.dbUpdate.updateUserData(userData, this.loginRequest);
    this.gaTrack.updateCheckoutOptionOnAnalytics(1, {'action': 'Login'});
    this.cart.getPersistedCartData();
    // this.notification.getStoreNotification();
    this.wishlistService.init();
    this.auth.processFcm(userData.profileKey);
    
    // this.auth.checkAssistanceEligibility(userData.profileKey).subscribe(response => {
    //   if (response.code === 200) {
    //     window.localStorage.setItem(btoa('canAssist'), JSON.parse(response.data).isAssistant);
    //   }
    // });
    
    // setTimeout(() => {
    //   this.auth.isInProgress = false;
    //   this.spinner.changeStatus(false, false);
    //   this.moveToNextPage();
    // },600)

    this.auth.isInProgress = false;
    this.spinner.changeStatus(false, false);
    this.moveToNextPage();
  }
  
  /**
   * This will decide whether to navigate to blocked page or to previous page
   */
  private moveToNextPage () {
    if (this.redirectionUrl || window.localStorage['blockedUrl']) {
      this.moveToBlockedUrl();
    } else {
      this.moveToPreviousUrl(); 
    }
  }

  /**
   * If user has been blocked because of authentication fail, after 
   * autherization control will move to blocked page 
   */
  private moveToBlockedUrl () {
    const url = this.redirectionUrl || window.localStorage['blockedUrl'];
    delete window.localStorage['blockedUrl'];
    this.router.navigateByUrl(url);
  }

  /**
   * This will navigate the control to previous page, 
   * from where the user has come to signin page
   */
  private moveToPreviousUrl () {
    this.router.navigateByUrl(window.localStorage['previousUrl']);
  }

 
    
    public sendOTPForRecoverPassword (req,isOtpPage?): Observable<any> {
    let request = {};
    isOtpPage = isOtpPage ? isOtpPage : false;
    this.countryCode = '+91';
    this.localStorage.getItem('countryId').subscribe(code => {
      this.countryCode = code ? code : '+91';
    });
    if (req.type === 'email') {
      request = {
        'emailId': req.userId,
        'mobileNumber': '',
        'mobileTelecomCode': ''
      };
      this.localStorage.setItemSubscribe('recoveryId', req.userId);
    } else {
      request = {
        'emailId': '',
        'mobileNumber': this.countryCode + req.userId,
        'mobileTelecomCode': this.countryCode
      };
      this.localStorage.setItemSubscribe('recoveryId', this.countryCode + req.userId);
    }
    this.spinner.changeStatus(true, true);
    return new Observable(observer => {
      this.auth.generateOtpToRecoverPassword(request).subscribe( response => {
        this.spinner.changeStatus(false, false);
        if ( response.code === 200) {
          window.sessionStorage['otpRequest'] = JSON.stringify(request);
          this.localStorage.setItemSubscribe('recoveryIdType', req.type);
          setTimeout(() => {
            if (isOtpPage)
            this.router.navigateByUrl('auth/recover');
          }, 300);
        } else {
          const error = response.error.split(':');
          this.translator.get(['Something is wrong please try after some time', 'OK']).subscribe(translatedText=>{
          this.snackbar.open(translatedText['Something is wrong please try after some time'],translatedText['OK'], {
            duration: 5000,  panelClass: ['snakbar-color']
          });
        })
        }
        observer.next(response);
        observer.complete();
      }, error => {
        this.spinner.changeStatus(false, false);
        console.error(error);
        observer.error(error);
        observer.complete();
      });
    });
  }

  public processRecoverPassword (recoveryType,req): Observable<any> {

    const request = {
      'mobileNumber': '',
      'mobileTelecomCode': '',
      'otp': '',
      'emailId': '',
      'emailVertifyVal': '',
      'password': ''
    };

    if (recoveryType === 'email') {
      request.emailId =  req.userId;
      request.emailVertifyVal = req.otp;
    } else {
      request.mobileNumber =  req.userId;
      request.mobileTelecomCode = this.countryCode;
      request.otp = req.otp;
    }
    request.password = req.password;

    this.spinner.changeStatus(true, true);
    return new Observable(observer => {
      this.auth.recoverPassword(request).subscribe( response => {
        this.spinner.changeStatus(false, false);
        if ( response.code === 200) {
          this.spinner.changeStatus(true, true);
          response = JSON.parse(response.data);
          window.sessionStorage.removeItem('otpRequest');
          this.processLogin(response).subscribe();
          observer.next(response);
          observer.complete();
        } else {
          const error = response.error.split(':')
          this.snackbar.open(error[error.length - 1], 'OK', {
            duration: 5000,  panelClass: ['snakbar-color']
          });
          observer.error(response);
          observer.complete();
        }
      });
    });
  }

  public verifyRegistrationOtp (req): Observable<any> {
    let request;
    if (req.signupType === 'E-mail') {
        request = {
            emailVertifyVal   : req.otp,
            emailId         : req.userId
        };
    } else {
        request = {
            mobileNumber  : req.userId,
            otp           : req.otp,
        };
    }

    this.spinner.changeStatus(true, true);
    return new Observable(observer => {
      this.auth.verifyOtp(request).subscribe( response => {
        this.spinner.changeStatus(false, false);
  
        if (response.code === 200) {
          this.httpWithAuth.setImediateHeaders(response.mobileNo, response.decreptedPwd);
          this.gaTrack.updateCheckoutOptionOnAnalytics(1, {'action': 'Signup'});
          if ( req.isDilog ) {
            const confirmDilog = this.dialog.open(OtpConfirmationDialogComponent,{
              width: '250px',
              data: { userId: req.userId, signupType: req.signupType}
            })
            confirmDilog.afterClosed().subscribe(result=>{
              
            });
          }
          const userProfileData = JSON.parse(response.data);
          // console.log('Result after closed '+result)
          const login = {
            mobileNo : response.mobileNo,
            password : userProfileData.decreptedPwd
          };
          
          this.updateDB.updateUserData(userProfileData, login);
          this.auth.getProfileDataFromDynamoDB(userProfileData.profileKey, true).subscribe( userData => {
            this.updateDB.updateUserData(userData);
            this.localStorage.removeItemSubscribe('signupData');
            this.router.navigateByUrl(window.localStorage['previousUrl']);
            observer.next(JSON.parse(response.data));
            observer.complete();
          });
        } else {
          this.translator.get(['Invalid OTP', response.error, 'error']).subscribe(translatedText=>{
          //this.toaster.showMessage(translatedText['Invalid OTP'], translatedText[response.error], translatedText['error'], 8000);
          this.notification.showSweetAlert({
            title: 'Invalid OTP',
            icon: 'warning',
          });
          })
          observer.error(response);
          observer.complete();
        }
      }, error => {
        this.spinner.changeStatus(false, false);
        observer.error(error);
        observer.complete();
      });
    });
  }
}
