import { Quotation, StateInformation } from "./quotation.model";

import { Attachment } from "./attachment.model";
import { ProductLevel3, BaseProduct } from "./product-level-3.model";
import { BasicUserInformation } from "./basic-user-information.model";
import { SmartElement } from "./smart-element.model";

export class Requirement {
    requirementId:          string;
    createdTS:              string;
    updatedTS:              string;
    numberOfViews:          number;
    quotations?:             Quotation[];
    participants?:           string[];
    bestQuotation?:          Quotation;
    latestReply?:            ReplyMessage;
    stateInformation:       StateInformation;
    totalQuotationReceived: number;
    totalEnquiries:         number;
    smsSentTS?:             any;
    emailSentTS?:           any;
    s3Url:                  string;
    isAutoCreatedRFQ:       boolean;
    isARFQ:                 boolean;
    isAssistedRFQ:          boolean;
    defaultImage:           string;
    productName:            string;
    product:                BaseProduct;
    level3Product:          ProductLevel3;
    title:                  string;
    quantity:               string | number;
    unit:                   Unit;
    isPrivateEnquiry:       boolean;
    priceRange:             PriceRange;
    tradeTerms:             string;
    shippingTerms:          string;
    details:                string;
    expiresOn:              string;
    attachments:            Attachment[];
    createdBy:              BasicUserInformation;
    assistedBy?:             Assistant;
    assignedBOS?:            Assistant;
    assignedBOSLead?:        Assistant;
    salesPerson?:            Assistant;
    salesManager?:           Assistant;
    salesPartner1?:          Assistant;
    salesPartner2?:          Assistant;
    salesPartner3?:          Assistant;
    arfqType?:               any;
    isViewed:               boolean;
    listOfSMS?:             any;
    [property: string]:    any; 

}

export interface Assistant {
    firstName: string;
    email?:     string;
    profileKey?: string;
    role?:      AssistantRole;
    profilePicUrl?: string
}

export enum AssistantRole {
    MANAGER = 'salesManager',
    BOS = 'BOS',
    LEAD = 'BOSLead',
    SALESMAN = 'salesman',
    CONTRIBUTOR = 'contributor',
    SALESPARTNER = 'salesPartner'
}

export interface ReplyMessage {
    CT:          string;
    type:        MessageType;
    quotedPrice?: number;
    message?:     string;
    sender:      BasicUserInformation;
    assistedBy?: Assistant;
    quotationId: string;
    isErased?: boolean;
}

export enum MessageType {
    TEXT = 'TEXT',
    QUOTE = 'QUOTE',
    INFO = 'INFO',
    ATTACHMENT = 'ATTACHMENT'
}

export interface PriceRange {
    minimum: string;
    maximum: string;
}

export interface Unit {
    label: string;
    value: string;
}

export enum RequirementType {
    all = 'ALL',
    enquiry = 'ENQUIRY',
    generic = 'GENERIC',
    assisted = 'ASSISTED'
}