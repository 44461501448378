<div class="thank-you-card d-flex flex-column align-items-center">
  <!-- <i class="material-icons check-icon">check_circle_outline</i> -->
  <i class="fa fa-check-circle-o check-icon" aria-hidden="true"></i>

  <h1 class="congrats-text">Congratulation</h1>

  <p class="t-text">
    Your pre-booking has been<br/> 
    confirmed, a confirmation 
    <span *ngIf="preBookInfo?.userDetails?.email"> email </span>
    <span *ngIf="preBookInfo?.userDetails?.email && preBookInfo?.userDetails?.mobile">& </span>
    <span *ngIf="preBookInfo?.userDetails?.mobile">SMS </span>has been sent to <br/>
    <span class="t-email" *ngIf="preBookInfo?.userDetails?.email">{{preBookInfo?.userDetails?.email}}</span><br/>
    <span *ngIf="preBookInfo?.userDetails?.email && preBookInfo?.userDetails?.mobile">&</span><br/>
    <span class="t-email" *ngIf="preBookInfo?.userDetails?.mobile">{{preBookInfo?.userDetails?.mobile}}</span>
  </p>

  <button 
		mat-button 
		class="t-close-btn"
		(click)="onClose()"
		>Close</button>
</div>
