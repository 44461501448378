import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { finalize } from 'rxjs/operators';
import { ThirdPartyLibraries } from 'src/app/project-common/properties/constants';
import { ScriptLoaderService } from 'src/app/project-common/services/script-loader.service';
import { SideBarService } from 'src/app/side-bar.service';

import { MangoService } from '../../mango.service';

@Component({
  selector: 'mango-cart-side-bar',
  templateUrl: './mango-cart-side-bar.component.html',
  styleUrls: ['./mango-cart-side-bar.component.scss']
})
export class MangoCartSideBarComponent implements OnInit {

  isPreBookingForm = false;

  isOtpVerified = false;

  isOtpFormSuccess = false;

  verificationInfo: any = {};

  selectedProductList: any;

  numOfItems: number;

  preBookInfo: any;

  isExistingUser = false;

  isExistingAds = false;

  constructor(
    private sidebarService: SideBarService,
    private loader: ScriptLoaderService,
    private service: MangoService,
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,
  ) { 
    this.loader.loadStyle(ThirdPartyLibraries.jostFont).subscribe();

    this.service.mangoCartItemObject$.subscribe(res => {
      this.selectedProductList = this.getSelectedProductList();
      this.numOfItems = this.getNumOfItems();

      this.isPreBookingForm = false;
      this.isOtpFormSuccess = false;
    })
  }

  ngOnInit() {
    
  }

  public close(): void {
    this.sidebarService.closeMangoCartSidebar();
  }

  getSelectedProductList() {
    return this.service.getPreBookingObjects();
  }

  getNumOfItems() {
    return Object.keys(this.selectedProductList).length;
  }

  onNext() {
    this.isPreBookingForm = true;
  }

  onBack() {
    this.isPreBookingForm = false;
  }

  onOtpVerification(event) {
    this.verificationInfo = event;
  }

  /** Call service to verify the Pre-booking */
  verifyPreBooking() {
    if(this.verificationInfo && this.verificationInfo.otp && this.verificationInfo.requestId) {
      this.isOtpVerified = true;
      let otp = this.verificationInfo.otp;
      this.service.verifyPreBooking(this.verificationInfo.requestId, otp)
      .pipe(
        finalize(() => this.isOtpVerified = false)
      )
      .subscribe((res: any) => {
        if(res.data) {
          this.service.clearDbOnPreBookingSuccess();
          this.preBookInfo = res.data;
          this.isPreBookingForm = false;
          this.isOtpFormSuccess = true;
          // console.log(res);
        }
      },
      error => {
        this.isOtpFormSuccess = false;
        //this.notification.showMessage(error.error.info);
        this.notification.showSweetAlert({
          title: error.error.info,
          icon: 'warning',
        });
      });
    }
  }

  onSuccessOfPrebook(event) {
    this.isPreBookingForm = false;
    this.isOtpFormSuccess = false;
  }

  onExistingAddressSelection(preBookInfo) {
    this.service.clearDbOnPreBookingSuccess();
    this.preBookInfo = preBookInfo;
    this.isExistingUser = preBookInfo.isVerified;
    this.isPreBookingForm = false;
    this.isOtpFormSuccess = true;
  }

  onExistingAd(event) {
    this.isExistingUser = event;
  }
}
