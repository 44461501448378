import { Injectable } from '@angular/core';
import { HttpWithAuthService } from './http-interceptor.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Storefront } from '../properties/constants';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private httpWithAuth: HttpWithAuthService) { }

  public search (keyword, request?, baseCategory?, l2?, l3?, l4?): Observable<any> {
    let url = environment.baseUrl;
    request = request || {};
    // https://www.kalgudi.com/rest/v1/store/bizstore/productsearch?keyword=shirt&offset=0&limit=20&category=
    url += `/v2/search/bizstore/products?keyword=${keyword}&offset=${request.offset || 0}&limit=${ request.limit || 10}&category=${baseCategory || ''}&l2Id=${l2}&l3Id=${l3}&l4Id=${l4}`;

    url = `${url}&store=${Storefront.APP_NAME}`;

    return this.httpWithAuth.get(url).pipe(map(data => {
      return data;
    }));
  }

  public loadMore (request: any, from: string, category: string): Observable<any> {
    let url = environment.baseUrl;
    request = request || {};
    url += `/v2/search/bizstore/products?from=${from}&offset=${request.offset || 0}&limit=${ request.limit || 10}&category=${category}`;
    return this.httpWithAuth.get(url).pipe(map(data => {
      return data;
    }));
  }

  public getSuggessions (keyword: string, request?: any, category = "" ,l2?, l3?, l4?): Observable<any> {
    let url = environment.baseUrl;
    request = request || {};
    // http://192.168.1.65/rest/v1/store/bizstore/autocomplete/storeproduct?keyword=a&offset=0&limit=20

    url += `/v2/search/bizstore/products/autocomplete?keyword=${keyword}&offset=${request.offset || 0}&limit=${ request.limit || 10}&category=${category || ''}&l2Id=${l2}&l3Id=${l3}&l4Id=${l4}`;

    url = `${url}&store=${Storefront.APP_NAME}`;

    return this.httpWithAuth.get(url).pipe(map((res) => {
      return res;
    }));
  }


  public searchUsers ({keyword, offset, limit}) {
    let url = environment.baseUrl;
    url += `/rest/v1/search/profile?q=${keyword}&offset=${offset}&limit=${limit}`;
    return this.httpWithAuth.get(url).pipe(map(res => {
      const result: any =  res;

      // Parsing all individual users here instead of doing it in consumers of this method
      const {data = []} = result; // Destructuring data from result object
      result.data = data.map(i => JSON.parse(i));
      return result;
    }));
  } 

  public searchProducts (keyword, profileKey, limit = 20, offset = 0): Observable<any> {
    const url = `${environment.baseUrl}/v2/search/farmstore/products?keyword=${keyword}&offset=${offset}&limit=${limit}&sellerId=${profileKey}`

    return this.httpWithAuth.get(url).pipe(
      map((res: any) => res)
    );
  }

}
