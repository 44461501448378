<div class="mango-pre-booking-form">
  <form [formGroup]="preBookForm">
    
    <div class="form-heading">Personal information</div>

    <div formGroupName="userDetails">
  
      <!-- name -->
      <mat-form-field appearance="outline" class="w-100">
        <input matInput type="text"
          class="" placeholder="Name*" formControlName="firstName"
          autocomplete="off"/>
        <!-- <mat-error>Please enter your name</mat-error> -->
      </mat-form-field>
    
      <!-- email -->
      <mat-form-field appearance="outline" class="w-100">
        <input matInput type="email"
          class="" placeholder="Email id*" formControlName="email"
          autocomplete="off"/>
        <!-- <mat-error>Please enter valid email id</mat-error> -->
      </mat-form-field>
    
      <!-- mobile number -->
      <mat-form-field appearance="outline" class="w-100">
        <input matInput type="tel" 
        (keypress)="($event.charCode >= 48 && $event.charCode < 58)" 
        class="" placeholder="Mobile*" formControlName="mobile"
          autocomplete="off" />
        <!-- <mat-error>Please enter valid mobile number</mat-error> -->
      </mat-form-field>
  
    </div>
    
    <!-- <div fxLayout="row wrap" fxLayoutAlign="start center" class="address-form-cls">
      <a *ngIf="isLoggedIn" (click)="selectExistingAddress()" class="mr-10">Select from existing address</a>
      <a (click)="newAddress()">Add new address</a>
    </div> -->
    <div fxLayout="row wrap" fxLayoutAlign="start center" class="address-form-cls" *ngIf="isLoggedIn">
      <mat-radio-group aria-label="Select an option" fxFlex fxLayoutAlign="space-between center" [formControl]="favoriteAddress">
        <mat-radio-button value="OLD">Select existing address</mat-radio-button>
        <mat-radio-button value="NEW">Add new address</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="isExistingAddress && !selectedAddress" 
    class="w-100 d-flex align-items-center justify-content-center py-30">
      <button mat-button (click)="selectExistingAddress()" class="edit-btn">
        Select address
      </button>
    </div>

    <div *ngIf="isExistingAddress && selectedAddress" class="w-100">
      <div class="selected-address">
        <div class="name">{{selectedAddress?.name}} ({{ selectedAddress?.phone }})</div>
        <div *ngIf="selectedAddress?.addressLine1">{{ selectedAddress?.addressLine1 }},</div>
        <div *ngIf="selectedAddress?.addressLine2">{{ selectedAddress?.addressLine2 }},</div>
        <div *ngIf="selectedAddress?.landmark">{{ selectedAddress?.landmark }},</div>
        <div *ngIf="selectedAddress?.city">{{ selectedAddress?.city }}</div>
        <div> {{ selectedAddress?.state }} - {{ selectedAddress?.postalCode }}</div>

        <div class="d-flex align-items-center justify-content-end">
          <button mat-button (click)="selectExistingAddress()" class="edit-btn">
            <i class="material-icons">create</i>
            Edit
          </button>
        </div>
      </div>
    </div>

    <div formGroupName="address" *ngIf="!isExistingAddress">
      
      <!-- d.no -->
      <mat-form-field appearance="outline" class="w-100">
        <input matInput type="text" [errorStateMatcher]="errorMatcher"
          class="" placeholder="House / Apartment / Street Address*" formControlName="addressLine1"
          autocomplete="off"/>
        <!-- <mat-error>Please enter door number</mat-error> -->
      </mat-form-field>
    
      <!-- city -->
      <mat-form-field appearance="outline" class="w-100">
        <input matInput type="text" [errorStateMatcher]="errorMatcher"
          class="" placeholder="City*" formControlName="city"
          autocomplete="off"/>
        <!-- <mat-error>Please enter door number</mat-error> -->
      </mat-form-field>
      <!-- <div class="d-flex align-items-center" fxLayoutGap="10px">
      </div> -->
    
      <div class="d-flex align-items-center" fxLayoutGap="10px">
        <!-- state -->
        <mat-form-field appearance="outline" fxFlex="calc(50% -5px)">
          <mat-select formControlName="state" placeholder="State*" panelClass="mango-select-cls" 
          [errorStateMatcher]="errorMatcher">
            <mat-option *ngFor="let state of stateList" [value]="state?.value">
              {{state?.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      
        <!-- pin code -->
        <mat-form-field appearance="outline" fxFlex="calc(50% -5px)">
          <input matInput type="text" [errorStateMatcher]="errorMatcher"
            class="" placeholder="Pin code*" formControlName="postalCode"
            autocomplete="off"/>
          <!-- <mat-error>Please enter valid pin code</mat-error> -->
        </mat-form-field>
      </div>
  
    </div>

    <!-- delivery expected date -->
    <mat-form-field appearance="outline" class="w-100">
      <mat-select formControlName="deliveryExpectedDate" placeholder="Delivery expected date" panelClass="mango-select-cls">
        <mat-option *ngFor="let time of expectedTimeLine" [value]="time?.value" [disabled]="verifyWeek(time?.value)">
          {{time?.id}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  
    <div class="d-flex align-items-center justify-content-center request-otp">
      <ng-container *ngIf="!isExistingAddress">

        <kl-flat-button 
          [type]="'submit'" 
          [label]="'GET OTP'" 
          [progress]="isOtpRequested" 
          [disabled]="preBookForm?.invalid || requestId"
          (clicked)="getPreBookingOTP()">
        </kl-flat-button>

      </ng-container>
      

      <ng-container *ngIf="isExistingAddress">

        <kl-flat-button 
          [type]="'submit'" 
          [label]="'SUBMIT'" 
          [progress]="isOtpRequested" 
          [disabled]="!selectedAddress?.name"
          (clicked)="submitWithExistingAddress()">
        </kl-flat-button>

      </ng-container>
      

    </div>
  </form>
  
  <div class="pt-15" *ngIf="requestId">

    <div class="form-heading">OTP has been sent to {{preBookForm?.value?.userDetails?.mobile || preBookForm?.value?.userDetails?.email}}</div>
  
    <mat-form-field appearance="outline" class="w-100">
      <input matInput type="text"
        class="" 
        placeholder="Enter your 6 digit OTP here" 
        [formControl]="otp"
        autocomplete="off"/>
    </mat-form-field>

  </div>

</div>