import { Component, OnInit } from '@angular/core';
import { StorefrontService } from 'src/app/storefront/storefront.service';

@Component({
  selector: 'nested-menu',
  templateUrl: './nested-menu.component.html',
  styleUrls: ['./nested-menu.component.scss']
})
export class NestedMenuComponent implements OnInit {

  isCategoriesVisible = false;

  allCategories: any[] = [];

  constructor(private store: StorefrontService) { }

  ngOnInit() {

    // this.store.getCategories().subscribe(cat => this.allCategories = cat ? cat.subCategories: []);


  }

}
