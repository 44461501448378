<mat-dialog-content class="mat-typography">
    <ng-container  *ngIf="updatedProducts?.length">
        <h2 translate>Prices for following products have been updated</h2>
        <table mat-table [dataSource]="updatedProducts" class="mat-elevation-z3 mb-20" style="min-width: 700px;">
    
            <!-- Position Column -->
            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef><span translate>Product name</span></th>
                <td mat-cell *matCellDef="let product"> {{product?.productLevel3Title}} </td>
            </ng-container>
    
            <!-- Name Column -->
            <ng-container matColumnDef="old">
                <th mat-header-cell *matHeaderCellDef><span translate>Old price</span></th>
                <td mat-cell *matCellDef="let product"><del> {{product?.pricePerUnit | currency : 'INR' : 'symbol' : '1.2-2' }} </del></td>
            </ng-container>
    
            <!-- Weight Column -->
            <ng-container matColumnDef="new">
                <th mat-header-cell *matHeaderCellDef><span translate>New price</span></th>
                <td mat-cell *matCellDef="let product"> {{product?.updatedPrice | currency : 'INR' : 'symbol' : '1.2-2' }} </td>
            </ng-container>
    
            <!-- Symbol Column -->
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef><span translate>Amount</span></th>
                <td mat-cell *matCellDef="let product"> {{product?.productQuantity * product?.updatedPrice | currency : 'INR' : 'symbol' : '1.2-2' }} </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="updatedProductsDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: updatedProductsDisplayedColumns;"></tr>
        </table>
    </ng-container>
    

    <ng-container   *ngIf="invalidRemovedProducts?.length">

        <h2>Below products are unavailable and will be removed from cart</h2>
        <table mat-table [dataSource]="invalidRemovedProducts" class="mat-elevation-z3 mb-10" style="min-width: 700px;">
    
            <!-- Position Column -->
            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef><span translate>Product name</span></th>
                <td mat-cell *matCellDef="let product"> {{product?.productLevel3Title}} </td>
            </ng-container>
    
            <!-- Name Column -->
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef><span translate>Price</span></th>
                <td mat-cell *matCellDef="let product"><del> {{product?.pricePerUnit | currency : 'INR' : 'symbol' : '1.2-2' }} </del></td>
            </ng-container>
    
            <!-- Symbol Column -->
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef><span translate>Amount</span></th>
                <td mat-cell *matCellDef="let product"> {{product?.productQuantity * product?.updatedPrice | currency : 'INR' : 'symbol' : '1.2-2' }} </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="invalidRemovedProductsDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: invalidRemovedProductsDisplayedColumns;"></tr>
        </table>
    </ng-container>
    



</mat-dialog-content>
<mat-dialog-actions align="end">
    <!-- <button mat-button mat-dialog-close>Cancel</button> -->
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial><span>{{'Got it!' | translate}}</span></button>
</mat-dialog-actions>