

export class Country {
    id?: number;
    name: string;
    states?: string[];
    currencyCode: string;
    currencySymbol: string;
    mobileTelecomCode: string;
    mobileNoLength: number;
    pincodeLength: number;
    currencyName: string;
    imageUrl?: string;
    currencyInINR: number;
    incrementBy: number;

    constructor(country) {
        Object.assign(this, country);
    }

    getPrice(price, increment = true) {
        let calculatedPrice = increment ? (+price + (+price * this.incrementBy)) : price;

        return +(calculatedPrice / this.currencyInINR).toFixed(2);
    }
}