import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'all-store',
  templateUrl: './all-store.component.html',
  styleUrls: ['./all-store.component.scss']
})
export class AllStoreComponent implements OnInit {

  @Input() otherAppsList: otherAppsList;

  constructor() { }

  ngOnInit() {
  }

}

export interface otherAppsList {
  storeImageUrl:string;
  storeTitle:string;
  storeDescription:string;
  title:string;
  storeDetails?: {name:string , url:string, imageUrl: string, description: string}[];
}


