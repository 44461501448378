import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { KalgudiDialogsModule, KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { CheckoutModule } from '../checkout/checkout.module';
import { PSliderComponent } from '../project-common/components/p-slider/p-slider.component';
import { ProjectCommonModule } from './../project-common/project-common.module';
import { AboutUsComponent } from './about-us/about-us.component';
import { BizstoreFeaturesComponent } from './bizstore-features/bizstore-features.component';
import { CartSideBarItemComponent } from './cart-side-bar-item/cart-side-bar-item.component';
import { CartSideBarComponent } from './cart-side-bar/cart-side-bar.component';
import { DiscoverComponent } from './discover/discover.component';
import { DryFruitSectionComponent } from './dry-fruit-section/dry-fruit-section.component';
import { FreshpulsesComponent } from './freshpulses/freshpulses.component';
import { GetAppComponent } from './get-app/get-app.component';
import { AllStoreComponent } from './header/all-store/all-store.component';
import { HerbalProductSectionComponent } from './herbal-product-section/herbal-product-section.component';
import { HomeComponent } from './home/home.component';
import { AddressInfoComponent } from './mangoes/components/address-info/address-info.component';
import {
  ExistingAddressDialogComponent,
} from './mangoes/components/existing-address-dialog/existing-address-dialog.component';
import {
  ExistingAddressMobileDialogComponent,
} from './mangoes/components/existing-address-mobile-dialog/existing-address-mobile-dialog.component';
import { MangoBannerComponent } from './mangoes/components/mango-banner/mango-banner.component';
import { MangoCartItemComponent } from './mangoes/components/mango-cart-item/mango-cart-item.component';
import { MangoCartSideBarComponent } from './mangoes/components/mango-cart-side-bar/mango-cart-side-bar.component';
import { MangoHomePageComponent } from './mangoes/components/mango-home-page/mango-home-page.component';
import { MangoTileComponent } from './mangoes/components/mango-tile/mango-tile.component';
import { PreBookingFormComponent } from './mangoes/components/pre-booking-form/pre-booking-form.component';
import { ThankYouCardComponent } from './mangoes/components/thank-you-card/thank-you-card.component';
import { MultiPackProductsComponent } from './multi-pack-products/multi-pack-products.component';
import { PopularCategoryComponent } from './popular-category/popular-category.component';
import { ProductReviewsComponent } from './product-reviews/product-reviews.component';
import { ReviewItemComponent } from './product-reviews/review-item/review-item.component';
import { StoreFeaturesComponent } from './store-features/store-features.component';
import { StorefrontRoutingModule } from './storefont-routing.module';
import { SubscribeUsComponent } from './subscribe-us/subscribe-us.component';
import { TopCategoriesComponent } from './top-categories/top-categories.component';
import { TracebilityComponent } from './tracebility/tracebility.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { VeggieBasketComponent } from './veggie-basket/veggie-basket.component';
import { AllAboutComponent } from './header/all-about/all-about.component';
import { CustomMangoesComponent } from './custom-mangoes/custom-mangoes.component';
import { MangoTileSkeletonComponent } from './custom-mangoes/mango-tile-skeleton/mango-tile-skeleton.component';
import { SeasonalProductsHomePageComponent } from './seasonal-products/components/seasonal-products-home-page/seasonal-products-home-page.component';
import { SeasonalProductTileComponent } from './seasonal-products/components/seasonal-product-tile/seasonal-product-tile.component';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  imports: [
    CommonModule,
    StorefrontRoutingModule,
    ProjectCommonModule,
    FlexLayoutModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    TranslateModule,
    MatButtonModule,
    RouterModule,
    KalgudiNoContentModule,
    CheckoutModule,

    MatIconModule,
    MatTooltipModule,
    AngularSvgIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatRadioModule,
    MatButtonToggleModule,
    
    KalgudiButtonModule,
    KalgudiPipesModule,
    KalgudiDialogsModule
  ],
  declarations: [
    BizstoreFeaturesComponent,
    AboutUsComponent,
    HomeComponent,
    PSliderComponent,
    UnsubscribeComponent,
    HerbalProductSectionComponent,
    FreshpulsesComponent,
    DryFruitSectionComponent,
    VeggieBasketComponent,
    TopCategoriesComponent,
    TracebilityComponent,
    PopularCategoryComponent,
    DiscoverComponent,
    SubscribeUsComponent,
    MultiPackProductsComponent,
    GetAppComponent,
    ProductReviewsComponent,
    ReviewItemComponent,
    StoreFeaturesComponent,

    CartSideBarComponent,

    CartSideBarItemComponent,

    MangoHomePageComponent,

    MangoTileComponent,

    MangoBannerComponent,

    MangoCartSideBarComponent,

    MangoCartItemComponent,

    PreBookingFormComponent,

    ThankYouCardComponent,

    AllStoreComponent,
    
    ExistingAddressDialogComponent,

    ExistingAddressMobileDialogComponent,

    AddressInfoComponent,

    AllAboutComponent,

    CustomMangoesComponent,

    MangoTileSkeletonComponent,

    SeasonalProductsHomePageComponent,

    SeasonalProductTileComponent,


  ],
  exports: [
    // JuicerComponent,
    CartSideBarComponent,
    MangoCartSideBarComponent,
    AllStoreComponent,
    ExistingAddressDialogComponent,
    ExistingAddressMobileDialogComponent,
    AllAboutComponent
  ],
  entryComponents: [
    ExistingAddressDialogComponent,

    ExistingAddressMobileDialogComponent
  ],
})
export class StorefrontModule { }
