import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { GoogleLocation } from '@app/models';

@Component({
  selector: 'google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnChanges {

  @Input() location: GoogleLocation;

  /** To be shown in the marker */
  @Input() label: string;

  @Output() positionChanged: EventEmitter<{ latitude: number; longitude: number }> = new EventEmitter();

  hasValidLocation: boolean;

  latitude: number;
  longitude: number;

  labelOptions: any;

  constructor() { }

  ngOnChanges() {
    if (this.location) {
      try {
        this.latitude = JSON.parse(this.location.latitude);
        this.longitude = JSON.parse(this.location.longitude);
        this.hasValidLocation = true;
      } catch (e) {
        console.error(`Not a valid position`, e);
      }
      this.labelOptions = {
        color: 'white',
        fontFamily: '',
        fontSize: '14px',
        fontWeight: 'bold',
        text: this.label
      }
    }
  }

  /**
   * To emit current location lat,lng to parent component
   * 
   * @param event emitted value where position is dragged
   */
  public selectPosition(event: { coords: { lat: number; lng: number } }) {
    this.positionChanged.emit({ latitude: event.coords.lat, longitude: event.coords.lng });
  }

}
