<ng-container *ngIf="!requestedSuccessfully">

  <p class="font-22 p-10 m-0" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="data?.pincodeAvalilable">
    <i class="fa fa-check-circle fa-2x text-success" aria-hidden="true"></i>
    <span>
      Yay! We are shipping to <b>"{{data?.pincode}}"</b>
    </span>
  </p>

  <p class="font-22" *ngIf="!data?.pincodeAvalilable">
    Sorry! fruits and vegetables are unavailable for <b>"{{data?.pincode}}"</b> at this moment
  </p>


  <ng-container *ngIf="!data?.pincodeAvailable && pincodeRequestForm">

    <p>Explore the taste of Andhra pradesh across Spices, Pulses, Pickles, Herbal & Other Food Items !</p>

    <div fxLayoutAlign="center center">
      <button mat-stroked-button color="warn" type="button" mat-dialog-close>OK</button>
    </div>



    <!--<form [formGroup]="pincodeRequestForm">
      <div class="my-20" fxLayout fxLayout.xs="column" fxLayoutGap="20px">
        <div fxLayout="column">
          <input formControlName="name" placeholder="Name" type="text">
          <span class="mt-2 pl-5 text-danger font-12" 
            *ngIf="pincodeRequestForm?.get('name')?.touched && pincodeRequestForm?.get('name')?.invalid">
            Name is required
          </span>
        </div>
        <div fxLayout="column">
          <ng-container *ngIf="pincodeRequestForm?.get('email') as emailControl">
            <input 
              [class.disabled]="emailControl?.untouched && emailControl?.value" 
              formControlName="email" placeholder="Email" type="email">
            <span class="mt-2 pl-5 text-danger font-12" 
              *ngIf="emailControl?.touched && emailControl?.invalid">
              Enter valid email
            </span>
          </ng-container>
          
        </div>
        <div fxLayout="column">
          <ng-container *ngIf="pincodeRequestForm?.get('phone') as phoneControl">
            <input 
              [class.disabled]="phoneControl?.untouched && phoneControl?.value" 
              formControlName="phone" placeholder="Mobile" type="number">
            <span class="mt-2 pl-5 text-danger font-12" 
              *ngIf="phoneControl?.touched && phoneControl?.invalid">
              Enter valid mobile number
            </span>
          </ng-container>
          
        </div>
      </div>
  
      <div fxLayoutAlign="end center" fxLayoutGap="15px">
        <button mat-stroked-button color="warn" type="button" mat-dialog-close>Cancel</button>
        <button class="primary" mat-raised-button color="primary" [disabled]="!isValid" type="submit" (click)="submitRequest()">Request</button>
      </div>
    </form>-->

  </ng-container>
</ng-container>

<ng-container *ngIf="requestedSuccessfully">
  <p class="font-22 p-10 m-0" fxLayoutAlign="start center" fxLayoutGap="10px">
    <i class="fa fa-check-circle fa-2x text-success" aria-hidden="true"></i>
    <span>
      Request submitted successfully
    </span>
  </p>
</ng-container>


<button class="dialog-close" mat-icon-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>