<div class="isLoading">
  <div class="loader" style="height: auto;" fxLayoutAlign="space-between center">
    <div class="elm _6 img-ht"></div>
    <div class="elm _7 img-ht"></div>
  </div>
  <div fxLayout="row wrap" class="p-container">


    <div class="p-5" fxFlex="20%" fxFlex.sm="25%" fxFlex.xs="50%"
      *ngFor="let x of [1,1,1,1,1,1,1,1,1,1]">

      <div class="loader p-tile" fxLayout="column" fxLayoutGap="12.5px">
        <div fxFlex="250px" fxFlex.xs="191px" style="border-radius: 5px;align-items: center;" class="elm"></div>
        <div fxFlex="10px" class="elm _1"></div>
        <div fxFlex="10px" class="elm _2"></div>
        <div fxFlex="10px" class="elm _2"></div>
      </div>

    </div>
  </div>
</div>