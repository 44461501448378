import { NgModule } from '@angular/core';

import { CapitalizePipe } from './capitalize.pipe';
import { JoinArrayFieldsPipe } from './join-array-fields.pipe';
import { SafePipe } from './safe.pipe';
import { ScaleImagePipe } from './scale-image.pipe';
import { SummaryPipe } from './summary.pipe';
import { TimeAgoIsoPipe } from './time-ago-iso.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { TrimSpecialCharactersPipe } from './trim-special-characters.pipe';
import { TrimPipe } from './trim.pipe';


@NgModule({
    imports: [
    ],
    declarations: [
        SummaryPipe,
        ScaleImagePipe,
        SafePipe,
        TrimPipe,
        TimeAgoIsoPipe,
        TimeAgoPipe,
        CapitalizePipe,
        JoinArrayFieldsPipe,
        TrimSpecialCharactersPipe,
    ],
    exports: [
        SummaryPipe,
        ScaleImagePipe,
        SafePipe,
        TrimPipe,
        TimeAgoIsoPipe,
        TimeAgoPipe,
        CapitalizePipe,
        JoinArrayFieldsPipe,
        TrimSpecialCharactersPipe,
    ],
    providers: [

        CapitalizePipe

    ]
})
export class PipeModule { }
