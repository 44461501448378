import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { tap } from 'rxjs/operators';
import { CartService } from 'src/app/project-common/services/cart.service';
import { SearchService } from 'src/app/project-common/services/search.service';
import { SideBarService } from 'src/app/side-bar.service';

@Component({
  selector: 'custom-mangoes',
  templateUrl: './custom-mangoes.component.html',
  styleUrls: ['./custom-mangoes.component.scss']
})
export class CustomMangoesComponent implements OnInit {

  products: any[];

  isProductLoading:boolean;

  cartItems: any[] = [];

  private readonly lsMangoBoxKey = 'mangoCustomBoxItems';

  constructor(
    private searchService: SearchService,
    private cartService: CartService,
    private cartSidebar: SideBarService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.cartItems = JSON.parse(localStorage[this.lsMangoBoxKey] || '[]');
    
    this.isProductLoading = true;
    this.searchService.search(
      '',
      { limit: 25 },
      'FRUITS_AND_VEGETABLES',
      'FRUITS',
      'MANGOES',
    ).pipe(tap(() => this.isProductLoading = false)).subscribe(
      res => {
        if (res.code !== 200 && !res.data) return;

        this.products = res.data;
      }
    )


  }


  increaseQuantity(product) {

    const cartItem = this.cartItems.find(item => item.productId_level3 === product.productId_level3);

    if (cartItem) cartItem.productQuantity += 1;
    else if(this.cartItems.length < 5) this.cartItems.push({ ...product, productQuantity: 1 });
    else this.snackBar.open('No space in box..', 'Ok', { duration: 4000 ,
      panelClass: ['snakbar-color']});

    this.saveCartInLocalStorage();
  }

  decreaseQuantity(product) {
    const cartItemIndex = this.cartItems.findIndex(item => item.productId_level3 === product.productId_level3);
    if (cartItemIndex === -1) return;

    const cartItem = this.cartItems[cartItemIndex];

    if (cartItem.productQuantity === 1) this.cartItems.splice(cartItemIndex, 1);
    else cartItem.productQuantity -= 1;

    this.saveCartInLocalStorage();
  }

  removeFromCart(product) {
    this.cartItems.splice(
      this.cartItems.findIndex(item => item.productId_level3 === product.productId_level3),
      1
    );

    this.saveCartInLocalStorage();
  }

  saveCartInLocalStorage() {
    localStorage.setItem(this.lsMangoBoxKey, JSON.stringify(this.cartItems));
  }

  getProductQuantity(product) {
    const cartItem  = this.cartItems.find(item => item.productId_level3 === product.productId_level3);

    return cartItem ? cartItem.productQuantity : 0;
  }

  get cartTotalAmount() {
    return this.cartItems.reduce((cartTotalAmount, item) => (cartTotalAmount + (item.productQuantity * +item.pricePerSKU)), 0);
  }

  addMangoBoxToRealCart() {
    const l2Id = '3tef';
    const l3Id = '3teh';
    const stores = ['https://tgreens.in'];

    this.cartService.addProductToCartById(l2Id, l3Id, stores);
    this.cartSidebar.openCartSidebar();

    this.cartItems = [];
    this.saveCartInLocalStorage();

  }

}
