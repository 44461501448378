import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class SideBarService {


    public cartSidebarStatus: Observable<'OPEN' | 'CLOSE' | 'TOGGLE'>;

    private cartSidebarStream: BehaviorSubject<'OPEN' | 'CLOSE' | 'TOGGLE'>;

    public mangoCartSidebarStatus: Observable<'OPEN' | 'CLOSE' | 'TOGGLE'>;

    private mangoCartSidebarStream: BehaviorSubject<'OPEN' | 'CLOSE' | 'TOGGLE'>;


    constructor() {

        this.cartSidebarStream = new BehaviorSubject('CLOSE');

        this.cartSidebarStatus = this.cartSidebarStream.asObservable();

        // mango page
        this.mangoCartSidebarStream = new BehaviorSubject('CLOSE');

        this.mangoCartSidebarStatus = this.mangoCartSidebarStream.asObservable();

    }


    public openCartSidebar(): void {

        this.cartSidebarStream.next('OPEN');

    }


    public closeCartSidebar(): void {

        this.cartSidebarStream.next('CLOSE');

    }


    public toggleCartSidebar(): void {

        this.cartSidebarStream.next('TOGGLE');

    }

    // mango page

    public openMangoCartSidebar(): void {

        this.mangoCartSidebarStream.next('OPEN');

    }


    public closeMangoCartSidebar(): void {

        this.mangoCartSidebarStream.next('CLOSE');

    }


    public toggleMangoCartSidebar(): void {

        this.mangoCartSidebarStream.next('TOGGLE');

    }

}