import { Component, OnInit, Inject } from '@angular/core';
import { ConfirmDialogComponent } from '../../project-common/components/confirm-dialog/confirm-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'payment-conformation-dilog',
  templateUrl: './payment-conformation-dilog.component.html',
  styleUrls: ['./payment-conformation-dilog.component.scss']
})
export class PaymentConformationDilogComponent implements OnInit {

  message: string;
  tabName: string;

  cashPaymentRemarks: string;
  constructor(public dialogRef: MatDialogRef<PaymentConformationDilogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PaymentConformationDilogComponent) { }

    onNoClick(): any {
      this.dialogRef.close();
    }
  

  ngOnInit() {
    this.tabName = this.data.tabName;
  }

}
