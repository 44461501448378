import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../project-common/services/auth-guard.service';
import { CartGaurdService } from '../project-common/services/cart-guard.service';
import { AssistanceGuardService } from './assistance-guard.service';
import { AssistanceComponent } from './assistance/assistance.component';
import { AuthorizeBuyerGuardService } from './authorize-buyer-guard.service';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout.component';
import { MinimumCartValueGuard } from './minimum-cart-value.gaurd';
import { PaymentComponent } from './payment/payment.component';
import { SavedItemComponent } from './saved-item/saved-item.component';
import { ShippingComponent } from './shipping/shipping.component';


const checkoutRoutes: Routes = [
  {
    path: '',
    component: CheckoutComponent,
    children: [
      { 
        path: 'cart', component: CartComponent, 
        data: { title: 'Storefront Cart: list of products', submitGTag: true} 
      },
      {

        path: 'assist',

        component: AssistanceComponent,

        data: { title: 'Help users! place order for them' },

        canActivate: [AuthGuardService, CartGaurdService, AuthorizeBuyerGuardService],

      },
      { path: 'shipping',
        component: ShippingComponent,
        canActivate: [AuthGuardService,CartGaurdService, AssistanceGuardService, MinimumCartValueGuard],
        data: { title: 'Shipping address', submitGTag: true }
      },
      { path: 'confirm',
        component: PaymentComponent,
        canActivate: [AuthGuardService,CartGaurdService, MinimumCartValueGuard],
        data: { title: 'Payment: Pay in simple and easy way', submitGTag: true }
      },
      { path: '', redirectTo: '/checkout/cart', pathMatch: 'full'}
    ]
  },
  { path: 'saved-item', component: SavedItemComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(checkoutRoutes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class CheckoutRoutingModule { }
