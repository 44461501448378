import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpWithAuthService } from './http-interceptor.service';
import { NotificationService } from './notification.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild, CanLoad {

  constructor(private httpWithAuth: HttpWithAuthService, private router: Router, private notification: NotificationService,
    private storage: LocalStorage, private util: UtilityService) { }

  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> {
    const url = `/${route.path}`;

    return this.checkLogin(url);
  }

  checkLogin (url: string): Observable<boolean> {
    return this.storage.getItem('userdata').pipe(map(userData => {
      if (userData) {
        this.notification.notifyForUserDataChange();
        return true;
      } else {
        this.httpWithAuth.redirectUrl = url;
        this.httpWithAuth.redirectUrl = url;
        window.localStorage['blockedUrl'] = url;
        window.localStorage.setItem('previousUrl', this.httpWithAuth.redirectUrl);
        let prevUrl = this.httpWithAuth.redirectUrl;
        if(prevUrl.includes('/checkout/shipping')) {
          this.util.isGuestLoginPage.next(true);
          this.router.navigate(['auth', 'login'], {
            queryParams: {
                isCheckout: true
            }
          })
        }else {
          this.util.isGuestLoginPage.next(false);  
          this.router.navigate(['auth', 'login']);
        }
        return false;
      }
    }));
  }
}
