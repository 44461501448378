import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'all-about',
  templateUrl: './all-about.component.html',
  styleUrls: ['./all-about.component.scss']
})
export class AllAboutComponent implements OnInit {

  @Input() otherAboutList: otherAboutList;

  constructor() { }

  ngOnInit() {
  }

}
export interface otherAboutList {
  storeImageUrl:string;
  storeTitle:string;
  storeDescription:string;
  title:string;
  forWhoms?: {service:string , url:string}[];
  coreService?: {service:string , url:string}[];
}