import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
// import { KalgudiAppService } from '@kalgudi/core';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { GooglePlacesApiService } from 'src/app/modules/google-places/services/google-places-api.service';
import { ProfileService } from 'src/app/profile/profile.service';

import { DeliveryAddress } from '../../models/shipping-form.model';
import { DeliveryAddressService } from '../../services/delivery-address.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'delivery-pincode-selector',
  templateUrl: './delivery-pincode-selector.component.html',
  styleUrls: ['./delivery-pincode-selector.component.scss']
})
export class DeliveryPincodeSelectorComponent implements OnInit {

  @Input() isUserLoggedIn: boolean;

  loading = false;

  deliveryPincodeInput = new FormControl('', [Validators.minLength(6)]);
  addresses$: Observable<DeliveryAddress[]>;
  deliveryLocationSelected$: Observable<DeliveryAddress | string>;

  @Output() onChange = new EventEmitter();

  constructor(
    private profileService: ProfileService,
    private deliveryAddressService: DeliveryAddressService,
    private util: UtilityService,
    private googlePlacesApi: GooglePlacesApiService,
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification
    // private appService: KalgudiAppService
  ) { }

  ngOnInit() {
    // let isLoggedIn = this.appService.loggedIn;
    if(this.isLoggedIn()) {

      this.addresses$ = this.profileService.getSavedAddresses().pipe(map(res => {
        const _addresses = JSON.parse(res.data);
        const defaultAddress = _addresses.filter(address => address.isDefault)[0];
        if (!localStorage['pin'] && !this.deliveryPincodeInput.value) this.setDeliveryAddress(defaultAddress);
        return _addresses;
      }));
      
    }


    this.deliveryLocationSelected$ = this.deliveryAddressService.deliveryLocationSelected$
      .pipe(
        tap((address: DeliveryAddress) => this.deliveryPincodeInput.setValue(address.postalCode || address))
      );
  }

  isLoggedIn() {
    return JSON.parse(localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || 'false');
  }

  setDeliveryAddress(address: DeliveryAddress | string) {
    this.deliveryAddressService.setDeliveryAddress(address);

    this.onChange.emit();
  }

    setPincode() {
      this.loading = true;
        if (!this.deliveryPincodeInput.valid) {
          this.loading = false;
          this.notification.showSweetAlert({
            title: 'Please enter valid pincode',
            icon: 'warning'
          });
          return
        }

        // this.setDeliveryAddress(this.deliveryPincodeInput.value);

        this.googlePlacesApi.searchForPinCode(this.deliveryPincodeInput.value)
        .pipe(finalize(() => this.loading = false))
            .subscribe(
                (res: any) => {                  
                    const address = {
                        postalCode: res.googleLocationTo.zipcode,
                        googleLocation: res.googleLocationTo,
                        country: res.googleLocationTo.countryName,
                        state: res.googleLocationTo.adminLevel1,
                        city: res.googleLocationTo.adminLevel2 || res.googleLocationTo.locality,
                        addressLine2: res.googleLocationTo.locality || res.googleLocationTo.adminLevel2,
                    } as DeliveryAddress

                    this.isValidLocation(address) ? this.setDeliveryAddress(address) : this.setDeliveryAddress(this.deliveryPincodeInput.value);
                },
                err => { this.setDeliveryAddress(this.deliveryPincodeInput.value); }
            );
            
    }

    private isValidLocation(addressForm: DeliveryAddress) {
      return addressForm.postalCode && addressForm.city && addressForm.state && addressForm.country && addressForm.postalCode !== '000000';
    }

  public isNumber(event: KeyboardEvent) {
    return this.util.numberOnly(event);
  }
}
