<span class="message">

    
    <span *ngIf="product?.ships">
        
        <i class="fa fa-truck mr-5" aria-hidden="true"></i>
        <ng-container *ngIf="product?.ships?.days || product?.ships?.days === 0 ">
            <span>Ships by </span>
            <span class="highlight">{{ deliveryDate | date: "d MMM yyyy" }}</span>
        </ng-container>
  
        <ng-container *ngIf="product?.ships?.text && !product?.ships?.days">
            <span>{{product?.ships?.text}}</span>
        </ng-container>
  
    </span>

    <!-- <span *ngIf="!product?.ships && product?.shipping">
        <ng-container *ngIf="selectedLanguage !=='te'">Ships for <span class="highlight">free {{ isFruitsOrVegetables ? ' in 3-5 days' : ''}}</span></ng-container>
        <ng-container *ngIf="selectedLanguage ==='te'">డెలివరీ <span class="highlight">ఉచితం</span></ng-container>
    </span> -->

    <ng-container *ngIf="ShowFreeChargesMsg">
        <ng-container *ngIf="!product?.ships && !product?.shipping && product?.freeShippingEligible">
    
            <ng-container *ngIf="product?.shippingCostPerUnit">
                <i class="fa fa-truck mr-5" aria-hidden="true"></i>
                <ng-container *ngIf="selectedLanguage !=='te'">Shipping charges
                    <span class="highlight">{{product?.shippingCostPerUnit | currency:'INR':'symbol':'1.2-2'}}</span>
                </ng-container>
                <ng-container *ngIf="selectedLanguage ==='te'">డెలివరీ ఛార్జీలు
                    <span class="highlight">{{product?.shippingCostPerUnit | currency:'INR':'symbol':'1.2-2'}}</span>
                </ng-container>
    
                <!-- <span class="px-5">|</span> -->
            </ng-container>
    
            <!-- <ng-container *ngIf="product?.shippingCostPerUnit || !product?.productQuantity">
                <ng-container *ngIf="selectedLanguage !=='te'">
                    Ships <span class="highlight">free</span> over <span
                        class="highlight">{{999  | currency:'INR':'symbol':'1.2-2'}}</span>
                </ng-container>
                <ng-container *ngIf="selectedLanguage ==='te'">
                    <span class="highlight">{{999  | currency:'INR':'symbol':'1.2-2'}}</span> కంటే ఎక్కువ ఆర్డర్‌తో <span
                        class="highlight">ఉచిత</span> డెలివరీ
                </ng-container>
    
            </ng-container> -->
    
            <ng-container *ngIf="product?.productQuantity && !product?.shippingCostPerUnit">
                <i class="fa fa-truck mr-5" aria-hidden="true"></i>
                <ng-container *ngIf="product?.shippingFreeWithOtherItems">
                    Shipping <span class="highlight">free</span> along with other items by <i>{{product?.sellerName}}</i>
                </ng-container>
                <ng-container *ngIf="!product?.shippingFreeWithOtherItems">
                    <!-- <ng-container *ngIf="selectedLanguage !=='te'">Ships for <span class="highlight">free</span>
                    </ng-container>
                    <ng-container *ngIf="selectedLanguage ==='te'">డెలివరీ <span class="highlight">ఉచితం</span>
                    </ng-container> -->
    
                </ng-container>
            </ng-container>
    
        </ng-container>
    
        <span *ngIf="!product?.ships && !product?.shipping && !product?.freeShippingEligible">
            <i class="fa fa-truck mr-5" aria-hidden="true"></i>
            <ng-container *ngIf="selectedLanguage !=='te'">Shipping charges </ng-container>
            <ng-container *ngIf="selectedLanguage ==='te'">డెలివరీ ఛార్జీలు </ng-container>
            <ng-container *ngIf="product?.shippingCostPerUnit; else noCharges">
                <span class="highlight"> {{product?.shippingCostPerUnit | currency:'INR':'symbol':'1.2-2'}}</span>
            </ng-container>
    
    
            <ng-template #noCharges>
                <span translate> applicable</span>
            </ng-template>
        </span>
    </ng-container>

</span>