<!-- Start Unsubscribe -->
<section class="app-container">
  <div class="unsubscribe" fxLayout="column" fxLayoutAlign="center center">
    <div class="unsubscribe-img">
      <mat-icon>check_circle</mat-icon>
    </div>
    <div class="unsubscribe-text">
      <p translate>You have been unsubscribed.</p>
    </div>
  </div>
</section>
<!-- END Unsubscribe -->