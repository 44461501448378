const S3_PATH_FOR_STORE = {
    'https://andhragreens.com': 'biz',
    'https://tfresh.org': 'biz',
    'https://ebharathi.org/home': 'biz',
    'https://tgreens.in': 'biz',
    'https://swayamsikkim.com': 'sikkim'
}


const STORE_NAME_AND_LOGO = {
    "https://tfresh.org": {
        'name': 't-Fresh',
        'logo': 'assets/tfresh/images/t-white.png'
    },
    "https://andhragreens.com": {
        'name': 'Andhragreens',
        'logo': 'assets/apgreen/ag-logo2500.png',
        'message': 'Produced in Andhra Pradesh, fulfilled by Andhragreens',

    },
    "https://ebharathi.org/home": {
        'name': 'e-Mahila',
        'logo': 'assets/images/ebharti.png',
        'message': 'Produced by rural women, fulfilled by eMahila',
    },
    "https://tgreens.in": {
        'name': 'tGreens',
        'logo': 'assets/icons/tGreen-favicon.png',
        'message': 'Produced in Telangana, fulfilled by TGreens',
    },
    "https://swayamsikkim.com": {
        'name': 'swayamsikkim',
        'logo': 'assets/images/swayamsikkim.svg',
    },
    "https://missionshaktibazar.com": {
        'name': 'msb',
        'logo': 'assets/images/msbbazaar.png',
    },
}

const STORE_LABELS_MAP = {
    'APGREEN': {
        'name': 'Andhragreens',
        'logo': 'assets/apgreen/ag-logo2500.png'
    },
    'TFRESH': {
        'name': 't-Fresh',
        'logo': 'assets/tfresh/images/t-white.png'
    },
    'EMAHILA': {
        'name': 'e-Mahila',
        'logo': 'assets/images/ebharti.png'
    },
    'TGREENS': {
        'name': 'tGreens',
        'logo': 'assets/icons/tGreen-favicon.png'
    },
    'SIKKIM': {
        'name': 'Swayam Sikkim',
        'logo': 'assets/images/swayamsikkim.svg',
    },
    'MSB': {
        'name': 'Swayam Sikkim',
        'logo': 'assets/images/msbbazaar.png',
    },
    'MS_NET': {
        'name': 'Swayam Sikkim',
        'logo': 'assets/images/msbbazaar.png',
    },

}

const APP_NAME_FOR_STORE = {
    'https://andhragreens.com': 'APGREEN',
    'https://tfresh.org': 'TFRESH',
    'https://ebharathi.org/home': 'EMAHILA',
    'https://tgreens.in': 'TGREENS',
    'https://swayamsikkim.com': 'SIKKIM'
}


const DOMAIN_URL_FOR_STORE = {
    'APGREEN': {
        'dev': 'https://devkalgudi.vasudhaika.net/apgreens',
        'prod': 'https://andhragreens.com'
    },
    'TFRESH': {
        'dev': 'https://devkalgudi.vasudhaika.net/tfresh',
        'prod': 'https://tfresh.org'
    },
    'EMAHILA': {
        'dev': 'https://devkalgudi.vasudhaika.net/ebharathi',
        'prod': 'https://ebharathi.org/home'
    },
    'TGREENS': {
        'dev': 'https://devkalgudi.vasudhaika.net/tgreens',
        'prod': 'https://tgreens.in'
    },
    'SIKKIM': {
        'dev': 'https://devkalgudi.vasudhaika.net/esikkim/',
        'prod': 'https://swayamsikkim.com'
    }
}
export { APP_NAME_FOR_STORE, S3_PATH_FOR_STORE, DOMAIN_URL_FOR_STORE, STORE_NAME_AND_LOGO, STORE_LABELS_MAP }