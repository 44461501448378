<!-- SIDE PRODUCT CART TILE -->
<section class="item-container cursor" (click)="openFullView(); close()">
  <!-- PRODUCT IMAGE -->
  <div
    class="img-sec"
    fxFlex.xs="70px"
    fxFlex="100px"
    fxLayoutAlign="center center"
  >
    <img
    [src]="product.productImage | scaleImage: 'md'" alt="product-image" title="{{product.productLevel3Title}}"
      loading="lazy"
    />
  </div>
  <!-- /PRODUCT IMAGE -->

  <!-- PRODUCT DETAILS -->
  <div
    class="item-details-container"
    fxFlex="calc(100% - 125px)"
    fxLayout="column"
    fxLayoutGap="10px"
  >
    <div fxLayout="column"  fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <span class="p-name">
          {{ product?.productLevel3Title }}
        </span>
        <div matTooltip="{{'Remove this product' | translate}}" (click)="$event.stopPropagation();remove()">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </div>
      </div>

      <div fxLayout="row" class="product-quantity">
        <span class="mr-1"> {{ product?.productQuantity }}</span> x
        <span class="ml-1 price">
          {{ product?.pricePerUnit | currency: "INR":"symbol":"1.2-2" }}
        </span>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="8px"
        class="quantity-change mt-2"
        fxLayoutAlign="center center"
      >
        <div
          class="qty-changer"
          (click)="$event.stopPropagation();removeQuantity()"
        >
          <i class="fa fa-minus" aria-hidden="true"></i>
        </div>
        <div class="quantity-value">
          {{ product?.productQuantity }}
        </div>
        <div
          class="qty-changer"
          (click)="$event.stopPropagation();addQuantity()"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
  <!-- /PRODUCT DETAILS -->
</section>
<!-- /SIDE PRODUCT CART TILE -->
