import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { CartService } from './cart.service';
import { HttpWithAuthService } from './http-interceptor.service';
import { NotificationService } from './notification.service';

// import { FcmService } from './fcm.service';

// declare var ga: any;
@Injectable({
  providedIn: 'root'
})
export class DbUpdateService {

  constructor(private storage: LocalStorage, 
    private notification: NotificationService,
    private http: HttpWithAuthService, private router:Router, 
    private cart: CartService,
    // private fcm: FcmService
    ) { }

  updateUserData (userData: any, loginInfo?: any) {
     // setting 'pf' in localStorage to share the login information b/w core app & store.
     localStorage.setItem('pf', JSON.stringify(userData));
    this.storage.setItemSubscribe('userdata', userData);
    if (environment.production) {
      const userId = userData.firstName + ' - ' + userData.mobileNo;
    
      // ga('set', 'userId', userId);
      // ga('send', 'event', 'authentication', userId);      // console.log(userId);  
    }
    if (loginInfo) {
      loginInfo.mobileNo = userData.mobileNo;
      this.updateHeaders(loginInfo);
    }
    setTimeout(() => {
      this.notification.notifyForUserDataChange();
    }, 300);
  }

  updateHeaders (loginInfo) {
    this.storage.setItemSubscribe('username', loginInfo.mobileNo);
    localStorage['dXNlcm5hbWU='] = btoa(loginInfo.mobileNo);
    this.storage.setItemSubscribe('password', loginInfo.password);
    localStorage['cGFzc3dvcmQ='] = btoa(loginInfo.password);
    this.http.setImediateHeaders(loginInfo.mobileNo, loginInfo.password);
  }

  getUserName() {
    return this.storage.getItem('userdata').pipe(map(userData => {
      return userData.mobileNo;
    }));
  }

  getUserPassword() {
    return this.storage.getItem('password');
  }

  clearUserData( redirectionUrl?: string) {
    delete localStorage['YUdGelRHOW5aMlZrU1c0PQ=='];
    delete localStorage['cGFzc3dvcmQ='];
    delete localStorage['dXNlcm5hbWU='];
    delete localStorage[btoa('profileKey')];
    delete localStorage['assistant'];
    delete localStorage['_pb_'];
     // removing 'tk' , 'c', 'pf' from localStorage to remove login information.

     localStorage.removeItem('pf');

     localStorage.removeItem('Y2FuQXNzaXN0');

     localStorage.removeItem('tk');
 
     localStorage.removeItem('c');
    this.storage.removeItemSubscribe('userdata');
    this.storage.removeItemSubscribe('username');
    this.storage.removeItemSubscribe('password');
    this.storage.removeItemSubscribe('userWishList');
    this.storage.removeItemSubscribe('FAVOURITE_MAKERS');
    this.storage.removeItemSubscribe('SAVED_PRODUCTS');
    this.cart.persistTheCart();
    // this.fcm.resetNotification();
    setTimeout(() => {
      this.cart.setCartData({
        productIds : [],
        products   : []
      });
      this.cart.dispatchCartUpdate();
      this.notification.notifyForUserDataChange();
     
        this.router.navigateByUrl( redirectionUrl ||'home');
        console.log(`Redirected to: `, redirectionUrl || 'home');

    
        
        
    
    }, 300);
    setTimeout(() => {
      delete localStorage['previousUrl'];
    }, 1000);
  }
}
