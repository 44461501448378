import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'address-info',
  templateUrl: './address-info.component.html',
  styleUrls: ['./address-info.component.scss']
})
export class AddressInfoComponent implements OnInit {

  @Input() listOfAddress = [];

  @Output('selectedAddressEvent') selectedAddressEvent = new EventEmitter();

  @Input() selectedAddress: any;

  constructor(
    
  ) { }

  ngOnInit() {
  
  }


  selectAddress(address: any) {
    this.selectedAddress = address;
    this.selectedAddressEvent.emit(this.selectedAddress);
  }

}
