<form action="https://secure.payu.in/_payment" #payUBizForm="ngForm" method="post" name="payUbizForm" id="payUbizForm"
  hidden="true">
  <input type="hidden" name="firstname" #firstname="ngModel" [(ngModel)]="paymentRequest.firstname" />
  <input type="hidden" name="lastname" #lastname="ngModel" [(ngModel)]="paymentRequest.lastname" />
  <input type="hidden" name="phone" #phone="ngModel" [(ngModel)]="paymentRequest.phone" />
  <input type="hidden" name="email" #email="ngModel" [(ngModel)]="paymentRequest.email" />
  <input type="hidden" name="key" #key="ngModel" [(ngModel)]="paymentRequest.key" />
  <input type="hidden" name="hash" #hash="ngModel" [(ngModel)]="paymentRequest.hash" />
  <input type="hidden" name="txnid" #txnid="ngModel" [(ngModel)]="paymentRequest.txnid" />
  <input type="hidden" name="productinfo" [(ngModel)]="paymentRequest.productinfo" />
  <input type="hidden" name="amount" #amount="ngModel" [(ngModel)]="paymentRequest.amount" />
  <input type="hidden" name="surl" #surl="ngModel" [(ngModel)]="paymentRequest.surl" />
  <input type="hidden" name="curl" #curl="ngModel" [(ngModel)]="paymentRequest.curl" />
  <input type="hidden" name="furl" #furl="ngModel" [(ngModel)]="paymentRequest.furl" />
  <input type="submit" value="submit" />
</form>