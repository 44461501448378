import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CartProduct } from '../../models/cart-product.model';
import { Storefront } from '../../properties/constants';

@Component({
  selector: 'shipping-charge-message',
  templateUrl: './shipping-charge-message.component.html',
  styleUrls: ['./shipping-charge-message.component.scss']
})
export class ShippingChargeMessageComponent implements OnChanges {

  @Input() product: CartProduct;
  @Input() productCategory: string;
  @Input() ShowFreeChargesMsg: boolean = true;

  selectedLanguage: string;
  deliveryDate: Date;
  message: string;

  ngOnInit(): void {
    this.selectedLanguage = localStorage['lang'];
    this.getDeliveryDate();
  }

  constructor() { }

  ngOnChanges() {

  }

  get isFruitsOrVegetables() {
    if(!this.product) return false;

    return Storefront.limitedShippingCategory.includes(this.product.productCategory || this.productCategory);
  }

  getDeliveryDate() {

    let now = new Date();
    this.deliveryDate = now;
    
    if ( this.product.ships && this.product.ships.days ) {
      
      this.deliveryDate.setDate(now.getDate() + this.product.ships.days);
  
    }

  }

}
