import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({

    providedIn: 'root',

})

export class AuthorizeBuyerGuardService implements CanActivate {


    constructor(private router: Router) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {


        const canAssist = JSON.parse(localStorage.getItem('Y2FuQXNzaXN0') || 'false');

        const isAssistanceChecked = JSON.parse(sessionStorage.getItem('isAssistanceChecked') || 'false');


        const canActivate = (canAssist && !isAssistanceChecked);


        if(!canActivate)

            this.router.navigateByUrl('/checkout/cart');


        return canActivate;

    }

}