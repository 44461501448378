import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { CartItem } from 'src/app/classes/cart-item';
import { SideBarService } from 'src/app/side-bar.service';

@Component({
  selector: 'cart-side-bar-item',
  templateUrl: './cart-side-bar-item.component.html',
  styleUrls: ['./cart-side-bar-item.component.scss']
})
export class CartSideBarItemComponent extends CartItem implements OnInit {

  constructor(
    protected injector: Injector,
    private sidebarService: SideBarService

  ) {
    super(injector);
  }

  ngOnInit() {

  }
  public close(): void {

    this.sidebarService.closeCartSidebar();

  }
}
