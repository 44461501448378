import { Injectable } from '@angular/core';
import { ToastaConfig, ToastaService, ToastOptions } from 'ngx-toasta';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastConfig: ToastaConfig, private toastService: ToastaService) {
    toastConfig.theme = 'material';
    toastConfig.position = 'bottom-left';
  }

  showMessage (title: string, message: string, type: string = 'info', timeout?: number, onToastShow?, onToastClose?) {
    const toastOptions: ToastOptions = {
      title: title,
      msg: message,
      showClose: true,
      timeout: timeout || 5000,
      theme: 'material',
      onAdd: onToastShow,
      onRemove: onToastClose,
    };

    switch (type) {
      case 'info':
        this.toastService.info(toastOptions);
        break;

        case 'success':
        this.toastService.success(toastOptions);
        break;

        case 'wait':
        this.toastService.wait(toastOptions);
        break;

        case 'error':
        this.toastService.error(toastOptions);
        break;

        case 'warning':
        this.toastService.warning(toastOptions);
        break;
    }
  }
}
