<div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="15px" class="position-relative banner-design-wrapper w-100 overflow-hidden">



  <div fxFlex="calc(70% - 15px)" fxFlex.sm="none" class="design-left-section">

    <div class="fake-it-container" *ngIf="progress">
      <div class="fake-it fake-block">
      </div>
    </div>

    <img *ngIf="!bannerImagesList?.length && !progress" 
          width="100%" 
          height="100%" 
          srcset="{{'assets/images/m-banner-2.jpg' | scaleImage: 'format'}} 599w, {{'assets/images/banner-2.webp' }}"
          sizes="(max-width: 599px) 599px, 800px"
          class="cursor-pointer"
    >

    <ngx-slick-carousel class="web" #slickModal2="slick-carousel" (afterChange)="afterChange($event)" [config]="bannerSlideConfig">
      <ng-container *ngIf="bannerImagesList?.length && !progress">
        <div *ngFor="let banner of bannerImagesList; let i = index">
          <a [ngClass]="{'cursor-default': !banner?.imageDeeplinkUrl}" [href]="banner?.imageDeeplinkUrl ? banner?.imageDeeplinkUrl : ''">
            <img width="1920" class="img-responsive" height="820" ngxSlickItem srcset="{{banner?.imageUrl}}" sizes="(max-width: 599px) 599px, 800px" [alt]="banner?.altText">
          </a>
        </div>
      </ng-container>
    </ngx-slick-carousel>


    <div class="banner-content" routerLink="/categories/fruits-and-vegetables/fruits" *ngIf="!bannerImagesList?.length">
      <div class="app-container">
        <!-- <p class="font-25 dark-red mb-10" translate>Experience the season's tastiest Mangoes</p> -->
        <!-- <p fxHide.sm class="font-18 dark-green banner-detail" translate>Kalgudi brings you, the sweetest and juiciest mangoes from Andhra Pradesh & Telangana. We hand-pick the matured fruits, wash, naturally ripen them as per the standards in the traditional way, hygienically pack and then deliver them to your doorstep. You just order, sit back & relax and enjoy the coolest mangoes in the hottest season.</p> -->
  
        <!-- <p class="font-45 fw-700 light-green" translate>Mangoes</p> -->
        <!-- <button class="banner-btn text-uppercase" translate routerLink="/categories/fruits-and-vegetables/fruits">Shop now</button> -->
        <!-- <p class="font-24 dark-green mb-10" translate>Delivery across India</p> -->
      </div>
    </div>

    <div class="banner-nav" fxHide.xs fxHide.sm>
      <div [class.active]="currentSlide === 0" (click)="currentSlide = 0;slickModal?.slickGoTo(0)" class="banner-nav-button">
        <img height="25" class="mb-5" src="assets/images/kl.png" alt="" loading="lazy">
        <!-- <img height="20" [hidden]="currentSlide !== 0" src="assets/images/arrow.png" alt=""> -->
        <span class="font-11 fw-600" translate>Kalgudi</span>
      </div>
      <div [class.active]="currentSlide === 1" (click)="currentSlide = 1;slickModal?.slickGoTo(1)" class="banner-nav-button">
        <img height="25" class="mb-5" src="assets/images/em-nav.png" alt="" loading="lazy">
        <!-- <img height="20" [hidden]="currentSlide !== 1" src="assets/images/arrow.png" alt=""> -->
        <span class="font-11 fw-600" translate>eMahila</span>
      </div>
      <div [class.active]="currentSlide === 2" (click)="currentSlide = 2;slickModal?.slickGoTo(2)" class="banner-nav-button">
        <img height="25" class="mb-5" src="assets/images/swayamsikkim.svg" alt="">
        <!-- <img height="20" [hidden]="currentSlide !== 2" src="assets/images/arrow.png" alt=""> -->
        <span class="font-11 fw-600" translate>Swayam Sikkim</span>
      </div>
      <!-- <div [class.active]="currentSlide === 3" (click)="currentSlide = 3;slickModal?.slickGoTo(3)" class="banner-nav-button">
        <img height="25" class="mb-5" src="assets/images/ag-nav.png" alt=""> -->
        <!-- <img height="20" [hidden]="currentSlide !== 3" src="assets/images/arrow.png" alt=""> -->
        <!-- <span class="font-11 fw-600" translate>Andhragreens</span>
      </div> -->

      <!-- <div [class.active]="currentSlide === 4" (click)="currentSlide = 4;slickModal?.slickGoTo(4)" class="banner-nav-button">
        <img height="25" class="mb-5" src="assets/images/tg-nav.png" alt=""> -->
        <!-- <img height="20" [hidden]="currentSlide !== 4" src="assets/images/arrow.png" alt=""> -->
        <!-- <span class="font-11 fw-600" translate>TGreens</span>
      </div> -->
    </div>
  </div>
  
  <ngx-slick-carousel class="web" fxFlex="calc(30% - 15px)" fxHide.xs fxHide.sm #slickModal="slick-carousel" 
    (afterChange)="afterChange($event)" [config]="slideConfig">

    <a href="https://kalgudi.com" target="_blank" class="p-35" ngxSlickItem fxLayout="column" fxLayoutAlign="center center">      
      <img width="400" height="400" class="img-responsive" src="{{'assets/images/k.png' | scaleImage:'format'}}" loading="lazy" alt="">
    </a>

    <a href="https://emahila.org" target="_blank" class="p-35" ngxSlickItem fxLayout="column" fxLayoutAlign="center center">      
      <img width="400" height="400" class="img-responsive" src="{{'assets/images/e2.png' | scaleImage:'format'}}" loading="lazy" alt="">
    </a>

    <a href="https://swayamsikkim.com"  target="_blank" class="p-35" ngxSlickItem fxLayout="column" fxLayoutAlign="center center">      
      <img width="400" height="400" class="img-responsive" src="{{'assets/images/esikkim-kalgudi.png' | scaleImage:'format'}}" loading="lazy" alt="">
    </a>

    <!-- <a href="https://andhragreens.com"  target="_blank" class="p-35" ngxSlickItem fxLayout="column" fxLayoutAlign="center center">      
      <img width="400" height="400" class="img-responsive" src="{{'assets/images/agreen.png' | scaleImage:'format'}}" loading="lazy" alt="">
    </a> -->
    
    <!-- <a href="https://tgreens.in" target="_blank" class="p-35" ngxSlickItem fxLayout="column" fxLayoutAlign="center center">      
      <img width="400" height="400" class="img-responsive" src="{{'assets/images/g.png' | scaleImage:'format'}}" loading="lazy" alt="">
    </a> -->

  </ngx-slick-carousel>

  <ngx-slick-carousel fxHide.gt-sm #slickModal1="slick-carousel" (afterChange)="afterChange($event)" [config]="slideConfig">

    <a href="https://kalgudi.com" target="_blank" ngxSlickItem fxLayout="column" fxLayoutAlign="center center">      
      <img class="mb-10 img-responsive" width="100%" height="100%" src="{{'assets/images/kl.jpg' | scaleImage:'format'}}" loading="lazy" alt="">
    </a>

    <a href="https://emahila.org" target="_blank" ngxSlickItem fxLayout="column" fxLayoutAlign="center center">      
      <img class="mb-10 img-responsive" width="100%" height="100%" src="{{'assets/images/em.jpg' | scaleImage:'format'}}" loading="lazy" alt="">
    </a>
    
    <a href="https://swayamsikkim.com" target="_blank" ngxSlickItem fxLayout="column" fxLayoutAlign="center center">      
      <img class="mb-10 img-responsive" width="100%" height="100%" src="{{'assets/images/esikkim.jpg' | scaleImage:'format'}}" loading="lazy" alt="">
    </a>

    <!-- <a href="https://andhragreens.com"  target="_blank" ngxSlickItem fxLayout="column" fxLayoutAlign="center center">      
      <img class="mb-10 img-responsive" width="100%" height="100%" src="{{'assets/images/ag.jpg' | scaleImage:'format'}}" loading="lazy" alt="">
    </a> -->

    <!-- <a href="https://tgreens.in" target="_blank" ngxSlickItem fxLayout="column" fxLayoutAlign="center center">      
      <img class="mb-10 img-responsive" width="100%" height="100%" src="{{'assets/images/tg.jpg' | scaleImage:'format'}}" loading="lazy" alt="">
    </a> -->

  </ngx-slick-carousel>
</div>