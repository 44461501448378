import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeliveryAddress } from '@app/models';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogResult } from '@kalgudi/types';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from 'src/app/project-common/services/spinner.service';

import { AddressService } from '../../services/address.service';

@Component({
  selector: 'address-form-dialog',
  templateUrl: './address-form-dialog.component.html',
  styleUrls: ['./address-form-dialog.component.scss']
})
export class AddressFormDialogComponent extends KalgudiDialog<AddressFormDialogComponent> implements OnInit {

  addressForm: DeliveryAddress;

  constructor(
    protected dialogRef: MatDialogRef<AddressFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private spinner: SpinnerService,
    private addressService: AddressService
    ) {
    super(dialogRef);
   }

  ngOnInit() {
    this.addressForm = this.dialogData.data.address;
  }

  submitClicked(data) {

    if(!this.dialogData || !this.dialogData.data || !this.dialogData.data.forUserCreation) {

      this.ok();

    } else {

      this.dialogRef.close({ data, accepted: true });

    }

  }



  ok(): void {
    if(this.dialogData && this.dialogData.data && !this.dialogData.data.forUserCreation) {
      this.spinner.start();
      this.addressService.saveAddress(this.addressForm)
        .pipe(finalize(() => this.spinner.stop()))
        .subscribe(response => {
          this.dialogRef.close({ data: response, accepted: true });
        })
    }else {
      
    }
  }


  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

}
