import { Directive, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[detectSwipe]'
})
export class DetectSwipeDirective {

    @Output('swipe-right') swipedRight = new EventEmitter();
    @Output('swipe-left') swipedLeft = new EventEmitter();
    @Output('swipe-up') swipedUp = new EventEmitter();
    @Output('swipe-down') swipedDown = new EventEmitter();

    xDown = null;
    yDown = null;

    constructor(
        private el: ElementRef
    ) {

        el.nativeElement.addEventListener('touchstart', evt => {
            const firstTouch = evt.touches[0];                                      
            this.xDown = firstTouch.clientX;                                      
            this.yDown = firstTouch.clientY;
        }, false);

        el.nativeElement.addEventListener('touchmove', evt => {
            if ( ! this.xDown || ! this.yDown ) return;
    
            var xUp = evt.touches[0].clientX;                                    
            var yUp = evt.touches[0].clientY;
        
            var xDiff = this.xDown - xUp;
            var yDiff = this.yDown - yUp;
        
            if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
                if ( xDiff > 0 ) {
                    /* left swipe */ 
                    this.swipedLeft.emit();
                } else {
                    /* right swipe */
                    this.swipedRight.emit();
                }                       
            } else {
                if ( yDiff > 0 ) {
                    /* up swipe */ 
                    this.swipedUp.emit();
                } else { 
                    /* down swipe */
                    this.swipedDown.emit();
                }                                                                 
            }
            /* reset values */
            this.xDown = null;
            this.yDown = null;
        }, false);
    }

}
