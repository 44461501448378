<div class="sec" fxLayout="column" fxLayoutGap="5px">

  <div>
      <h4 class="mt-0 mb-10" translate>Free account created</h4>
      <p>
        <span translate>We have created a free account for you in Kalgudi. Your password is sent to your&nbsp;</span>
        <span *ngIf="data.signupType === 'E-mail'" translate>Email</span>
        <span *ngIf="data.signupType !== 'E-mail'" translate>mobile</span>
        <span> : <b>{{data.userId}}</b></span>
      </p>
      <p translate>Login to Kalgudi to check your orders, change password get latest offers and coupons.</p>
  </div>

  <div mat-dialog-actions class="close">
      <button id='#ok' mat-flat-button fxLayoutAlign="center center" [mat-dialog-close]="true">Ok</button>
  </div>
</div>

