import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';



@Component({
  selector: 'feature-pincode-picker',
  templateUrl: './pincode-picker.component.html',
  styleUrls: ['./pincode-picker.component.scss']
})
export class PincodePickerComponent implements OnInit {

  postOffices:any;
  uniquePostOfficeNames:any = [];
  chosenPostOffice:any;  
  blocks: { blockName: string, count: number }[] = [];
  recommendedPostOffice: any = 'notFound';
  pincode: string;

  constructor(

    public bottomSheetRef: MatBottomSheetRef<PincodePickerComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private ngZone: NgZone,   
  ) { }

  ngOnInit(): void {
    this.postOffices = this.data.postOffices;
    this.categoriseBlocks();
    this.getRecommendedName();
    this.pincode = this.postOffices[0].Pincode;
  }


  selectPostOffice(event) {
    this.chosenPostOffice = event
  }

  // categorise Blocks in descending order (in terms of their occurence)
  categoriseBlocks() {
    
    let newBlock = 0;    
    for (var i=0; i<this.postOffices.length; i++) {     
      let matchedBlocks = -1;          
      this.blocks.forEach((block,index) => {
        if (block.blockName == this.postOffices[i].Block) {
          matchedBlocks = index;
          return;
        }
      })
      if (matchedBlocks != -1) {        
        this.blocks[matchedBlocks].count++;
      } else if (matchedBlocks == -1) {
        this.blocks[newBlock] = { blockName: this.postOffices[i].Block, count: 1};        
        newBlock++;
      }     
    }
  }

  // see if block name matches with any postOffice name
  getRecommendedName() {

    if (this.blocks.length == 1) {
      this.compareBlocksAndNames(this.blocks[0]);
    } 
    while ((this.blocks.length > 1) && this.recommendedPostOffice == 'notFound') {      
      var recurringBlock = this.mostRecurringBlock();
      this.compareBlocksAndNames(recurringBlock);
    }
  }



  // find the block with most occurences
  mostRecurringBlock() {
    var recommendedBlock = this.blocks[0];
    var recommendedIndex;
    this.blocks.forEach((block, index) => {
      if (recommendedBlock.count < block.count) {
        recommendedBlock = block;
        recommendedIndex = index;
      }
    });
    this.blocks.splice(recommendedIndex,1);
    return recommendedBlock;
  }

  // trim paranthesis in postOffice.Name
  trimParanthesisAndRemoveDuplicates() {
    this.postOffices.forEach((block, index) => {
      block.Name = (block.Name.indexOf('(') == -1) ? block.Name : block.Name.substring(0, block.Name.indexOf('('))
      if (!this.uniquePostOfficeNames.includes(block.Name)) {        
        this.uniquePostOfficeNames.push(block.Name);
      }
    });
  }

  // method to check if any 'Block' matches with 'Name' in postOffices array
  compareBlocksAndNames(abc:any) {

    abc.blockName = (abc.blockName.indexOf('(') == -1) ? abc.blockName : abc.blockName.substring(0, abc.blockName.indexOf('('))
    this.trimParanthesisAndRemoveDuplicates();
    
    var matchIndex = -1;
    this.uniquePostOfficeNames.forEach((block, index) => {

      if (block.trim() == abc.blockName.trim()) {      
        matchIndex = index;        
        this.recommendedPostOffice = block.trim();        
      }
    })
        
    if (matchIndex == -1) {
      this.recommendedPostOffice = 'notFound'
    } else {
      //swap recommended post office with 1st element
      var swapElem = this.uniquePostOfficeNames[0];
      this.uniquePostOfficeNames[0] = this.uniquePostOfficeNames[matchIndex].trim();
      this.uniquePostOfficeNames[matchIndex] = swapElem;

      //splice 1st element (ie) recommended post office from array
      this.uniquePostOfficeNames.splice(0,1);      
      this.uniquePostOfficeNames.sort();
    }
  }

  public ok(): void {
    
    this.ngZone.run(() => {
      this.bottomSheetRef.dismiss({ 
        name: this.chosenPostOffice.trim(),
        accepted: true });
    });
  }

  public cancel() {

    this.ngZone.run(() => {
      this.bottomSheetRef.dismiss({
        accepted: false,
      });
    });
  }

}