import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Storefront } from 'src/app/project-common/properties/constants';
import { SideBarService } from 'src/app/side-bar.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MangoService {

  private STORE_V2: string = `${environment.restBaseUrlV2}/store/`;

  private POST_PREBOOKING: string = `${this.STORE_V2}${Storefront.APP_NAME}/order/pre-booking`;

  private VERIFY_PREBOOKING: string = `${this.STORE_V2}${Storefront.APP_NAME}/order/verify/pre-booking`;
  
  /**
   * EndPoint to get the mangos for B2C
   * "0wse" is baseProductId for mango 
   * "sellerId" dummy Id
   */
  private GET_MANGOES_END_POINT: string = `${environment.restBaseUrlV2}/listing/outputstore/sellers/sellerId/baseProducts/0wse`;

  private PRE_BOOKING_DB_KEY = `_pb_`;

  private mangoCartItemSubject = new BehaviorSubject(this.getPreBookingObjects());

  mangoCartItemObject$ = this.mangoCartItemSubject.asObservable();

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    public sideBarService: SideBarService,
  ) { }
  
  
  /** To get the mango for B2C sell */
  getMangoes(APP_NAME): Observable<any>{
    let params = { a:`a`, appName: APP_NAME, storeType:`BIZ_STORE`, baseProductId: `0wse`}
    return this.http.get(this.GET_MANGOES_END_POINT,{params}).pipe(map(data => {
      const res: any = data;
      return res;
    }));
  }

  /**
   * To save prebook details and send OTP
   * @param {products:any[], address:any, userDetails:{firstName:string, mobile:number, email:string, profileKey?: string}} payload 
   * @returns 
   */
  postPreBooking(payload) {
    return this.http.post(this.POST_PREBOOKING,payload); //.pipe(map(res=> res.data));
  }

  /** To verify the OTP */
  verifyPreBooking(requestId: string, otp: string) {
    return this.http.put(this.VERIFY_PREBOOKING,{requestId, otp});
  }

  /** To create the form of pre booking */
  getPreBookingForm(): FormGroup {
    let proBookForm = this.fb.group({
      userDetails: new FormGroup({
        firstName: new FormControl('',Validators.required),
        email: new FormControl('',[Validators.required,Validators.email]),
        mobile: new FormControl('',[
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^[6-9]\d{9}$/)]),
        profileKey: new FormControl('')
      }),
      products: new FormControl([],Validators.required),
      deliveryExpectedDate:new FormControl('')
    });

    this.mangoCartItemObject$.subscribe(res => {
      let productList = Object.values(res) || [];
      proBookForm.get('products').patchValue(productList);
    })

    return proBookForm;
  }

  getOtpFormControl() {
    return new FormControl(``,Validators.required);
  }

  /** To get the pre booking object from local DB */
  getPreBookingProducts(){
    let items = this.getPreBookingObjects();
    let keys = Object.keys(items);
    return keys.length ? Object.values(items) : [];
  }

  /** To get the prebook whole object */
  getPreBookingObjects() {
    return JSON.parse(localStorage[this.PRE_BOOKING_DB_KEY] || `{}`);
  }

  /** To set the pre booking products in the local DB */
  private setPreBookingObjects(products): void {
    localStorage[this.PRE_BOOKING_DB_KEY]= JSON.stringify(products);
    this.updatedPreBookingObject();
    this.sideBarService.openMangoCartSidebar();
  }
  
  /** TO save item in prebook bag */
  public addToPreBookingObject(product) {
    let availableList = this.getPreBookingObjects();
    if(!availableList[product.productId_level3]) {
      availableList[product.productId_level3] = product;
    }
    this.setPreBookingObjects(availableList);
  }

  /** To remove item from prebook bag */
  public removeFromPrebookObject(l3Id) {
    let availableList = this.getPreBookingObjects();
    delete availableList[l3Id];
    this.setPreBookingObjects(availableList);
  }

  public clearDbOnPreBookingSuccess() {
    delete localStorage[this.PRE_BOOKING_DB_KEY];
    this.updatedPreBookingObject();
  }

  updatedPreBookingObject() {
    this.mangoCartItemSubject.next(this.getPreBookingObjects());
  }
  
}

// Sample payload
// {"products":[{"productId_level2":"3shw","productName_level2":"Banaganapalli","approxDeliveryTime":null,"baseProductName":"Mango","sellerId":"M01q3x50PRFREG2020112431450066UNH001","sellerName":"Avinash V","level2published":true,"productPicURL_level2":"/data/p_images/000000004025_1.jpg","productId_level3":"3shx","productName_level3":"Mango 5 kg","mrp":"1000","pricePerSKU":"1000","level3published":true,"isBulkOrder":false,"SKUSmartElements":{"color":{"type":"text","unit":"","units":[],"value":"","enabled":false},"shape":{"type":"text","unit":"","units":[],"value":"","enabled":false},"weight":{"type":"text_option","unit":"kg","units":["kg","quintal","ton","grams"],"value":"5","enabled":true},"diameter":{"type":"text_option","unit":"","units":[],"value":"","enabled":false},"setCount":{"type":"text_option","unit":"","units":[],"value":"","enabled":false},"dimensions":{"type":"text_option","unit":"","units":[],"width":{"value":"","enabled":false},"height":{"value":"","enabled":false},"length":{"value":"","enabled":false},"enabled":false,"diameter":{"value":"","enabled":false},"circumference":{"value":"","enabled":false}},"sizeByName":{"type":"text","unit":"","units":[],"value":"","enabled":false},"smartLabel":{"type":"text","units":[],"value":"","enabled":false},"sizeByNumber":{"type":"text","unit":"","units":[],"value":"","enabled":false}},"productPicURL_level3":"/data/p_images/1614920254148.jpeg","selectedSKU":["weight"],"shipping":false,"varietyOf":{"productId":"10gm","VProductId":"10gm","productName":"Banaganapalli","VProductName":"Banaganapalli"},"teluguName":"బంగినపల్లి","isBasket":false,"basketItems":[],"stores":["https://andhragreens.com"],"baseCategory":{"id":"FRUITS","value":"Fruits"},"categories":[{"mainCategory":{"id":"FRUITS","value":"Fruits"}}]}],"address":{},"userDetails":{"email":"","mobile":"","firstName":"","profileKey":""}}