<div id="notifications" class="my-2 cursor-pointer" *ngIf="!isMobileDevice"> 

    <div *ngFor="let notification of notifications; index as i">
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between" 
            class="tile slide-left" [style.color]="notification?.color" *ngIf="notification.isActive" (click)="openNotification(notification, i);">
            
            <div fxFlex="90%" fxLayout="column" fxLayoutGap="10px">
                <h3 [innerHtml]="notification?.title"></h3>
                <div class="notifier-content" fxLayout="row" fxLayoutGap="10px">
                    <div class="image">
                        <img class="rounded-circle" src="assets/images/kl-dark-green_22x20.png">
                    </div>
                    <div class="w-100p"fxLayout="column" fxLayoutGap="10px">
                        <div class="content" *ngIf="notification?.type === 'liveChat'">You have got &nbsp;<span> {{notification?.count > 1 ? notification?.count + ' messages' : 'a message'}}</span></div>
                        <div fxLayout fxLayoutGap="20px" fxLayoutAlign="start center">
                            <div class="info one-line">{{notification?.message}}</div>
                            <div class="time-stamp" fxLayoutAlign="start center" fxLayoutGap="5px">
                                <div fxLayoutAlign="center center" class="dot"></div>
                                <div [innerHtml]="notification?.TS | timeAgoIso"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <mat-icon (click)="notification.isActive = false">cancel</mat-icon>
            </div>
        </div>
    </div>
</div>