<div class="mango-item d-flex flex-column">
  <div class="d-flex align-items-center mr-4 state-details">
    <svg-icon [applyCss]="true" class="state-map"
      src="assets/images/mango-page/ap.svg"
      [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"></svg-icon>
    <span class="state-name">From Andhra Pradesh</span>
  </div>
  <div class="product-details d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <div class="product-image mr-10" 
      [style.background-image]="'url('+ (product?.productPicURL_level3 | prefixDomain) +')'"></div>
      <div class="product-info d-flex flex-column mr-10">
        <span class="product-name">{{product?.productName_level3}}</span>
        <span class="d-flex align-items-center">
          <ng-container *ngFor="let smartElement of product?.selectedSKU | slice: 0:1">
            <span class="sku mr-10" *ngIf="smartElement !== 'dimensions'">
                {{ (product?.SKUSmartElements)[smartElement].value }}
                {{ (product?.SKUSmartElements)[smartElement].unit }}
            </span>
          </ng-container>

          <kl-link-button 
          [label]="'Remove'" 
          (clicked)="removeFromCart()">
          </kl-link-button>
        </span>
      </div>
    </div>
    <div class="product-price">{{product?.pricePerSKU | currency:'INR':'symbol':'1.0'}}</div>
  </div>
</div>
