import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'scaleImage'
})
export class ScaleImagePipe implements PipeTransform {

  static ua = navigator.userAgent.toLowerCase();
  static isSafariBrowser = !(ScaleImagePipe.ua.indexOf('safari') != -1 && ScaleImagePipe.ua.indexOf('chrome') > -1);
  
  static transform(value: string, size?: string): any {
    if (!value) return '';
    // value = `https://kalgudibeta.s3.amazonaws.com/profilepics/1486458698853blob`;
    // size = 'pic150';
    const urlArray = value.split('.');
    const extentionType = urlArray[urlArray.length - 1];
    let preparedUrl = '';
    switch (size) {
      case 'sm':
        preparedUrl = value.replace('.' + extentionType, '_100x100.' + extentionType); break;
      case 'md':
        preparedUrl = value.replace('.' + extentionType, '_220x220.' + extentionType); break;
      case 'lg':
        preparedUrl = value.replace('.' + extentionType, '_600x600.' + extentionType); break;
      case 'pic150':
        if (value.includes('http://') && environment.production) {
          value = value.replace('http:', 'https:');
        }
        if (value.includes('www.')) {
          value = value.replace('www.', '');
        }
        if (value.includes('https://kalgudi.com')) {
          value = value.replace(`https://kalgudi.com`, environment.baseUrl);
        }
        if (value.includes('blob')) {
          preparedUrl = `${value}_150x150`; break;
        } else if (value.includes('s3')) {
          preparedUrl = value.replace('.' + extentionType, '_150x150.' + extentionType); break;
        }
        preparedUrl = value.replace('.' + extentionType, '_150x150.' + extentionType); break;
      case 'format':
        if (this.isSafariBrowser) {
          preparedUrl = value;
        } else {
          preparedUrl = value.replace(`.${extentionType}`, `.webp`);
        }
        break;
      default:
        preparedUrl = value; break;
    }


    if (preparedUrl.startsWith('/store/assets')) return `https://kalgudi.com${preparedUrl}`;

    return preparedUrl.includes('http') || preparedUrl.includes('assets')
      ? preparedUrl
      : environment.baseUrl + preparedUrl;
  }

  transform(value: string, size?: string): any {
    return ScaleImagePipe.transform(value, size);
  }

}