import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DeliveryAddress } from '@app/models';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiUser } from '@kalgudi/types';
import { forkJoin, interval, Observable, of, Subject } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, finalize, take, takeLast, takeUntil, tap } from 'rxjs/operators';
import { GooglePlacesApiService } from 'src/app/modules/google-places/services/google-places-api.service';
import { SpinnerService } from 'src/app/project-common/services/spinner.service';
import { UtilityService } from 'src/app/project-common/services/utility.service';

import { AddressService } from '../../services/address.service';
import { MapDialogComponent } from '../map-dialog/map-dialog.component';
import { PincodePickerComponent } from '../pincode-picker/pincode-picker.component';

// import { SmartsuppService } from 'src/app/project-common/services/smartsupp.service';
@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnDestroy {

 addressForm: DeliveryAddress;
 isEditing: boolean;

 isAccountForGuest = false;

 forUserCreation = false; // this boolean is used for guest checkout purpose
 userCreationProcessing = false;
 userAlreadyExistsError = false;

  @Input() formData: { address? : DeliveryAddress , isEditing?: boolean, forUserCreation?: boolean };

  isProcessing: boolean;
  hasValidAddress: boolean;
  hasInvalidAddress: boolean;
  selectedTab: number;

  private destroyed$: Subject<boolean> = new Subject();

  // @Output() closeForm = new EventEmitter();
  @ViewChild('pinCode') pinCode: FormControl;
  @ViewChild('address') shippingForm: NgForm;
  @ViewChild('pin') pin: ElementRef;

  @Output() cancelClicked = new EventEmitter();
  @Output() submitClicked = new EventEmitter();

  currentPosition: { latitude: number; longitude: number; accuracy?: number };

  userData: KalgudiUser;

  numbersRegex = /^[0-9]+$/;

  @Input() isGuestAddress = false;
  googleLocation: any;
  thirdPartyAddr: any;



  constructor(
    private bottomSheet: MatBottomSheet,        // changes for pincode bottomsheet
    private addressService: AddressService,
    private googlePlacesService: GooglePlacesApiService,
    private spinner: SpinnerService,
    private util: UtilityService,
    private detectChange: ChangeDetectorRef,
    private ngZone: NgZone,
    // private smartSuppService: SmartsuppService,
    private mobileDialog: KalgudiDialogsService,
  ) {
  }

  ngOnInit() {

    try {
      this.addressForm = this.formData.address;
      this.isEditing = this.formData.isEditing;
      this.forUserCreation = this.formData.forUserCreation;
    } catch(e) {}

    // this.smartSuppService.hideSmartSupport();
    if (!this.addressForm.addressType) {
      this.addressForm.addressType = 'PIN';
    }
    this.selectedTab = this.addressForm.addressType === 'PIN' ? 0 : 1;

    this.addressForm.country = this.addressForm.country || 'India';

    this.addressService.getUserProfile().subscribe(userData => {
      this.userData = userData;
      if(!this.isGuestAddress) {

        this.autoFillUserDetails(this.userData);
      }
      this.registerPinCodeChangeListener(); 
      if (this.addressForm.postalCode) {
        this.applyFlags();
      }
    });
  }


  /**
   * To switch between address types
   * 
   * @param event Change event
   */
  public switchAddressType(tabIndex: number): void {

    this.addressForm.addressType = tabIndex ===0 ? 'PIN' : 'GPS';

    this.clearAutoFilledData();
    if (this.addressForm.addressType === 'GPS') {
      this.hasInvalidAddress = false;
      this.hasValidAddress = false;
      this.getCurrentLocation(true);
    }
  }


  /**
   * Will be triggered when user adjust the marker on map
   * 
   * @param position position selected on the map
   */
  public reposition(position: { latitude: number; longitude: number }) {
    this.currentPosition = position;
    this.clearAutoFilledData();
    const observer = this.googlePlacesService.searchOnGoogle(position)
    this.handleSearchObserver(observer);
  }

  /**
   * To close address form and emit a value to parent component
   * 
   * @param param 
   */
  public closeAddressForm(param) {
    // this.closeForm.emit(param);
  }

  /**
   * To accept only numeric characters from keyboard
   * 
   * @param event pressed key event
   */
  public isNumber(event: KeyboardEvent): boolean {

    return this.util.numberOnly(event)

  }

  /**
   * To save the address to user profile and emit list of addresses to parent component
   */
  public saveAddress(): void {
    // this.spinner.start();
    this.addressService.saveAddress(this.addressForm)
      .subscribe(response => {
        // this.spinner.stop();
        // this.closeForm.emit({ addressList: response });
      })

  }


  submitForm() {
    if (!this.forUserCreation) {
      if(this.isGuestAddress) {
        this.addressForm['createAccount'] = this.isAccountForGuest;
      }
      return this.submitClicked.emit(this.addressForm);
    }

    this.userCreationProcessing = true;
    this.addressService.createProfileWithAddress(this.addressForm)
    .pipe(
      delay(1000),
      tap(console.log),
      finalize(() => this.userCreationProcessing = false),
    )
    .subscribe(data => {
      this.userAlreadyExistsError = (data.registrationError && data.registrationError.error && data.registrationError.error.includes('already registered')) || data.isExistingUser;

      if(data.isNewRegistrationSuccess) {
        this.submitClicked.emit(data);
      }

    });
  }



  protected onDestroyed() { }

  /**
   * Registers a listener for pincode form-control
   */
  private registerPinCodeChangeListener() {
    this.pinCode.valueChanges.pipe(
      // debounceTime(600),
      distinctUntilChanged(),
      tap(() => {
        if (this.addressForm.addressType === 'PIN') {
          this.hasInvalidAddress = false;
          this.hasValidAddress = false;
        }
      }),
      filter((input: string) => this.validatePincode(input)),
      tap(() => this.pin.nativeElement.blur()),
      takeUntil(this.destroyed$)
    ).subscribe((text: string) => {


      // Commenting listening to the pincode value changes as `check` button is introduced
      // if (this.addressForm.addressType === 'PIN') {
        

      //   this.clearAutoFilledData(true);
      //   const observer = this.googlePlacesService.searchForPinCode(this.addressForm.postalCode);
      //   this.handleSearchObserver(observer);
      // }
    })
  }

  checkValidityAndGetAddressFromPincode() {
    
    const isValidPincode = this.validatePincode(this.addressForm.postalCode);
    this.hasInvalidAddress = !isValidPincode;
    if(!isValidPincode) return; // Retruning from function as pincode is invalid
    
    this.pin.nativeElement.blur(); // focusing out from pincode input

    this.clearAutoFilledData(true);
    this.isProcessing = true;
    const googleObserver = this.googlePlacesService.searchForPinCode(this.addressForm.postalCode).pipe(
      takeLast(1),
      delay(1000),
      finalize(() => {
        this.ngZone.run(() => {
          this.isProcessing = false;
          this.onInputFocus();
        })
      }),
      catchError((e) => {
        console.log(e);
        return of('GOOGLE_PLACES_SERVICE_ERROR')
      })
    );
    
    const thirdPartyPincodeObserver = this.addressService.getAddressFromThirdPartyAPI(this.addressForm.postalCode).pipe(
      catchError((e) => {
        console.log(e);
        return of('PINCODE_SERVICE_FAILED');})
    );

    forkJoin([googleObserver, thirdPartyPincodeObserver]).subscribe(results => {
      this.googleLocation = results[0];
      this.thirdPartyAddr = results[1];
      if (this.thirdPartyAddr != 'PINCODE_SERVICE_FAILED' && this.thirdPartyAddr[0].Status != 'Error') {       // 3rd party service didnt fail
        this.mergeAddresses();
      } else if (this.googleLocation.googleLocationTo) {    // 3rd party service failed... but google didnt fail
        this.autoFillFromSearchResultForKalgudi(this.googleLocation);
      } else {                                            // both google & 3rd party services failed
        this.hasInvalidAddress = true;
        this.hasValidAddress = false;
      }
    })
  }

  autoFillFromSearchResultForKalgudi(res:any) {
        // console.log(res);
        this.ngZone.run(() => {
          if (this.addressForm.addressType === 'GPS') {
            this.addressForm.postalCode = res.googleLocationTo.zipcode;
          }
    
          if (this.addressForm.addressType == 'PIN' && this.thirdPartyAddr != 'PINCODE_SERVICE_FAILED' && this.thirdPartyAddr[0].Status != 'Error') {
            this.addressForm.googleLocation = res.googleLocationTo;
    
            if (res.googleLocationTo.countryName) {
              this.addressForm.country = res.googleLocationTo.countryName;
            }
    
            // if present, use "state" from (google object) by default... if not present, use 3rd party pincode "state"
            if (res.googleLocationTo.adminLevel1) {
              this.addressForm.state = res.googleLocationTo.adminLevel1;
            } else {
              this.addressForm.state = this.addressForm.pincodeDetails.state;
            }
    
            // if present, use "district" from (google object) by default... if not present, use 3rd party pincode "district"
            if (res.googleLocationTo.adminLevel2) {
              this.addressForm.district = res.googleLocationTo.adminLevel2;
            } else {
              this.addressForm.district = this.addressForm.pincodeDetails.district;
            }
    
            // addressForm city will now be
            // pincode name + google locality + district
            if (!res.googleLocationTo.locality) {
              this.addressForm.city = this.addressForm.pincodeDetails.name + ', ' + this.addressForm.district;
            } else if (res.googleLocationTo.locality != this.addressForm.district) {
    
              if (this.addressForm.pincodeDetails.name != res.googleLocationTo.locality) {
                this.addressForm.city = this.addressForm.pincodeDetails.name + ', ' + res.googleLocationTo.locality + ', ' + this.addressForm.district;
              } else {
                this.addressForm.city = res.googleLocationTo.locality + ', ' + this.addressForm.district;
              }
    
            } else
              this.addressForm.city = this.addressForm.pincodeDetails.name + ', ' + res.googleLocationTo.locality;
    
          } else {
    
            this.addressForm.googleLocation = res.googleLocationTo;
            this.addressForm.country = res.googleLocationTo.countryName;
    
            this.addressForm.googleLocation = res.googleLocationTo;
            this.addressForm.country = res.googleLocationTo.countryName;
            this.addressForm.state = res.googleLocationTo.adminLevel1;
            this.addressForm.city = res.googleLocationTo.adminLevel2 || res.googleLocationTo.locality;
    
            let addressLine2 = res.googleLocationTo.locality || res.googleLocationTo.adminLevel2;
            addressLine2 = addressLine2 === this.addressForm.city ? res.googleLocationTo.political : addressLine2;
    
            this.addressForm.addressLine2 = addressLine2;
          }
    
          // this.addressForm.addressLine2 = res.googleLocationTo.subLocality || res.googleLocationTo.locality || '';
          this.applyFlags();
          // this.addressForm.residence = res.formatted_address;
          this.detectChange.detectChanges();
        })
        // this.appDetectChange.tick();
  }

  storePincodeDetails(result) {
    this.thirdPartyAddr[0].PostOffice.forEach((block, index) => {
      if (block.Name.includes(result)) {
        this.addressForm.pincodeDetails = {
          block: block.Block,
          branchType: block.BranchType,
          circle: block.Circle,
          country: block.Country,
          deliveryStatus: block.DeliveryStatus,
          district: block.District,
          division: block.Division,
          description: block.Description,
          name: result,
          pincode: block.Pincode,
          region: block.Region,
          state: block.State
        };
      }
    })
    this.hasInvalidAddress = false;
    this.hasValidAddress = true;

    this.autoFillFromSearchResultForKalgudi(this.googleLocation);
  }

  mergeAddresses() {
    const postOfficesList = this.thirdPartyAddr[0].PostOffice;
    if (postOfficesList.length > 1) {
      this.bottomSheet.open(PincodePickerComponent, {
        panelClass: 'p-0',
        data: {
          postOffices: postOfficesList,
        }
      }).afterDismissed().subscribe(result => {
        if (result && result.accepted) {
          this.storePincodeDetails(result.name);
        }
      })
    } else {
      this.storePincodeDetails(this.thirdPartyAddr[0].PostOffice[0].Name);
      this.autoFillFromSearchResultForKalgudi(this.googleLocation);
    }
  }


  clearLocation() {
    if(this.addressForm.addressType !== 'GPS') {
      this.addressForm.postalCode = ''
    } else {
      this.switchAddressType(1);
    }
  }



  private validatePincode(pincode: string):boolean {
    if(!pincode) return false;

    const invalidPincodes = ['000000'];

    return pincode.length > 5 && this.numbersRegex.test(pincode) && !invalidPincodes.includes(pincode);
  }



  /**
   * Common place to handle observable subscription
   * 
   * @param observer any
   */
  private handleSearchObserver(observer: Observable<any>, skipDialog?: boolean) {

    // this.spinner.start();
    this.isProcessing = true;
    observer.pipe(
      takeLast(1),
      delay(1000),
      finalize(() => {
        this.ngZone.run(() => {
          // this.spinner.stop();
          this.isProcessing = false;
          this.onInputFocus();
        })
      })
    ).subscribe(res => {

      if (this.util.isMobileDevice() && !skipDialog) {
        const dialogRef = this.mobileDialog.openMobileDialog(MapDialogComponent, {
          title: 'Select location',
          acceptButtonTitle: 'Continue',
          rejectButtonTitle: 'Close',
          autoFocus: false,
          data: res
        });

        // this.smartSuppService.hideSmartSupport();
        if (typeof this.pin.nativeElement.blur === 'function') {
          this.pin.nativeElement.blur();
        }
        dialogRef.subscribe(res => {
          // this.smartSuppService.showSmartSupport();
          if (!res || !res.accepted) {
            this.selectedTab = 0;
            return this.switchAddressType(0);
          } 



          const result: any = res.data;
          if (result) {

            return this.autoFillFromSearchResult(result);

            // result.latitude = JSON.parse(result.latitude);
            // result.longitude = JSON.parse(result.longitude);
            // this.currentPosition = result;
            // this.clearAutoFilledData();
            // const observer = this.googlePlacesService.searchOnGoogle(result)
            // this.handleSearchObserver(observer, true);
          }
        });
      } else {
        this.autoFillFromSearchResult(res)
      }

    },
      err => {
        this.hasInvalidAddress = true;
        console.error(err);
      });
  }

  /**
   * To read current lat long using GPS and get location result from google
   * It will also autofill mapped fields from google response
   */
  private getCurrentLocation(shouldSearchInGoogle?: boolean): void {
    this.googlePlacesService.fetchCurrentLocation()
      .pipe(takeUntil(this.destroyed$),
      // filter(value => !!value)
      )
      .subscribe(res => {
        if (!res) {
          setTimeout(() => {
            this.ngZone.run(() => {
              this.pin.nativeElement.focus();
            })
          }, 300);

          this.selectedTab = 0;
          return this.addressForm.addressType = 'PIN';
        }
        this.currentPosition = res;
        if (shouldSearchInGoogle) {
          const observer = this.googlePlacesService.searchOnGoogle(this.currentPosition);
          this.handleSearchObserver(observer);
        } else {
          this.addressForm.googleLocation = {
            latitude: res.latitude.toString(),
            longitude: res.longitude.toString(),
            adminLevel1: '',
            countryName: 'India'
          };
        }
      });
  }

  private autoFillFromSearchResult(res: any) {
    // console.log(res);
    this.ngZone.run(() => {
      if (this.addressForm.addressType === 'GPS') {
        this.addressForm.postalCode = res.googleLocationTo.zipcode;
      }
      this.addressForm.googleLocation = res.googleLocationTo;
      this.addressForm.country = res.googleLocationTo.countryName;
      this.addressForm.state = res.googleLocationTo.adminLevel1;
      this.addressForm.city = res.googleLocationTo.adminLevel2 || res.googleLocationTo.locality;

      let addressLine2 = res.googleLocationTo.locality || res.googleLocationTo.adminLevel2;
      addressLine2 = addressLine2 === this.addressForm.city ? res.googleLocationTo.political : addressLine2;

      this.addressForm.addressLine2 = addressLine2;

      // this.addressForm.addressLine2 = res.googleLocationTo.subLocality || res.googleLocationTo.locality || '';
      this.applyFlags();
      // this.addressForm.residence = res.formatted_address;
      this.detectChange.detectChanges();
    })
    // this.appDetectChange.tick();
  }

  private applyFlags() {
    if (this.isValidLocation(this.addressForm)) {
      this.hasValidAddress = true;
      this.hasInvalidAddress = false;
      interval(300).pipe(
        take(1),
        tap(() => {
          this.shippingForm.controls['address-line-1'].markAllAsTouched();
          this.shippingForm.control.updateValueAndValidity();
        })
      ).subscribe();
    } else {
      this.hasInvalidAddress = true;
      this.hasValidAddress = false;
    }
  }

  private isValidLocation(addressForm: DeliveryAddress) {
    return addressForm.postalCode && addressForm.city && addressForm.state && addressForm.country && addressForm.postalCode !== '000000';
  }

  /**
   * To clear data which are auto-filled 
   */
  private clearAutoFilledData(ignorePin?: boolean) {
    this.hasValidAddress = false;
    this.addressForm.country = '';
    this.addressForm.state = '';
    this.addressForm.city = '';
    this.addressForm.addressLine2 = '';
    this.addressForm.googleLocation = undefined;
    if (!ignorePin) {
      this.addressForm.postalCode = '';
    }
  }


  private autoFillUserDetails(user: KalgudiUser) {
    if (!this.addressForm.name) {
      this.addressForm.name = user.firstName;
    }
    if (!this.addressForm.phone) {
      this.addressForm.phone = this.util.getUserMobileNumber(user);
    }

  }

  onInputFocus() {
    setTimeout(() => {
      if(!this.isGuestAddress) {
        const scroller = document.getElementsByClassName('dialog-content')[0];
        scroller.scrollTop = scroller.scrollHeight;
      }
    }, 300);

  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

}
