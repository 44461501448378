<section class="seasonal-product-tile bg-white">
  <main fxLayout="row wrap" fxLayoutGap="30px grid" fxLayoutGap.sm="10px grid" fxLayoutGap.xs="15px grid">
    <div *ngFor="let product of pTileProducts" fxFlex="25%" fxFlex.sm="33.33%" fxFlex.xs="50%">
      <div class="product-tile position-relative w-100 my-5 cursor-pointer" fxLayout="column" (click)="goToProductFullView(product)">
        <!-- Image  -->
        <div class="product-image position-relative" style="height: 100%;" fxLayoutAlign="center center" fxLayout="column">
            <!-- <div class="image-container w-100" 
              [ngStyle]="{'background': (product?.productPicURL_level3 | prefixDomain ) ? 'url(' + (product?.productPicURL_level3 | prefixDomain) + ')' : 'url(assets/icons/no-image.jpg)', height: '150px', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center', 'objet-fit': 'cover'}">
            -->
            <div class="bg-img p-10 justify-content-center d-flex align-items-center" [title]="product?.productName_level3">
              <img class="img-responsive" width="100%" height="100%" loading="lazy" [src]="product?.productPicURL_level3 || 'assets/icons/No_Image.jpg'| scaleImage: 'md'" alt="" >
            </div>
            <div class="delivery-info text-white w-100 position-absolute" fxLayoutAlign="center center" *ngIf="product?.ships">
              <shipping-charge-message class="info w-100" fxLayoutAlign="center center" [ShowFreeChargesMsg]=false [product]="product"></shipping-charge-message>
            </div>
          <!-- </div> -->
        </div>
        <!-- /Image  -->

        <!-- Product Title -->
        <div class="product-info bg-white pos-relative">
          <div class="text-truncate product-title mt-0 font-16 font-weight-bold" translate>{{ product?.productName_level3 }}</div>
          <div>
            <ng-container *ngIf="product?.pricePerSKU > '1'">
              <span class="font-weight-bold">{{ product?.pricePerSKU | currency: "INR":"symbol":"1.0" }}</span>
            </ng-container>
            <ng-container *ngIf="product?.pricePerSKU < '1'">
              <span class="price-zero" translate>Coming Soon</span>
            </ng-container>
            <ng-container *ngIf="product?.mrp && product?.mrp > product?.pricePerSKU">
              <span class="mrp ml-5">{{
                product?.mrp | currency: "INR":"symbol":"1.0"
              }}</span>
            </ng-container>
          </div>

          <div fxLayout  class="mt-3 w-100" fxLayoutGap="10px" fxLayoutAlign="center center">
            <div class="quantity" fxFlex="calc(40% - 10px)">
              <ng-container *ngFor="let smartElement of product?.selectedSKU | slice: 0:1">
                <span class="sku" *ngIf="smartElement !== 'dimensions'">
                  {{ (product?.SKUSmartElements)[smartElement].value }}
                  {{ (product?.SKUSmartElements)[smartElement].unit }}
                </span>
              </ng-container>
            </div>

            <div fxFlex="calc(60% - 10px)" class="w-100"> 
              <button mat-flat-button class="pre-order-btn w-100" fxLayoutAlign="center center" (click)="addToCart(product.productId_level2, product.productId_level3, product.stores); $event.stopPropagation(); $event.preventDefault()"> 
                <span class="d-inline-block font-weight-bold text-truncate w-100">
                  Add To Cart
                </span>
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </main>
</section>
