<div class="final-details mb-3">
    <div class="detail-row" fxLayoutAlign="space-between center">
        <span translate>Items total</span>
        <span>{{ charges?.overAllItemsValue | currency:'INR':'symbol':'1.2-2'}}</span>
    </div>

    <div class="detail-row" fxLayoutAlign="space-between center" *ngIf="charges?.totalPackagingCost">
        <span translate>Packaging cost</span>
        <span>{{ (charges?.totalPackagingCost || 0) | currency:'INR':'symbol':'1.2-2'}}</span>
    </div>

    <div class="detail-row" fxLayoutAlign="space-between center" *ngIf="charges?.totalHandlingCost">
        <span translate>Transaction fees</span>
        <span>{{ (charges?.totalHandlingCost || 0) | currency:'INR':'symbol':'1.2-2'}}</span>
    </div>

    <div class="detail-row" fxLayoutAlign="space-between center">
        <span translate>Shipping charges</span>
        <span>{{ (charges?.totalShippingCost || 0) | currency:'INR':'symbol':'1.2-2'}}</span>
    </div>

    <div class="detail-row text-success" fxLayoutAlign="space-between center">
        <span translate>Discount</span>
        <span>-{{ charges?.totalDiscounts  | currency:'INR':'symbol':'1.2-2'}}</span>
    </div>

    <div class="divider"></div>

    <div class="detail-row total-price" fxLayoutAlign="space-between center">
        <span translate>Total</span>
        <span>{{ charges?.grandTotal | currency:'INR':'symbol':'1.2-2'}}</span>
    </div>

</div>

<!-- /////// deprecated -->
<div class="bg-white mt-3" fxLayoutAlign="start center" *ngIf="false">
    <mat-icon class="release-icon">new_releases</mat-icon>
    <small class="delivery" translate>Delivers with in 9-12 days.</small>
</div>

