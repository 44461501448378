import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssistantRole } from 'src/app/project-common/models/requirement.model';

import { ServiceResponse } from '../project-common/models/network-request.model';
import { CountryDetails } from '../project-common/models/subscriber-payload';
import { Storefront } from '../project-common/properties/constants';
import { CartService } from '../project-common/services/cart.service';
import { DbUpdateService } from '../project-common/services/db-update.service';
import { FcmService } from '../project-common/services/fcm.service';
import { HttpWithAuthService } from '../project-common/services/http-interceptor.service';
import { NotificationService } from '../project-common/services/notification.service';
import { UtilityService } from '../project-common/services/utility.service';
import { QuotationService } from '../quotation/quotation.service';
import { environment } from './../../environments/environment';
import { Assistant } from './../project-common/models/requirement.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public redirectionUrl: string;
  public userData: any;
  public isInProgress: boolean;
  public countryCode: string = '+91';
  signupObject: any = {
    name: '',
    mobileTelecomCode: '',
    mobileNumber: '',
    email: '',
    expressSignup: true,
    profileTypeId: 'PT000006',
    profileTypeName: 'I\'ll tell later',
    businessTypeId: 'BT000012',
    businessTypeName: 'I\'ll tell later',
    nativeBusinessTypeId: 'BT000012',
    nativeBusinessTypeName: 'I\'ll tell later',
    businessName: '',
    organizationName: '',
    password: '12345678',
    address: 'Hyderabad, Telangana, India',
    googleLocationTo: {
      locality: 'Hyderabad',
      adminLevel2: 'Hyderabad',
      adminLevel1: 'Telangana',
      countryName: 'India',
      latitude: '17.385044',
      longitude: '78.486671'
    },
    lstOfProducts: [],
    studentDetails: {
      universityName: '',
      universityKey: '',
      degreeName: '',
      courseId: '',
      courseName: '',
      courseStartYear: '',
      courseEndYear: ''
    },
    industryId: '',
    companyName: '',
    jobDescription: '',
    sourcePortal: Storefront.APP_NAME
  };

  private countryDetails$: BehaviorSubject<CountryDetails>;

  constructor(
    public httpWithAuth: HttpWithAuthService,
    private http: HttpClient,
    private localStorage: LocalStorage,
    private updateDB: DbUpdateService,
    private notification: NotificationService,
    private cart: CartService,
    private fcmService: FcmService, 
    private quotationService: QuotationService,

    private util: UtilityService
    ) {
    this.localStorage.getItem('userdata').subscribe(response => {
      if (response) {
        this.userData = response;
        this.getProfileDataFromDynamoDB(response.profileKey, true).subscribe(userDataOnStorage => {
        });
      }
    });

    this.notification.userDataChanges.subscribe(() => {
      this.localStorage.getItem('userdata').subscribe(response => {
        if (response) {
          this.userData = response;
        }
      });
    });
  }
  public getCountryCode(): Observable<string> {
    if (!this.countryDetails$) {
      this.countryDetails$ = new BehaviorSubject<CountryDetails>(null);
      this.localStorage.getItem('countryDetails').subscribe(country => {
        if (country) {
          this.countryDetails$.next(country);
        } else {
          this.countryDetailsApi().subscribe(country => {
            this.localStorage.setItemSubscribe('countryDetails', country);
            this.localStorage.setItemSubscribe('countryId', country.MobileTelecomcode);
            this.localStorage.setItemSubscribe('mobileTelecomCode', country.MobileTelecomcode);
            this.countryDetails$.next(country);
          })
        }
      })
    }

    return new Observable<string>(observer => {
      this.countryDetails$.asObservable().subscribe(country => {
        if(country) {
          observer.next(country.MobileTelecomcode);
          observer.complete();
        }
      });
    })
  }

  private countryDetailsApi(): Observable<CountryDetails> {
    const url = `${environment.baseUrl}/rest/v1/profiles/coudtls`;

    return this.http.get(url).pipe(map((res: ServiceResponse) => JSON.parse(res.data)));
  }

  public login(request: any): Observable<any> {
    const url = `${environment.baseUrl}/v2/auth/login`;

    request.password = btoa(request.password);

    return this.http.post(url, request).pipe(map((response: ServiceResponse) => {

      this.redirectionUrl = this.httpWithAuth.redirectUrl || window.localStorage['blockedUrl'];

      if (response.code === 200) {

        this.processAfterLogin(response, request);

    }
    return response;

  }));

}

processAfterLogin(loginResponse: ServiceResponse, request: any = { password: '12345678'}, decodePassword = true) {


  // setting 'tk' , 'c' in localStorage to share the login information b/w core app & store.

  const userData = loginResponse.data.userBasicDetail;

  const credentials = JSON.parse(JSON.stringify(request));

  credentials['userName'] = userData.mobileNo;
  localStorage.setItem('tk', btoa(JSON.stringify(loginResponse.data.auth)));
  userData.password = decodePassword ?  atob(request.password) : request.password;
  credentials['password'] = userData.password;
  localStorage.setItem('c', btoa(JSON.stringify(credentials)));
  // this.updateAssistantLocalStorage(request.userName);

  this.updateAuthenticationFlags(userData);

  if(request.userName) {

    this.updateAssistantLocalStorage(request.userName)
  }

}
  

async updateAuthenticationFlags(userData) {
    this.httpWithAuth.isLoggedIn = true;
    this.httpWithAuth.userData = userData;
    window.localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] = true;
    window.localStorage[btoa('profileKey')] = btoa(userData.profileKey);
    await this.httpWithAuth.setImediateHeaders(userData.mobileNo, userData.password);
    /* setTimeout(() => {
      this.cart.getPersistedCartData();
    }, 400); */
  }

  verifyPassword(request): Observable<any> {

    const url = `${environment.baseUrl}/v2/store/${environment.APP_NAME}/auth/guest-verify`;    

    return this.http.put(url, request).pipe(map(response => {

      return response;

    }));

  }

  private async updateAssistantLocalStorage(email: string) {
    this.quotationService.getAssistants().subscribe(response => {
      const salesManagers: Assistant[] = response.salesManagers;
      const salesTeam: Assistant[] = response.salesTeam;
      const bosLeads: Assistant[] = response.bosLeads;
      const bosTeam: Assistant[] = response.bosTeam;
      const contributors: Assistant[] = response.contributors;
      const salesPartners: Assistant[] = response.salesPartners;

      let tmpEmail = email.includes('@kalgudi.com') ? email.replace('@kalgudi.com', '@vasudhaika.net') : email.replace('@vasudhaika.net', '@kalgudi.com');
      // console.log(email,'--------',tmpEmail);

      let assistant: Assistant = (salesManagers && salesManagers.length) ? salesManagers.find(manager => manager.email === email || manager.email === tmpEmail) : undefined;
      if (assistant) {
        assistant.role = AssistantRole.MANAGER;
        localStorage.setItem('assistant', JSON.stringify(assistant));
        this.setCanAssistInLocalStorage();
        return;
      }

      assistant = (bosTeam && bosTeam.length) ? bosTeam.find(supportGuy => supportGuy.email === email || supportGuy.email === tmpEmail) : undefined;
      if (assistant) {
        assistant.role = AssistantRole.BOS;
        localStorage.setItem('assistant', JSON.stringify(assistant));
        this.setCanAssistInLocalStorage();
        return;
      }

      assistant = (bosLeads && bosLeads.length) ? bosLeads.find(lead => lead.email === email || lead.email === tmpEmail) : undefined;
      if (assistant) {
        assistant.role = AssistantRole.LEAD;
        localStorage.setItem('assistant', JSON.stringify(assistant));
        return;
      }

      assistant = (salesTeam && salesTeam.length) ? salesTeam.find(salesman => salesman.email === email || salesman.email === tmpEmail) : undefined;
      if (assistant) {
        assistant.role = AssistantRole.SALESMAN;
        localStorage.setItem('assistant', JSON.stringify(assistant));
        this.setCanAssistInLocalStorage();
        return;
      }

      // Product contributor
      assistant = (contributors && contributors.length) ? contributors.find(contributor => contributor.email === email) : undefined;
      if (assistant) {
        assistant.role = AssistantRole.CONTRIBUTOR;
        localStorage.setItem('assistant', JSON.stringify(assistant));
        return;
      }

      // Sales Patner
      assistant = (salesPartners && salesPartners.length) ? salesPartners.find(partner => partner.email === email) : undefined;
      if (assistant) {
        assistant.role = AssistantRole.SALESPARTNER;
        localStorage.setItem('assistant', JSON.stringify(assistant));
        return;
      }

    });
  }


  public getProfileDataFromDynamoDB(profileKey: string, updateIndexDB?: boolean): Observable<any> {
    const url = `${environment.baseUrl}/rest/v1/profiles/${profileKey}`;
    return this.httpWithAuth.get(url).pipe(map(data => {
      let response: any = data;
      response = JSON.parse(response.data);
      if (updateIndexDB) {
        this.updateDB.updateUserData(response);
      }
      return response;
    }));
  }

  public getProfileDataFromS3(profileKey: string) {
    const url = `${environment.baseUrl}/data/profiles/${profileKey}`;
    return this.httpWithAuth.get(url).pipe(map(data => {
      let response: any = data;
      return response;
    }));
  }


  public signup(request: any): Observable<any> {
    const url = `${environment.baseUrl}/v2/auth/simplesignup`;
    this.signupObject.email = request.email;
    this.signupObject.mobileTelecomCode = request.countryCode;
    this.signupObject.mobileNumber = (request.email ? '' : request.countryCode) + request.mobile;
    this.signupObject.name = request.name;
    return this.http.post(url, this.signupObject).pipe(map(data => {
      return data;
    }));
  }

  public verifyOtp(request: any): Observable<any> {
    const url = `${environment.baseUrl}/rest/v1/profiles/expresssignin`;
    return this.http.post(url, request).pipe(map(data => {
      return data;
    }));
  }
  public logout(navigationPath?: string) {
    if (environment.production) this.processFcm();
    this.updateDB.clearUserData(navigationPath);
  }

  public generateOtpToRecoverPassword(request): Observable<any> {
    return this.http.post(`${environment.baseUrl}/rest/v1/profiles/forgotpwdgenkey`, request).pipe(map(data => {
      return data;
    }, error => {
      throw Error('Server is busy: ' + error);
    }));
  }

  public recoverPassword(request): Observable<any> {
    return this.http.post(`${environment.baseUrl}/rest/v1/profiles/forgotpwdverify`, request).pipe(map(data => {
      return data;
    }, error => {
      throw Error('Server is busy: ' + error);
    }));
  }

  processFcm(profileKey?) {
    let tokenKey = window.localStorage['fcmToken'];
    if (tokenKey && environment.production)
      this.fcmService.updateDeviceDetails({ refreshedToken: tokenKey, profileKey: profileKey ? profileKey : '' }).subscribe(res => {
        // console.log('Device details updated successfully ');
      });
  }

  public checkAssistanceEligibility(profileKey: string) {
    const url = `${environment.baseUrl}/rest/v1/farmerstore/assist?profileKey=${profileKey}`;
    return this.httpWithAuth.get(url).pipe(
      map((response: any) => {
        const result: ServiceResponse = response;
        return result;
      })
    )
  }

  /**
     * 
     * @param req expected object link { emailId : '', mobileNo: '' } ( either one or both fields )
     * @param otpLogin 
     */
  public verifyRegistration(req: any, otpLogin?) {

    let url = `${environment.baseUrl}/rest/v1/profiles/isRegistered`;
    let otp = otpLogin && otpLogin === 'OTP' ? '&otpLogin=1' : '';

    if (req.emailId && req.mobileNo) {
      url += `?mobileNo=${req.mobileNo}&emailId=${req.emailId}` + otp;
    } else if (req.emailId) {
      url += `?emailId=${req.emailId}` + otp;
    } else if (req.mobileNo) {
      url += `?mobileNo=${req.mobileNo}` + otp;
    }

    return this.httpWithAuth.get(url).pipe(
      map((response: any) => {
        const result: ServiceResponse = response;
        return result;
      })
    )
  }

  public assistedSignup(request, creatingByAssistant = false): Observable<any> {
    this.signupObject.mobileNumber = `+91${request.mobileNumber}`;
    this.signupObject.name = request.firstName;
    this.signupObject.mobileTelecomCode = '+91';
    this.signupObject.googleLocationTo = request.googleLocationTo;
    if (creatingByAssistant) {
      this.signupObject.assistedBy = {
        profileKey: this.userData.profileKey,
        firstName: this.userData.firstName,
        profilePicURL: this.userData.profilePicUrl,
        email: this.util.getEmailId(this.userData),
        mobileNo: this.util.getUserMobileNumber(this.userData),
        locationLong: this.util.getUserLocation(this.userData)
      }
    }
    return this.generateOtpForSignup(this.signupObject);
  }

  public resendOtpForAssistance(mobileNo) {

    const url = `${environment.baseUrl}/rest/v1/profiles/resendVerificationOtp`;

    const params = { mobileNo };


    return this.http.get<ServiceResponse>(url, { params });

  }


  public generateOtpForSignup(request): Observable<ServiceResponse> {
    const url = `${environment.baseUrl}/v2/auth/simplesignup`;
    return this.http.post<ServiceResponse>(url, request);
  }

  
  public verifyOtpForExistingUser(request: any): Observable<any> {
    const url = `${environment.baseUrl}/rest/v1/profiles/verifyMobileNo`;
    return this.httpWithAuth.put(url, request).pipe(map(data => {
        return data as ServiceResponse;
    }));
  }

  private setCanAssistInLocalStorage() {

    // Only assistants can do assisted ordering
    window.localStorage.setItem(btoa('canAssist'), 'true');
  }

  /**
   * Generates OTP
   */
  generateOtp(payload: any): Observable<any> {
    const url = `${environment.baseUrl}/v2/auth/generate-otp`;
    return this.http.post(url, payload).pipe(map(data => {
      return  data;
    }));
  }

  /**
   * Invalidates OTP
   */
  validateOtp(payload: any): Observable<any> {
    const url = `${environment.baseUrl}/v2/auth/validate-otp`;
    return this.http.post(url, payload).pipe(map(data => {
      return data;
    }));
  }

}