import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/env';
import { DeliveryAddress } from '@app/models';
import { ApiResponseCommon, ApiResponseCommonV1 } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Storefront } from 'src/app/project-common/properties/constants';

@Injectable()
export class AddressApiService {

  private readonly ADDRESS_API = `${environment.nodeServer}/v2/store/${Storefront.APP_NAME}/profiles/addresses`;

  private readonly GUEST_LOGIN_API = `${environment.nodeServer}/v2/store/${Storefront.APP_NAME}/auth/guest-login`;

  constructor(private http: HttpClient) { }


  /**
   * To get all saved addresses for a user based on profile key
   * 
   * @param profileKey user profileKey for whom address list has to be fetched
   * 
   */
  public getAddress(profileKey?: string): Observable<DeliveryAddress[]> {
    let params;
    if (profileKey) {
      params = { profileKey };
    }

    return this.http.get(this.ADDRESS_API, { params })
      .pipe(map((res: ApiResponseCommonV1) => {
        return JSON.parse(res.data);
      }));
  }

  /**
   * To save or update an address to user's address list. 
   * 
   * It checks if payload has an ID. If available, it processes the payload for update,
   * else it will request for address save
   * 
   * @param payload address payload
   * @param profileKey user profileKey for whom address list has to be fetched
   */
  public addAddress(payload: DeliveryAddress, profileKey?: string) {
    if (payload.id) {
      return this.updateAddress(payload, profileKey);
    } else {
      return this.saveAddress(payload, profileKey);
    }
  }

  /**
   * To save a new address payload to user's address list
   * 
   * @param payload new address payload
   * @param profileKey user profileKey for whom address list has to be fetched
   */
  private saveAddress(payload: DeliveryAddress, profileKey?: string): Observable<DeliveryAddress[]> {
    let params;
    if (profileKey) {
      params = { profileKey };
    }

    return this.http.post(this.ADDRESS_API, payload, { params }).pipe(map((res: ApiResponseCommonV1) => {
      return JSON.parse(res.data);
    }));
  }

  /**
   * To update an address from user's address list
   * 
   * @param payload address payload which has to be updated
   * @param profileKey user profileKey for whom address list has to be fetched
   */
  private updateAddress(payload: DeliveryAddress, profileKey?: string): Observable<DeliveryAddress[]> {
    let params;
    if (profileKey) {
      params = { profileKey };
    }

    return this.http.put(this.ADDRESS_API, payload, { params }).pipe(map((res: ApiResponseCommonV1) => {
      return JSON.parse(res.data);
    }));
  }

  /**
   * To remove an address from user's address list
   * 
   * @param addressId of the address which has to be deleted
   * @param profileKey user profileKey for whom address list has to be fetched
   */
  public deleteAddress(addressId: string, profileKey?: string): Observable<DeliveryAddress[]> {
    let params;
    if (profileKey) {
      params = { profileKey };
    }

    return this.http.put(`${this.ADDRESS_API}/${addressId}`, {}, { params }).pipe(map((res: ApiResponseCommonV1) => {
      return JSON.parse(res.data);
    }));
  }

  /**
   * To set an address as the default address for the user
   * 
   * @param addressId of the address which has to be set as default address
   * @param profileKey user profileKey for whom address list has to be fetched
   */
  public setAsDefaultAddress(addressId: string, profileKey?: string): Observable<DeliveryAddress[]> {
    let params;
    if (profileKey) {
      params = { profileKey };
    }

    return this.http.put(`${this.ADDRESS_API}/${addressId}`, {}, { params }).pipe(map((res: ApiResponseCommonV1) => {
      return JSON.parse(res.data);
    }));
  }

  /**

   * To create new profile/user with address

   */

  createProfileWithAddressApi(address: DeliveryAddress) {

    return this.http.post<ApiResponseCommon>(this.GUEST_LOGIN_API, address)

    .pipe(map(res => res.data));

  }

  getAddressFromThirdPartyAPI(pincode:string) {
    const url = 'https://api.postalpincode.in/pincode/'+pincode;
    return this.http.get<any>(url);
  }

}
