<div class="item" fxLayout>

    <div class="img-sec" fxFlex.xs="70px" fxFlex="125px" fxLayoutAlign="center center" (click)="openFullView()">
        <img [src]="product.productImage | scaleImage: 'md'" alt="product-image" title="{{product.productLevel3Title}}">
    </div>

    <div class="right-sec" fxFlex="calc(100% - 125px)" fxLayout>
        <div class="item-details-container" fxFlex="100%" fxLayout="column">

            <div class="mb-10">
                <div class="app-row">
                    <div class="p-name" (click)="openFullView()">{{ product?.productLevel3Title }}
                        <ng-container *ngFor="let smartElement of product.selectedSKU">
                            <span *ngIf="smartElement !== 'dimensions'">
                ({{ product.smartElements[smartElement]?.value }} {{ product.smartElements[smartElement]?.unit }})
              </span>

                        </ng-container>
                    </div>
                </div>

                <div class="app-row">
                    <div class="p-seller-name"><span translate>Sold by:</span> {{product?.sellerName}}</div>
                </div>

                <div class="mt-10" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start" fxLayoutGap="8px">
                    <div class="quantity-change" fxLayout fxLayoutGap="10px" fxLayoutAlign="start center" *ngIf="!isSavedItem">
                        <div class="qty-changer" (click)="removeQuantity()">-</div>
                        <div class="quantity-value" fxFlex="10px">{{ product?.productQuantity }}</div>
                        <div class="qty-changer" (click)="addQuantity()">+</div>
                    </div>
                    

                    <div fxLayoutAlign="end" *ngIf="product?.ships">
                        <shipping-charge-message class="info w-100" fxLayoutAlign="center center" [ShowFreeChargesMsg]=false [product]="product"></shipping-charge-message>
                    </div>
                </div>

                <!-- <ng-container *ngIf="!isSavedItem">
                <div fxLayout="row" fxLayoutAlign="end start" class="delivery-charge">
                  <span class="font-14">Estimated delivery 
                    <b [class.green-text]="!product?.shippingCost">
                      {{ (product?.shippingCost > 0) ? (product?.shippingCost | currency:'INR':'symbol':'1.0') : 'Free' }}
                    </b>
                  </span>
                  <span class="px-5 font-11" *ngIf="product?.freeShippingEligible && product?.shippingCost"> | </span>
                  <span class="font-14" *ngIf="product?.freeShippingEligible && product?.shippingCost"> Ships free on order over {{ 499 | currency: 'INR'}}</span>
                </div>
              </ng-container> -->

                <div class="p-price" *ngIf="!isSavedItem">
                    {{ product?.productQuantity }} x
                    <span>
            {{product?.pricePerUnit | currency:'INR':'symbol':'1.2-2'}}
          </span>
                </div>

            </div>

            <div>
                <div class="item-actions" fxLayout fxLayoutGap="20px" fxLayoutGap.xs="15px">

                    <ng-container *ngIf="isSavedItem; then savedItemActions; else cartItemActions"></ng-container>


                    <ng-template #cartItemActions>
                        <!-- <div *ngIf="isUserLogedIn && !product.isBulkOrder" class="action-link" (click)="saveForLater(product)">Save for later</div> -->
                        <div class="action-link" (click)="remove()" translate>remove</div>
                        <div *ngIf="product?.customOrders" class="action-link" (click)="isExpanded = !isExpanded">
                            <span fxHide.xs>{{ isCustomisedBefore ? 'Update ' : 'Add ' }}</span> <span fxHide.xs>customizations</span>
                            <span fxHide fxShow.xs>customize</span>
                        </div>
                        <span class="ml-auto" *ngIf="basketItemsCount">
                            Items: {{basketItemsCount}}
                        </span>
                    </ng-template>

                    <ng-template #savedItemActions>
                        <div class="action-link" (click)="moveToCart(product)" translate>Move to cart</div>
                        <div class="action-link" (click)="moveToWishList(product)" translate>Move to wish list</div>
                        <div class="action-link" (click)="removeProductFromSavedList(product)" translate>remove</div>
                    </ng-template>

                </div>
            </div>

            <div class="customization-body">

                <!-- CUSTOMIZATION ANSWERS -->
                <ng-container *ngIf="!isExpanded">

                    <div class="customizations" *ngFor="let c of product?.customOrders">
                        <ng-container *ngIf="c.answer">
                            <p>
                                <span class="type">Q. </span>
                                <span>{{ c.title }}</span>
                            </p>
                            <p class="mt-0">
                                <span class="type">A. </span>
                                <span>{{ c.answer }}</span>
                            </p>
                        </ng-container>
                    </div>

                </ng-container>


                <!-- CUSTOMIZATION FORM -->
                <ng-container>

                    <div class="custom-sec" [class.no-access]="!isExpanded" [class.open]="isExpanded" [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'">
                        <div class="question mt-10" *ngFor="let c of product?.customOrders; index as index">

                            <p class="mt-0 title">{{ index+1 }}. {{c.title}}</p>
                            <p class="mt-0 desc">{{c.description}}</p>

                            <mat-form-field appearance="outline">
                                <mat-label>Answer</mat-label>
                                <input matInput [(ngModel)]="c['answer']" placeholder="Answer here...">
                            </mat-form-field>
                        </div>

                        <!-- SAVE BTN -->
                        <div fxLayoutGap="15px" class="mt-10">

                            <button class="save" type="button" mat-flat-button (click)="saveCustomizationAnswers()" translate>save</button>

                            <button type="button" mat-stroked-button (click)="isExpanded = false" translate>cancel</button>

                        </div>

                    </div>


                </ng-container>

            </div>
        </div>
    </div>

</div>