import { Injectable } from '@angular/core';
import { DeliveryAddress } from '../models/shipping-form.model';
import { Observable, BehaviorSubject, of, Subject } from 'rxjs';
import { Storefront } from '../properties/constants';
import { environment } from '../../../environments/environment';
import { HttpWithAuthService } from './http-interceptor.service';
import { map, switchMap } from 'rxjs/operators';
import { GooglePlacesApiService } from 'src/app/modules/google-places/services/google-places-api.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryAddressService {

  private deliveryLocationLocalStorageKey = 'deliveryLocationSelected';
  private pincodeLocalStorageKey = 'pin';
  private KEY_FOR_LOCATION_BY_PIN = Storefront.KEY_FOR_LOCATION_BY_PIN;

  private deliveryLocationSelectedSubject = new BehaviorSubject<DeliveryAddress | string>('');
  deliveryLocationSelected$: Observable<DeliveryAddress | string>

  private selectedPinLocationSubject = new BehaviorSubject<string[] | any>('');
  selectedPinLocationSubject$: Observable<string[] | any>;

  private locBlockedErrorSubject = new Subject<true>();
  locBlockedError$: Observable<true>;

  constructor(
    private http: HttpWithAuthService,
    private googlePlacesService: GooglePlacesApiService
  ) {
    
    this.deliveryLocationSelected$ = this.deliveryLocationSelectedSubject.asObservable();
    this.selectedPinLocationSubject$ = this.selectedPinLocationSubject.asObservable();
    this.locBlockedError$ = this.locBlockedErrorSubject.asObservable();

    let deliveryLocationSelected = '';
    try {
      deliveryLocationSelected = (
        JSON.parse(localStorage.getItem(this.deliveryLocationLocalStorageKey) || null)
        || localStorage[this.pincodeLocalStorageKey]
        || ''
      );

    } catch (e) {
      console.warn(`Failed to parse existing pin`);
    }
    if(deliveryLocationSelected) {
      this.deliveryLocationSelectedSubject.next(deliveryLocationSelected);
      this.emitLocationKeys();
    } else {
      if(!this.http.isLoggedIn) {
        this.googlePlacesService.fetchCurrentLocation(false)
        .pipe(
            switchMap(currentLocation =>  currentLocation ? this.googlePlacesService.searchOnGoogle(currentLocation) : of(null))
        )
        .subscribe((res: any) => {
          if(!res) return this.locBlockedErrorSubject.next(true);

          
          const address = {
            postalCode : res.googleLocationTo.zipcode,
            googleLocation : res.googleLocationTo,
            country : res.googleLocationTo.countryName,
            state : res.googleLocationTo.adminLevel1,
            city : res.googleLocationTo.adminLevel2 || res.googleLocationTo.locality,
            addressLine2 : res.googleLocationTo.locality || res.googleLocationTo.adminLevel2,
          } as DeliveryAddress
          
          this.setDeliveryAddress(address);
          this.emitLocationKeys();
        });
      }
    }
  }

  setDeliveryAddress(address: DeliveryAddress | string) {
    
    if(!(address as DeliveryAddress).postalCode && (address as string).length !== 6)  {
      return ;
    }
    this.deliveryLocationSelectedSubject.next(address);

    const delAddress = (typeof address === 'string') ? address : JSON.stringify(address);
    localStorage[this.deliveryLocationLocalStorageKey] = delAddress;
    
    if (address) {
      const postalCode = (address as DeliveryAddress).postalCode || address;      
      localStorage[this.pincodeLocalStorageKey] = postalCode;      
      this.setPinLocationObject(postalCode);
    }
    
  }

  setPinLocationObject(postalCode) {
    this.getLocationDetailsByPinCode(postalCode).subscribe( location =>{
      localStorage[this.KEY_FOR_LOCATION_BY_PIN] = JSON.stringify(location);
      this.selectedPinLocationSubject.next(location);
    });
  }

  /** API CALL TO FETCH LOCATION DETAILS BY PIN CODE */
  private getLocationDetailsByPinCode(pin: string): Observable<any> {
    // console.log(`Fetching the location details by pin ${pin}`);
    // const testObj = { state:`Telangana`, city: `Hyderabad`, district:``, country:`India`, pinCode: pin }
    // const testObj = [`${pin}`,"Hyderabad","Telangana"];
    /* return new Observable ( observer => {
      observer.next(testObj);
    }); */
    const url = `${environment.baseUrl}/rest/v1/bizcommon/pincodes?pincode=${pin}`;
    return this.http.get(url).pipe(map(data => {
      let response = [];
      if (data.code === 201) {
        let d = JSON.parse(data.data);
        response = d.tokens;
        data = JSON.parse(data.data)
      }
      return response;
    }));
  }

  private emitLocationKeys() {
    this.selectedPinLocationSubject.next(this.getSelectedLocationTokens());
  }
  
  getSelectedLocationTokens() {
    let rule = [];
    try {
      return JSON.parse(localStorage[this.KEY_FOR_LOCATION_BY_PIN] || '[]');
    } catch (e) {
      console.warn(`Failed to fetch rule`);
    }
    return rule;
  }

  getSelectedPin() {
    let pin = '';
    try {
      pin = localStorage[this.pincodeLocalStorageKey];
    } catch (e) {
      console.warn(`Failed to fetch pin`);
    }
    return pin;
  }
}
