<section class="checkout">

    <div class="step-header" *ngIf="!isLoading || canAssist">
        <div class="container">
            <div fxLayout fxLayoutAlign="space-between center">
                <div fxHide.xs class="title" translate>Placing order</div>

                <div class="steps">
                    <div [class.active]="router.url == '/checkout/cart'" class="step cart" translate>Products</div>
                    <div *ngIf="canAssist" [class.active]="router.url == '/checkout/assist'" [class.disabled]="!!assistingUser?.firstName" class="step cart"
                        translate>Authorize</div>
                    <div [class.active]="router.url == '/checkout/shipping'" class="step shipping" translate>Shipping</div>
                    <div [class.active]="router.url == '/checkout/confirm'" class="step payment" translate>Summary</div>
                </div>

                <div fxHide.xs class="cart-value">
                    <div fxLayout fxLayoutAlign="center start" fxLayoutGap="5px">
                        <span translate>Cart Value :</span>
                        <currency [actualPrice]="cartSummary?.totalAmount || cartItemsTotalValue" class="total-cart-value">
                        </currency>
                        <!-- <span class="total-cart-value">
              {{
                cartSummary?.totalAmount | currency: "INR":"symbol":"1.0"
              }}</span> -->
                    </div>
                    <div class="total-cart-items">
                        {{ cartSummary?.products || cartItemsTotalCount }}
                        <span translate>item(s)</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container pt-15" *ngIf="canAssist">
        <div class="assist-state primary" *ngIf="assistingUser?.firstName" fxLayoutAlign="space-between center" fxLayout.xs="column"
            fxLayoutAlign.xs="start start">
            <span fxLayout.xs="column" fxLayoutGap.xs="8px">
                <span translate>Placing order on behalf of
                    <span class="capitalize fw-600 pl-6">{{ assistingUser?.firstName }}</span>. &nbsp;
                </span>
                <a class="cursor-pointer" (click)="changeUser()" translate>Change buyer</a> &nbsp;
            </span>

            
        </div>
        <div class="assist-state warn" *ngIf="
        canAssist &&
        !assistingUser?.firstName &&
        router.url !== '/checkout/assist'
        ">
            <span translate>Click</span>
            <a class="pl-5" [routerLink]="['/checkout', 'assist']" translate>continue</a>
            <span translate>,&nbsp;to proceed to buyer authorization page</span>
        </div>
    </div>
    <div class="container">
        <router-outlet></router-outlet>
    </div>
    <div #footer fxShow.xs fxHide class="copy-rights" [class.fixed]="!cartSummary?.totalAmount" fxLayout fxLayoutAlign="center center" *ngIf="!isLoading">
        <span translate>Copyright © 2021, Kalgudi. All Rights Reserved</span>
    </div>
    <div fxHide.gt-xs *ngIf="!cartSummary?.totalAmount" [style.height]="'74px'"></div>
</section>