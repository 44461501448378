import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { finalize } from 'rxjs/operators';
import { CartService } from 'src/app/project-common/services/cart.service';
import { UtilityService } from 'src/app/project-common/services/utility.service';
import { SideBarService } from 'src/app/side-bar.service';
import { SeasonalProductsService } from '../../seasonal-products.service';

@Component({
    selector: 'seasonal-product-tile',
    templateUrl: './seasonal-product-tile.component.html',
    styleUrls: ['./seasonal-product-tile.component.scss']
})
export class SeasonalProductTileComponent implements OnInit {

    pTileProducts: void;
    productPath: string[];

    constructor(
        protected cartService: CartService,
        private cartSidebar: SideBarService,
        private SeasonalProductsService: SeasonalProductsService,
        @Inject (KL_NOTIFICATION) private notification: KalgudiNotification,
        public utility: UtilityService,
        private router: Router
    ) { }

    ngOnInit() {
        this.getPreOrderProducts()
    }

    /*
    * Fetch pre-order products
    */
    getPreOrderProducts() {
        this.notification.showSpinner(true);
        
        this.SeasonalProductsService.getPreOrderProducts()
        .pipe(finalize(() => this.notification.hideSpinner()))
        .subscribe(res => {
            this.pTileProducts = res.data;
        })
    }


    // Adding product to cart with Product Id.
    addToCart(productId_level2: any, productId_level3: any, stores: any) {
        this.cartService.addProductToCartById(productId_level2, productId_level3, stores);
        this.cartSidebar.openCartSidebar();
    }

    // Navigate to product full view
    goToProductFullView(product: any){
        this.productPath = this.utility.getProductFullViewRoutingPath(product);
        this.router.navigate(this.productPath);
    }

}
