import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@app/env';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KL_ENV } from '@kalgudi/core/config';
import { StoreEnvironmentConfig } from '@kalgudi/types';
import { map } from 'rxjs/operators';
import { HttpWithAuthService } from 'src/app/project-common/services/http-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class SeasonalProductsService {

  constructor(
    private httpWithAuth: HttpWithAuthService,
    private http: HttpClient,
    @Inject(KL_ENV) public environment: StoreEnvironmentConfig,
    private utility: KalgudiUtilityService
  ) { }


  /*
  * Fetch pre-order products.
  * @params appName
  */
  public getPreOrderProducts() {
    const url = `${environment.restBaseUrlV2}/listing-ts/products/l2/preorder`;

    const params = {
      appName: this.environment.APP_NAME
    };

    return this.http.get(url, {params})
    .pipe(
      map((res:any) => this.utility.apiErrorHandler(res)),
    );
  }

}
