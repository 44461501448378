import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { CartService } from 'src/app/project-common/services/cart.service';

import { SpinnerService } from '../../project-common/services/spinner.service';
import { CheckoutService, ChargesAndOtherDetails } from './../checkout.service';
import { Assistant } from 'src/app/project-common/models/requirement.model';
import { GoogleAnalyticsService } from 'src/app/project-common/services/google-analytics.service';
import { Storefront } from 'src/app/project-common/properties/constants';
import { ItemsConfirmDialogComponent } from '../items-confirm-dialog/items-confirm-dialog.component';
import { StorefrontService } from 'src/app/storefront/storefront.service';
import { KalgudiDestroyable } from '@kalgudi/core';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
// import { ConfirmDialogComponent } from 'src/app/project-common/components/confirm-dialog/confirm-dialog.component';




@Component({
	selector: 'app-shipping',
	templateUrl: './shipping.component.html',
	styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent extends KalgudiDestroyable implements OnInit {

	cart: any;
	loggedInUser: any;
	selectedAddress: any;
	charges: any;
	productsTotalPrice: number;

	assistedUser: { profileKey: string, firstName: string };
	canBeShipped: boolean;
	validPins: string[] = [];

	chargesAndOtherDetails: ChargesAndOtherDetails;



	constructor(
		private checkout: CheckoutService,
		private router: Router,
		private spinner: SpinnerService,
		private snackbar: MatSnackBar,
		private cartService: CartService,
		private authService: AuthenticationService,
		private gaTrack: GoogleAnalyticsService,
		private matDialog: MatDialog,
		private translator: TranslateService,
		private storeService: StorefrontService
	) {
		super();
	}

	ngOnInit() {
		this.canBeShipped = false;
		this.cartService.getCartData().subscribe(cartData => {
			this.assistedUser = {} as any;
			this.loggedInUser = this.authService.userData;
			this.cart = cartData;
			this.productsTotalPrice = 0;
			if (this.assistant) {
				this.assistedUser = JSON.parse(sessionStorage.getItem('assistingTo') || '{}');
			}

			let totalAmount = 0;
			this.cart.products.forEach(element => {
				totalAmount = (element.pricePerUnit * element.productQuantity) + totalAmount;
			});
			this.gaTrack.updateCheckoutStepOnAnalytics(this.cart, totalAmount);
			this.gaTrack.updateCheckoutOptionOnAnalytics(1, { 'user': this.loggedInUser.mobileNo });
		});

		this.cartService.cartValueChanges
		.pipe(takeUntil(this.destroyed$))
		.subscribe(response => {
			this.cartService.getCartData().subscribe(cartData => {
				this.productsTotalPrice = 0;
				this.cart = cartData;

			});
		});

		this.storeService.deliverablePinCodes$.subscribe(pins => this.validPins = pins || []);
		// this.assistedUser = JSON.parse(sessionStorage.getItem('assistingTo') || '{}');

		// Enable this when we want to capture user [lat, long] location
		// this.geoApi.readCurrentGeoLocation();  
	}

	setAddress(address) {
		this.selectedAddress = address;
		this.checkout.raiseAddressEvent(address);
		this.getShippingCharges(address.postalCode);
	}

	saveShippingAddress() {
		if (!this.selectedAddress) {
			this.translator.get(['Please select delivery address', 'OK']).subscribe(translatedText=>{
			this.snackbar.open(translatedText['Please select delivery address'], translatedText['OK'], {
				duration: 5000, panelClass: ['snakbar-color']
			});
		})
			return false;
		}


		// console.log(this.validPins.includes(this.selectedAddress.postalCode));
		const fruitItems = this.hasShippingRestrictedItems();
		if (!this.validPins.includes(this.selectedAddress.postalCode) && fruitItems.length) {
			localStorage.setItem('canShipToLocation', 'false');
			if (fruitItems.length) {
				this.handleFruitItems(fruitItems);
			} else {
				this.translator.get([`We are sorry! Delivery is not enabled for this location`, 'OK']).subscribe(translatedText=>{
				this.snackbar.open(translatedText[`We are sorry! Delivery is not enabled for this location`], translatedText['OK'], {
					duration: 5000, panelClass: ['snakbar-color']
				});
			})
			}
			return false;
		}
		localStorage.setItem('canShipToLocation', 'true');
		this.spinner.changeStatus(true, true);

		delete this.selectedAddress.cts;
		delete this.selectedAddress.uts;

		const request: any = {
			buyerId: this.assistedUser.profileKey || this.loggedInUser.profileKey,
			buyerName: this.assistedUser.firstName || this.loggedInUser.firstName,
			buyerMobileNo: this.selectedAddress.phone,
			buyerCountryCode: this.loggedInUser.mobileCode || '+91',
			buyerAddress: this.selectedAddress,
			productList: this.cart.products,
		};
		if (this.assistedUser.profileKey) {
			const businessKey = this.loggedInUser.defaultBusinessKey;
			const emailId = this.loggedInUser.emailId;
			let mobileNumber = '';
			if (!emailId) {
				mobileNumber = this.loggedInUser.mobileNo;
			}
			request.assistantDetails = {
				firstName: this.loggedInUser.firstName,
				profileKey: this.loggedInUser.profileKey,
				profilePicUrl: this.loggedInUser.profilePicUrl,
				mobileNo: mobileNumber,
				emailId: emailId,
				businessKey: businessKey
			}
		}
		this.gaTrack.updateCheckoutOptionOnAnalytics(2, { pin: this.selectedAddress.postalCode });
		
		let totalAmount = 0;
		this.cart.products.forEach(element => {
			totalAmount = (element.pricePerUnit * element.productQuantity) + totalAmount;
		});
		this.gaTrack.updateAddShippingInfoToAnalytics(this.cart , totalAmount, this.selectedAddress.postalCode);

		this.checkout.storeShippingDetails(request, 'PAYMENT', true).subscribe(stored => {
			this.spinner.changeStatus(false, false);
			this.router.navigate(['checkout', 'confirm']);
		});

	}

	handleFruitItems(fruitItems: any[]) {
		this.matDialog.open(ItemsConfirmDialogComponent, {
			data: {
				message: `Following items are currently not deliverable to the selected address. 
				Do you wish to remove them & go back to cart ?`,
				okText: 'Yes, Go To Cart',
				hideCancel: false,
				selectedFruitItems: fruitItems
			},
			autoFocus: false,
			width: '600px'
		}).afterClosed().subscribe(action => {
			if (action) {
				this.spinner.start();
				this.cartService.batchRemoveFromCart(fruitItems.map(i => i.productLevel3Id)).subscribe(res => {
					this.spinner.stop();
					if (res.code === 200) {
						this.cart = res.data.cartItems;
						this.router.navigate(['/checkout', 'cart']);
					}
				});
			}
		})
	}

	/**
	 * Return items which should be shipped only to listed pin-codes
	 */
	hasShippingRestrictedItems(): any[] {
		return this.cart.products.filter(i => Storefront.limitedShippingCategory.includes(i.productCategory) || Storefront.limitedShippingProducts.includes(i.productLevel3Id));
	}


	getShippingCharges(pinCode?: string) {
		this.spinner.changeStatus(true);
		this.checkout.getShippingCharges(this.cart.products, 'SHIPPING', pinCode || this.selectedAddress.postalCode).subscribe(response => {
			this.spinner.changeStatus(false);
			this.chargesAndOtherDetails = response;
			
			// this.gaTrack.updateCheckoutStepOnAnalytics(this.cart, 2);	
		});
	}

	get assistant(): Assistant | null {
		return sessionStorage.getItem('assistingTo') ? JSON.parse(localStorage['assistant'] || 'null') : null;
	}

	protected onDestroyed() {}

}
