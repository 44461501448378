import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'items-confirm-dialog',
  templateUrl: './items-confirm-dialog.component.html',
  styleUrls: ['./items-confirm-dialog.component.scss']
})
export class ItemsConfirmDialogComponent implements OnInit {

  message: string;
  okText: string;
  hideCancel: boolean;
  selectedFruitItems: any;


  constructor(public dialogRef: MatDialogRef<ItemsConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ItemsConfirmDialogComponent) { }


    onNoClick(): void {
      this.dialogRef.close();
    }

  ngOnInit() {
   
  }

}
