<div class="dialog-tag" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
    <p class="dialog-tag-heading" translate>Ooops...</p>
</div>

<div class="location mt-30">
    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start">

        <p class="dialog-content">
            Sorry! You have blocked location access. Please type pincode and search your location.
        </p>

        <p class="dialog-content">
            To allow location access go to your browser settings
        </p>

        <div fxLayoutAlign="end end">
            <button class="dialog-btn" mat-dialog-close>OK</button>

        </div>

    </div>
</div>