<form name="paymentForm" [formGroup]="paymentForm" (ngSubmit)="doTransaction()" class="order-review" fxLayout fxLayoutGap="1.5%" fxLayoutGap.xs="0px" fxLayout.xs="column">
    <div fxFlex="70%" fxFlex.xs="none" class="review-shipment" fxLayout="column" fxLayout.xs="column" fxLayoutGap='15px' fxLayoutGap.xs="15px">
        <div class="" fxLayout fxLayoutAlign="space-between" fxLayoutGap="15px" fxLayoutGap.xs="15px" fxLayout.xs="column">

            <div class="shipping-details" fxFlex="50%" fxFlex.xs="100%" fxLayout="column">
                <h3 class="header filler" translate>Shipping Address</h3>
                <div class="address" fxLayout="column" fxLayoutGap="5px" fxLayoutGap.xs="3px">
                    <p class="buyer-name">{{transactionRequest?.buyerAddress?.name}}&nbsp; ({{transactionRequest?.buyerAddress?.phone}})
                    </p>
                    <p>{{transactionRequest?.buyerAddress?.addressLine1}}</p>
                    <p *ngIf="transactionRequest?.buyerAddress?.addressLine2">
                        {{transactionRequest?.buyerAddress?.addressLine2}}</p>
                    <p>{{transactionRequest?.buyerAddress?.city}}</p>
                    <p>{{transactionRequest?.buyerAddress?.state}}</p>
                    <p translate>Pincode: <span>{{transactionRequest?.buyerAddress?.postalCode}}</span></p>
                </div>
            </div>

            <!-- Form to take  mobile values -->
            <div class="shipping-details" fxFlexOrder.xs="-1">
                <h3 class="header filler" translate>Contact Information</h3>
                <div class="mandatory-filed" fxLayout="column" fxLayoutGap="10px" fxLayoutGap.xs="15px">
                    <p class="msg-text" translate>Please provide your mobile number to complete this transaction.</p>
                    <div class="required-fields">
                        <div class="field mobile-input">
                            <mat-form-field appearance="outline">
                                <mat-label translate>Mobile</mat-label>
                                <span matPrefix [innerHTML]="loggedInUser?.mobileCode"></span>
                                <input matInput minlength="10" maxlength="10" type="tel" (keypress)="numberOnly($event)" formControlName="phoneNumber" [placeholder]="'Enter your mobile' | translate">
                                <mat-error *ngIf="formControls.phoneNumber.invalid && (formControls.phoneNumber.touched || formControls.phoneNumber.dirty)">
                                    <div *ngIf="formControls.phoneNumber.errors.required" translate>Mobile number is required for later communication</div>
                                    <div *ngIf="formControls.phoneNumber.errors.minlength || formControls.phoneNumber.errors.maxlength" translate>Mobile number should be of 10 digits</div>
                                </mat-error>
                                <mat-icon matSuffix>phone</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="isGift" fxLayoutAlign="start center">
            <mat-icon class="text-success mr-1">redeem</mat-icon>
            <span class="text-success fw-600">Gift message included</span>
        </div>

        <!-- To show the list orders related to each different seller -->
        <ng-container *ngIf="transactionRequest?.storeMap">
            <ng-container *ngFor="let store of transactionRequest?.storeMap | keyvalue">
                <h3 class="header" translate>Items from <span>{{store.key}}</span></h3>
                <div *ngFor="let product of store.value?.productList" class="orders-list" fxLayout="column" fxLayoutGap.xs="0px" fxLayout.xs="column">
                    <div class="sku">
                        <div fxLayout>
                            <div fxFlex="125px" fxFlex.xs="90px" class="product-image" fxLayoutAlign="center center">
                                <img [src]="product.productImage | scaleImage: 'md'" alt="product-image" title="{{product.productLevel3Title}}">
                            </div>
                            <div class="item-details" fxLayout fxFlex="calc(100% - 125px)" fxFlex.xs="(100% - 70px)" fxLayoutAlign="space-between" fxLayout.xs="column" fxLayoutAlign.xs="none" fxLayoutGap.xs="3px" fxLayout>
                                <div fxLayout="column" fxLayoutGap="3px">
                                    <p class="prod-name">{{product.productLevel3Title}}</p>
                                    <!-- <p translate>Quantity: <span>{{product.productQuantity}}</span></p> -->
                                    <p><span translate>Quantity:</span> <span>{{product.productQuantity}}</span></p>
                                    <p><span translate>Item Price:</span> <span>{{product.pricePerUnit | currency:'INR'}}</span></p>

                                    <div *ngIf="product?.ships">
                                        <shipping-charge-message class="info w-100" fxLayoutAlign="center center" [ShowFreeChargesMsg]=false [product]="product"></shipping-charge-message>
                                    </div>
                                </div>
                                <div>
                                    <p translate>{{product.productQuantity * product.pricePerUnit | currency:'INR'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

    </div>

    <div class="order-summary" fxFlex.xs="none" fxFlex fxLayout="column" fxLayoutGap.xs="15px" fxLayoutGap="15px" fxFlexOrder.xs="-1">
        <!--Payment mode-->
        <div class="payment-mode" fxLayout="column" fxLayoutAlign="center center">
            <!-- <h3 translate class="header" translate>Mode Of Payment:</h3> -->
            <span class="header pb-10 w-100" fxLayoutAlign="center center">Mode of Payment:&nbsp;</span>
            <!-- <span class="font-weight-bold">Pay Online</span> -->
            <mat-radio-group fxLayoutAlign="start start" fxLayoutGap="10px">
                <!-- <mat-radio-button *ngIf="canAssist" value="COD" (click)="confirmation()" [checked]="paymentTab === 'COD'">{{'Bank transfer' | translate}}
                </mat-radio-button> -->
                <mat-radio-button *ngIf="isBulkOrder" value="CASH_PAYMENT" (click)="confirmationCashPayment('CASH_PAYMENT')" [checked]="paymentTab === 'CASH_PAYMENT'">{{'Bank Transfer' | translate}}
                </mat-radio-button>
                <mat-radio-button value="RBL_ONLINE" (click)="changeTab('RBL_ONLINE')" [checked]="paymentTab === 'RBL_ONLINE'">{{'Pay online' | translate}}
                </mat-radio-button>
                <!-- <ng-container *ngIf="loggedInUser?.profileKey == 'M01l3cs0PRFREG2018123132244091UNH001' || loggedInUser?.profileKey === 'M01teyj0PRFREG2023051824309636UNH001' || loggedInUser?.profileKey === 'M00j48b0PRFREG2017020625923624UNH001'">
                    <mat-radio-button value="RBL_ONLINE" (click)="changeTab('RBL_ONLINE')" [checked]="paymentTab === 'RBL_ONLINE'">{{'RBL pay online' | translate}}
                    </mat-radio-button>
                </ng-container> -->
                <!-- <mat-radio-button *ngIf="isKalgudiTeam" value="NEW_ONLINE" (click)="changeTab('NEW_ONLINE')" [checked]="paymentTab === 'NEW_ONLINE'">{{'Pay Online2' | translate}}
                </mat-radio-button> -->
            </mat-radio-group>
            <div *ngIf="isBulkOrder" class="tax pt-2" translate fxLayoutAlign="start center">
                *If pay online, Extra 5% payment gateway charges will be applied.
            </div>
        </div>

        <!--Promo code section-->
        <form class="promo-section">
            <!-- <div class="off-message">
                Use <span class="fw-600 text-warning">PONGAL10</span> coupon to get 10% off
            </div> -->
            <div *ngIf="!isCouponApplied">
                <input fxFlex placeholder="{{ 'Enter Promo Code' | translate }}" type="text" [(ngModel)]="couponCode" name="coupon" />
                <button [disabled]="!couponCode" (click)="applyCoupon('APPLY')" translate>Apply</button>
            </div>
            <div *ngIf="isCouponApplied" fxLayout fxLayoutAlign="space-between">
                <span class="coupan-applied">{{ couponCode }}</span>
                <mat-icon class="delete" matTooltip="Remove coupon" (click)="applyCoupon('REMOVE')">{{'delete' | translate}}</mat-icon>
            </div>
        </form>


        <div class="order-overview">
            <h3 class="header" translate>Order Summary</h3>
            <div class="price-summary">
                <div class="sub-total" fxLayoutGap="8px" fxLayout="column">

                    <!--Items total-->
                    <div fxLayoutAlign="space-between">
                        <span class="headers" translate>Items total</span>
                        <span class="price">{{transactionRequest?.overAllItemsValue | currency:'INR'}}</span>
                    </div>

                    <!--Coupon Discount-->
                    <div class="text-success c-discount" fxLayoutAlign="space-between" *ngIf="transactionRequest?.couponDiscount">
                        <span translate>Coupon Discount</span>
                        <span> {{-transactionRequest?.couponDiscount | currency:'INR'}}</span>
                    </div>

                    <!--Packaging cost-->
                    <div fxLayoutAlign="space-between" *ngIf="transactionRequest?.totalPackagingCost">
                        <span class="headers" translate>Packaging cost</span>
                        <span class="price">{{transactionRequest?.totalPackagingCost | currency:'INR'}}</span>
                    </div>

                    <!--Transaction fees-->
                    <div fxLayoutAlign="space-between" *ngIf="transactionRequest?.totalHandlingCost">
                        <span class="headers" translate>Transaction fees</span>
                        <span class="price">{{transactionRequest?.totalHandlingCost | currency:'INR'}}</span>
                    </div>

                    <!--Items net value-->
                    <div fxLayoutAlign="space-between" *ngIf="transactionRequest?.couponDiscount > 0">
                        <span class="headers" translate>Items Net Value</span>
                        <span class="price">{{transactionRequest?.overAllNetItemsValue | currency:'INR'}}</span>
                    </div>

                    <!--Shipping charges-->
                    <div fxLayoutAlign="space-between">
                        <span class="headers" translate>Shipping charges</span>
                        <span class="price">{{transactionRequest?.totalShippingCost | currency:'INR'}}</span>
                    </div>

                    <!--Other charges-->
                    <div fxLayoutAlign="space-between">
                        <span class="headers" translate>Other charges</span>
                        <span class="price">{{ otherCharges | currency:'INR' }} </span>
                    </div>

                    <!--Discount-->
                    <div class="text-success" fxLayoutAlign="space-between">
                        <span translate>Discount</span>
                        <span> -{{transactionRequest?.totalDiscounts | currency:'INR'}}</span>
                    </div>

                </div>

                <!--Grand total-->
                <div class="total" fxLayoutAlign="space-between">
                    <span translate>Grand Total</span>
                    <span>{{transactionRequest?.grandTotal | currency:'INR'}}</span>
                </div>

                <div class="tax-text">
                    <p translate>Inclusive of all taxes</p>
                </div>
            </div>
        </div>

        <div class="place-order-button">
            <button [ngClass]="{'disabled-btn': paymentForm.invalid || disablePayments}" [class.black]="paymentForm.invalid || disablePayments" translate>{{'PLACE ORDER' | translate}}</button>
        </div>
        <small class="text-danger" *ngIf="disablePayments"> We are not taking orders at this moment</small>
        <!-- <div class="tax-text" *ngIf="amountDifferenceForFreeShipping > 0">
            <p translate>Add more products worth <b>{{ amountDifferenceForFreeShipping | currency:'INR' }}</b> for free shipping</p>
        </div> -->
    </div>
</form>

<online-payment #onlinePayment></online-payment>