<div class="notify">

  <p class="n-title" translate>
    {{ notification?.title }}
  </p>

  <div fxLayout="row" fxLayoutGap="15px">

    <div fxFlex="65px" class="n-img" [style.backgroundImage]="
    'url(' +
    notification?.imageUrl +
    ')'
    "></div>

    <div fxFlex="calc(100% - 80px)" fxLayout="column" fxLayoutAlign="space-between start">
      <p class="mb-5">{{ notification?.message }}</p>
      <a [routerLink]="notification?.linkRoute" translate>{{ notification?.linkTitle }}</a>
    </div>
  </div>

</div>