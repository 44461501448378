import { Component, Injector, OnInit } from '@angular/core';
import { ProductCard } from 'src/app/classes/product-card';


@Component({
    selector: 'p-tile',
    templateUrl: './p-tile.component.html',
    styleUrls: ['./p-tile.component.scss']
})
export class PTileComponent extends ProductCard implements OnInit {

    smartElementsObject:any = [];
    constructor(
        protected injector: Injector

    ) {
        super(injector);
    }


    ngOnInit() {

        this.init();
        this.product.selectedSKU.forEach((element, index) => {
            if(element === 'dimensions') {
                Object.keys(this.product.SKUSmartElements.dimensions).forEach((item, index) => {
                    if(this.product.SKUSmartElements.dimensions[item] && this.product.SKUSmartElements.dimensions[item].enabled === true) {
                        this.smartElementsObject.push(this.product.SKUSmartElements.dimensions[item]);
                    }
                });
            }
        })
    }

    protected onDestroyed() { }

}
