import { Injectable } from '@angular/core';
import { HttpWithAuthService } from '../project-common/services/http-interceptor.service';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { S3UploadService } from '../project-common/services/s3-upload.service';
import { DbUpdateService } from '../project-common/services/db-update.service';
import { HttpClient } from '@angular/common/http';
import { Storefront } from '../project-common/properties/constants';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  myWishlist: any;

  storeName = {
    'APGREEN': 'Andhragreens',
    'EMAHILA': 'eMahila',
    'TFRESH': 'tFresh',
    'TGREENS': 'tGreens'
};


  constructor(
    private httpWithAuth: HttpWithAuthService,
    private storage: LocalStorage,
    private s3: S3UploadService,
    private updateDB: DbUpdateService,
    private httpClient: HttpClient
  ) {
    this.getMyWishList().subscribe();
    // this.validPins$ = new BehaviorSubject<string[]>([]);
  }

  /**
   * To get loggedin user data from index DB
   */
  public getUserDataFromLocalStorage() {
    return this.storage.getItem('userdata');
  }

  /**
   * To get list of save adderess, which can be used to complete the chekcout proccess.
   *
   * @param profileKey Loggedin user's profile key
   */
  public getSavedAddresses(profileKey?: string): Observable<any> {
    let url = `${environment.nodeServer}/v2/store/${Storefront.APP_NAME}/profiles/addresses`;
    if (profileKey) {
      url += `?profileKey=${profileKey}`;
    }
    return this.httpWithAuth.get(url).pipe(map(response => {
      const result: any = response;
      return result;
    }));
  }

  /**
   * To save new address or to update the existing address.
   *
   * @param profileKey Loggedin user's profile key
   * @param requestPayload ShippingForm models object, containing user address
   */
  public saveAddress(profileKey: string, requestPayload: any): Observable<any> {
    let url = `${environment.nodeServer}/v2/store/${Storefront.APP_NAME}/profiles/addresses`;
    if (profileKey) {
      url += `?profileKey=${profileKey}`;
    }
    if (requestPayload.id) {
      return this.httpWithAuth.
        put(url, requestPayload).pipe(map(resposne => {
          const result: any = resposne;
          return result;
        }));
    } else {
      return this.httpWithAuth.
        post(url, requestPayload).pipe(map(resposne => {
          const result: any = resposne;
          return result;
        }));
    }
  }

  /**
   * To delete an address from profile
   *
   * @param profileKey Loggedin user's profile key
   * @param addressId Will be id of address, which user wants to delete from his profile
   */
  public removeAddress(profileKey: string, addressId: string): Observable<any> {
    let url = `${environment.nodeServer}/v2/store/${Storefront.APP_NAME}/profiles/addresses/${addressId}`;
    if (profileKey) {
      url += `?profileKey=${profileKey}`;
    }
    return this.httpWithAuth
      .delete(url).pipe(map(resposne => {
        const result: any = resposne;
        return result;
      }));
  }

  /**
   * To change the password
   * @param profileKey Loggedin user's profile key
   * @param requestPayload PasswordForm object containing currentPassword and newPassword
   */
  public updatePassword(profileKey: string, requestPayload: any): Observable<any> {
    return this.httpWithAuth.
      put(`${environment.baseUrl}/rest/v1/profiles/${profileKey}/Password`, requestPayload).pipe(map(resposne => {
        const result: any = resposne;
        return result;
      }));
  }

  /**
   * To update the location object of a user
   * @param profileKey Profile key of the user for whom you want to update the loaction on his profile
   * @param businessKey Default business key of the user for whom you want to update the location on his profile
   * @param requestPayload A GoogleLocationTo object, see google-autocomple.ts for reference
   */
  public updateLocation(profileKey: string, businessKey: string, requestPayload: any): Observable<any> {

    const url = `${environment.baseUrl}/rest/v1/profiles/${profileKey}/Businesses/${businessKey}/gglLocation`;

    return this.httpWithAuth.put(url, requestPayload)
      .pipe(
        switchMap(resposne => {

          const result: any = resposne;

          if (result.code === 201) {
            return this.getProfileDataFromDynamoDB(profileKey);
          }

          throwError('Unable to update the location!');
        })
      );
  }

  public getProfileDataFromDynamoDB(profileKey: string): Observable<any> {
    const url = `${environment.baseUrl}/rest/v1/profiles/${profileKey}`;
    return this.httpWithAuth.get(url).pipe(map(data => {
      let response: any = data;
      response = JSON.parse(response.data);
      this.updateDB.updateUserData(response);
      return response;
    }));
  }

  public setDefaultAddress(profileKey: string, id: string): Observable<any> {
    return this.httpWithAuth.
      put(`${environment.nodeServer}/v2/store/${Storefront.APP_NAME}/profiles/addresses/${id}`,
        {}).pipe(map(resposne => {
          const result: any = resposne;
          return result;
        }));
  }

  /**
   * To upload image file to S3 and get a S3Url as response
   * @param profileKey ProfileKey of the user who is uploading the image to S3
   * @param file Image file object
   */
  public uploadToS3(profileKey: string, file: File, filePath?: string) {
    let fileName = `data/profilepics/${profileKey}.png`;
    let s3FolderName = 'profilepic';
    if (filePath) {
      // Cover pic upload
      fileName = `${filePath}${profileKey}.png`;
      s3FolderName = 'profilepic';
    }
    return this.s3.uploadImage(file, fileName, s3FolderName).pipe(map(response => {
      response += ('?' + new Date().getTime().toString());
      return response;
    }));
  }

  /**
   * To update the profile picture of a user
   * @param profileKey Profile key of the user for whom you want to update the profile pic URL
   * @param s3Url Image location on S3
   */
  public updateImageOnProfile(profileKey: string, s3Url: string): Observable<any> {
    const requestUrl = `${environment.baseUrl}/rest/v1/profiles/${profileKey}/profilePic`;
    return this.httpWithAuth.post(requestUrl, s3Url).pipe(map(response => {
      return response;
    }));
  }

  /**
   * To get the limited set of orders placed by user
   * @param type default value is empty string
   * @param offset default value is 0
   * @param limit default value is 100
   * https://devkalgudi.vasudhaika.net/rest/v1/unified/orders/myorders?limit=10&offset=0
   */
  public getOrdersList(type = '', offset = 0, limit = 100, searchKeyword: string = '', searchType = ''): Observable<any> {
    const requestUrl = `${environment.baseUrl}/rest/v1/unified/orders/myorders?limit=${limit}&offset=${offset}`;
    return this.httpWithAuth.get(requestUrl).pipe(map(response => {
      return response;
    }));
  }

  /**
   * To get the complete details about an order
   * @param orderId of placed order for which full detail is required
   */
  public getOrderDetails(orderId: string, orderStatus?: string): Observable<any> {
    const sufix = orderStatus ? '?isMasterOrder=true' : '';
    const requestUrl = `${environment.baseUrl}/rest/v1/unified/orders/${orderId + sufix}`;
    return this.httpWithAuth.get(requestUrl).pipe(map(response => {
      return response;
    }));
  }

  /**
   * To get the complete details about an order
   * @param orderId of placed order for which full detail is required
   */
  public getOrderFullView(orderId: string): Observable<any> {
    // const sufix = orderStatus ? '?isMasterOrder=true' : '';
    const store = orderId.includes('BORD') ? 'bizstore' : 'apgreen';
    const requestUrl = `${environment.baseUrl}/rest/v1/${store}/orders/${orderId}`;
    return this.httpClient.get(requestUrl, this.getHeaders(store, orderId)).pipe(map(response => {
      return response;
    }));
  }

  /**
   * To get the complete details about an order
   * @param orderId of placed order for which full detail is required
   */
  public getOrderFullViewDetails(orderId: string): Observable<any> {
    const store = orderId.includes('BORD') ? 'bizstore' : 'apgreen';
    const requestUrl = `${environment.baseUrl}/v2/estore/orders/${orderId}`;
    return this.httpClient.get(requestUrl, this.getHeaders(store, orderId)).pipe(map(response => {
      return response;
    }));
  }

  getHeaders(store, orderId) {
    let headers: any;
    if (store === 'bizstore') {
      headers = {
        headers: {project: 'OUTPUTS_STORE'}
      }
    } else {
      headers = {
        headers: {project: this.getStore(orderId)}
      }
    }

    return headers;
  }

  getStore(orderId) {
    if (orderId.includes('AGORD')) return 'APGREEN_STORE';
    if (orderId.includes('EMORD')) return 'EMAHILA_STORE';
    if (orderId.includes('TGORD')) return 'TGREENS_STORE';
    if (orderId.includes('SSORD')) return 'SIKKIM_GREENS_STORE';
  }
  

  /**
   * To generate an invoice of order and to get an encoded URL of S3 url
   * @param orderId of placed order for which you want to generate invoice
   */
  public generateInvoice(orderId: string, reportName: string): Observable<any> {
    // const store = orderId.includes('BORD') ? 'bizstore' : 'apgreen';
    // const requestUrl = `${environment.baseUrl}/rest/v1/${store}/orders/${orderId}/reports?reportName=${reportName}`;
    // return this.httpClient.post(requestUrl, {}, this.getHeaders(store, orderId)).pipe(map(response => {
    //   return response;
    // }));
    const url = `${environment.restBaseUrlV2}/estore/orders/${orderId}/${reportName}`;

    const store = orderId.includes('BORD') ? 'bizstore' : 'apgreen';
    const requestUrl = `${environment.restBaseUrlV2}/estore/orders/${orderId}/${reportName}`;
    return this.httpClient.get(requestUrl, this.getHeaders(store, orderId)).pipe(map(response => {
      return response;
    }));
  }

  /**
   * To download various reports from S3 using filename.
   * @param fileName available on S3, which can be found by decoding response of @generateInvoice method
   */
  public downloadInvoice(fileName: string): Observable<any> {
    const requestUrl = `${environment.baseUrl}/rest/download?filename=${fileName}`;
    return this.httpClient.get(requestUrl, { responseType: 'text' as 'json' });
  }

  /**
   * To submit a review for product.
   * @param productId for which you want to submit the review
   * @param requestPayload should consist of rating and review along with reviewer details and product details
   */
  public submitReview(productId: string, requestPayload: any): Observable<any> {
    const requestUrl = `${environment.baseUrl}/rest/v2/bizstore/review/product/${productId}`;
    // const requestUrl = `${environment.nodeServer}/rest/v2/bizstore/review/product/${productId}`;
    return this.httpWithAuth.post(requestUrl, requestPayload).pipe(map(response => {
      return response;
    }));
  }

  public getMyWishList(): Observable<string> {
    return new Observable(observer => {
      if (this.myWishlist) {
        observer.next(this.myWishlist);
        observer.complete();
      } else {
        this.storage.getItem('userWishList').subscribe(wishlist => {
          this.myWishlist = JSON.parse(wishlist || '{}');
          observer.next(this.myWishlist);
          observer.complete();
        })
      }

    });
  }

  /**
   * To get all submitted reviews of a user
   * @param profileKey of user, whose review list we wanted to get
   */
  public getMyReviews(profileKey: string): Observable<any> {
    const requestUrl = `${environment.nodeServer}/rest/v2/bizstore/review/myreview/products`;
    return this.httpWithAuth.get(requestUrl).pipe(map(response => {
      return response;
    }, error => {
      throw new Error('No reviews yet');
    }));
  }

  public getChatParticipants(orderId: string): Observable<any> {
    const requestUrl = `${environment.baseUrl}/rest/v1/bizstore/orders/${orderId}/participants`;
    return this.httpWithAuth.get(requestUrl).pipe(map(response => {
      return response;
    }));
  }

  public getChatMessages(orderId: string): Observable<any> {
    const requestUrl = `${environment.baseUrl}/rest/v1/bizstore/orders/${orderId}/chat?threadId=${orderId}_Buyer_Group`;
    return this.httpWithAuth.get(requestUrl).pipe(map(response => {
      return response;
    }));
  };

  public postMessage(orderId: string, payload: any): Observable<any> {
    const requestUrl = `${environment.baseUrl}/rest/v1/bizstore/orders/${orderId}/chat`;
    return this.httpWithAuth.post(requestUrl, payload).pipe(map(response => {
      return response;
    }));
  };

  /**
  * To update the personal Information of a user
  * @param profileKey Profile key of the user for whom you want to update the loaction on his profile
  * @param requestPayload A personaInfo object
  */
  public updatePersonalInfo(profileKey: string, requestPayload: any): Observable<any> {
    return this.httpWithAuth.
      put(`${environment.baseUrl}/rest/v1/profiles/${profileKey}`,
        requestPayload).pipe(map(resposne => {
          const result: any = resposne;
          return result;
        }));
  }


  public getUpdates(req: any): Observable<any> {
    // https://kalgudi.com/rest/v1/stream/socialactivity?profileKey=M01l4lt0PRFREG2019021316021151UNH001&offset=0&limit=10
    const requestUrl = `${environment.baseUrl}/rest/v1/stream/socialactivity?profileKey=${req.profileKey}&offset=${req.offset}&limit=${req.limit}`;
    return this.httpWithAuth.get(requestUrl).pipe(map(response => {
      return response;
    }));
  };

  /**
   * To update the profile picture of a user
   * @param profileKey Profile key of the user for whom you want to update the profile pic URL
   * @param s3Url Image location on S3
   */
  public updateCoverPicOnProfile(profileKey: string, req): Observable<any> {
    const requestUrl = `${environment.baseUrl}/rest/v1/profiles/${profileKey}/coverPic`;
    return this.httpWithAuth.put(requestUrl, req).pipe(map(response => {
      return response;
    }));
  }

  public getShareAthought(profileKey: string): Observable<any> {
    // https://kalgudi.com/rest/v1/stream/share/search/shareAthought?profileKey=M00j48o0PRFREG2017020640673778UNH001
    const requestUrl = `${environment.baseUrl}/rest/v1/stream/share/search/shareAthought?profileKey=${profileKey}`;
    return this.httpWithAuth.get(requestUrl).pipe(map(response => {
      return response;
    }));
  }

  public getQAndA(profileKey: string): Observable<any> {
    // https://kalgudi.com/rest/v1/stream/qa/search/QandA?profileKey=M00j48o0PRFREG2017020640673778UNH001
    const requestUrl = `${environment.baseUrl}/rest/v1/stream/share/search/QandA?profileKey=${profileKey}`;
    return this.httpWithAuth.get(requestUrl).pipe(map(response => {
      return response;
    }));
  }
  public updateRfqAssistants(payLoad): Observable<any> {
    const url = `${environment.nodeServer}/v2/bizstore/profiles/assistants`
    return this.httpWithAuth.
      put(url, payLoad).pipe(map(resposne => {
        const result: any = resposne;
        return result;
      }));
  }

  public userGallary(profileKey: string): Observable<any> {
    // https://dashboard.kalgudi.com/v1/dashboards/gallary/M00kd9h0PRFREG2017122443013063UNH001
    const requestUrl = `https://dashboard.kalgudi.com/v1/dashboards/gallary/${profileKey}`;
    return this.httpWithAuth.get(requestUrl).pipe(map(response => {
      return response;
    }));
  }

  /**
   * 
   * @param entityId To the respective page details of a seller
   */
  public getEntityDetails(entityId: string): Observable<any> {
    const requestUrl = `${environment.baseUrl}/rest/v1/entities/common/getentity?entityId=${entityId}`;
    return this.httpWithAuth.get(requestUrl).pipe(map(response => {
      return response;
    }));
  }

  /**
   * 
   * @param profileKey seller profileKey to get parent organization details
   */
  public getParentorganizationDetails(profileKey: string): Observable<any> {
    const requestUrl = `${environment.baseUrl}/rest/v1/profiles/myParent?profileKey=${profileKey}`;

    return this.httpWithAuth.get(requestUrl).pipe(map(response => response));
  }

}
