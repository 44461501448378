import { Injectable } from '@angular/core';
import { SpinnerService } from 'src/app/project-common/services/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { KalgudiNotification } from '@kalgudi/core/config';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CoreNotificationConfigService implements KalgudiNotification{

  constructor(private spinner: SpinnerService, private snackbar: MatSnackBar) { }

  showMessage(message: string, action = '', duration?: number): void {

    this.snackbar.open(message, action, {
      duration: duration || 5000
    });
  }

  showSpinner(blocking?: boolean): void {

    this.spinner.changeStatus(true, true);
  }

  hideSpinner(): void {

    this.spinner.changeStatus(false);
  }

  /**
   * Displays a Sweet Alert popup in the app
   * @param options A list of Sweet Alert options - as to how the popup should be displayed.
   */
    async showSweetAlert(options: SweetAlertOptions):Promise<any> {
      const response =  await Swal.fire(options);
      return new Promise ((resolve, reject) => {
        resolve(response)
      });
    }

}
