import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DeliveryAddress } from '@app/models';
import { KalgudiDestroyable } from '@kalgudi/core';
import { Observable } from 'rxjs';
import { delay, distinctUntilChanged, filter, finalize, shareReplay, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GooglePlacesApiService } from 'src/app/modules/google-places/services/google-places-api.service';
import { StorefrontService } from 'src/app/storefront/storefront.service';
import { DeliveryAddressService } from '../../services/delivery-address.service';

import { SearchService } from '../../services/search.service';
import { UtilityService } from '../../services/utility.service';

@Component({
    selector: 'searchbar',
    templateUrl: './searchbar.component.html',
    styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent extends KalgudiDestroyable implements OnInit {
    @Output() change = new EventEmitter();
    @Input('showSearch') showSearch: boolean;
    @Input() isUserLoggedIn: boolean;

    loading = false;

    showSelectPincodeDialog = false;
    toggleShowSelectPincodeDialog() { this.showSelectPincodeDialog = !this.showSelectPincodeDialog; }
    showPincodeChangedDialog = false;
    bottomPinSelectDialog = false;

    deliveryLocationSelected$: Observable<DeliveryAddress | string>;
    pincodeControl = new FormControl('', [Validators.minLength(6)]);

    @Output() onSearchFocusChange = new EventEmitter();

    searchText: any;
    view: string;
    limit: number;
    products: any[];
    request: any;
    selectedText: any;
    timeoutId: any;
    searchingItem: any;
    myControl = new FormControl('');
    isTrue: boolean = true;
    recentSearches: string[];
    isInputFocused: boolean = false;

    selectedCategory: { id?: string, value?: string } = { id: '', value: '' };

    suggestedCategory: string[];

    constructor(
        private searchService: SearchService,
        private storeFrontService: StorefrontService,
        private deliveryAddressService: DeliveryAddressService,
        private googlePlacesApi: GooglePlacesApiService,
        private util: UtilityService,
    ) { super(); }

    ngOnInit() {

        this.request = {
            offset: 0,
            limit: 10
        };

        this.initSuggestedCategory();
        const searchtextData = localStorage.getItem("recentSearches");
        const recentSearches = JSON.parse(searchtextData);
        if (recentSearches && recentSearches.length > 0) {
            this.recentSearches = recentSearches.reverse();
        } else {
            this.recentSearches = recentSearches;
        }

        this.deliveryLocationSelected$ = this.deliveryAddressService.deliveryLocationSelected$.pipe(shareReplay());

        this.deliveryLocationSelected$
            .pipe(
                takeUntil(this.destroyed$),

                // tap(delLoc => { if(delLoc) this.showPincodeChangedDialog = true}),

                // skip(1),

                filter(loc => !!loc),

                distinctUntilChanged(),

                tap(() => this.showPincodeChangedDialog = true),

                // delay(6000),

                // tap(() =>this.showPincodeChangedDialog = false)

            ).subscribe();

        this.deliveryAddressService.locBlockedError$
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => this.bottomPinSelectDialog = true);
    }

    hideChangedDialogAndPopupUpdateDialog() {
        this.showPincodeChangedDialog = false;
        this.bottomPinSelectDialog = true;
    }

    setPincode() {
        this.loading = true;
        if (!this.pincodeControl.valid) return;

        // this.setDeliveryAddress(this.pincodeControl.value);

        this.googlePlacesApi.searchForPinCode(this.pincodeControl.value)
        .pipe(
            finalize(() => {
                this.loading = false;
                this.bottomPinSelectDialog = false;
                this.pincodeControl.reset();
            })
        )
            .subscribe(
                (res: any) => {
                    const address = {
                        postalCode: res.googleLocationTo.zipcode,
                        googleLocation: res.googleLocationTo,
                        country: res.googleLocationTo.countryName,
                        state: res.googleLocationTo.adminLevel1,
                        city: res.googleLocationTo.adminLevel2 || res.googleLocationTo.locality,
                        addressLine2: res.googleLocationTo.locality || res.googleLocationTo.adminLevel2,
                    } as DeliveryAddress

                    this.isValidLocation(address) ? this.setDeliveryAddress(address) : this.setDeliveryAddress(this.pincodeControl.value);
                },
                err => { this.setDeliveryAddress(this.pincodeControl.value); }
            );

    }

    private isValidLocation(addressForm: DeliveryAddress) {
        return addressForm.postalCode && addressForm.city && addressForm.state && addressForm.country && addressForm.postalCode !== '000000';
    }

    setDeliveryAddress(address: DeliveryAddress | string) {
        this.deliveryAddressService.setDeliveryAddress(address);
        this.showSelectPincodeDialog = false;
    }

    autoComplete(text, event) {
        if (event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) {
            return;
        };
        if (text && text.length >= 3 && text != this.selectedText) {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.getSuggestions(text);
            }, 500);
        } else {
            clearTimeout(this.timeoutId);
        }
    }

    getSuggestions(text) {
        this.searchService.getSuggessions(text, this.request, this.selectedCategory.id).subscribe((res) => {
            if (typeof (res.data) == "string") {
                res.data = JSON.parse(res.data);
            }
            res.data.forEach(suggestion => {
                const title: string = suggestion.productLevel2Title;
                const exp = new RegExp(text.toLowerCase(), 'g');
                const result = title.toLowerCase().replace(exp, "<span class='bold'>" + text + "</span>");
                suggestion.productLevel2Title = result;
            });
            this.products = res.data;
        })

    }

    check(item) {
        const exp1 = new RegExp("<span class='bold'>", 'g');
        const exp2 = new RegExp("</span>", 'g');
        item.productLevel2Title = item.productLevel2Title.replace(exp1, '').replace(exp2, '');
        this.searchText = item.productLevel2Title;
        this.selectedText = item.productLevel2Title;
        this.search(this.searchText, item.unifiedStore.baseCategory.id);
        // this.change.emit(this.searchText);
        this.showSearch = false;
    }

    onSelect(event: MatAutocompleteSelectedEvent) {
        this.onSearchBarBlur(event);
        let ele = document.getElementById('mySearchInput');
        ele.blur();
    }

    keyEnter() {
        const exp1 = new RegExp("<span class='bold'>", 'g');
        const exp2 = new RegExp("</span>", 'g');
        let category = '';
        if (this.searchText.productLevel2Title) {
            category = this.searchText.baseCategory.id;
            this.searchText = this.searchText.productLevel2Title.replace(exp1, '').replace(exp2, '');
        }
        this.search(this.searchText, category);
        // this.change.emit(this.searchText);
        this.isTrue = false;
        this.showSearch = false;
    }
    keydown() {
        this.isTrue = true;
    }

    get categories$(): Observable<{ id?: string, value?: string }[]> {
        return this.storeFrontService.categories$;
    }

    search(keyword: string, category?: string) {
        const search = {
            keyword: keyword,
            category: category || this.selectedCategory.id || ''
        }
        this.change.emit(search);
        const searchText = JSON.parse(localStorage.getItem('recentSearches') || '[]');
        if (!searchText.includes(this.searchText)) {
            if (searchText.length == 6) {
                searchText.splice(0, 1);
            }
            searchText.push(this.searchText.toLowerCase());
            localStorage.setItem('recentSearches', JSON.stringify(searchText));
        }
    }

    onSearchBarFocus(event) {
        this.isInputFocused = true;
        this.onSearchFocusChange.emit(true);
    }

    onSearchBarBlur(event) {
        this.isInputFocused = false;
        this.onSearchFocusChange.emit(false);
    }

    private initSuggestedCategory() {
        this.suggestedCategory = [
            'Girijan Honey',
            'Pickles',
            'Karam',
            'Coffee',
            'Herbal Soaps',
            'Turmeric',
            'Pulses'
        ]
    }

    public isNumber(event: KeyboardEvent) {
        return this.util.numberOnly(event);
    }

    protected onDestroyed(): void { }

}