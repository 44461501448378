import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutUsComponent } from './about-us/about-us.component';
import { CustomMangoesComponent } from './custom-mangoes/custom-mangoes.component';
import { HomeComponent } from './home/home.component';
import { SeasonalProductsHomePageComponent } from './seasonal-products/components/seasonal-products-home-page/seasonal-products-home-page.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

// import { FarmerFormComponent } from './farmer-form/farmer-form.component';
// import { AllCategoriesComponent } from './all-categories/all-categories.component';

const storefrontRoutes: Routes = [
  // { path: 'all-categories', component: AllCategoriesComponent },
  { path: 'about-us', component: AboutUsComponent },
  // { path: 'mangoes', component: MangoHomePageComponent },
  { path: 'seasonal/products', component: SeasonalProductsHomePageComponent },

  { path: 'mangoes/custom-box', component: CustomMangoesComponent },
  { path: 'unsubscribe', component: UnsubscribeComponent },
  { path: 'p', loadChildren: () => import('../products/products.module').then(m => m.ProductsModule) },
  { path: 'product', loadChildren: () => import('../products/products.module').then(m => m.ProductsModule) },
  { path: 'seller', loadChildren: () => import('../seller/seller.module').then(m => m.SellerModule) },
  // { path: 'farmer', component: FarmerFormComponent },
  { path: 'c/:productName/cid/:productLevel2Id/csub/:productLevel3Id', redirectTo: 'product/c/:productName/cid/:productLevel2Id/csub/:productLevel3Id' }, // Deprecated
  { path: 'c/:productName/cid/:productLevel2Id', redirectTo: 'product/c/:productName/cid/:productLevel2Id' }, // Deprecated
  { path: 'preview/:productLevel2Id', redirectTo: 'product/preview/:productLevel2Id' }, // Deprecated
  { path: 'all-categories', redirectTo: 'product/categories' }, // Deprecated
  { path: 'product-list/:category', redirectTo: 'product/list/:category' }, // Deprecated
  { path: 'sellers/:category', redirectTo: 'product/sellers/:category' }, // Deprecated
  { path: 'seller-catalogue/:profileKey', redirectTo: 'product/seller-catalogue/:profileKey' }, // Deprecated
  { path: 'bulk-order-req/:productLevel2Id', redirectTo: 'product/bulk-req/:productLevel2Id' },
  {
    path: '', component: HomeComponent,
    data: {
      shouldDetach: true
    }
  },// HomeComponent
  { path: 'home', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(storefrontRoutes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class StorefrontRoutingModule { }
