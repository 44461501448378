import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'existing-address-dialog',
  templateUrl: './existing-address-dialog.component.html',
  styleUrls: ['./existing-address-dialog.component.scss']
})
export class ExistingAddressDialogComponent  extends KalgudiDialog<ExistingAddressDialogComponent> implements OnInit {

  listOfAddress = [];
  selectedAddress: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    protected dialogRef: MatDialogRef<ExistingAddressDialogComponent>,
  ) {
    super(dialogRef)
  }

  public cancel(): any {
    this.dialogRef.close();
  }
  public ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: this.selectedAddress
    };
    this.dialogRef.close(result);
  }

  ngOnInit() {
    this.listOfAddress = this.dialogData.data.listOfAddress || [];
    if(this.dialogData.data.selectedAddress) {
      this.selectedAddress = this.dialogData.data.selectedAddress;
    }
  }

  onAddressSelection(event) {
    this.selectedAddress = event;
  }
}
