<span
  class="price-detail"
  *ngIf="actualPrice"
  fxLayoutGap="5px"
  fxLayoutGap.xs="2.5px"
  fxLayout="row"
  fxLayoutAlign="start center"
>
  <span class="price {{ className }}">  {{
    price | currency: (currentSelectedCountry?.currencyCode || 'INR'):"symbol":"1.2-2"
  }}</span>
  <ng-container *ngIf="mrp > price">
    <span class="mrp">
     {{ mrp | currency: (currentSelectedCountry?.currencyCode || 'INR'):"symbol":"1.2-2" }}
    </span>
    <span class="offer-percent">
      {{ offerPercent | number: "1.0-0" }}% <span translate>off</span>
    </span>
  </ng-container>
</span>
