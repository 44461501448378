import { Component, OnInit } from "@angular/core";

@Component({
  selector: "about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"],
})
export class AboutUsComponent implements OnInit {
  selectedLanguage: string;
  aboutImage = [
    { img: "assets/apgreen/gallery1.jpg" },
    { img: "assets/apgreen/gallery2.jpg" },
    { img: "assets/apgreen/gallery3.jpg" },
  ];

  constructor() {}

  ngOnInit() {
    this.selectedLanguage = localStorage['lang'];
  }
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: `<div class="s-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i><div>`,
    nextArrow: `<div class="s-next"><i class="fa fa-chevron-right" aria-hidden="true"></i><div>`,
  };
  
}
