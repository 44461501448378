import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'inline-sku',
    templateUrl: './inline-sku.component.html',
    styleUrls: ['./inline-sku.component.scss']
})
export class InlineSkuComponent implements OnInit {

    @Input() smartElement: any;

    constructor() { }

    ngOnInit() {
    }

}
