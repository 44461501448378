import { Component, Input, OnInit } from '@angular/core';

import { Country } from '../../models/country';




@Component({
  selector: 'currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {

  @Input('actualPrice') actualPrice: number;
  @Input('actualMrp') actualMrp: number;

  @Input('class') className: string;
  @Input('increment') increment = true;

  currentSelectedCountry: Country;

  constructor() { }

  ngOnInit() {
    this.currentSelectedCountry = this.current;

    // this.cs.valueEmitter.subscribe(({current}) => this.currentSelectedCountry = current);
  }

  get price() {

      if(!this.currentSelectedCountry) return this.actualPrice || null;

      return this.currentSelectedCountry.getPrice(this.actualPrice, this.increment);
  }

  get mrp() {
    if(!this.currentSelectedCountry) return this.actualMrp || null;

    return this.currentSelectedCountry.getPrice(this.actualMrp, this.increment);
  }

  get offerPercent() {
    return 100 - ((100* this.price)/this.mrp);
  }

  get current() {
    let country: Country;
    country = new Country({
      name: 'India',
      currencyCode: 'INR',
      currencySymbol: '₹',
      mobileTelecomCode: "+91",
      mobileNoLength: 10,
      pincodeLength: 6,
      currencyName: "Indian Rupee",
      currencyInINR: 1,
      incrementBy: 0
    });
    return country;
  }

}
