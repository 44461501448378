<div class="cart" fxLayoutAlign="start center">
    <a  fxLayoutAlign="center center">
        <span class="mr-10" [matBadge]="cartQuantity" matBadgeSize="small" matBadgeColor="accent" matBadgePosition="below after">
            <img src="assets/images/cart.png" alt="Cart icon" class="cart-icon" width="100%" height="100%">
            <!-- <i class="fa fa-shopping-bag font-22"></i> -->
            <!-- <i class="material-icons">shopping_bag</i> -->
            <!-- <i class="material-icons">shopping_bag</i> -->
        </span>

        <span class="large-screen">
            <span fxLayout="column">
                <span class="font-14" translate>My Cart</span>
                <span class="font-16 fw-700">{{ cartItemsTotal | currency:'INR':'symbol':'1.2-2' }}</span>
            </span>
        </span>
    </a>

    <!-- <div class="cart-preview" fxHide.xs>
        <div class="cart-preview-container">
            <ng-container *ngIf="cart?.products?.length">
                <div class="c-items-wrapper">
                    <ng-container *ngFor="let product of cart?.products">
                        <div class="c-item" fxLayout fxLayoutGap="10px">
                            <div class="p-img" [style.backgroundImage]="
                                                  'url(' +
                                                  (product.productImage | scaleImage: 'sm') +
                                                  ')'
                                                  "></div>
                            <div fxFlex>
                                <div>
                                    <span class="p-title">
                                        {{ product?.productLevel3Title }}
                                    </span>
                                </div>
                                <span class="m-0" translate>Qty: </span>
                                <b>{{ product?.productQuantity }}</b>
                                <ng-container *ngFor="let smartElement of product.selectedSKU">
                                    <span *ngIf="smartElement !== 'dimensions'">
                                        ({{ product.smartElements[smartElement]?.value }}
                                        {{ product.smartElements[smartElement]?.unit }})
                                    </span>
                                </ng-container>

                            </div>
                            <i class="fa fa-trash-o cursor-pointer text-muted" aria-hidden="true"
                                (click)="deleteProduct.emit(product)">
                            </i>
                        </div>
                    </ng-container>
                </div>

                <p class="m-20 font-18 fw-700">{{'Subtotal' | translate}}:
                    {{ cartItemsTotal | currency:'INR':'symbol':'1.2-2' }}</p>

                <button [routerLink]="['/checkout/cart']" translate>Checkout</button>
            </ng-container>
            <ng-container *ngIf="!cart?.products || !cart?.products?.length">
                <i class="text-center d-block p-20 text-nowrap" translate>
                    No products added to cart
                </i>
            </ng-container>
        </div>
    </div> -->
</div>