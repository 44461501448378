import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'p-skeleton',
  templateUrl: './p-skeleton.component.html',
  styleUrls: ['./p-skeleton.component.scss']
})
export class PSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
