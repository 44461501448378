import { Component, Injector, OnInit } from '@angular/core';
import { ProductCard } from 'src/app/classes/product-card';
import { StorefrontService } from 'src/app/storefront/storefront.service';

@Component({
  selector: 'basket-tile',
  templateUrl: './basket-tile.component.html',
  styleUrls: ['./basket-tile.component.scss']
})
export class BasketTileComponent extends ProductCard implements OnInit {

  basketItems = [];

  constructor(
    private storeService: StorefrontService,

    protected injector: Injector
  ) {
    super(injector);
  }


  ngOnInit() {

    this.init();

    this.storeService.getBasketProducts(this.product.productId_level2).subscribe(data => {
      try {
        this.basketItems = data.basketItems[this.product.productId_level3];
      } catch (err) { }
    });
  }

  protected onDestroyed() { }

}
