import { CheckoutService } from 'src/app/checkout/checkout.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CartService } from 'src/app/project-common/services/cart.service';
import { UtilityService } from 'src/app/project-common/services/utility.service'

@Component({
  selector: 'checkout-title',
  templateUrl: './checkout-title.component.html',
  styleUrls: ['./checkout-title.component.scss']
})
export class CheckoutTitleComponent implements OnInit {

    constructor(
    ) { }

    ngOnInit() {
    }

}
