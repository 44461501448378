import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { fade } from '../../animations';
import { CapitalizePipe } from '../project-common/pipes/capitalize.pipe';
import { CartService } from '../project-common/services/cart.service';
import { ConfirmUserService } from '../project-common/services/confirm-user.service';
import { WishListService } from './../project-common/services/wish-list.service';
import { CheckoutService } from './checkout.service';


@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss'],
    animations: [
        fade
    ]
})
export class CheckoutComponent implements OnInit {

    public isLoading: boolean;
    public cartSummary: { totalAmount: number, products: number };

    public canAssist: boolean;

    public assistingUser: any;

    cartItemsTotalValue: number

    cartItemsTotalCount = 0;



    constructor(
        private checkoutService: CheckoutService,
        public router: Router,
        public wishListService: WishListService,
        private confirmUserService: ConfirmUserService,
        private cartService: CartService,
        private capitalize: CapitalizePipe
    ) { }


    ngOnInit() {
        this.isLoading = true;
        this.canAssist = JSON.parse(localStorage.getItem('Y2FuQXNzaXN0') || 'false');
        this.checkoutService.cartSummary.subscribe(summary => {
            this.isLoading = false;
            this.cartSummary = summary;
        });

        this.router.events.subscribe(changed => {

            this.assistingUser = JSON.parse(sessionStorage.getItem('assistingTo') || '{}');

        })

        this.assistingUser = JSON.parse(sessionStorage.getItem('assistingTo') || '{}');

        this.getCartTotal();

    }



    getCartTotal() {

        this.cartService.getCartData().subscribe(cartData => {

            this.cartItemsTotalValue = cartData.products

                .reduce((cartItemsTotalValue, product) => {

                    this.cartItemsTotalCount += product.productQuantity;

                    return cartItemsTotalValue + (product.pricePerUnit * product.productQuantity);

                }, 0)

        });


    }

    clearAssistance() {

        const { firstName:name } = this.assistingUser;

        const message = `This will clear the authorization information of ${this.capitalize.transform(name)}. Continue?`


        this.confirmUserService.confirm(message, () => {


            this.assistingUser = {};


            sessionStorage.setItem('assistingTo', '{}');


            sessionStorage.setItem('assistingToUserData', 'null');


            this.router.navigate(['/checkout', 'cart']);


        })


    }


    changeUser() {

        const { firstName:name } = this.assistingUser;


        const message = `This will clear the authorization information of ${this.capitalize.transform(name)}. Continue?`


        this.confirmUserService.confirm(message, () => {


            sessionStorage.clear();


            this.router.navigate(['/checkout', 'assist']);


        });


    }

}
