

export class Wishlist {

    constructor(private wishlistItems) { }

    isAvailable(id) {
        if(!this.wishlistItems) return false;

        return this.wishlistItems[id] ? true : false;
    }

    get itemCount() {
        return this.items ? this.items.length : 0;
    }

    get items() {
        return Object.values(this.wishlistItems)
    }
}