import { Component, ViewChild, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { KalgudiDestroyable } from '@kalgudi/core';

@Component({
  selector: 'feature-icici-payment',
  templateUrl: './icici-payment.component.html',
  styleUrls: ['./icici-payment.component.scss']
})
export class IciciPaymentComponent extends KalgudiDestroyable implements OnInit {

  @ViewChild('externalIframe') externalIframe: ElementRef;
  public htmlContent: any;
  public progress: boolean = false;
  public url: any;
  public orderDetails: any;
  private hiddenElements: any[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private el: ElementRef
    ) { 
      super();
    }

  ngOnInit(): void {
    this.orderDetails = window.history.state
    this.url = window.history.state.htmlCode
    if (this.url) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }else {
      this.router.navigate(['checkout/confirm'])
    }

    this.hideElementsById('smartsupp-widget-container');
    this.hideElementsByClassName('common-footer');
    this.hideElementsByClassName('top-header');
    this.hideElementsByClassName('side_link');
  }

  protected onDestroyed(): void {
    this.restoreHiddenElements();
  }

  /**
   * This method for hiding header section
   */
  private hideElementsByClassName(className: string) {
    const elements = document.getElementsByClassName(className);
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLElement;
      // Store the initial display property to restore it later
      this.hiddenElements.push({ element, display: element.style.display });
      element.style.display = 'none';
    }
  }

  /**
   * This method to hide the section by using id
   * @param id
   */
  private hideElementsById(id: string) {
    const element = document.getElementById(id);
    if (element) {
      this.hiddenElements.push({ element, display: element.style.display });
      element.style.display = 'none';
    }
  }

  /**
   * This method for restoring header section
   */
  private restoreHiddenElements() {
    for (const hiddenElement of this.hiddenElements) {
      const { element, display } = hiddenElement;
      element.style.display = display || '';
    }
    this.hiddenElements = [];
  }

}
