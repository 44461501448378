<form id="address-form" fxLayout="column" #address="ngForm" autocomplete="off">
    <div [class.form-field-group]="!isGuestAddress">
        <!-- <h5>Add address</h5> -->
        <div fxLayout="column">

            <!-- 1st row -->
            <div fxLayout="row" fxLayoutGap="10px" 
            fxLayoutGap.xs="0px" fxLayout.xs="column" *ngIf="!isGuestAddress">
                <mat-form-field fxFlex appearance="outline" class="prefix-field">
                    <!-- <mat-label translate>Name</mat-label> -->
                    <input matInput [placeholder]="'Name' | translate" [(ngModel)]="addressForm.name" name="name" autocomplete="name"
                        required>
                        <img class="custom-prefix" src="assets/images/guest-checkout-icons/bx-user.svg" width="20">
                </mat-form-field>
                <mat-form-field class="prefix-field" fxFlex appearance="outline">
                    <!-- <mat-label translate>Contact mobile number</mat-label> -->
                    <input matInput [placeholder]="'Contact mobile number' | translate" [(ngModel)]="addressForm.phone" name="phone"
                        (keypress)="isNumber($event)" minlength="10" maxlength="10" required
                        autocomplete="tel-national">
                        <span class="custom-prefix">+91 |</span>
                </mat-form-field>
            </div>

            <mat-form-field class="prefix-field" fxFlex appearance="outline" *ngIf="!isGuestAddress">
                <!-- <mat-label translate>Contact email address</mat-label> -->
                <input matInput [placeholder]="'Contact email address' | translate" [(ngModel)]="addressForm.email" name="email"
                    autocomplete="email" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}">
                    <img class="custom-prefix" src="assets/images/guest-checkout-icons/baseline-alternate-email.svg" width="20">
            </mat-form-field>

            <div [class.hide-country]="isGuestAddress" class="w-100">

                <mat-form-field class="country-form-field prefix-field w-100" fxHide.xs appearance="outline">
                    <!-- <mat-label translate>Country</mat-label> -->
                    <mat-select value="india">
                        <mat-option [value]="'india'" translate>India</mat-option>
                    </mat-select>
    
                    <img class="custom-prefix" src="assets/images/India.png" width="20">
                </mat-form-field>
                
                <mat-form-field class="country-form-field prefix-field w-100" fxHide.gt-xs appearance="outline">
                    <!-- <mat-label translate>Country</mat-label> -->
                    <select matNativeControl >
                        <option selected [value]="'india'" translate>India</option>
                    </select>
    
                    <img class="custom-prefix" src="assets/images/India.png" width="15">
                </mat-form-field>

            </div>

            <div class="item-header text-uppercase" *ngIf="isGuestAddress">Address</div>

            <!-- 2rd row -->
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0px">
                
                <div fxLayout="column" fxFlex="100%">
                    <!-- 2.A row -->
                    <div class="address-type">  <!-- [fxHide]="isEditing"-->
                        <mat-tab-group class="mb-5" [class.disabled]="isProcessing" [(selectedIndex)]="selectedTab" (selectedIndexChange)="switchAddressType($event)" >
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <svg-icon src="assets/images/mailbox.svg" [svgStyle]="{'width.px': '18', 'height.px': '20'}"
                                        [applyCss]="true" class="mr-5 mail-box-icon"></svg-icon>
                                    <!-- <img src="assets/images/mailbox.svg" loading="lazy" class="mr-5" height="20" alt=""> -->
                                    <span class="d-flex flex-column align-items-start">
                                        <span class="pin-line-ht" translate>Use</span>
                                        <span class="pin-line-ht" translate>pincode</span>
                                    </span>
                                </ng-template>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <img src="assets/images/guest-checkout-icons/location-12-regular.svg" loading="lazy" class="mr-5" height="20" alt="">
                                    <span translate class="d-flex flex-column align-items-start">
                                        <span class="pin-line-ht" translate>Use current</span>
                                        <span class="pin-line-ht" translate>location</span>
                                    </span>
                                  </ng-template>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                    <!-- 2.B row -->
                    <div class="pos-relative pincode-form-field-wrapper" [fxHide]="addressForm.addressType === 'GPS'"> <!--  || isEditing-->
                        <mat-form-field fxFlex="100%" fxFlex.xs="100%" appearance="outline" class="prefix-field">
                            <!-- <mat-label translate>Enter Indian pin code</mat-label> -->
                            <input type="text" inputmode="numeric" matInput [placeholder]="'Enter pin code' | translate"
                                autocomplete="off"
                                pattern="[0-9]+"
                                [(ngModel)]="addressForm.postalCode" name="postalCode" required
                                (keypress)="isNumber($event)" minlength="6" maxlength="6" #pinCode="ngModel" #pin [autofocus]="!isGuestAddress">
                                <i class="material-icons custom-prefix">more_horiz</i>
                            </mat-form-field>
                            <button mat-stroked-button  class="position-absolute text-uppercase" (click)="checkValidityAndGetAddressFromPincode()">{{'get address' | translate}}</button>
                    </div>

                    <div class="text-center" [hidden]="!isProcessing">
                        <span class="font-17 text-center text-black-50 d-block loading-text">
                            <ng-container *ngIf="addressForm.addressType !== 'GPS'">
                                Searching for {{ addressForm?.postalCode }}...
                            </ng-container>
                            <ng-container *ngIf="addressForm.addressType === 'GPS'">
                                Getting your current location...
                            </ng-container>
                        </span>
                        <img src="assets/images/location-lodaing.gif" width="320" alt="">
                    </div>
                    <ng-container *ngIf="!isProcessing && hasValidAddress">
                        <small class="d-block mb-10">City based on {{ addressForm.addressType !== 'GPS' ?  (addressForm?.postalCode + ' pincode') : 'your location' }} </small>
                        <!-- <h6>Location found</h6> -->
                        <div class="fetched-address py-10" fxLayout="row" fxLayoutAlign="start center">
                            <img class="mr-18" src="assets/images/guest-checkout-icons/bx-building-house.svg" width="20" alt="">
                            <div>
                                
                                <span>
                                    {{addressForm?.city}},
                                    {{addressForm?.state}}, {{addressForm.country}} - {{addressForm.postalCode}}
                                </span>
                            </div>

                            <!-- <i class="fa fa-times-circle"  (click)="clearLocation()" aria-hidden="true"></i> -->
                        </div>

                        <mat-form-field fxFlex fxFlex.xs="100%" appearance="outline" class="prefix-field">
                            <!-- <mat-label translate>House / Apartment / Street Address</mat-label> -->
                            <input (focus)="onInputFocus()" matInput [placeholder]="'House / Apartment / Street Address' | translate"
                                [(ngModel)]="addressForm.addressLine1" name="address-line-1" required>
                            <img class="custom-prefix" src="assets/images/guest-checkout-icons/bx-building-house.svg" width="20" alt="">
                        </mat-form-field>

                        <!-- 2.C row -->
                        <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.xs="5px" class="mb-0">
                            <mat-form-field fxFlex="100%" appearance="outline" class="prefix-field">
                                <!-- <mat-label translate>Locality</mat-label> -->
                                <input (focus)="onInputFocus()" matInput [placeholder]="'Locality' | translate" [(ngModel)]="addressForm.addressLine2"
                                    name="locality">
                                    <img class="custom-prefix" src="assets/images/guest-checkout-icons/bx-building-house.svg" width="20" alt="">
                            </mat-form-field>
                            <!-- <mat-form-field fxFlex="calc(50% - 5px)" appearance="outline">
                                <mat-label>City/Town</mat-label>
                                <input matInput placeholder="City/Town" [(ngModel)]="addressForm.city" name="city" required
                                    readonly>
                            </mat-form-field> -->
                        </div>
                        <!-- 2.D row -->
                        <!-- <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.xs="5px" class="mb-0">
                            <mat-form-field fxFlex="calc(50% - 5px)" appearance="outline">
                                <mat-label>State</mat-label>
                                <input matInput placeholder="State" [(ngModel)]="addressForm.state" name="state" required
                                    readonly>
                            </mat-form-field>
                            <mat-form-field fxFlex="calc(50% - 5px)" appearance="outline">
                                <mat-label>Country</mat-label>
                                <input matInput placeholder="Country" [(ngModel)]="addressForm.country" name="country"
                                    required readonly>
                            </mat-form-field>
    
    
    
                        </div> -->
                        <mat-form-field fxFlex="50%" fxFlex.xs="100%" appearance="outline" class="prefix-field">
                            <!-- <mat-label translate>Landmark</mat-label> -->
                            <input (focus)="onInputFocus()" matInput [placeholder]="'Landmark' | translate" [(ngModel)]="addressForm.landmark" name="landmark">
                            <img class="custom-prefix" src="assets/images/guest-checkout-icons/location-12-regular.svg" width="20" alt="">
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!isProcessing && hasInvalidAddress">
                        <h6 class="text-danger" translate>Invalid pincode</h6>
                    </ng-container>

                    <!-- 2.E row -->

                </div>
                <div *ngIf="false" fxFlex="50%" fxLayout="column" fxLayoutGap="10px" fxHide.xs>
                    <div class="mt-16 map">
                        <current-location *ngIf="addressForm?.googleLocation" [location]="addressForm"
                            (positionChanged)="reposition($event)">
                        </current-location>


                        <!-- IF ADDRESS TYPE IS PINCODE-->
                        <ng-container *ngIf="addressForm?.addressType === 'PIN' && !addressForm?.googleLocation">

                            <div class="map-text" fxLayout="column" fxLayoutAlign="center center">
                                <div class="map-icon">
                                    <mat-icon>map</mat-icon>
                                </div>
                                <p class="content-text" fxLayout="column">
                                    <span translate>Please type pincode and search your location.</span>
                                    <!-- <span>to show map of your area</span> -->
                                </p>
                            </div>

                        </ng-container>

                        <!--IF ADDRESS TYPE IS GPS-->
                        <ng-container *ngIf="!addressForm?.googleLocation && addressForm?.addressType === 'GPS'">

                            <div class="map-text" fxLayout="column" fxLayoutAlign="center center">
                                <div class="map-icon">
                                    <mat-icon>map</mat-icon>
                                </div>
                                <div fxLayout="column">

                                    <p class="content-text" translate>Sorry! You have blocked location access.Please type and
                                        search your location.
                                    </p>

                                    <p class="content-text">To allow location access go to your browser settings
                                    </p>
                                </div>
                            </div>

                        </ng-container>
                    </div>

                    <ng-container *ngIf="addressForm?.googleLocation">
                        <p class="note" translate>
                            Drag location pointer on map to select another location
                        </p>
                    </ng-container>
                </div>

            </div>


        </div>
    
        <small class="mb-15 fw-700" *ngIf="userCreationProcessing">
            Please wait, we are creating a free account for you...
        </small>
        <small class="mb-15 fw-700 text-danger" *ngIf="!userCreationProcessing && userAlreadyExistsError">
            Mobile number used is already taken, please use different mobile number or try to login.
        </small>
    </div>
    <!-- <div class="action" fxLayout="row" fxLayoutGap="10px" fxFlexAlign="start">
        <button class="button-action" fxFlex mat-stroked-button color="primary" [disabled]="address?.invalid"
            [matTooltip]="address?.invalid ? 'Fill in all mandatory fields to enable this button': 'Save your address'">Save</button>
        <button class="button-action" fxFlex mat-button (click)="closeAddressForm(true)">Close</button>
    </div> -->
    <!-- Action buttons -->

    <div *ngIf="isGuestAddress" class="d-flex flex-column w-100">

        <div class="item-header text-uppercase mt-10">Contact details</div>

        <!-- 1st row -->
        <div fxLayout="column" fxLayoutGap.xs="0px" fxLayout.xs="column">
            <mat-form-field fxFlex appearance="outline" class="prefix-field">
                <!-- <mat-label translate>Name</mat-label> -->
                <input matInput [placeholder]="'Name' | translate" [(ngModel)]="addressForm.name" name="name" autocomplete="name"
                    required>
                    <img class="custom-prefix" src="assets/images/guest-checkout-icons/bx-user.svg" width="20">
            </mat-form-field>
            <mat-form-field class="prefix-field" fxFlex appearance="outline">
                <!-- <mat-label translate>Contact mobile number</mat-label> -->
                <input matInput [placeholder]="'Contact mobile number' | translate" [(ngModel)]="addressForm.phone" name="phone"
                    (keypress)="isNumber($event)" minlength="10" maxlength="10" required
                    autocomplete="tel-national">
                    <span class="custom-prefix">+91 |</span>
            </mat-form-field>
        </div>
    
        <mat-form-field class="prefix-field" fxFlex appearance="outline">
            <!-- <mat-label translate>Contact email address</mat-label> -->
            <input matInput [placeholder]="'Contact email address' | translate" [(ngModel)]="addressForm.email" name="email"
                autocomplete="email" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}">
                <img class="custom-prefix" src="assets/images/guest-checkout-icons/baseline-alternate-email.svg" width="20">
        </mat-form-field>
    
    
        <section class="w-100">
            <mat-checkbox class="example-margin" [(ngModel)]="isAccountForGuest" name="isAccountForGuest" labelPosition="after">
                Create an account for me
            </mat-checkbox>
        </section>

    </div>


    <div class="buttom-section bg-white" [class.fixed-bottom]="!isGuestAddress">
        <div class="pt-5 pb-15" fxLayout="column" fxLayoutGap="10px" [class.px-15]="!isGuestAddress">
            <div class="pt-0 action-buttons w-100" fxLayout="row" fxLayoutAlign="space-between center"
                fxLayoutGap="20px">

                <button class="w-100" mat-stroked-button color="primary"
                    (click)="cancelClicked.emit()" *ngIf="!isGuestAddress">{{'Cancel' | translate}}</button>
                <button class="w-100" mat-flat-button color="primary" [disabled]="address?.invalid || !addressForm?.addressLine1 || userCreationProcessing"
                    (click)="submitForm()" *ngIf="!isGuestAddress">{{'Save' | translate}}</button>

                    <div  *ngIf="isGuestAddress" class="w-100">
                        <kl-flat-button 
                            [label]="'CONTINUE'" 
                            [disabled]="address?.invalid || !addressForm?.addressLine1 || userCreationProcessing"
                            (clicked)="submitForm()">
                        </kl-flat-button>
                    </div>
            </div>
            <!--/ Action buttons -->
        </div>
    </div>
</form>